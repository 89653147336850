import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  useTheme,
} from "@mui/material";
import Audio from "@uppy/audio";
import Uppy from "@uppy/core";
import { Dashboard } from "@uppy/react";
import Transloadit from "@uppy/transloadit";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { TRANSLOADIT_AUTH_KEY } from "src/config/config";
import { useAppDispatch, useAppSelector } from "src/hooks/stateHooks";
import { selectPrimaryTrainer } from "src/slices/trainerSlice";
import { updateTrainer } from "src/slices/trainersSlice";

export function TrainerVoicemailGreetingButton() {
  const dispatch = useAppDispatch();
  const trainer = useAppSelector(selectPrimaryTrainer);
  const [open, setOpen] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [uppy] = useState(() =>
    new Uppy({
      restrictions: {
        maxNumberOfFiles: 1,
        allowedFileTypes: ["audio/*"],
      },
    })
      .use(Audio)
      .use(Transloadit, {
        assemblyOptions: {
          params: {
            auth: { key: TRANSLOADIT_AUTH_KEY },
            template_id: "0329b2c966ca4c2689ca7e9da230ecab",
          },
        },
        waitForEncoding: true,
      })
      .on("transloadit:complete", (assembly) => {
        if (
          "mp3_encoded" in assembly.results &&
          assembly.results["mp3_encoded"].length &&
          assembly.results["mp3_encoded"][0].ssl_url
        ) {
          const audioFile = assembly.results["mp3_encoded"][0];

          dispatch(
            updateTrainer({
              trainer_id: trainer!.trainer_id,
              voicemail_greeting_url: audioFile.ssl_url,
            }),
          )
            .unwrap()
            .catch(() => {
              enqueueSnackbar("Failed to upload", {
                variant: "error",
              });
            });
        }
      })
      .on("complete", () => {
        uppy.cancelAll();
        setOpen(false);

        enqueueSnackbar("Changed voicemail greeting", {
          variant: "success",
        });
      }),
  );
  const theme = useTheme();

  return (
    <>
      {trainer?.voicemail_greeting_url ? (
        <audio controls src={trainer.voicemail_greeting_url}></audio>
      ) : (
        <Typography>No voicemail greeting set</Typography>
      )}
      <Box sx={{ mt: 1 }}>
        <Button
          onClick={() => {
            setOpen(true);
          }}
        >
          {trainer?.voicemail_greeting_url ? "Change" : "Upload"} voicemail
          greeting
        </Button>
      </Box>
      <Dialog
        open={open}
        onClose={() => {
          setOpen(false);
        }}
      >
        <DialogTitle>Upload voicemail greeting</DialogTitle>
        <DialogContent>
          <Dashboard
            uppy={uppy}
            plugins={["Audio"]}
            proudlyDisplayPoweredByUppy={false}
            showLinkToFileUploadResult={false}
            height={"300px"}
            theme={theme.palette.mode}
          />
        </DialogContent>
        <DialogActions>
          <Button
            variant="text"
            onClick={() => {
              setOpen(false);
            }}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
