import BoltRoundedIcon from "@mui/icons-material/BoltRounded";
import { Avatar, type SxProps, type Theme } from "@mui/material";

type Props = {
  sx?: SxProps<Theme>;
};

export function CompletedWorkoutAndStepsIcon({ sx = [] }: Props) {
  return (
    <Avatar
      sx={{
        width: 18,
        height: 18,
        backgroundColor: (theme) => theme.palette.success.main,
        ...sx,
      }}
    >
      <BoltRoundedIcon sx={{ fontSize: 14 }} />
    </Avatar>
  );
}
