import { draggable } from "@atlaskit/pragmatic-drag-and-drop/element/adapter";
import { pointerOutsideOfPreview } from "@atlaskit/pragmatic-drag-and-drop/element/pointer-outside-of-preview";
import { setCustomNativeDragPreview } from "@atlaskit/pragmatic-drag-and-drop/element/set-custom-native-drag-preview";
import CalendarViewWeekRoundedIcon from "@mui/icons-material/CalendarViewWeekRounded";
import FitnessCenterRoundedIcon from "@mui/icons-material/FitnessCenterRounded";
import SpeakerNotesRoundedIcon from "@mui/icons-material/SpeakerNotesRounded";
import {
  Avatar,
  Box,
  CardActionArea,
  Tooltip,
  Typography,
  alpha,
} from "@mui/material";
import { format } from "date-fns";
import { memo, useEffect, useRef, useState } from "react";
import { createPortal } from "react-dom";
import { useNavigate, useParams } from "react-router";
import { DragPreview } from "src/components/common/DragPreview";
import { useAppSelector } from "src/hooks/stateHooks";
import { selectHabitTaskById } from "src/slices/clientSlice";
import { selectPhaseById, selectWorkoutById } from "src/slices/phasesSlice";
import WorkoutPreviewPopover from "../phase-column/WorkoutPreviewPopover";

type DraggableProps = {
  habitTaskId: string;
  isLast?: boolean;
};

export default function WorkoutTaskCellPastDraggable({
  habitTaskId,
  isLast,
}: DraggableProps) {
  const habitTaskWorkout = useAppSelector((state) =>
    selectHabitTaskById(state, habitTaskId),
  );
  const phaseType = useAppSelector(
    (state) =>
      selectPhaseById(
        state,
        selectWorkoutById(state, habitTaskWorkout?.workout_id ?? "")
          ?.phase_id ?? "",
      )?.type,
  );
  const ref = useRef(null);
  const [dragging, setDragging] = useState<boolean>(false);
  const [previewContainer, setPreviewContainer] = useState<HTMLElement | null>(
    null,
  );

  useEffect(() => {
    const element = ref.current;

    if (!element) {
      return;
    }

    const data = {
      type: "workout_task_past",
      workoutId: habitTaskWorkout?.workout_id,
      habitTaskId: habitTaskId,
      isSouce: true,
      phaseType: phaseType,
    };

    return draggable({
      element: element,
      getInitialData: () => data,
      onDragStart: () => setDragging(true),
      onDrop: () => setDragging(false),
      onGenerateDragPreview({ nativeSetDragImage }) {
        setCustomNativeDragPreview({
          nativeSetDragImage,
          getOffset: pointerOutsideOfPreview({
            x: "16px",
            y: "8px",
          }),
          render({ container }) {
            setPreviewContainer(container);
          },
        });
      },
    });
  }, [habitTaskWorkout?.workout_id, habitTaskId, phaseType]);

  return (
    <>
      <div
        style={{
          position: "relative",
        }}
      >
        <div
          ref={ref}
          style={{
            opacity: dragging ? 0.5 : 1,
          }}
        >
          <WorkoutTaskCellPastContent
            habitTaskId={habitTaskId}
            isLast={isLast}
          />
        </div>
      </div>
      {previewContainer
        ? createPortal(
            <DragPreview text={habitTaskWorkout?.name ?? "Workout"} />,
            previewContainer,
          )
        : null}
    </>
  );
}

type Props = {
  habitTaskId: string;
  isLast?: boolean;
};

const WorkoutTaskCellPastContent = memo(function WorkoutTaskCellPastContent({
  habitTaskId,
  isLast,
}: Props) {
  const { userId } = useParams();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const habitTask = useAppSelector((state) =>
    selectHabitTaskById(state, habitTaskId),
  );
  const dateActuallyCompletedOn = useAppSelector((state) =>
    habitTask?.habit_task_id_completed
      ? selectHabitTaskById(state, habitTask.habit_task_id_completed)
          ?.date_completed
      : undefined,
  );
  const phase = useAppSelector((state) =>
    selectPhaseById(
      state,
      selectWorkoutById(state, habitTask?.workout_id ?? "")?.phase_id ?? "",
    ),
  );

  if (!habitTask) {
    return <Typography>Error loading habit task</Typography>;
  }

  return (
    <>
      <Tooltip
        title={
          habitTask.habit_task_id_completed
            ? `Completed on ${
                dateActuallyCompletedOn
                  ? format(
                      new Date(dateActuallyCompletedOn as string),
                      "MMM do",
                    )
                  : "different day"
              }`
            : (habitTask.notes_client ?? "")
        }
        disableInteractive
      >
        <CardActionArea
          onClick={(event) => {
            if (habitTask.workout_log_id) {
              navigate(`/clients/${userId}/logs/${habitTask.workout_log_id}`);
            } else if (habitTask.workout_id) {
              navigate(`/clients/${userId}/workouts/${habitTask.workout_id}`);
            } else {
              setAnchorEl(event.currentTarget);
            }
          }}
          onContextMenu={(event) => {
            event.preventDefault();

            setAnchorEl(event.currentTarget);
          }}
          sx={{
            opacity: habitTask.habit_task_id_completed ? 0.5 : undefined,
            backgroundColor: (theme) =>
              habitTask.date_completed || habitTask.habit_task_id_completed
                ? alpha(theme.palette.blue.main, 0.1)
                : theme.palette.background.paper,
            p: 0.5,
            borderBottom: isLast ? 0 : 1,
            borderColor: "divider",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Box>
            {phase?.type === "multiple" && (
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Avatar
                  sx={{
                    backgroundColor: (theme) => theme.palette.success.main,
                    mr: 0.25,
                    width: 8,
                    height: 8,
                    borderRadius: "2px",
                    opacity: 0.75,
                  }}
                >
                  <CalendarViewWeekRoundedIcon
                    sx={{
                      fontSize: 6,
                    }}
                  />
                </Avatar>
                <Typography
                  sx={{
                    fontSize: 9,
                    lineHeight: 1,
                    color: (theme) => theme.palette.text.secondary,
                  }}
                >
                  {phase.name}
                </Typography>
              </Box>
            )}
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <Avatar
                sx={{
                  backgroundColor: (theme) =>
                    habitTask.date_completed
                      ? theme.palette.blue.main
                      : theme.palette.text.disabled,
                  mr: 0.5,
                  width: 15,
                  height: 15,
                  borderRadius: "4px",
                }}
              >
                <FitnessCenterRoundedIcon
                  sx={{
                    fontSize: 12,
                  }}
                />
              </Avatar>
              <Typography variant="body2">{habitTask.name}</Typography>
            </Box>
          </Box>
          {habitTask.notes_client && (
            <SpeakerNotesRoundedIcon sx={{ fontSize: "inherit" }} />
          )}
        </CardActionArea>
      </Tooltip>
      {habitTask.workout_id
        ? anchorEl && (
            <WorkoutPreviewPopover
              anchorEl={anchorEl}
              workoutId={habitTask.workout_id}
              onClose={() => {
                setAnchorEl(null);
              }}
            />
          )
        : null}
    </>
  );
});
