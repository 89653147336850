export function getTrainerName(
  trainerID: string,
  trainerNames: { trainer_id: string; full_name: string }[],
): string {
  const index = trainerNames.findIndex(
    (trainer) => trainer.trainer_id === trainerID,
  );

  if (index === -1) {
    return "Trainer not found";
  } else {
    return trainerNames[index].full_name;
  }
}

export function getTrainerTimezone(
  trainerID: string,
  coachTimezones: { trainer_id: string; timezone?: string }[],
): string {
  const index = coachTimezones.findIndex(
    (trainer) => trainer.trainer_id === trainerID,
  );

  if (index === -1) {
    return "America/New_York";
  } else {
    return coachTimezones[index].timezone ?? "America/New_York";
  }
}
