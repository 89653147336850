import CircleRoundedIcon from "@mui/icons-material/CircleRounded";
import { Box, Paper, Popover, Typography, useTheme } from "@mui/material";
import { ScatterChart } from "@mui/x-charts";
import type {
  CoachMetric,
  CoachMetricTemplateSubmodule,
} from "@trainwell/types";
import React, { useState } from "react";

const styles: Record<string, React.CSSProperties> = {
  headshot: {
    height: "24px",
    width: "24px",
    borderRadius: "25px",
  },
  trainerName: {
    fontSize: "17px",
    marginLeft: "0.5em",
  },
  horizStack: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
    margin: "1em",
    width: "auto",
  },
};
const rankColors = {
  gold: "rgba(212, 175, 55, 1)",
  silver: "rgba(170, 169, 173, 1)",
  bronze: "rgba(176, 141, 87, 1)",
};

type Props = {
  sortedSubmoduleMetrics: any;
  metrics: any;
  submodule: CoachMetricTemplateSubmodule;
};

export default function Ranking({
  sortedSubmoduleMetrics,
  metrics,
  submodule,
}: Props) {
  const theme = useTheme();
  const [rankingAnchorEl, setRankingAnchorEl] = useState<HTMLDivElement | null>(
    null,
  );
  const openRanking = Boolean(rankingAnchorEl);
  const [trainers, setTrainers] = useState<CoachMetric[]>([]);
  const [tick, setTick] = useState(null);
  const openTick = Boolean(tick);

  function handleOpenRanking(e: React.MouseEvent<HTMLDivElement, MouseEvent>) {
    setRankingAnchorEl(e.currentTarget);
  }

  function handleCloseRanking(e: React.MouseEvent<HTMLDivElement, MouseEvent>) {
    setRankingAnchorEl(null);
  }

  function handleCloseTick() {
    console.log("Close");
    setTrainers([]);
    setTick(null);
  }

  if (metrics.trainer_id === "deltatrainer") {
    return null;
  }

  const ordinalSuffixOf = (i: number) => {
    const j = i % 10,
      k = i % 100;
    if (j === 1 && k !== 11) {
      return i + "st";
    }
    if (j === 2 && k !== 12) {
      return i + "nd";
    }
    if (j === 3 && k !== 13) {
      return i + "rd";
    }
    return i + "th";
  };

  const [sortedSubmoduleMetric] = sortedSubmoduleMetrics.filter(
      (metricsObj: any) => metricsObj.title === submodule.metric_title,
    ),
    sortedMetrics = sortedSubmoduleMetric.metrics;
  let personalFormattedRank: any;
  let personalMetric: any;

  const sortedNames = sortedMetrics.map((metricsObj: any, i: number) => {
    let circleColor = "rgba(0, 0, 0, 1)";
    const index = sortedMetrics.indexOf(metricsObj);
    if (index > 2 && metricsObj.trainer.trainer_id !== metrics.trainer_id) {
      return undefined;
    }
    if (index === 0) {
      circleColor = rankColors.gold;
    }
    if (index === 1) {
      circleColor = rankColors.silver;
    }
    if (index === 2) {
      circleColor = rankColors.bronze;
    }
    let hLine;
    if (index > 2) {
      hLine = (
        <div
          style={{
            borderTop: "3px solid rgba(0, 0, 0, 1)",
            width: "100%",
            textAlign: "center",
            margin: "8px 0",
          }}
        ></div>
      );
    }

    const formattedRank = ordinalSuffixOf(index + 1);

    let metric = metricsObj.metric;
    if (metricsObj.trainer.trainer_id === metrics.trainer_id) {
      personalFormattedRank = formattedRank;
      personalMetric = metric;
    }

    if (submodule.is_percent) {
      metric = metricsObj.metric + "%";
    }

    if (metricsObj.trainer.trainer_id === metrics.trainer_id) {
      personalFormattedRank = formattedRank;
      personalMetric = metric;
    }

    return (
      <React.Fragment key={i}>
        {hLine}
        <div
          style={{
            display: "flex",
            flexFlow: "row wrap",
            alignItems: "center",
            margin: "8px 0",
            width: "100%",
            justifyContent: "space-between",
          }}
        >
          <div style={{ width: "5%", textAlign: "start" }}>
            <CircleRoundedIcon
              style={{ fontSize: "12px", color: circleColor }}
            />
          </div>
          <div style={{ width: "15%", textAlign: "center" }}>
            <Typography>{formattedRank}</Typography>
          </div>
          <div style={{ width: "10%", textAlign: "center" }}>
            <img
              src={metricsObj.trainer.headshot_url}
              style={styles.headshot}
              alt=""
            />
          </div>
          <div style={{ width: "55%", textAlign: "start" }}>
            <Typography>{metricsObj.trainer.full_name}</Typography>
          </div>
          <div style={{ width: "10%", textAlign: "end" }}>
            <Typography>{metric}</Typography>
          </div>
        </div>
      </React.Fragment>
    );
  });

  const chartData: {
    x: number;
    y: number;
    color: string;
    trainerName: string;
  }[] = sortedMetrics.map((metricsObj: any) => {
    const metric = metricsObj.metric,
      index = sortedMetrics.indexOf(metricsObj),
      standard = {
        x: metric,
        y: 0,
        color: "rgba(0, 0, 0, 0.5)",
        trainerName: metricsObj.trainer.full_name,
      };

    if (index > 2 && metricsObj.trainer.trainer_id !== metrics.trainer_id) {
      return standard;
    } else if (index === 0) {
      return { ...standard, color: rankColors.gold };
    } else if (index === 1) {
      return { ...standard, color: rankColors.silver };
    } else if (index === 2) {
      return { ...standard, color: rankColors.bronze };
    }

    return { ...standard, color: "rgba(0, 0, 0, 1)" };
  });

  let reversed = false;
  if (!submodule.rank_low_to_high) {
    reversed = true;
  }

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: (theme) => theme.palette.backgroundSecondary.main,
          flexGrow: 1,
          margin: "0 2px 0 2px",
          borderRadius: "4px",
        }}
        aria-owns={openRanking ? "mouse-over-popover" : undefined}
        aria-haspopup="true"
        onMouseEnter={handleOpenRanking}
        onMouseLeave={handleCloseRanking}
      >
        <Typography
          style={{ fontSize: "11px", cursor: "default", pointerEvents: "none" }}
        >
          {personalFormattedRank}
        </Typography>
        <Popover
          open={openRanking}
          anchorEl={rankingAnchorEl}
          sx={{
            pointerEvents: "none",
          }}
          PaperProps={{
            sx: {
              pointerEvents: "auto",
            },
          }}
          onClose={() => {
            setRankingAnchorEl(null);
          }}
          disableRestoreFocus
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
        >
          <Paper
            sx={{
              maxHeight: "500px",
              width: "350px",
              padding: 3,
              display: "flex",
              flexFlow: "column nowrap",
              alignItems: "center",
            }}
          >
            <Typography variant="h3" sx={{ mb: 1 }}>
              {submodule.metric_title}
            </Typography>
            <Typography style={{ fontSize: "18px" }}>
              {personalMetric}
            </Typography>
            <Typography style={{ fontSize: "11px" }}>
              ({personalFormattedRank} out of {sortedMetrics.length})
            </Typography>
            <ScatterChart
              width={350}
              height={100}
              colors={[theme.palette.primary.main]}
              xAxis={[
                {
                  reverse: reversed,
                  valueFormatter: (value) => {
                    if (submodule.is_percent) {
                      return `${value}%`;
                    }
                    return value.toString();
                  },
                },
              ]}
              series={chartData.map((d) => {
                return {
                  type: "scatter",
                  data: [
                    {
                      x: d.x,
                      y: 0,
                      id: d.trainerName,
                    },
                  ],
                  color: d.color,
                  label: d.trainerName,
                  valueFormatter: (value) => value.x.toString(),
                };
              })}
              slotProps={{
                legend: {
                  hidden: true,
                },
              }}
            />
            {sortedNames}
          </Paper>
        </Popover>
      </Box>
      <Popover
        open={openTick}
        anchorEl={tick}
        onClose={() => {
          handleCloseTick();
        }}
        sx={{
          pointerEvents: "none",
        }}
        disableRestoreFocus
      >
        <Paper sx={{ p: 0.5 }}>
          <Typography style={{ fontSize: "17px", textAlign: "center" }}>
            {/* @ts-expect-error */}
            {trainers.length > 0 ? trainers[0].metric : null}
          </Typography>
          <Typography style={{ fontSize: "12px" }}>
            {trainers.map((trainer, i) => {
              return (
                <div style={styles.horizStack} key={i}>
                  <img
                    src={trainer.trainer.headshot_url}
                    style={styles.headshot}
                    alt=""
                  />
                  <Typography style={styles.trainerName}>
                    {trainer.trainer.full_name}
                  </Typography>
                </div>
              );
            })}
          </Typography>
        </Paper>
      </Popover>
    </>
  );
}
