import ArrowBackRoundedIcon from "@mui/icons-material/ArrowBackRounded";
import { Box, Button, Card, Typography, useTheme } from "@mui/material";
import Grid from "@mui/material/Grid2";
import AutosaveTextField from "src/components/misc/AutosaveTextField";
import ControlBar from "src/components/misc/ControlBar";
import { useAppDispatch, useAppSelector } from "src/hooks/stateHooks";
import { selectPrimaryTrainer } from "src/slices/trainerSlice";
import { updateTrainer } from "src/slices/trainersSlice";

export default function MessageTemplatesPage() {
  const dispatch = useAppDispatch();
  const trainer = useAppSelector(selectPrimaryTrainer);
  const theme = useTheme();

  if (!trainer) {
    return null;
  }

  return (
    <Box>
      <ControlBar
        leftContent={
          <Button
            variant="contained"
            href="/"
            startIcon={<ArrowBackRoundedIcon />}
            size="small"
          >
            Back
          </Button>
        }
      />
      <Box sx={{ p: 3 }}>
        <Typography variant="h1" sx={{ mb: 4 }}>
          Message templates
        </Typography>
        <Grid
          container
          spacing={4}
          alignItems={"center"}
          maxWidth={600}
          sx={{ mb: 4 }}
        >
          <Grid
            size={{
              xs: 4,
            }}
          >
            <img
              src="/assets/intro-message.jpeg"
              width={"100%"}
              style={{
                borderRadius: 12,
                maxWidth: 250,
                border: `1px solid ${theme.palette.divider}`,
              }}
            />
          </Grid>
          <Grid
            size={{
              xs: 8,
            }}
          >
            <Typography>
              From your first introduction to their one year check in call,
              message templates give you the power to personalize
              trainwell&apos;s messages.
              <br />
              <br />
              Be silly. Be serious. Be slick. Be you 🤪
            </Typography>
          </Grid>
        </Grid>
        <Card variant="outlined" sx={{ p: 2, mb: 4 }}>
          <Typography variant="h2" sx={{ mb: 2 }}>
            Intro message
          </Typography>
          <Typography sx={{ mb: 4 }}>
            Give a quick welcome and introduction 👋 then start a conversation
            with your welcome survey questions.
            <br />
            <br />
            We typically recommend 1-3 questions for approachability.
            <br />
            <br />
            Also make sure there&apos;s no header to this message, just start
            with a greeting like you are messaging someone for the first time!
          </Typography>
          <AutosaveTextField
            defaultValue={trainer.message_templates.intro_message ?? ""}
            disableGutter
            onSave={(newValue) => {
              dispatch(
                updateTrainer({
                  trainer_id: trainer.trainer_id,
                  // @ts-expect-error
                  "message_templates.intro_message": newValue,
                }),
              );
            }}
            sanitizeValue={(value) => {
              // Remove non-ascii characters
              // https://stackoverflow.com/questions/20856197/remove-non-ascii-character-in-string
              // eslint-disable-next-line no-control-regex
              return value.replace(/[^\x00-\x7F]/g, "");
            }}
            helperText="This messages currently does not support emojis"
          />
        </Card>
        <Card variant="outlined" sx={{ p: 2, mb: 4 }}>
          <Typography variant="h2" sx={{ mb: 2 }}>
            Client switched trainer
          </Typography>
          <Typography sx={{ mb: 4 }}>
            Automatically sent when a client switches trainers to you 👋. Sent
            both as sms and in the trainwell app. If a client switches to you
            before they&apos;re onboarded, your Intro Message is sent instead.
            <br />
            <br />
            This is often similar to your Intro Message. Introduce yourself and
            ask a few starter questions.
          </Typography>
          <AutosaveTextField
            defaultValue={
              trainer.message_templates.client_switched_trainer ?? ""
            }
            disableGutter
            onSave={(newValue) => {
              dispatch(
                updateTrainer({
                  trainer_id: trainer.trainer_id,
                  // @ts-expect-error
                  "message_templates.client_switched_trainer": newValue,
                }),
              );
            }}
            sanitizeValue={(value) => {
              // Remove non-ascii characters
              // https://stackoverflow.com/questions/20856197/remove-non-ascii-character-in-string
              // eslint-disable-next-line no-control-regex
              return value.replace(/[^\x00-\x7F]/g, "");
            }}
            helperText="This messages currently does not support emojis"
          />
        </Card>
        <Card variant="outlined" sx={{ p: 2, mb: 4 }}>
          <Typography variant="h2" sx={{ mb: 2 }}>
            Client returned from cancel
          </Typography>
          <Typography sx={{ mb: 4 }}>
            Automatically sent when a client returns from being cancelled
            🎉🎉🎉. This is NOT sent for clients that are active and decide to
            change their mind about cancelling, only for clients that go from
            fully cancelled to active.
            <br />
            <br />
            This is a good moment to ask what brings the client back and set
            expectations for next steps.
          </Typography>
          <AutosaveTextField
            defaultValue={
              trainer.message_templates.client_returned_from_cancel ?? ""
            }
            disableGutter
            onSave={(newValue) => {
              dispatch(
                updateTrainer({
                  trainer_id: trainer.trainer_id,
                  // @ts-expect-error
                  "message_templates.client_returned_from_cancel": newValue,
                }),
              );
            }}
          />
        </Card>
        <Card variant="outlined" sx={{ p: 2, mb: 4 }}>
          <Typography variant="h2" sx={{ mb: 2 }}>
            Check in call scheduled
          </Typography>
          <Typography sx={{ mb: 4 }}>
            Automatically sent when a client schedules a check in call 🤙. Sent
            in the trainwell app.
            <br />
            <br />
            Ask some questions to give you a head start on the call.
          </Typography>
          <AutosaveTextField
            disableGutter
            defaultValue={trainer.pre_call_survey_checkin}
            onSave={(newValue) => {
              dispatch(
                updateTrainer({
                  trainer_id: trainer.trainer_id,
                  pre_call_survey_checkin: newValue,
                }),
              );
            }}
          />
        </Card>
        <Card variant="outlined" sx={{ p: 2, mb: 4 }}>
          <Typography variant="h2" sx={{ mb: 2 }}>
            Pop-ups
          </Typography>
          <Typography sx={{ mb: 4 }}>
            These messages are sent after you take an action (ex. finishing
            onboarding) and are <b>not</b> sent automatically.
            <br />
            <br />A popup will let you change the message or choose not to send
            the message.
          </Typography>
          <AutosaveTextField
            label="Finished onboarding call"
            defaultValue={
              trainer.message_templates.client_finished_onboarding_call
            }
            onSave={(newValue) => {
              dispatch(
                updateTrainer({
                  trainer_id: trainer.trainer_id,
                  // @ts-expect-error
                  "message_templates.client_finished_onboarding_call": newValue,
                }),
              );
            }}
          />
          <AutosaveTextField
            label="Missed onboarding call"
            defaultValue={
              trainer.message_templates.client_missed_onboarding_call
            }
            onSave={(newValue) => {
              dispatch(
                updateTrainer({
                  trainer_id: trainer.trainer_id,
                  // @ts-expect-error
                  "message_templates.client_missed_onboarding_call": newValue,
                }),
              );
            }}
          />
          <AutosaveTextField
            label="Check in survey action item"
            helperText="The system will automatically add a link to the check-in survey at the bottom of this message."
            disableGutter
            defaultValue={trainer.message_templates.check_in_survey}
            onSave={(newValue) => {
              dispatch(
                updateTrainer({
                  trainer_id: trainer.trainer_id,
                  // @ts-expect-error
                  "message_templates.check_in_survey": newValue,
                }),
              );
            }}
          />
        </Card>
      </Box>
    </Box>
  );
}
