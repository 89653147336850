import WorkoutBuilderDialog from "src/features/workout-builder/WorkoutBuilderDialog";
import { useAppDispatch, useAppSelector } from "src/hooks/stateHooks";
import { closeWorkoutBuilder } from "src/slices/workoutBuilderSlice";

export function GlobalTemplateDialog() {
  const dispatch = useAppDispatch();
  const workoutTemplateId = useAppSelector(
    (state) => state.workoutBuilder.workoutTemplateId,
  );

  if (workoutTemplateId) {
    return (
      <WorkoutBuilderDialog
        open
        onClose={() => {
          dispatch(closeWorkoutBuilder());
        }}
        templateId={workoutTemplateId}
      />
    );
  }

  return null;
}
