import { Card, CardActionArea, useTheme } from "@mui/material";
import type { Client } from "@trainwell/types";
import { isFuture } from "date-fns";
import { useNavigate } from "react-router";
import ClientName from "src/features/client/ClientName";
import { useAppSelector } from "src/hooks/stateHooks";
import { selectClientById } from "src/slices/clientsSlice";

type Props = {
  userId: string;
};

export default function ClientGridCell({ userId }: Props) {
  const navigate = useNavigate();
  const theme = useTheme();
  const client = useAppSelector((state) =>
    selectClientById(state, userId),
  ) as Client;
  const clientInfo = useAppSelector((state) => state.clients.clientInfo);

  let color = theme.palette.errorSurface.main;

  if (!client.account.dashboard.date_onboarded) {
    color = theme.palette.blueSurface.main;
  } else if (
    client.account.plan.date_trial_end &&
    isFuture(new Date(client.account.plan.date_trial_end))
  ) {
    color = theme.palette.purpleSurface.main;
  } else if (clientInfo[userId]?.weeks >= 2) {
    color = theme.palette.successSurface.main;
  } else if (clientInfo[userId]?.weeks >= 1) {
    color = theme.palette.background.paper;
  }

  return (
    <Card
      variant="outlined"
      sx={{
        mb: 1,
        backgroundColor: color,
      }}
    >
      <CardActionArea
        onClick={() => {
          navigate("/clients/" + userId);
        }}
        sx={{ py: 1, px: 2 }}
      >
        <ClientName userId={client.user_id} />
      </CardActionArea>
    </Card>
  );
}
