import useAnalytics from "src/hooks/useAnalytics";
import useNotificationListener from "src/hooks/useNotificationListener";
import useSocket from "src/hooks/useSocket";

export default function AppHooks() {
  useAnalytics();
  useSocket();
  useNotificationListener();

  return null;
}
