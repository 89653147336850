import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import { Button, Popover, Typography } from "@mui/material";
import type { HabitTask } from "@trainwell/types";
import { format } from "date-fns";
import Label from "src/components/misc/Label";
import { useAppDispatch } from "src/hooks/stateHooks";
import { deleteHabitTask } from "src/slices/clientSlice";
import HabitTaskHistory from "./HabitTaskHistory";

type Props = {
  habitTask: HabitTask;
  anchorEl: HTMLElement | null;
  onClose: () => void;
};

export default function HabitTaskPopover({
  habitTask,
  anchorEl,
  onClose,
}: Props) {
  const dispatch = useAppDispatch();
  const open = Boolean(anchorEl);

  return (
    <Popover
      open={open}
      anchorEl={anchorEl}
      onClose={() => {
        onClose();
      }}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      slotProps={{
        paper: {
          sx: {
            p: 2,
            width: "250px",
          },
        },
      }}
    >
      <Typography
        variant="h3"
        sx={{ textAlign: "center", mb: 1, fontWeight: "bold" }}
      >
        {habitTask.name}
      </Typography>
      {!habitTask.date_completed && (
        <Typography
          sx={{
            textAlign: "center",
            mb: 1,
            color: (theme) => theme.palette.text.secondary,
          }}
        >
          {habitTask.dates_steps_completed &&
          habitTask.dates_steps_completed.some((date) => Boolean(date))
            ? "Partially complete"
            : "Incomplete"}
        </Typography>
      )}
      <HabitTaskHistory habitTask={habitTask} />
      {habitTask.date_completed && (
        <Label
          label="Date completed"
          content={format(
            new Date(habitTask.date_completed),
            "MMMM do, yyyy h:mm aaa",
          )}
        />
      )}
      {habitTask.dates_steps_completed && (
        <Label
          label="Steps completed"
          content={`${
            habitTask.dates_steps_completed.filter(Boolean).length
          } / ${habitTask.dates_steps_completed.length}`}
        />
      )}
      {habitTask.notes_client && (
        <Label label="Client notes" content={habitTask.notes_client} />
      )}
      {habitTask.type === "movement" && (
        <Button
          size="small"
          variant="text"
          color="error"
          startIcon={<DeleteRoundedIcon />}
          onClick={() => {
            dispatch(deleteHabitTask(habitTask.id));
          }}
        >
          Delete
        </Button>
      )}
    </Popover>
  );
}
