import ArrowBackRoundedIcon from "@mui/icons-material/ArrowBackRounded";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
} from "@mui/material";
import { useCompany } from "@trainwell/features";
import { useState } from "react";
import ControlBar from "src/components/misc/ControlBar";
import { DialogTitleWithClose } from "src/components/misc/DialogTitleWithClose";
import MetricsPage from "src/features/trainer-metrics/MetricsPage";
import { useAppSelector } from "src/hooks/stateHooks";
import { selectPrimaryTrainer } from "src/slices/trainerSlice";

export default function CoachMetricsPage() {
  const trainer = useAppSelector(selectPrimaryTrainer);
  const { data: company } = useCompany();
  const [dismissedSoftDisable, setDismissedSoftDisable] = useState(false);

  if (!trainer) {
    return null;
  }

  if (company?.soft_disable_metrics && !dismissedSoftDisable) {
    return (
      <Dialog
        open
        onClick={() => {
          setDismissedSoftDisable(true);
        }}
      >
        <DialogTitleWithClose
          onClose={() => {
            setDismissedSoftDisable(true);
          }}
        >
          Take a moment
        </DialogTitleWithClose>
        <DialogContent>
          <DialogContentText>
            This is a surge, and surges can be stressful. When times get
            stressful, it&apos;s ok to check the &quot;score&quot;, but as a
            company we are much more focused on nailing our &quot;plays&quot;.
            We believe in
            <ol>
              <li>Purposes over Metrics</li>
              <li>Quality over Speed</li>
            </ol>
            We hope this gives you peace of mind.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setDismissedSoftDisable(true);
            }}
          >
            Got it
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  return (
    <Box
      sx={{
        height: "100vh",
        overflowY: "auto",
      }}
    >
      <ControlBar
        leftContent={
          <Button
            variant="contained"
            href="/"
            startIcon={<ArrowBackRoundedIcon />}
            size="small"
          >
            Back
          </Button>
        }
      />
      <MetricsPage trainerId={trainer.trainer_id} />
    </Box>
  );
}
