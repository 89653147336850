import HotelRoundedIcon from "@mui/icons-material/HotelRounded";
import PauseCircleRoundedIcon from "@mui/icons-material/PauseCircleRounded";
import QuestionMarkRoundedIcon from "@mui/icons-material/QuestionMarkRounded";
import RemoveCircleRoundedIcon from "@mui/icons-material/RemoveCircleRounded";
import SwitchRightRoundedIcon from "@mui/icons-material/SwitchRightRounded";
import VisibilityOffRoundedIcon from "@mui/icons-material/VisibilityOffRounded";
import type { CoachNotification } from "@trainwell/types";

type CoachNotificationDetails = {
  name: string;
  notes?: string;
  icon: JSX.Element;
};

export function getCoachNotificationDetails(
  coachNotification: CoachNotification,
): CoachNotificationDetails {
  switch (coachNotification.type) {
    case "switched_coach_away":
      return {
        name: "Switched away from you",
        notes: coachNotification.notes,
        icon: (
          <SwitchRightRoundedIcon
            color={coachNotification.date_read ? "disabled" : "primary"}
          />
        ),
      };

    case "client_cancelled":
      return {
        name: "Cancelled",
        notes: coachNotification.notes,
        icon: (
          <RemoveCircleRoundedIcon
            color={coachNotification.date_read ? "disabled" : "primary"}
          />
        ),
      };

    case "client_paused":
      return {
        name: "Paused",
        notes: coachNotification.notes,
        icon: (
          <PauseCircleRoundedIcon
            color={coachNotification.date_read ? "disabled" : "primary"}
          />
        ),
      };

    case "client_hidden":
      return {
        name: "Hidden",
        notes: coachNotification.notes,
        icon: (
          <VisibilityOffRoundedIcon
            color={coachNotification.date_read ? "disabled" : "primary"}
          />
        ),
      };

    case "inactive_client":
      return {
        name: "Inactive",
        notes: coachNotification.notes,
        icon: (
          <HotelRoundedIcon
            color={coachNotification.date_read ? "disabled" : "primary"}
          />
        ),
      };

    default:
      return {
        name: "Unknown",
        notes: "The dash doesn't know how to display this action item",
        icon: (
          <QuestionMarkRoundedIcon
            color={coachNotification.date_read ? "disabled" : "primary"}
          />
        ),
      };
  }
}
