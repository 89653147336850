import { useMediaQuery, useTheme } from "@mui/material";
import { Navigate } from "react-router";
import ControlBar from "src/components/misc/ControlBar";
import ClientSelector from "./ClientSelector";

export default function HomePage() {
  const theme = useTheme();
  const isPhone = useMediaQuery(theme.breakpoints.down("sm"));

  if (isPhone) {
    return <Navigate to="/chat?pwa=true" />;
  }

  console.log("Render: home");

  return (
    <>
      <ControlBar />
      <main>
        <ClientSelector />
      </main>
    </>
  );
}
