import { draggable } from "@atlaskit/pragmatic-drag-and-drop/element/adapter";
import { disableNativeDragPreview } from "@atlaskit/pragmatic-drag-and-drop/element/disable-native-drag-preview";
import { preventUnhandled } from "@atlaskit/pragmatic-drag-and-drop/prevent-unhandled";
import type { DragLocationHistory } from "@atlaskit/pragmatic-drag-and-drop/types";
import DragIndicatorRoundedIcon from "@mui/icons-material/DragIndicatorRounded";
import { Box, Button, IconButton, Paper, useTheme } from "@mui/material";
import Uppy from "@uppy/core";
import { Dashboard, useUppyEvent } from "@uppy/react";
import ScreenCapture from "@uppy/screen-capture";
import Transloadit from "@uppy/transloadit";
import Webcam from "@uppy/webcam";
import { useSnackbar } from "notistack";
import { memo, useEffect, useRef, useState } from "react";
import { TRANSLOADIT_AUTH_KEY } from "src/config/config";
import { useAppDispatch, useAppSelector } from "src/hooks/stateHooks";
import { useWindowDimensions } from "src/hooks/useWindowDimensions";
import {
  selectSelectedChat,
  sendImageMessage,
  sendVideoMessage,
  setMediaUploadUi,
} from "src/slices/chatSlice";

type Coordinates = {
  x: number;
  y: number;
};

export function ChatMediaUpload() {
  const ref = useRef<HTMLDivElement>(null);
  const dragHandleRef = useRef<HTMLButtonElement>(null);
  const [dragging, setDragging] = useState<boolean>(false);
  const windowDimensions = useWindowDimensions();
  const [initialCoords, setInitialCoords] = useState<Coordinates>({
    x: windowDimensions.width / 2 - 200,
    y: 0,
  });

  useEffect(() => {
    const element = ref.current;
    const dragHandle = dragHandleRef.current;

    if (!element || !dragHandle) {
      return;
    }

    const maxX = windowDimensions.width - ref.current.clientWidth;
    const maxY = windowDimensions.height - ref.current.clientHeight;

    return draggable({
      element: dragHandle,
      onDragStart: ({ location }) => {
        const newLocation = getProposedCoords({
          initialCoords: {
            x: initialCoords.x,
            y: initialCoords.y,
          },
          location,
          maxX,
          maxY,
        });

        ref.current?.style.setProperty(
          "--local-translating-x",
          `${newLocation.x}px`,
        );
        ref.current?.style.setProperty(
          "--local-translating-y",
          `${newLocation.y}px`,
        );

        setDragging(true);
      },
      onDrag: ({ location }) => {
        const newLocation = getProposedCoords({
          initialCoords: {
            x: initialCoords.x,
            y: initialCoords.y,
          },
          location,
          maxX,
          maxY,
        });

        ref.current?.style.setProperty(
          "--local-translating-x",
          `${newLocation.x}px`,
        );
        ref.current?.style.setProperty(
          "--local-translating-y",
          `${newLocation.y}px`,
        );
      },
      onDrop: ({ location }) => {
        preventUnhandled.stop();

        const newCoords = getProposedCoords({
          initialCoords: {
            x: initialCoords.x,
            y: initialCoords.y,
          },
          location,
          maxX,
          maxY,
        });

        setInitialCoords(newCoords);

        setDragging(false);

        ref.current?.style.removeProperty("--local-translating-x");
        ref.current?.style.removeProperty("--local-translating-y");
      },
      onGenerateDragPreview: ({ nativeSetDragImage }) => {
        // we will be moving the line to indicate a drag
        // we can disable the native drag preview
        disableNativeDragPreview({ nativeSetDragImage });
        // we don't want any native drop animation for when the user
        // does not drop on a drop target. we want the drag to finish immediately
        preventUnhandled.start();
      },
    });
  }, [
    initialCoords.x,
    initialCoords.y,
    windowDimensions.width,
    windowDimensions.height,
  ]);

  return (
    <Paper
      ref={ref}
      sx={{
        "--local-initial-x": initialCoords.x,
        "--local-initial-y": initialCoords.y,
        height: 400,
        width: 400,
        position: "absolute",
        top: dragging ? "var(--local-translating-y)" : initialCoords.y,
        left: dragging ? "var(--local-translating-x)" : initialCoords.x,
        pointerEvents: dragging ? "none" : undefined,
        zIndex: 9999,
        overflow: "hidden",
        display: "flex",
        flexDirection: "column",
        boxShadow: dragging ? 12 : 6,
      }}
    >
      <ChatMediaUploadContent dragHandleRef={dragHandleRef} />
    </Paper>
  );
}

type ContentProps = {
  dragHandleRef: React.RefObject<HTMLButtonElement>;
};

const ChatMediaUploadContent = memo(function ChatMediaUploadContent({
  dragHandleRef,
}: ContentProps) {
  const dispatch = useAppDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const selectedChat = useAppSelector(selectSelectedChat);
  const chatRef = useRef(selectedChat);
  const theme = useTheme();
  const [uppy] = useState(() =>
    new Uppy({
      restrictions: {
        maxNumberOfFiles: 1,
        allowedFileTypes: ["image/*", "video/*"],
      },
      onBeforeFileAdded: () => {
        dispatch(setMediaUploadUi("uploading"));
        return true;
      },
    })
      .use(Webcam, {
        modes: ["video-audio", "picture"],
        videoConstraints: {
          facingMode: "user",
          width: { min: 480, max: 720, ideal: 480 },
          height: { min: 480, max: 720, ideal: 480 },
        },
        showRecordingLength: true,
        mobileNativeCamera: true,
        preferredImageMimeType: "image/jpeg",
      })
      .use(ScreenCapture)
      .use(Transloadit, {
        assemblyOptions: {
          params: {
            auth: { key: TRANSLOADIT_AUTH_KEY },
            template_id: "chat-media",
          },
        },
        waitForEncoding: true,
      }),
  );

  useUppyEvent(uppy, "transloadit:complete", (assemblyResponse) => {
    console.log("Uppy: upload success", assemblyResponse);

    if (
      "videos" in assemblyResponse.results &&
      assemblyResponse.results.videos.length &&
      assemblyResponse.results.videos[0].ssl_url &&
      "thumbnails" in assemblyResponse.results &&
      assemblyResponse.results.thumbnails.length &&
      assemblyResponse.results.thumbnails[0].ssl_url
    ) {
      const videoResult = assemblyResponse.results.videos[0];
      const thumbnailResult = assemblyResponse.results.thumbnails[0];

      dispatch(
        sendVideoMessage({
          video_url: videoResult.ssl_url,
          thumbnail_url: thumbnailResult.ssl_url,
          userId: chatRef.current!.id,
          toGroup: chatRef.current!.isGroupChat,
          asTrainwell: chatRef.current!.isTrainwell ?? false,
          width: videoResult.meta.width,
          height: videoResult.meta.height,
        }),
      )
        .unwrap()
        .catch(() => {
          enqueueSnackbar("Video failed to send", {
            variant: "error",
          });
        });
    } else if (
      "images" in assemblyResponse.results &&
      assemblyResponse.results.images.length &&
      assemblyResponse.results.images[0].ssl_url
    ) {
      const imageResult = assemblyResponse.results.images[0];

      dispatch(
        sendImageMessage({
          image_url: imageResult.ssl_url,
          userId: chatRef.current!.id,
          toGroup: chatRef.current!.isGroupChat,
          asTrainwell: chatRef.current!.isTrainwell ?? false,
          width: imageResult.meta.width,
          height: imageResult.meta.height,
        }),
      )
        .unwrap()
        .catch(() => {
          enqueueSnackbar("Image failed to send", {
            variant: "error",
          });
        });
    }

    closeMediaUpload();
    dispatch(setMediaUploadUi("hide"));
  });

  function closeMediaUpload() {
    dispatch(setMediaUploadUi("hide"));
  }

  return (
    <>
      <Box sx={{ flex: 1, borderBottom: 1, borderColor: "divider" }}>
        <Dashboard
          uppy={uppy}
          plugins={["Webcam", "ScreenCapture"]}
          proudlyDisplayPoweredByUppy={false}
          showLinkToFileUploadResult={false}
          height={"100%"}
          doneButtonHandler={() => {
            closeMediaUpload();
          }}
          theme={theme.palette.mode}
        />
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          px: 2,
          py: 1,
        }}
      >
        <IconButton
          ref={dragHandleRef}
          sx={{
            cursor: "grab",
          }}
          color="default"
        >
          <DragIndicatorRoundedIcon
            onClick={(event) => {
              event.stopPropagation();
            }}
            onMouseDown={(event) => {
              event.stopPropagation();
            }}
          />
        </IconButton>
        <Button
          onClick={() => {
            dispatch(setMediaUploadUi("hide"));
          }}
          variant="text"
        >
          Cancel
        </Button>
      </Box>
    </>
  );
});

function getProposedCoords({
  initialCoords,
  location,
  maxX,
  maxY,
}: {
  initialCoords: Coordinates;
  location: DragLocationHistory;
  maxX: number;
  maxY: number;
}): Coordinates {
  const diffX = location.current.input.clientX - location.initial.input.clientX;
  const proposedX = initialCoords.x + diffX;

  const diffY = location.current.input.clientY - location.initial.input.clientY;
  const proposedY = initialCoords.y + diffY;

  return {
    x: Math.min(Math.max(proposedX, 0), maxX),
    y: Math.min(Math.max(proposedY, 0), maxY),
  };
}
