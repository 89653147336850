import type { WeekPlan } from "@trainwell/features";
import { isProgramHabit } from "./habits";

export function canAddWorkoutToWeekPlan(data: {
  weekPlan: WeekPlan;
  dayIndex: number;
  workoutId: string;
}) {
  const { weekPlan, dayIndex, workoutId } = data;

  if (!weekPlan) {
    return false;
  }

  const habitWeek = weekPlan.habit_weeks.find((habitWeek) =>
    isProgramHabit(habitWeek),
  );

  if (!habitWeek) {
    return false;
  }

  if (
    habitWeek.anchored_workout_days &&
    habitWeek.anchored_workout_days[dayIndex] &&
    habitWeek.anchored_workout_days[dayIndex]?.includes(workoutId)
  ) {
    return false;
  }

  return true;
}
