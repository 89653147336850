import { Slider } from "@mui/material";
import type { UserFeedback2, WorkoutLog } from "@trainwell/types";

type Mark = {
  value: number;
  label?: string;
  color: string;
  tag: UserFeedback2["difficulty"];
};

const marks: Mark[] = [
  {
    value: 0,
    label: "Way too easy",
    color: "rgba(84,150,217,1)",
    tag: "way_too_easy",
  },
  {
    value: 25,
    color: "rgba(125,208,196,1)",
    tag: "too_easy",
  },
  {
    value: 50,
    label: "Just right",
    color: "rgba(234,212,133,1)",
    tag: "just_right",
  },
  {
    value: 75,
    color: "rgba(237,150,109,1)",
    tag: "too_hard",
  },
  {
    value: 100,
    label: "Way too hard",
    color: "rgba(236,72,53,1)",
    tag: "way_too_hard",
  },
];

type Props = {
  log: WorkoutLog;
};

export function WorkoutLogDifficulty({ log }: Props) {
  const difficulty = log.log_summary.user_feedback2?.difficulty;

  if (!difficulty) {
    return null;
  }

  console.log("dif", difficulty);

  const mark = marks.find((m) => m.tag === difficulty);

  return (
    <Slider
      disabled
      min={0}
      max={100}
      defaultValue={50}
      value={mark?.value}
      valueLabelDisplay="auto"
      marks={marks}
      sx={{
        "& .MuiSlider-rail": {
          background:
            "linear-gradient(90deg, rgba(84,150,217,1) 0%, rgba(125,208,196,1) 25%, rgba(234,212,133,1) 50%, rgba(237,150,109,1) 75%, rgba(236,72,53,1) 100%)",
        },
        "& .MuiSlider-track": {
          opacity: 0,
        },
        "& .MuiSlider-thumb": {
          backgroundColor: mark?.color,
        },
      }}
    />
  );
}
