import CommentRoundedIcon from "@mui/icons-material/CommentRounded";
import FitnessCenterRoundedIcon from "@mui/icons-material/FitnessCenterRounded";
import {
  Box,
  Button,
  Chip,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import type { Ticket } from "@trainwell/types";
import { format } from "date-fns";
import { useAppDispatch, useAppSelector } from "src/hooks/stateHooks";
import { openTicketChat } from "src/slices/chatSlice";
import { selectTicketById } from "src/slices/ticketsSlice";

type Props = {
  ticketId: string;
  ticket?: Ticket;
};

export default function TicketCell({ ticketId, ticket: forceTicket }: Props) {
  const dispatch = useAppDispatch();
  const ticket =
    useAppSelector((state) => selectTicketById(state, ticketId)) ?? forceTicket;

  if (!ticket) {
    return <Typography>Loading</Typography>;
  }

  return (
    <Box
      sx={{
        py: 1,
        px: 2,
        borderBottom: 1,
        borderColor: "divider",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "flex-start",
          justifyContent: "space-between",
          mb: 1,
        }}
      >
        <Box>
          <Typography>
            <b>#{ticket.number}</b>
          </Typography>
          <Typography variant="overline">
            {format(new Date(ticket.date_created), "MMM d, yyyy")}
          </Typography>
        </Box>
        <Stack direction={"row"} spacing={1} alignItems="center">
          {ticket.state === "done" && (
            <Chip
              color="success"
              size="small"
              label="Done"
              sx={{ fontSize: "12px", height: "20px" }}
            />
          )}
          {ticket.log_id && (
            <Button
              variant="text"
              size="small"
              href={`/clients/${ticket.user_id}/logs/${ticket.log_id}`}
              startIcon={<FitnessCenterRoundedIcon />}
            >
              Log
            </Button>
          )}
          <IconButton
            onClick={() => {
              dispatch(openTicketChat(ticket.id));
            }}
          >
            <CommentRoundedIcon />
          </IconButton>
        </Stack>
      </Box>
      {ticket.message_client && (
        <Typography>
          <b>Client message:</b> {ticket.message_client}
        </Typography>
      )}
      {ticket.message_coach && (
        <Typography>
          <b>Trainer message:</b> {ticket.message_coach}
        </Typography>
      )}
    </Box>
  );
}
