import type { LinkProps } from "@mui/material";
import { Link } from "@mui/material";
import { LinkBehavior } from "./LinkBehavior";

type Props = {
  text: string;
} & LinkProps;

export default function CustomLink({ text, ...otherProps }: Props) {
  return (
    <Link
      underline="hover"
      sx={{
        color: (theme) => theme.palette.link.main,
        fontWeight: "bold",
      }}
      component={LinkBehavior}
      {...otherProps}
    >
      {text}
    </Link>
  );
}
