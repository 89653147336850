import { Box, Typography, type SxProps, type Theme } from "@mui/material";
import { useUpdateMilestone, type MilestoneText } from "@trainwell/features";
import AutosaveTextField from "src/components/misc/AutosaveTextField";

type Props = {
  sx?: SxProps<Theme>;
  milestone: MilestoneText;
};

export function MilestoneCellText({ sx = [], milestone }: Props) {
  const updateMilestone = useUpdateMilestone();

  if (!milestone.text) {
    return (
      <Box sx={{ ...sx }}>
        <AutosaveTextField
          disableGutter
          onlySaveOnBlur
          blurOnEnter
          onSave={(value) => {
            updateMilestone.mutate({
              milestoneId: milestone._id,
              data: {
                text: value,
              },
            });
          }}
          size="small"
          placeholder="Get a little win"
          autoFocus
          variant="standard"
        />
      </Box>
    );
  } else {
    return (
      <Box sx={{ ...sx }}>
        <Typography>{milestone.text}</Typography>
      </Box>
    );
  }
}
