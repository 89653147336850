import type { ActionItem } from "@trainwell/types";
import { useSnackbar } from "notistack";
import { matchPath, useLocation, useNavigate } from "react-router";
import { useAppDispatch } from "src/hooks/stateHooks";
import { getActionItemDetails } from "src/lib/actionItem";
import { dismissActionItem } from "src/slices/actionItemSlice";
import { openChat } from "src/slices/chatSlice";

export default function useActionItem() {
  const { pathname } = useLocation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const userIdMatch = matchPath({ path: "/clients/:userId" }, pathname);
  const logIdMatch = matchPath(
    { path: "/clients/:userId/logs/:logId" },
    pathname,
  );

  const { userId } = userIdMatch?.params || {};
  const { logId } = logIdMatch?.params || {};

  async function handleActionItemClick(actionItem: ActionItem) {
    if (actionItem.url) {
      window.open(actionItem.url, "_blank");
    }

    const actionItemDetails = getActionItemDetails(actionItem);

    if (actionItemDetails.clearTypes?.includes("click")) {
      await dispatch(
        dismissActionItem({
          actionItemId: actionItem.id,
          usedClearButton: false,
        }),
      )
        .unwrap()
        .catch((err) => {
          if (err.showToast !== false) {
            enqueueSnackbar("Error dismissing action item", {
              variant: "error",
            });
            console.error("Error dismissing action item: ", err);
          }
        });
    }

    if (actionItem.type === "workout_review") {
      if (logId !== actionItem.log_id) {
        navigate(
          "/clients/" + actionItem.user_id + "/logs/" + actionItem.log_id,
        );
      }
    } else {
      if (userId !== actionItem.user_id) {
        navigate("/clients/" + actionItem.user_id);
      }
    }

    dispatch(openChat({ chatId: actionItem.user_id }));
  }

  async function handleActionItemClearButton(actionItem: ActionItem) {
    const actionItemDetails = getActionItemDetails(actionItem);

    if (actionItemDetails.clearTypes?.includes("clear_button")) {
      await dispatch(
        dismissActionItem({
          actionItemId: actionItem.id,
          usedClearButton: true,
        }),
      )
        .unwrap()
        .catch((err) => {
          if (err.showToast !== false) {
            enqueueSnackbar("Error dismissing action item", {
              variant: "error",
            });
          }
        });
    }
  }

  return {
    handleActionItemClick: handleActionItemClick,
    handleActionItemClearButton: handleActionItemClearButton,
  };
}
