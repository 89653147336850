import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import ExtensionRoundedIcon from "@mui/icons-material/ExtensionRounded";
import { Box, Button, Grid, Typography } from "@mui/material";
import { useParams } from "react-router";
import LoadingPage from "src/components/miscPages/LoadingPage";
import { useAppDispatch, useAppSelector } from "src/hooks/stateHooks";
import { closeTemplateLibrary } from "src/slices/phaseTemplatesSlice";
import TemplateLibraryLayout from "../template-library/TemplateLibraryLayout";
import { Calendar } from "./calendar/Calendar";

export default function ClientPlanPage() {
  const dispatch = useAppDispatch();
  const { userId } = useParams();
  const localUserId = useAppSelector((state) => state.client.client?.user_id);
  const templateLibraryOpen = useAppSelector((state) =>
    Boolean(state.phaseTemplates.openForUserId),
  );

  console.log("Render plan page");

  if (!localUserId || !userId || userId !== localUserId) {
    return <LoadingPage message={"Loading your client 😄"} />;
  }

  return (
    <Box sx={{ display: "flex", flex: 1 }}>
      {templateLibraryOpen ? (
        <Box
          sx={{
            position: "relative",
            height: "calc(100vh - 46px)",
            mt: 1,
            ml: 1,
            borderTopLeftRadius: 10,
            overflow: "hidden",
            borderTop: 1,
            borderLeft: 1,
            borderColor: "divider",
            width: "100%",
            boxShadow: 2,
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Grid
            container
            sx={{
              backgroundColor: (theme) =>
                theme.palette.backgroundSecondary.main,
              borderBottom: 1,
              borderColor: "divider",
              px: 1,
              alignItems: "center",
              position: "relative",
              zIndex: (theme) => theme.zIndex.drawer + 1,
            }}
          >
            <Grid item xs={4}>
              <Button
                variant="text"
                size="small"
                startIcon={<CloseRoundedIcon />}
                onClick={() => {
                  dispatch(closeTemplateLibrary());
                }}
              >
                Close
              </Button>
            </Grid>
            <Grid
              item
              xs={4}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <ExtensionRoundedIcon
                sx={{
                  fontSize: 15,
                  mr: 0.5,
                }}
              />
              <Typography variant="h6">Template library</Typography>
            </Grid>
            <Grid
              item
              xs={4}
              sx={{ justifyContent: "flex-end", display: "flex" }}
            ></Grid>
          </Grid>
          <Box
            sx={{
              flex: 1,
              display: "flex",
              overflowY: "hidden",
            }}
          >
            <TemplateLibraryLayout />
          </Box>
        </Box>
      ) : (
        <Calendar userId={userId} />
      )}
    </Box>
  );
}
