import type { PayloadAction } from "@reduxjs/toolkit";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { setExerciseLibrary, setExerciseListGrouped } from "src/lib/exercises";
import { api } from "src/lib/trainwellApi";
import { workoutLib } from "src/lib/trainwellWorkoutLib";
import type { ExerciseLibrary } from "src/types/ExerciseLibrary";

export const fetchExerciseLibrary = createAsyncThunk(
  "app/fetchExerciseLibrary",
  async () => {
    const response = await api.exercises.getAll();
    return response;
  },
);

type DashMode = "default" | "programming";

// Define a type for the slice state
interface AppState {
  exerciseStatus: "idle" | "loading" | "succeeded" | "failed";
  pwa: boolean;
  error: string | undefined;
  dashMode: DashMode;
  socketConnected: boolean;
  isAuditMode: boolean;
}

// Define the initial state using that type
const initialState: AppState = {
  exerciseStatus: "idle",
  pwa: false,
  error: undefined,
  dashMode: "default",
  socketConnected: false,
  isAuditMode: false,
};

export const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    resetApp: () => initialState,
    setDashMode: (state, action: PayloadAction<DashMode>) => {
      const dashMode = action.payload;

      state.dashMode = dashMode;
    },
    setSocketConnected: (state, action: PayloadAction<boolean>) => {
      const socketConnected = action.payload;

      state.socketConnected = socketConnected;
    },
    setPwa: (state, action: PayloadAction<boolean>) => {
      const pwa = action.payload;

      state.pwa = pwa;
    },
    setIsAuditMode: (state, action: PayloadAction<boolean>) => {
      const isAuditMode = action.payload;

      state.isAuditMode = isAuditMode;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchExerciseLibrary.pending, (state) => {
      state.exerciseStatus = "loading";
    });
    builder.addCase(fetchExerciseLibrary.fulfilled, (state, action) => {
      console.log("Redux: Got exercise library");
      state.exerciseStatus = "succeeded";

      const fetchedExerciseList = action.payload;

      fetchedExerciseList.map((exercise) => {
        if (!exercise.picker_swap_ids) {
          exercise.picker_swap_ids = [];
        }
      });

      const tempExerciseLibrary: ExerciseLibrary = {};

      for (const exercise of fetchedExerciseList) {
        tempExerciseLibrary[exercise.id] = exercise;
      }

      setExerciseLibrary(JSON.parse(JSON.stringify(tempExerciseLibrary)));
      const groups =
        workoutLib.exerciseGroups.createExerciseGroups(fetchedExerciseList);
      setExerciseListGrouped(JSON.parse(JSON.stringify(groups)));
    });
    builder.addCase(fetchExerciseLibrary.rejected, (state, action) => {
      state.exerciseStatus = "failed";
      state.error = action.error.message;
    });
  },
});

export const {
  resetApp,
  setDashMode,
  setSocketConnected,
  setPwa,
  setIsAuditMode,
} = appSlice.actions;

export default appSlice.reducer;
