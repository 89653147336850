import { Typography } from "@mui/material";
import { exerciseMap } from "src/lib/exercises";
import { getExerciseVideoURL } from "src/lib/mediaUtility";

type Props = {
  exerciseMasterId: string;
  width?: number;
  height?: number;
};

export default function ExerciseMedia({
  exerciseMasterId,
  width = 300,
  height = 300,
}: Props) {
  if (!(exerciseMap && exerciseMasterId in exerciseMap)) {
    return <Typography>Error</Typography>;
  }

  return (
    <video
      width={width}
      height={height}
      autoPlay
      muted
      loop
      style={{ borderRadius: "8px" }}
    >
      <source src={getExerciseVideoURL(exerciseMasterId)} />
    </video>
  );
}
