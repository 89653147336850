import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import ExpandMoreRoundedIcon from "@mui/icons-material/ExpandMoreRounded";
import MoreVertRoundedIcon from "@mui/icons-material/MoreVertRounded";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Grid,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Popover,
  Typography,
} from "@mui/material";
import { format } from "date-fns";
import { useState } from "react";
import LoadingComponent from "src/components/miscPages/LoadingComponent";
import {
  useDeleteProgressMetricLogMutation,
  useGetProgressMetricLogsQuery,
} from "src/slices/api/progressMetricLogsApi";

type Props = {
  userId: string;
};

export default function ProgressPicturesPanel({ userId }: Props) {
  const [isExpanded, setIsExpanded] = useState(false);
  const { data: progressMetricLogs } = useGetProgressMetricLogsQuery(
    {
      userId,
      progressMetricId: "progress_picture",
    },
    {
      skip: !isExpanded,
    },
  );
  const [deleteProgressMetricLog] = useDeleteProgressMetricLogMutation();
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [popoverImageUrl, setPopoverImageUrl] = useState("");
  const [moreMenuData, setMoreMenuData] = useState<{
    anchorEl: HTMLElement;
    progressMetricLogId: string;
  } | null>(null);

  const open = Boolean(anchorEl);

  return (
    <Accordion
      disabled={progressMetricLogs && progressMetricLogs.length <= 0}
      disableGutters
      elevation={0}
      square
      expanded={isExpanded}
      onChange={() => {
        setIsExpanded(!isExpanded);
      }}
      slotProps={{ transition: { timeout: 0, unmountOnExit: true } }}
      sx={{
        backgroundColor: "transparent",
      }}
    >
      <AccordionSummary expandIcon={<ExpandMoreRoundedIcon />}>
        <Typography variant="h6">🖼️ Progress pictures</Typography>
      </AccordionSummary>
      <AccordionDetails>
        {!progressMetricLogs ? (
          <LoadingComponent />
        ) : (
          <Box sx={{ height: "300px", overflowY: "auto" }}>
            <Grid container spacing={2}>
              {progressMetricLogs.map((log) => {
                return (
                  <Grid item xs={6} key={log.id}>
                    <img
                      crossOrigin="anonymous"
                      src={log.url_image ?? ""}
                      alt="progress picture"
                      style={{
                        width: "100%",
                        height: "75px",
                        objectFit: "cover",
                        display: "flex",
                        borderRadius: "8px",
                      }}
                      onMouseEnter={(event) => {
                        setPopoverImageUrl(log.url_image ?? "");
                        setAnchorEl(event.currentTarget);
                      }}
                      onMouseLeave={() => {
                        setAnchorEl(null);
                      }}
                    />
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <Typography variant="overline">
                        {format(new Date(log.date), "MMM d, yyyy")}
                      </Typography>
                      <IconButton
                        size="small"
                        onClick={(event) => {
                          setMoreMenuData({
                            anchorEl: event.currentTarget,
                            progressMetricLogId: log.id,
                          });
                        }}
                      >
                        <MoreVertRoundedIcon fontSize="small" />
                      </IconButton>
                    </Box>
                  </Grid>
                );
              })}
            </Grid>
            <Popover
              id="mouse-over-popover"
              sx={{
                pointerEvents: "none",
                p: 2,
              }}
              open={open}
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              onClose={() => {
                setAnchorEl(null);
              }}
              disableRestoreFocus
            >
              <img
                crossOrigin="anonymous"
                src={popoverImageUrl}
                alt="progress picture"
                style={{
                  width: "300px",
                  display: "flex",
                  borderRadius: "8px",
                }}
              />
            </Popover>
          </Box>
        )}
      </AccordionDetails>
      <Menu
        open={Boolean(moreMenuData)}
        anchorEl={moreMenuData?.anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        onClose={() => {
          setMoreMenuData(null);
        }}
      >
        <MenuItem
          onClick={() => {
            deleteProgressMetricLog({
              progressMetricLogId: moreMenuData?.progressMetricLogId ?? "",
            });

            setMoreMenuData(null);
          }}
        >
          <ListItemIcon>
            <DeleteRoundedIcon fontSize="small" color="error" />
          </ListItemIcon>
          <ListItemText
            primary="Delete"
            sx={{ color: (theme) => theme.palette.error.main }}
          />
        </MenuItem>
      </Menu>
    </Accordion>
  );
}
