import { Box, Skeleton } from "@mui/material";

export default function ChatMessagesLoading() {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-end",
        minHeight: "100%",
        overflow: "hidden",
        pb: 6,
      }}
    >
      <Skeleton
        variant="rounded"
        height={100}
        sx={{
          width: "300px",
          maxWidth: "80%",
          ml: 1,
          mb: 2,
          backgroundColor: (theme) => theme.palette.background.default,
        }}
      />
      <Skeleton
        variant="rounded"
        height={20}
        sx={{
          width: "300px",
          maxWidth: "80%",
          mr: 1,
          alignSelf: "flex-end",
          mb: 2,
          backgroundColor: (theme) => theme.palette.primary.main,
        }}
      />
      <Skeleton
        variant="rounded"
        height={60}
        sx={{
          width: "300px",
          maxWidth: "80%",
          ml: 1,
          mb: 2,
          backgroundColor: (theme) => theme.palette.background.default,
        }}
      />
      <Skeleton
        variant="rounded"
        height={80}
        sx={{
          width: "300px",
          maxWidth: "80%",
          mr: 1,
          alignSelf: "flex-end",
          mb: 1,
          backgroundColor: (theme) => theme.palette.primary.main,
        }}
      />
      <Skeleton
        variant="rounded"
        height={20}
        sx={{
          width: "300px",
          maxWidth: "80%",
          mr: 1,
          alignSelf: "flex-end",
          mb: 1,
          backgroundColor: (theme) => theme.palette.primary.main,
        }}
      />
      <Skeleton
        variant="rounded"
        height={40}
        sx={{
          width: "300px",
          maxWidth: "80%",
          mr: 1,
          mb: 2,
          alignSelf: "flex-end",
          backgroundColor: (theme) => theme.palette.primary.main,
        }}
      />
      <Skeleton
        variant="rounded"
        height={20}
        sx={{
          width: "300px",
          maxWidth: "80%",
          ml: 1,
          mb: 2,
          backgroundColor: (theme) => theme.palette.background.default,
        }}
      />
      <Skeleton
        variant="rounded"
        height={40}
        sx={{
          width: "300px",
          maxWidth: "80%",
          mr: 1,
          mb: 2,
          alignSelf: "flex-end",
          backgroundColor: (theme) => theme.palette.primary.main,
        }}
      />
      <Skeleton
        variant="rounded"
        height={80}
        sx={{
          width: "300px",
          maxWidth: "80%",
          ml: 1,
          mb: 2,
          backgroundColor: (theme) => theme.palette.background.default,
        }}
      />
      <Skeleton
        variant="rounded"
        height={20}
        sx={{
          width: "300px",
          maxWidth: "80%",
          mr: 1,
          alignSelf: "flex-end",
          backgroundColor: (theme) => theme.palette.primary.main,
        }}
      />
    </Box>
  );
}
