import CheckBoxOutlineBlankRoundedIcon from "@mui/icons-material/CheckBoxOutlineBlankRounded";
import CheckBoxRoundedIcon from "@mui/icons-material/CheckBoxRounded";
import type { SxProps, Theme } from "@mui/material";
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Chip,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { useState } from "react";

const icon = <CheckBoxOutlineBlankRoundedIcon fontSize="small" />;
const checkedIcon = <CheckBoxRoundedIcon fontSize="small" />;

type Props = {
  label: string;
  values?: string[];
  possibleValues: Record<string, string>;
  onSave: (values: string[]) => void;
  sx?: SxProps<Theme>;
};

export default function SmartEditChips({
  label,
  values,
  possibleValues,
  onSave,
  sx = [],
}: Props) {
  const [isHovering, setIsHovering] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [newValues, setNewValues] = useState(values ?? []);

  const possibleValueKeys = Object.keys(possibleValues).sort();

  return (
    <Box
      onMouseEnter={() => {
        setIsHovering(true);
      }}
      onMouseLeave={() => {
        setIsHovering(false);
      }}
      sx={Array.isArray(sx) ? sx : [sx]}
    >
      <Box sx={{ display: "flex", alignItems: "center", mb: 0.5 }}>
        <Typography variant="h6">{label}</Typography>
        <Box
          sx={{
            opacity: isHovering || isEditing || !values ? undefined : 0,
            pointerEvents:
              isHovering || isEditing || !values ? undefined : "none",
          }}
        >
          <Button
            variant={isEditing ? "contained" : "text"}
            size="small"
            sx={{
              ml: 1,
              lineHeight: 1.25,
              px: 1,
              py: 0.5,
              minWidth: "0px",
            }}
            onClick={() => {
              if (isEditing) {
                onSave(newValues);
                setIsEditing(false);
              } else {
                setIsEditing(true);
              }
            }}
          >
            {isEditing ? "Save" : !values ? "Add" : "Edit"}
          </Button>
          {isEditing && (
            <Button
              variant="text"
              size="small"
              sx={{
                ml: 1,
                lineHeight: 1.25,
                px: 1,
                py: 0.5,
                minWidth: "0px",
              }}
              onClick={() => {
                setIsEditing(false);
                setNewValues(values ?? []);
              }}
            >
              Cancel
            </Button>
          )}
        </Box>
      </Box>
      {isEditing ? (
        <Autocomplete
          multiple
          options={possibleValueKeys}
          disableCloseOnSelect
          getOptionLabel={(key) => possibleValues[key]}
          value={newValues}
          size="small"
          fullWidth
          onChange={(event, passedValues) => {
            setNewValues(passedValues as any);
          }}
          renderOption={(props, key, { selected }) => (
            <li {...props}>
              <Checkbox
                icon={icon}
                checkedIcon={checkedIcon}
                style={{ marginRight: 8 }}
                checked={selected}
              />
              {possibleValues[key]}
            </li>
          )}
          renderInput={(params) => <TextField {...params} />}
        />
      ) : (
        <Grid container spacing={0.5}>
          {values?.map((key) => {
            return (
              <Grid item key={key}>
                <Chip
                  label={possibleValues[key]}
                  variant="filled"
                  size="small"
                />
              </Grid>
            );
          })}
        </Grid>
      )}
    </Box>
  );
}
