import {
  Dialog,
  DialogContent,
  Link,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { BarChart } from "@mui/x-charts";
import type { CoachMetricTemplateSubmodule } from "@trainwell/types";
import { useState } from "react";
import { DialogTitleWithClose } from "src/components/misc/DialogTitleWithClose";
import { queryMetric } from "src/lib/metrics";

type ClientBucket = {
  bucketName: string;
  y: number;
  userIds: string[];
};

type Props = {
  metricSnapshot: any;
  submodule: CoachMetricTemplateSubmodule;
  height?: number;
};

export function ClientBucketsGraph({
  metricSnapshot,
  submodule,
  height,
}: Props) {
  const theme = useTheme();
  const [focusedBucket, setFocusedBucket] = useState<ClientBucket | null>(null);

  if (!submodule.client_buckets) {
    return;
  }

  const bucketNames = Object.keys(submodule.client_buckets);

  const chartData: ClientBucket[] = bucketNames.map((bucketName) => {
    const userIds = queryMetric(
      metricSnapshot,
      submodule.client_buckets![bucketName],
    ) as string[];
    return { bucketName: bucketName, y: userIds.length, userIds: userIds };
  });

  return (
    <>
      <BarChart
        colors={[theme.palette.primary.main]}
        // @ts-expect-error
        dataset={chartData}
        yAxis={[
          {
            label: "Clients",
          },
        ]}
        xAxis={[
          {
            scaleType: "band",
            dataKey: "bucketName",
            label: "Bucket",
          },
        ]}
        series={[
          {
            type: "bar",
            dataKey: "y",
            label: "Clients",
          },
        ]}
        onAxisClick={(event, params) => {
          const axisValue = params?.axisValue as string;

          const data = chartData.find((d) => d.bucketName === axisValue);

          if (data) {
            setFocusedBucket(data);
          }
        }}
        width={500}
        height={height ?? 300}
        skipAnimation
      />
      <Typography variant="overline">
        Click a bucket to get client links
      </Typography>
      <Dialog
        open={focusedBucket !== null}
        onClose={() => {
          setFocusedBucket(null);
        }}
      >
        <DialogTitleWithClose
          onClose={() => {
            setFocusedBucket(null);
          }}
        >
          {focusedBucket?.bucketName}
        </DialogTitleWithClose>
        <DialogContent>
          <Stack spacing={1}>
            {focusedBucket?.userIds.map((userId) => (
              <Link
                key={userId}
                href={`https://leadtrainer.trainwell.net/clients/${userId}`}
              >
                https://leadtrainer.trainwell.net/clients/{userId}
              </Link>
            ))}
          </Stack>
        </DialogContent>
      </Dialog>
    </>
  );
}
