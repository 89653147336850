import {
  Avatar,
  Box,
  Checkbox,
  Chip,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Menu,
  styled,
  type SxProps,
  type Theme,
} from "@mui/material";
import { useState } from "react";
import TagFilterButton from "../home/TagFilterButton";

const ChipListItem = styled("li")(({ theme }) => ({
  margin: theme.spacing(0.5),
}));

const filters: {
  name: string;
  key: string;
  subFilters?: { name: string; key: string }[];
}[] = [
  {
    name: "Upcoming workouts",
    key: "workouts",
    subFilters: [
      { name: "Workout today", key: "workout_today" },
      { name: "Workout tomorrow", key: "workout_tomorrow" },
      { name: "Some workout this week", key: "any_workout_week" },
    ],
  },
  {
    name: "Workout completion",
    key: "workout_completion",
    subFilters: [
      { name: "Did a workout today", key: "worked_out_today" },
      { name: "Did a workout this week", key: "worked_out_week" },
    ],
  },
  { name: "Active", key: "active" },
  { name: "Inactive", key: "inactive" },
  {
    name: "Last message",
    key: "last_message",
    subFilters: [
      { name: "3+ days", key: "unmessaged_3_or_more" },
      { name: "7+ days", key: "unmessaged_7_or_more" },
    ],
  },
  {
    name: "Tenure",
    key: "tenure",
    subFilters: [
      { name: "Pre trial (blue)", key: "pre_trial" },
      { name: "In trial (purple)", key: "in_trial" },
      { name: "Post trial", key: "post_trial" },
    ],
  },
  {
    name: "Missed streak",
    key: "missed_streak",
    subFilters: [
      { name: "None", key: "missed_streak_0" },
      { name: "1 day", key: "missed_streak_1" },
      { name: "2 or more days", key: "missed_streak_2_or_more" },
    ],
  },
  { name: "Not checked in on", key: "not_visited" },
  {
    name: "Visibility",
    key: "visibility",
    subFilters: [
      { name: "Visible", key: "visible" },
      { name: "Hidden", key: "hidden" },
    ],
  },
];

type Props = {
  onToggle: (filter: string) => void;
  selectedFilters: string[];
  sx?: SxProps<Theme>;
};

export default function ChatListFilter({
  onToggle,
  selectedFilters,
  sx = [],
}: Props) {
  const [filterKey, setFilterKey] = useState<null | string>(null);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box
      sx={[
        {
          display: "flex",
          flexWrap: "wrap",
          listStyle: "none",
        },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    >
      <ChipListItem>
        <TagFilterButton
          variant="chip"
          value={selectedFilters
            .filter((f) => f.startsWith("client_tag:"))
            .map((f) => f.split(":")[1])}
          onChange={(newActionItems) => {
            const newTagFilters = newActionItems.map((f) => `client_tag:${f}`);

            const existingTagFilters = selectedFilters.filter((f) =>
              f.startsWith("client_tag:"),
            );

            for (const existingTagFilter of existingTagFilters) {
              if (!newTagFilters.includes(existingTagFilter)) {
                // turn off
                onToggle(existingTagFilter);
              }
            }

            for (const newTagFilter of newTagFilters) {
              if (!existingTagFilters.includes(newTagFilter)) {
                // turn on
                onToggle(newTagFilter);
              }
            }
          }}
        />
      </ChipListItem>
      {filters.map((filter) => {
        const selected =
          selectedFilters.includes(filter.key) ||
          filter.subFilters?.some((subFilter) =>
            selectedFilters.includes(subFilter.key),
          );

        const count = filter.subFilters?.filter((subFilter) =>
          selectedFilters.includes(subFilter.key),
        ).length;

        return (
          <ChipListItem key={filter.key}>
            <Chip
              label={filter.name}
              onClick={(event) => {
                if (filter.subFilters) {
                  setFilterKey(filter.key);
                  setAnchorEl(event.currentTarget);
                } else {
                  onToggle(filter.key);
                }
              }}
              size="small"
              variant={!selected ? "outlined" : "filled"}
              color={!selected ? "default" : "blueSurface"}
              sx={{
                borderStyle: !selected ? "dashed" : undefined,
              }}
              avatar={count ? <Avatar>{count}</Avatar> : undefined}
            />
          </ChipListItem>
        );
      })}
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{ disablePadding: true }}
      >
        <List dense>
          {filters
            .find((filter) => filter.key === filterKey)
            ?.subFilters?.map((subFilter) => (
              <ListItem key={subFilter.key} disablePadding>
                <ListItemButton
                  role={undefined}
                  onClick={() => {
                    onToggle(subFilter.key);
                  }}
                  dense
                >
                  <ListItemIcon sx={{ minWidth: "42px" }}>
                    <Checkbox
                      edge="start"
                      checked={selectedFilters.includes(subFilter.key)}
                      tabIndex={-1}
                      disableRipple
                      sx={{ py: 0.25 }}
                    />
                  </ListItemIcon>
                  <ListItemText
                    primaryTypographyProps={{ variant: "body1" }}
                    primary={subFilter.name}
                  />
                </ListItemButton>
              </ListItem>
            ))}
        </List>
      </Menu>
    </Box>
  );
}
