import CalendarMonthRoundedIcon from "@mui/icons-material/CalendarMonthRounded";
import ErrorRoundedIcon from "@mui/icons-material/ErrorRounded";
import {
  Avatar,
  Box,
  Button,
  LinearProgress,
  Portal,
  Tooltip,
  Typography,
} from "@mui/material";
import { useVirtualizer } from "@tanstack/react-virtual";
import { getOldestUnreadMessageByClient } from "@trainwell/features";
import type { Message } from "@trainwell/types";
import { format, isFuture } from "date-fns";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useInView } from "react-intersection-observer";
import { useAppDispatch, useAppSelector } from "src/hooks/stateHooks";
import {
  fetchFirstMessages,
  fetchMoreMessages,
  readChat,
  selectSelectedChat,
  updateChat,
  type Chat,
} from "src/slices/chatSlice";
import { selectTicketById } from "src/slices/ticketsSlice";
import { selectPrimaryTrainer } from "src/slices/trainerSlice";
import { ChatMediaUpload } from "../ChatMediaUpload";
import ChatMessageBar from "../ChatMessageBar";
import ChatMessagesLoading from "../ChatMessagesLoading";
import { MessageCell } from "../messages/MessageCell";
import TicketMessageCard from "../messages/TicketMessageCard";
import { ClientChatFooter } from "./ClientChatFooter";

export function ClientChat() {
  const dispatch = useAppDispatch();
  const selectedChat = useAppSelector(selectSelectedChat);
  const clientBanner = useAppSelector((state) =>
    state.trainer.trainer?.banner_custom &&
    isFuture(state.trainer.trainer.banner_custom.date_active_until)
      ? state.trainer.trainer.banner_custom.text
      : state.client.client?.banner_coach?.active
        ? state.client.client?.banner_coach?.text
        : "",
  );
  const customBannerActiveUntilDate = useAppSelector((state) =>
    state.trainer.trainer?.banner_custom &&
    isFuture(state.trainer.trainer.banner_custom.date_active_until)
      ? state.trainer.trainer.banner_custom.date_active_until
      : null,
  );
  const mediaUploadUi = useAppSelector((state) => state.chat.mediaUploadUi);
  const isAuditMode = useAppSelector((state) => state.app.isAuditMode);

  useEffect(() => {
    if (!selectedChat?.id) {
      return;
    }

    if (isAuditMode === (selectedChat.isAuditMode ?? false)) {
      return;
    }

    dispatch(
      updateChat({
        id: selectedChat?.id ?? "",
        firstMessageFetchState: "idle",
      }),
    );
  }, [dispatch, isAuditMode]);

  useEffect(() => {
    if (selectedChat?.firstMessageFetchState === "idle") {
      dispatch(fetchFirstMessages(selectedChat.id));
    }
  }, [selectedChat, dispatch]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        flex: 1,
        overflow: "hidden",
        backgroundColor: (theme) => theme.palette.background.default,
      }}
    >
      {clientBanner && (
        <Tooltip
          title={
            customBannerActiveUntilDate !== null
              ? `Set by you until ${format(customBannerActiveUntilDate, "h:mm a")}`
              : "Set by trainwell"
          }
        >
          <Box
            sx={{
              px: 1,
              py: 0.5,
              backgroundColor: (theme) => theme.palette.warningSurface.main,
              display: "flex",
              alignItems: "center",
              borderBottom: 1,
              borderColor: "divider",
            }}
          >
            <Avatar
              sx={{
                backgroundColor: (theme) => theme.palette.primary.main,
                width: 19,
                height: 19,
                mr: 1,
              }}
            >
              <CalendarMonthRoundedIcon sx={{ fontSize: 13 }} />
            </Avatar>
            <Typography variant="body2" sx={{ whiteSpace: "pre-line" }}>
              {clientBanner}
            </Typography>
          </Box>
        </Tooltip>
      )}
      {selectedChat?.firstMessageFetchState === "done" ? (
        <ClientChatScrollable chat={selectedChat} />
      ) : selectedChat ? (
        <ChatMessagesLoading />
      ) : null}
      {selectedChat && <ChatMessageBar />}
      {(mediaUploadUi === "show" || mediaUploadUi === "uploading") && (
        <Portal>
          <ChatMediaUpload />
        </Portal>
      )}
    </Box>
  );
}

type ClientChatScrollableProps = {
  chat: Chat;
};

function ClientChatScrollable({
  chat: selectedChat,
}: ClientChatScrollableProps) {
  const { ref, inView } = useInView();
  const itemSize = 10;
  const dispatch = useAppDispatch();

  const trainer = useAppSelector(selectPrimaryTrainer);
  const shouldScroll = useRef(false);
  const dashMode = useAppSelector((state) => state.app.dashMode);
  const ticketForChat = useAppSelector((state) =>
    selectedChat?.ticketId
      ? selectTicketById(state, selectedChat.ticketId)
      : undefined,
  );
  const isChatFullscreen = useAppSelector(
    (state) => state.chat.isChatFullscreen,
  );
  const [didFirstScroll, setDidFirstScroll] = useState(false);

  console.log("Chat: Render");

  const messages = useMemo(
    () => selectedChat?.messages ?? [],
    [selectedChat?.messages],
  );
  const oldestMessage = useRef<Message>();
  const newestMessage = useRef(messages.at(-1));

  const messageCount = messages.length;

  const parentRef = useRef(null);
  const virtualizer = useVirtualizer({
    getScrollElement: () => parentRef.current,
    count: messageCount,
    estimateSize: () => itemSize,
    getItemKey: useCallback((index) => messages[index].message_id, [messages]),
    overscan: 5,
    scrollMargin: 50,
  });

  if (oldestMessage.current?.message_id !== messages.at(0)?.message_id) {
    // https://github.com/TanStack/virtual/discussions/195?sort=new#discussioncomment-10915070
    // https://stackblitz.com/edit/tanstack-query-xrw3fp?file=src%2Fpages%2Findex.js

    if (!oldestMessage.current) {
      // First load of messages

      let index = messageCount - 1;

      if (
        selectedChat?.oldestUnreadMessageIdByTrainer &&
        selectedChat.messages?.length
      ) {
        index = selectedChat.messages.findIndex(
          (m) => m.message_id === selectedChat.oldestUnreadMessageIdByTrainer,
        );
      }

      console.log(
        `Chat: First batch of messages loaded. Scroll to bottom / oldest unread. index: ${index}`,
      );

      virtualizer.calculateRange();

      setTimeout(() => {
        virtualizer.calculateRange();

        virtualizer.scrollToIndex(index, {
          align: "start",
        });

        requestAnimationFrame(() => {
          setDidFirstScroll(true);
        });
      }, 250);
    } else {
      // More messages were loaded, try to keep scroll position the same

      const delta = messages.findIndex(
        (m) => m.message_id === oldestMessage.current?.message_id,
      );
      const offset = (virtualizer.scrollOffset ?? 0) + delta * itemSize;
      virtualizer.scrollOffset = offset;
      virtualizer.calculateRange();

      console.log(
        `Chat: Older messages were prepended. Keep scroll position the same. offset: ${offset}`,
      );
      virtualizer.scrollToOffset(offset, { align: "start" });
    }
  } else if (
    newestMessage.current?.message_id !== messages.at(-1)?.message_id
  ) {
    const index = messageCount - 1;

    console.log(
      `Chat: Newer messages were appended. Scroll to bottom. index: ${index}`,
    );

    requestAnimationFrame(() => {
      virtualizer.scrollToIndex(index, {
        align: "start",
      });
    });
  }

  oldestMessage.current = messages.at(0);
  newestMessage.current = messages.at(-1);

  const items = virtualizer.getVirtualItems();

  const loadMoreMessages = useCallback(() => {
    if (
      selectedChat?.loadingState === "loading" ||
      selectedChat?.loadingState === "endReached" ||
      !didFirstScroll
    ) {
      return;
    }

    shouldScroll.current = true;

    dispatch(fetchMoreMessages({ chatId: selectedChat?.id ?? "" }));
  }, [selectedChat?.loadingState, dispatch, selectedChat?.id, didFirstScroll]);

  useEffect(() => {
    if (inView) {
      if (selectedChat.firstMessageFetchState === "done") {
        loadMoreMessages();
      } else {
        dispatch(fetchFirstMessages(selectedChat.id));
      }
    }
  }, [inView, dispatch]);

  useEffect(() => {
    if (
      selectedChat &&
      selectedChat.messages.length > 0 &&
      dashMode !== "programming"
    ) {
      dispatch(readChat(selectedChat.id));
    }
  }, [dispatch, selectedChat, trainer?.trainer_id, dashMode]);

  function scrollToTop() {
    if (virtualizer) {
      virtualizer.scrollToOffset(0, { align: "start" });
    }
  }

  function handleGoToTop() {
    if (selectedChat?.loadingState === "endReached") {
      scrollToTop();
    } else {
      shouldScroll.current = false;

      dispatch(
        fetchMoreMessages({
          chatId: selectedChat!.id,
          all: true,
        }),
      ).then(() => {
        scrollToTop();
      });
    }
  }

  const oldestUnreadMessageIdByClient = useMemo(() => {
    return getOldestUnreadMessageByClient({
      messages: messages ?? [],
      trainerId: trainer?.trainer_id ?? "",
    })?.message_id;
  }, [
    selectedChat?.id,
    selectedChat?.firstMessageFetchState,
    trainer?.trainer_id,
    messages,
  ]);

  return (
    <div
      style={{
        overflowY: "auto",
        flex: 1,
        paddingLeft: isChatFullscreen ? "16px" : "8px",
        paddingRight: isChatFullscreen ? "16px" : "8px",
        contain: "strict",
      }}
      ref={parentRef}
    >
      <Box
        sx={{
          height: "50px",
          maxHeight: "50px",
          overflow: "hidden",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
          flexDirection: "column",
        }}
        ref={ref}
      >
        {selectedChat.loadingState === "loading" ||
        selectedChat.loadingState === "succeeded" ? (
          <>
            <Typography sx={{ textAlign: "center" }}>
              Loading more...
            </Typography>
            <LinearProgress sx={{ width: 200 }} />
          </>
        ) : selectedChat.loadingState === "endReached" ? (
          <Typography sx={{ textAlign: "center" }}>
            The very beginning
          </Typography>
        ) : selectedChat.loadingState === "failed" ? (
          <Button
            startIcon={<ErrorRoundedIcon />}
            onClick={() => {
              loadMoreMessages();
            }}
          >
            Error loading messages. Try again
          </Button>
        ) : (
          <Button
            onClick={() => {
              loadMoreMessages();
            }}
          >
            Load more
          </Button>
        )}
      </Box>
      {ticketForChat && <TicketMessageCard chat={selectedChat} />}
      <div
        style={{
          height: `${virtualizer.getTotalSize()}px`,
          width: "100%",
          position: "relative",
          overflowAnchor: "none",
        }}
      >
        {items.map((virtualRow) => {
          const message = messages[virtualRow.index];
          const nextMessage = messages.at(virtualRow.index + 1);
          const previousMessage =
            virtualRow.index === 0
              ? undefined
              : messages.at(virtualRow.index - 1);

          return (
            <div
              key={virtualRow.key}
              data-index={virtualRow.index}
              ref={virtualizer.measureElement}
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                transform: `translateY(${
                  virtualRow.start - virtualizer.options.scrollMargin
                }px)`,
              }}
            >
              <MessageCell
                message={message}
                trainerId={trainer?.trainer_id ?? ""}
                nextMessage={nextMessage}
                previousMessage={previousMessage}
                isOldestUnreadMessageByClient={
                  message.message_id === oldestUnreadMessageIdByClient
                }
                isOldestUnreadMessageByTrainer={
                  message.message_id ===
                  selectedChat.oldestUnreadMessageIdByTrainer
                }
              />
            </div>
          );
        })}
      </div>
      <ClientChatFooter
        onGoToTop={handleGoToTop}
        scrollToWorkoutLog={(logId) => {
          const index = messages.findIndex(
            (m) =>
              m.type === "habit_task_log" &&
              m.habit_task.workout_log_id === logId,
          );

          console.log(`Scroll to workout log: ${logId}, index: ${index}`);

          if (index !== -1) {
            virtualizer.scrollToIndex(index, {
              align: "start",
              behavior: "smooth",
            });
          }
        }}
      />
    </div>
  );
}
