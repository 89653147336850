import { Card, Typography } from "@mui/material";
import { shallowEqual } from "react-redux";
import { AutosaveRichTextField } from "src/components/misc/AutosaveRichTextField";
import AutosaveTextField from "src/components/misc/AutosaveTextField";
import { useAppDispatch, useAppSelector } from "src/hooks/stateHooks";
import { updateClient } from "src/slices/clientSlice";
import { selectPrimaryTrainer } from "src/slices/trainerSlice";
import { ClientSource } from "./ClientSource";

type Props = {
  userId: string;
};

export default function OnboardingNotesPanel({ userId }: Props) {
  const dispatch = useAppDispatch();
  const trainer = useAppSelector(selectPrimaryTrainer);
  const client = useAppSelector(
    (state) =>
      state.client.client?.user_id === userId
        ? {
            quick_notes: state.client.client.quick_notes,
            personal_notes: state.client.client.personal_notes,
            outcome_notes: state.client.client.outcome_notes,
            process_notes: state.client.client.process_notes,
            injuries_notes: state.client.client.injuries_notes,
            programming_notes: state.client.client.programming_notes,
            notes: state.client.client.notes,
          }
        : undefined,
    shallowEqual,
  );

  if (!trainer || !trainer.note_templates || !client) {
    return null;
  }

  return (
    <Card variant="outlined" sx={{ p: 2 }}>
      <Typography variant="h2" sx={{ mb: 2 }}>
        📝 Notes
      </Typography>
      <AutosaveTextField
        label="Personal notes"
        defaultValue={client.personal_notes ?? trainer?.note_templates.personal}
        onSave={(newValue) => {
          dispatch(
            updateClient({
              user_id: userId,
              personal_notes: newValue,
            }),
          );
        }}
        name="onboardingNotesPanel.personalNotes"
      />
      <ClientSource userId={userId} />
      <AutosaveTextField
        label="Outcome goals"
        defaultValue={
          client.outcome_notes ?? trainer?.note_templates.goal_outcome
        }
        onSave={(newValue) => {
          dispatch(
            updateClient({
              user_id: userId,
              outcome_notes: newValue,
            }),
          );
        }}
        name="onboardingNotesPanel.outcomeGoalsNotes"
      />
      <AutosaveTextField
        label="Process goals"
        defaultValue={
          client.process_notes ?? trainer?.note_templates.goal_process
        }
        onSave={(newValue) => {
          dispatch(
            updateClient({
              user_id: userId,
              process_notes: newValue,
            }),
          );
        }}
        name="onboardingNotesPanel.processGoalsNotes"
      />
      <AutosaveTextField
        label="History"
        defaultValue={
          client.injuries_notes ?? trainer?.note_templates.exercise_history
        }
        onSave={(newValue) => {
          dispatch(
            updateClient({
              user_id: userId,
              injuries_notes: newValue,
            }),
          );
        }}
        name="onboardingNotesPanel.exerciseHistoryNotes"
      />
      <AutosaveTextField
        label="Programming"
        defaultValue={
          client.programming_notes ?? trainer?.note_templates.programming
        }
        onSave={(newValue) => {
          dispatch(
            updateClient({
              user_id: userId,
              programming_notes: newValue,
            }),
          );
        }}
        name="onboardingNotesPanel.programmingNotes"
      />
      {/* <OnboardingHabitsPanel client={client} /> */}
      <AutosaveTextField
        label="Quick notes"
        defaultValue={client.quick_notes ?? trainer?.note_templates.quick}
        onSave={(newValue) => {
          dispatch(
            updateClient({
              user_id: userId,
              quick_notes: newValue,
            }),
          );
        }}
        name="onboardingNotesPanel.quickNotes"
      />
      <AutosaveRichTextField
        label="Detailed notes"
        defaultValue={client.notes || trainer?.note_templates.detailed}
        onSave={(newValue) => {
          dispatch(
            updateClient({
              user_id: userId,
              notes: newValue,
            }),
          );
        }}
      />
    </Card>
  );
}
