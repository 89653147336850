import { Box, Typography } from "@mui/material";

export default function AccessDeniedPage() {
  return (
    <Box
      sx={{
        width: "100%",
        height: "100vh",
        maxHeight: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        backgroundColor: (theme) => theme.palette.background.default,
      }}
    >
      <Box sx={{ mb: 4 }}>
        <img
          src="/assets/trainwell-wordmark.svg"
          alt="Logo"
          height={60}
          width={200}
        />
      </Box>
      <Typography variant="h1" sx={{ mb: 1 }}>
        Access disabled
      </Typography>
      <Typography>You don&apos;t have access to the trainwell dash</Typography>
    </Box>
  );
}
