import ClearRoundedIcon from "@mui/icons-material/ClearRounded";
import SendRoundedIcon from "@mui/icons-material/SendRounded";
import {
  Button,
  ButtonGroup,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  type SxProps,
  type Theme,
} from "@mui/material";
import { isBlank } from "@trainwell/features";
import { useState } from "react";
import { useAppDispatch, useAppSelector } from "src/hooks/stateHooks";
import { dismissActionItemWithType } from "src/slices/actionItemSlice";
import { selectSelectedChat, sendTextMessage } from "src/slices/chatSlice";
import { selectPrimaryTrainer } from "src/slices/trainerSlice";

type Props = {
  sx?: SxProps<Theme>;
};

export default function SendPrecallSurveyButton({ sx = [] }: Props) {
  const dispatch = useAppDispatch();
  const selectedChat = useAppSelector(selectSelectedChat);
  const trainer = useAppSelector(selectPrimaryTrainer);
  const [preCallSurveyDialogOpen, setPreCallSurveyDialogOpen] = useState(false);
  const [preCallSurveyMessage, setPreCallSurveyMessage] = useState(
    trainer?.pre_call_survey_checkin,
  );

  if (!selectedChat) {
    return null;
  }

  return (
    <>
      <ButtonGroup variant="outlined" disableElevation size="small" sx={sx}>
        <Button
          startIcon={<SendRoundedIcon />}
          onClick={() => {
            if (!selectedChat) {
              return;
            }

            setPreCallSurveyDialogOpen(true);
          }}
        >
          Send pre-call survey
        </Button>
        <Button
          size="small"
          onClick={() => {
            dispatch(
              dismissActionItemWithType({
                userId: selectedChat.id,
                type: "send_pre_check_in_survey",
              }),
            );
          }}
        >
          <ClearRoundedIcon />
        </Button>
      </ButtonGroup>
      <Dialog
        open={preCallSurveyDialogOpen}
        onClose={() => {
          setPreCallSurveyDialogOpen(false);
        }}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle>Send a pre-call survey</DialogTitle>
        <DialogContent>
          <DialogContentText sx={{ mb: 2 }}>
            Send a survey to {selectedChat?.clientName} before their check-in
            call to save time on the call.
          </DialogContentText>
          <TextField
            multiline
            minRows={2}
            fullWidth
            label="Message"
            value={preCallSurveyMessage}
            onChange={(event) => {
              setPreCallSurveyMessage(event.target.value);
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button
            variant="text"
            onClick={() => {
              if (!selectedChat) {
                return;
              }

              dispatch(
                dismissActionItemWithType({
                  userId: selectedChat.id,
                  type: "send_pre_check_in_survey",
                }),
              );

              setPreCallSurveyDialogOpen(false);
            }}
          >
            This client doesn&apos;t need a message
          </Button>
          <Button
            variant="contained"
            startIcon={<SendRoundedIcon />}
            onClick={() => {
              if (preCallSurveyMessage && selectedChat?.id) {
                dispatch(
                  sendTextMessage({
                    text: preCallSurveyMessage,
                    userId: selectedChat.id,
                  }),
                );

                dispatch(
                  dismissActionItemWithType({
                    userId: selectedChat.id,
                    type: "send_pre_check_in_survey",
                  }),
                );

                setPreCallSurveyDialogOpen(false);
              }
            }}
            disabled={isBlank(preCallSurveyMessage)}
          >
            Send message
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
