import BookmarkRoundedIcon from "@mui/icons-material/BookmarkRounded";
import CalendarMonthRoundedIcon from "@mui/icons-material/CalendarMonthRounded";
import ChatBubbleRoundedIcon from "@mui/icons-material/ChatBubbleRounded";
import FitnessCenterRoundedIcon from "@mui/icons-material/FitnessCenterRounded";
import PriorityHighRoundedIcon from "@mui/icons-material/PriorityHighRounded";
import VideocamRoundedIcon from "@mui/icons-material/VideocamRounded";
import type { SvgIconProps } from "@mui/material";
import type { SaveForLaterType } from "@trainwell/types";

type Props = {
  type: SaveForLaterType;
} & SvgIconProps;

export function SaveForLaterIcon({ type, ...rest }: Props) {
  switch (type) {
    case "calendar":
      return <CalendarMonthRoundedIcon {...rest} />;
    case "message":
      return <ChatBubbleRoundedIcon {...rest} />;
    case "priority":
      return <PriorityHighRoundedIcon {...rest} />;
    case "video":
      return <VideocamRoundedIcon {...rest} />;
    case "workout":
      return <FitnessCenterRoundedIcon {...rest} />;

    default:
      return <BookmarkRoundedIcon {...rest} />;
  }
}
