import { Box, Typography } from "@mui/material";
import { format } from "date-fns";
import LoadingComponent from "src/components/miscPages/LoadingComponent";
import { useAppSelector } from "src/hooks/stateHooks";
import { useGetLogQuery } from "src/slices/api/logApi";
import DetailsPanel from "./DetailsPanel";
import LogCycleCell from "./LogCycleCell";

type Props = {
  logId: string;
};

export default function LogOverview({ logId }: Props) {
  const client = useAppSelector((state) => state.client.client!);
  const exerciseLibraryStatus = useAppSelector(
    (state) => state.app.exerciseStatus,
  );
  const { data: log } = useGetLogQuery(logId);

  if (!log || exerciseLibraryStatus !== "succeeded") {
    return <LoadingComponent message="Fetching log" />;
  }

  return (
    <Box
      sx={{
        height: "100%",
      }}
    >
      <Box
        sx={{
          backgroundColor: (theme) => theme.palette.background.paper,
          px: 2,
          py: 1,
          textAlign: "start",
          borderBottom: 1,
          borderColor: "divider",
        }}
      >
        <Typography variant="h1">{client.full_name}&apos;s log</Typography>
        <Typography variant="overline">{log.name}</Typography>
        <Typography variant="overline">
          {format(
            new Date(log.log_summary.date_started),
            "EEEE, MMM do yyyy, h:mm a",
          )}
        </Typography>
      </Box>
      {log.share_photo_with_coach && log.photo_url && (
        <Box sx={{ m: 1 }}>
          <Typography sx={{ fontWeight: "bold" }}>FitFrame</Typography>
          <img
            style={{ borderRadius: "8px", objectFit: "cover" }}
            width={200}
            height={267}
            alt="fitframe"
            src={log.photo_url}
          />
        </Box>
      )}
      <Box
        sx={{
          padding: 1,
          backgroundColor: (theme) => theme.palette.background.default,
          borderTop: 1,
          borderColor: "divider",
        }}
      >
        {log.sections.map((section) =>
          section.cycles.map((cycle, cycleIndex) => (
            <LogCycleCell
              logId={log.log_id}
              key={cycle.cycle_id}
              section={section}
              cycle={cycle}
              cycleIndex={cycleIndex}
              enablePreview={true}
            />
          )),
        )}
      </Box>
      <DetailsPanel log={log} />
    </Box>
  );
}
