import { Typography } from "@mui/material";
import { shallowEqual } from "react-redux";
import { Fragment } from "react/jsx-runtime";
import { AutosaveRichTextField } from "src/components/misc/AutosaveRichTextField";
import AutosaveTextField from "src/components/misc/AutosaveTextField";
import { useAppDispatch, useAppSelector } from "src/hooks/stateHooks";
import { setNotesSaving, updateClient } from "src/slices/clientSlice";
import { selectPrimaryTrainer } from "src/slices/trainerSlice";
import { ClientSource } from "./ClientSource";

type Props = {
  userId: string;
};

export function ClientNotes({ userId }: Props) {
  const dispatch = useAppDispatch();
  const trainer = useAppSelector(selectPrimaryTrainer);
  const client = useAppSelector(
    (state) =>
      state.client.client?.user_id === userId
        ? {
            quick_notes: state.client.client.quick_notes,
            personal_notes: state.client.client.personal_notes,
            outcome_notes: state.client.client.outcome_notes,
            process_notes: state.client.client.process_notes,
            injuries_notes: state.client.client.injuries_notes,
            source_notes: state.client.client.source_notes,
            programming_notes: state.client.client.programming_notes,
            notes: state.client.client.notes,
          }
        : undefined,
    shallowEqual,
  );
  const notesDialogOpen = useAppSelector(
    (state) => state.client.notesDialogOpen,
  );

  if (!client || !trainer?.note_templates) {
    return;
  }

  return (
    <Fragment key={String(notesDialogOpen)}>
      <AutosaveTextField
        label="Quick notes"
        defaultValue={client.quick_notes ?? trainer?.note_templates.quick}
        onSave={(newValue) => {
          dispatch(
            updateClient({
              user_id: userId,
              quick_notes: newValue,
            }),
          )
            .unwrap()
            .then(() => {
              dispatch(setNotesSaving(false));
            });
        }}
        onChange={() => {
          dispatch(setNotesSaving(true));
        }}
        name="notesPanel.quickNotes"
      />
      <AutosaveTextField
        label="Outcome goals"
        defaultValue={
          client.outcome_notes ?? trainer?.note_templates.goal_outcome
        }
        onSave={(newValue) => {
          dispatch(
            updateClient({
              user_id: userId,
              outcome_notes: newValue,
            }),
          )
            .unwrap()
            .then(() => {
              dispatch(setNotesSaving(false));
            });
        }}
        onChange={() => {
          dispatch(setNotesSaving(true));
        }}
        name="notesPanel.outcomeGoalsNotes"
      />
      <AutosaveTextField
        label="Process goals"
        defaultValue={
          client.process_notes ?? trainer?.note_templates.goal_process
        }
        onSave={(newValue) => {
          dispatch(
            updateClient({
              user_id: userId,
              process_notes: newValue,
            }),
          )
            .unwrap()
            .then(() => {
              dispatch(setNotesSaving(false));
            });
        }}
        onChange={() => {
          dispatch(setNotesSaving(true));
        }}
        name="notesPanel.processGoalsNotes"
      />
      <AutosaveTextField
        label="Exercise history"
        defaultValue={
          client.injuries_notes ?? trainer?.note_templates.exercise_history
        }
        onSave={(newValue) => {
          dispatch(
            updateClient({
              user_id: userId,
              injuries_notes: newValue,
            }),
          )
            .unwrap()
            .then(() => {
              dispatch(setNotesSaving(false));
            });
        }}
        onChange={() => {
          dispatch(setNotesSaving(true));
        }}
        name="notesPanel.exerciseHistoryNotes"
      />
      <ClientSource userId={userId} />
      {client.source_notes && (
        <Typography variant="overline" sx={{ mb: 4 }}>
          Old source: {client.source_notes}
        </Typography>
      )}
      {/* <AutosaveTextField
        label="Source"
        defaultValue={client.source_notes ?? trainer?.note_templates.source}
        onSave={(newValue) => {
          dispatch(
            updateClient({
              user_id: userId,
              source_notes: newValue,
            }),
          )
            .unwrap()
            .then(() => {
              dispatch(setNotesSaving(false));
            });
        }}
        onChange={() => {
          dispatch(setNotesSaving(true));
        }}
        name="notesPanel.sourceNotes"
        disabled
      /> */}
      <AutosaveTextField
        label="Personal notes"
        defaultValue={client.personal_notes ?? trainer?.note_templates.personal}
        onSave={(newValue) => {
          dispatch(
            updateClient({
              user_id: userId,
              personal_notes: newValue,
            }),
          )
            .unwrap()
            .then(() => {
              dispatch(setNotesSaving(false));
            });
        }}
        onChange={() => {
          dispatch(setNotesSaving(true));
        }}
        name="notesPanel.personalNotes"
      />
      <AutosaveTextField
        label="Programming"
        defaultValue={
          client.programming_notes ?? trainer?.note_templates.programming
        }
        onSave={(newValue) => {
          dispatch(
            updateClient({
              user_id: userId,
              programming_notes: newValue,
            }),
          )
            .unwrap()
            .then(() => {
              dispatch(setNotesSaving(false));
            });
        }}
        onChange={() => {
          dispatch(setNotesSaving(true));
        }}
        name="notesPanel.programmingNotes"
      />
      <AutosaveRichTextField
        label="Detailed notes"
        defaultValue={client.notes ?? trainer?.note_templates.detailed}
        onSave={(newValue) => {
          dispatch(
            updateClient({
              user_id: userId,
              notes: newValue,
            }),
          )
            .unwrap()
            .then(() => {
              dispatch(setNotesSaving(false));
            });
        }}
        onChange={() => {
          dispatch(setNotesSaving(true));
        }}
      />
    </Fragment>
  );
}
