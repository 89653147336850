import EditRoundedIcon from "@mui/icons-material/EditRounded";
import StickyNote2RoundedIcon from "@mui/icons-material/StickyNote2Rounded";
import {
  Box,
  Checkbox,
  IconButton,
  LinearProgress,
  Stack,
  Tooltip,
  Typography,
  type SxProps,
  type Theme,
} from "@mui/material";
import { useUpdateMilestone, type Milestone } from "@trainwell/features";
import { differenceInSeconds, format, isFuture } from "date-fns";
import { useState } from "react";
import { MilestoneCellExercise } from "./MilestoneCellExercise";
import { MilestoneCellProgressMetric } from "./MilestoneCellProgressMetric";
import { MilestoneCellText } from "./MilestoneCellText";
import MilestonePopover from "./MilestonePopover";

type Props = {
  sx?: SxProps<Theme>;
  milestone: Milestone;
};

export function MilestoneCell({ sx = [], milestone }: Props) {
  const [editAndhowEl, setEditAnchorEl] = useState<HTMLElement | null>(null);
  const updateMilestone = useUpdateMilestone();

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        ...sx,
      }}
    >
      <Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <Checkbox
            checked={Boolean(milestone.date_achieved)}
            onChange={(event) => {
              if (event.target.checked) {
                updateMilestone.mutate({
                  milestoneId: milestone._id,
                  data: {
                    date_achieved: new Date().toISOString(),
                  },
                });
              } else {
                updateMilestone.mutate({
                  milestoneId: milestone._id,
                  data: {
                    date_achieved: null,
                  },
                });
              }
            }}
            size="small"
          />
          <Box>
            {milestone.type === "exercise" ? (
              <MilestoneCellExercise milestone={milestone} sx={{ flex: 1 }} />
            ) : milestone.type === "progress_metric" ? (
              <MilestoneCellProgressMetric milestone={milestone} />
            ) : (
              <MilestoneCellText milestone={milestone} />
            )}
            {milestone.date_to_achieve && (
              <Box
                sx={{ display: "flex", alignItems: "center", minWidth: 150 }}
              >
                <Typography
                  variant="body2"
                  sx={{
                    color: (theme) => theme.palette.text.secondary,
                  }}
                >
                  {format(milestone.date_created, "MMM d")}
                </Typography>
                <LinearProgress
                  variant="determinate"
                  value={
                    isFuture(milestone.date_to_achieve)
                      ? (differenceInSeconds(
                          new Date(),
                          milestone.date_created,
                        ) /
                          differenceInSeconds(
                            milestone.date_to_achieve,
                            milestone.date_created,
                          )) *
                        100
                      : 100
                  }
                  sx={{
                    flex: 1,
                    height: 3,
                    mx: 1,
                  }}
                />
                <Typography
                  variant="body2"
                  sx={{
                    color: (theme) => theme.palette.text.secondary,
                  }}
                >
                  {format(milestone.date_to_achieve, "MMM d")}
                </Typography>
              </Box>
            )}
          </Box>
        </Box>
      </Box>
      <Stack sx={{ ml: 1 }} direction={"row"} spacing={0} alignItems={"center"}>
        {milestone.notes && (
          <Tooltip title={milestone.notes} disableInteractive>
            <IconButton size="small">
              <StickyNote2RoundedIcon fontSize="inherit" />
            </IconButton>
          </Tooltip>
        )}
        {!milestone.date_achieved && (
          <IconButton
            size="small"
            onClick={(event) => {
              setEditAnchorEl(event.currentTarget);
            }}
            color="inherit"
          >
            <EditRoundedIcon fontSize="inherit" />
          </IconButton>
        )}
      </Stack>
      <MilestonePopover
        anchorEl={editAndhowEl}
        onClose={() => {
          setEditAnchorEl(null);
        }}
        userId={milestone.user_id}
        defaultMilestone={milestone}
      />
    </Box>
  );
}
