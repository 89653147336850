import { monitorForElements } from "@atlaskit/pragmatic-drag-and-drop/element/adapter";
import ChevronLeftRoundedIcon from "@mui/icons-material/ChevronLeftRounded";
import DataArrayRoundedIcon from "@mui/icons-material/DataArrayRounded";
import { Box, Button, Typography } from "@mui/material";
import { useEffect } from "react";
import { useParams } from "react-router";
import LoadingPage from "src/components/miscPages/LoadingPage";
import { useAppDispatch, useAppSelector } from "src/hooks/stateHooks";
import { useGetLogQuery } from "src/slices/api/logApi";
import {
  fetchTemplate,
  fetchWorkout,
  handleDragEndInWorkoutBuilder,
} from "src/slices/workoutSlice";
import { WorkoutLogCard } from "../workout-logs/WorkoutLogCard";
import ExerciseSelector from "./ExerciseSelector/ExerciseSelector";
import TemplateSelector from "./TemplateSelector/TemplateSelector";
import WorkoutBuilder from "./WorkoutBuilder";

type Props = {
  workoutId?: string;
  templateId?: string;
};

export default function WorkoutBuilderParent({
  workoutId: forceWorkoutId,
  templateId: forceTemplateId,
}: Props) {
  // eslint-disable-next-line prefer-const
  let { workoutId, templateId, logId, userId } = useParams();

  if (forceWorkoutId) {
    workoutId = forceWorkoutId;
  }
  if (forceTemplateId) {
    templateId = forceTemplateId;
  }

  const dispatch = useAppDispatch();
  const workoutStatus = useAppSelector((state) => state.workout.status);
  const exercisesOpen = useAppSelector((state) => state.workout.exercisesOpen);
  const currentWorkoutId = useAppSelector(
    (state) => state.workout.workoutNormalized?.result.workout_id,
  );
  const { data: log } = useGetLogQuery(logId ?? "", { skip: !logId });
  const isSelectingTemplate = useAppSelector(
    (state) => state.workout.isSelectingTemplate,
  );
  const exerciseLibraryStatus = useAppSelector(
    (state) => state.app.exerciseStatus,
  );

  const foundWorkoutId = workoutId ?? log?.workout_id;

  useEffect(() => {
    if (foundWorkoutId && currentWorkoutId !== foundWorkoutId) {
      dispatch(
        fetchWorkout({ workoutId: foundWorkoutId, fromLog: Boolean(logId) }),
      );
    }
  }, [dispatch, foundWorkoutId, currentWorkoutId]);

  useEffect(() => {
    if (
      templateId &&
      (workoutStatus === "idle" ||
        (currentWorkoutId && currentWorkoutId !== templateId))
    ) {
      dispatch(fetchTemplate(templateId));
    }
  }, [dispatch, workoutStatus, templateId, currentWorkoutId]);

  useEffect(() => {
    return monitorForElements({
      onDrop(dropResult) {
        dispatch(handleDragEndInWorkoutBuilder(dropResult));
      },
      canMonitor: ({ source }) =>
        [
          "section",
          "exercise",
          "empty_section",
          "exercise_source",
          "template",
          "empty_workout",
        ].includes(source.data.type as string),
    });
  }, [dispatch]);

  if (log && !log.workout_id) {
    return (
      <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
        {userId && (
          <Box sx={{ ml: 2 }}>
            <Button
              variant="text"
              size="small"
              startIcon={<ChevronLeftRoundedIcon />}
              href={`/clients/${userId}`}
              sx={{ mt: 0.5 }}
            >
              Calendar
            </Button>
          </Box>
        )}
        <WorkoutLogCard />
        <Box
          sx={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <DataArrayRoundedIcon
            sx={{ color: (theme) => theme.palette.text.secondary }}
          />
          <Typography
            sx={{
              textAlign: "center",
              color: (theme) => theme.palette.text.secondary,
            }}
          >
            This movement doesn&apos;t have a trainwell workout log
          </Typography>
        </Box>
      </Box>
    );
  }

  if (exerciseLibraryStatus !== "succeeded") {
    return <LoadingPage message="Fetching trainwell exercise library" />;
  }

  return (
    <>
      <Box sx={{ display: "flex", width: "100%" }}>
        {(isSelectingTemplate || exercisesOpen) && (
          <Box
            sx={{
              width: "220px",
              borderRight: 1,
              borderColor: "divider",
            }}
          >
            {isSelectingTemplate ? <TemplateSelector /> : <ExerciseSelector />}
          </Box>
        )}
        <Box sx={{ flex: 1 }}>
          <WorkoutBuilder />
        </Box>
      </Box>
    </>
  );
}
