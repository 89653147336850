import ArrowBackRoundedIcon from "@mui/icons-material/ArrowBackRounded";
import {
  Box,
  Button,
  FormControlLabel,
  FormGroup,
  Stack,
  Switch,
  Typography,
} from "@mui/material";
import { useVacations } from "@trainwell/features";
import React, { useState } from "react";
import ControlBar from "src/components/misc/ControlBar";
import LoadingPage from "src/components/miscPages/LoadingPage";
import NotFoundComponent from "src/components/miscPages/NotFoundComponent";
import { SimpleVacationCell } from "src/features/admin/company/SimpleVacationCell";
import { useAppSelector } from "src/hooks/stateHooks";
import { selectPrimaryTrainer } from "src/slices/trainerSlice";

export default function CoachVacationsPage() {
  const trainer = useAppSelector(selectPrimaryTrainer);
  const [onlyUpcoming, setOnlyUpcoming] = useState(true);
  const [now] = useState(new Date().toISOString());
  const { data: vacations, isPending: isPendingVacations } = useVacations({
    filter: {
      trainer_id: trainer?.trainer_id,
      starting_after: onlyUpcoming ? now : undefined,
    },
  });

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setOnlyUpcoming(event.target.checked);
  };

  return (
    <>
      <ControlBar
        leftContent={
          <Button
            variant="contained"
            href="/"
            startIcon={<ArrowBackRoundedIcon />}
            size="small"
          >
            Back
          </Button>
        }
      />
      <Box sx={{ px: 3, pt: 3, borderBottom: 1, borderColor: "divider" }}>
        <Typography variant="h1" sx={{ mb: 2 }}>
          Vacations
        </Typography>
        <FormGroup sx={{ mb: 2 }}>
          <FormControlLabel
            control={<Switch checked={onlyUpcoming} onChange={handleChange} />}
            label="Show only upcoming"
          />
        </FormGroup>
      </Box>
      {isPendingVacations ? (
        <LoadingPage message="Getting vacations" />
      ) : vacations?.length === 0 ? (
        <NotFoundComponent message="No vacations found" />
      ) : (
        <Stack
          direction="column"
          sx={{
            backgroundColor: (theme) => theme.palette.background.paper,
          }}
        >
          {vacations?.map((vacation) => (
            <SimpleVacationCell
              key={vacation.id}
              vacation={vacation}
              disableEditing
            />
          ))}
        </Stack>
      )}
    </>
  );
}
