import MarkUnreadChatAltRoundedIcon from "@mui/icons-material/MarkUnreadChatAltRounded";
import { IconButton, Tooltip } from "@mui/material";
import { useEffect, useRef } from "react";
import { useAppDispatch, useAppSelector } from "src/hooks/stateHooks";
import { selectClientChats, setChatView } from "src/slices/chatSlice";

export function ThreadsButton() {
  const dispatch = useAppDispatch();
  let chats = useAppSelector(selectClientChats);
  const displayedChatIds = useRef<string[]>([]);

  useEffect(() => {
    displayedChatIds.current = chats.map((chat) => chat.id);
  }, []);

  chats = chats.filter(
    (chat) =>
      chat.oldestUnreadMessageFromClient ||
      displayedChatIds.current.includes(chat.id),
  );

  return (
    <Tooltip disableInteractive title="Unread threads">
      <span>
        <IconButton
          size="small"
          onClick={(event) => {
            event.stopPropagation();

            dispatch(setChatView("threads"));
          }}
          onMouseDown={(event) => {
            event.stopPropagation();
          }}
          sx={{ mr: 1 }}
          disabled={chats.length === 0}
        >
          <MarkUnreadChatAltRoundedIcon fontSize="inherit" />
        </IconButton>
      </span>
    </Tooltip>
  );
}
