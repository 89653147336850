import CloudDoneOutlinedIcon from "@mui/icons-material/CloudDoneOutlined";
import CloudOffRoundedIcon from "@mui/icons-material/CloudOffRounded";
import ErrorRoundedIcon from "@mui/icons-material/ErrorRounded";
import LoopRoundedIcon from "@mui/icons-material/LoopRounded";
import { Box, Button, Tooltip, Typography, debounce } from "@mui/material";
import * as Sentry from "@sentry/react";
import { useSnackbar } from "notistack";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "src/hooks/stateHooks";
import { selectIsGhosting } from "src/slices/trainerSlice";
import { saveWorkout } from "src/slices/workoutSlice";

export function WorkoutAutoSave() {
  const dispatch = useAppDispatch();
  const workout = useAppSelector((state) => state.workout.workoutNormalized);
  const isGhosting = useAppSelector(selectIsGhosting);
  const [isAutosaveDisabled, setIsAutosaveDisabled] = useState(isGhosting);
  const [saveStatus, setSaveStatus] = useState<"saved" | "saving" | "error">(
    "saved",
  );
  const { enqueueSnackbar } = useSnackbar();

  const handleSaveWorkout = useCallback(
    (workoutId: string) => {
      console.log(`Autosave: saving workout '${workoutId}'`);

      dispatch(saveWorkout(workoutId))
        .unwrap()
        .then(() => {
          console.log("Autosave: saved workout");

          setSaveStatus("saved");
        })
        .catch(() => {
          console.error("Autosave: error saving workout");

          enqueueSnackbar("Error saving workout", {
            variant: "error",
          });

          Sentry.captureException(new Error("Error saving workout"));

          setSaveStatus("error");
        });
    },
    [dispatch, enqueueSnackbar],
  );

  const debouncedSaveWorkout = useMemo(() => {
    return debounce(handleSaveWorkout, 3000);
  }, [handleSaveWorkout]);

  useEffect(() => {
    if (isGhosting) {
      setIsAutosaveDisabled(true);
    } else {
      setIsAutosaveDisabled(false);
    }
  }, [isGhosting]);

  const didMount = useRef(false);
  useEffect(() => {
    setSaveStatus("saved");
    didMount.current = false;
    debouncedSaveWorkout.clear();
  }, [workout?.result.workout_id, debouncedSaveWorkout]);

  useEffect(() => {
    if (!workout) {
      return;
    }

    if (!didMount.current) {
      console.log("Autosave: skip first save");

      didMount.current = true;

      return;
    }

    if (isAutosaveDisabled) {
      console.log("Autosave: disabled");

      return;
    }

    attemptSave();
  }, [dispatch, workout, debouncedSaveWorkout]);

  function attemptSave() {
    if (!workout) {
      return;
    }

    setSaveStatus("saving");

    debouncedSaveWorkout(workout.result.workout_id);
  }

  if (!workout) {
    return null;
  } else if (isAutosaveDisabled) {
    return (
      <Tooltip title={"Autosave is disabled while ghosting"}>
        <Button
          startIcon={
            <CloudOffRoundedIcon sx={{ fontSize: "10px !important" }} />
          }
          variant="text"
          color="error"
          size="small"
          onClick={() => {
            setIsAutosaveDisabled(false);

            handleSaveWorkout(workout.result.workout_id);
          }}
          sx={{
            fontSize: 10,
            py: 0.25,
          }}
        >
          Turn autosave on
        </Button>
      </Tooltip>
    );
  } else if (saveStatus === "saved") {
    return (
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Typography variant="overline" sx={{ mr: 1 }}>
          Saved
        </Typography>
        <CloudDoneOutlinedIcon
          sx={{
            fontSize: (theme) => theme.typography.overline.fontSize,
            color: (theme) => theme.palette.text.secondary,
          }}
        />
      </Box>
    );
  } else if (saveStatus === "error") {
    return (
      <Tooltip title={"Click to try again"}>
        <Button
          startIcon={<ErrorRoundedIcon sx={{ fontSize: "10px !important" }} />}
          variant="text"
          color="error"
          size="small"
          onClick={() => {
            attemptSave();
          }}
          sx={{
            fontSize: 10,
            py: 0.25,
          }}
        >
          Error saving
        </Button>
      </Tooltip>
    );
  } else if (saveStatus === "saving") {
    return (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          color: (theme) => theme.palette.text.secondary,
        }}
      >
        <Typography variant="overline" sx={{ mr: 1 }}>
          Saving...
        </Typography>
        <LoopRoundedIcon
          sx={{
            fontSize: (theme) => theme.typography.overline.fontSize,
            color: (theme) => theme.palette.text.secondary,
          }}
        />
      </Box>
    );
  }

  return null;
}
