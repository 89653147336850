import { exerciseMap } from "./exercises";

export function getExerciseDisplayName(exerciseMasterID: string) {
  if (
    exerciseMap &&
    exerciseMasterID in exerciseMap &&
    "key_readable_copilot" in exerciseMap[exerciseMasterID]
  ) {
    return exerciseMap[exerciseMasterID].key_readable_copilot;
  } else {
    return "Unknown Name";
  }
}

export function getExerciseIconURL(exerciseMasterID: string) {
  if (
    exerciseMap &&
    exerciseMasterID &&
    exerciseMasterID in exerciseMap &&
    "media_fname" in exerciseMap[exerciseMasterID]
  ) {
    const url =
      "https://copilot-exercise-media.s3.us-east-2.amazonaws.com/60_still_png2/" +
      exerciseMap[exerciseMasterID].media_fname +
      ".png";

    return url;
  }

  return undefined;
}

export function getExerciseVideoURL(exerciseMasterID: string) {
  if (
    exerciseMap &&
    exerciseMasterID &&
    exerciseMasterID in exerciseMap &&
    "media_fname" in exerciseMap[exerciseMasterID]
  ) {
    const url =
      "https://copilot-exercise-media.s3.us-east-2.amazonaws.com/watch2/" +
      exerciseMap[exerciseMasterID].media_fname +
      ".mp4";

    return url;
  }

  return undefined;
}
