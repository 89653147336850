import FormatListBulletedRoundedIcon from "@mui/icons-material/FormatListBulletedRounded";
import { Box, Fade, Paper, Popover, Popper, Typography } from "@mui/material";
import type { CoachMetricTemplateSubmodule } from "@trainwell/types";
import React, { useState } from "react";
import { queryMetric } from "src/lib/metrics";

type Props = {
  metrics: any;
  submodule: CoachMetricTemplateSubmodule;
};

export default function List({ metrics, submodule }: Props) {
  const [listAnchorEl, setListAnchorEl] = useState<HTMLDivElement | null>(null);
  const openList = Boolean(listAnchorEl);
  const [anchorCopyEl, setAnchorCopyEl] = useState<HTMLDivElement | null>(null);
  const openCopy = Boolean(anchorCopyEl);

  const copyListMetric = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    setAnchorCopyEl(e.currentTarget);
    setTimeout(function () {
      setAnchorCopyEl(null);
    }, 500);
  };

  const data = queryMetric(metrics, submodule.list_metric!);

  const count = data.length;
  const popoverContent = data.map((item, i) => (
    <Typography
      key={i}
      sx={{
        p: 0.5,
        fontSize: "12px",
        flex: 1,
        float: "right",
        wordWrap: "break-word",
      }}
    >
      {item}
    </Typography>
  ));

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: (theme) => theme.palette.backgroundSecondary.main,
        flexGrow: 1,
        margin: "0 2px 0 2px",
        borderRadius: "4px",
      }}
      aria-owns={openList ? "mouse-over-popover" : undefined}
      aria-haspopup="true"
      onMouseEnter={(event) => {
        setListAnchorEl(event.currentTarget);
      }}
      onMouseLeave={() => {
        setListAnchorEl(null);
      }}
      onClick={copyListMetric}
      className="list-metric"
      data-clipboard-text={`[${data}]`}
    >
      <FormatListBulletedRoundedIcon style={{ fontSize: "18px" }} />
      <Popover
        open={openList}
        anchorEl={listAnchorEl}
        sx={{
          pointerEvents: "none",
        }}
        PaperProps={{
          sx: {
            pointerEvents: "auto",
          },
        }}
        onClose={() => {
          setListAnchorEl(null);
        }}
        disableRestoreFocus
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <Paper sx={{ maxHeight: "1000px", p: 1 }}>
          <Typography variant="h3" sx={{ textAlign: "center" }}>
            {submodule.metric_title}
          </Typography>
          <Typography sx={{ textAlign: "center", fontWeight: "bold" }}>
            {count}
          </Typography>
          <div
            style={{
              maxWidth: "750px",
              overflow: "auto",
              display: "grid",
              gridAutoFlow: "column",
              gridTemplateRows: "repeat(15, auto)",
            }}
          >
            {popoverContent}
          </div>
        </Paper>
      </Popover>
      <Popper
        open={openCopy}
        anchorEl={anchorCopyEl}
        placement="bottom-start"
        transition
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <Paper style={{ padding: "4px" }}>
              <Typography style={{ fontSize: "12px" }}>Copied!</Typography>
            </Paper>
          </Fade>
        )}
      </Popper>
    </Box>
  );
}
