import CallRoundedIcon from "@mui/icons-material/CallRounded";
import {
  Button,
  type ButtonProps,
  type SxProps,
  type Theme,
} from "@mui/material";
import { useConfirm } from "material-ui-confirm";
import { useSnackbar } from "notistack";
import { cloneElement, type ReactElement } from "react";
import { useAppSelector } from "src/hooks/stateHooks";
import { api } from "src/lib/trainwellApi";
import { selectPrimaryTrainer } from "src/slices/trainerSlice";

type Props = {
  clientPhoneNumber: string;
  sx?: SxProps<Theme>;
  button?: ReactElement<ButtonProps>;
  onCall?: () => void;
};

export function CallClientButton({
  clientPhoneNumber,
  sx = [],
  button,
  onCall,
}: Props) {
  const trainer = useAppSelector(selectPrimaryTrainer);
  const { enqueueSnackbar } = useSnackbar();
  const confirm = useConfirm();

  function showConfirmationDialog() {
    confirm({
      title: "Call client",
      description:
        "Trainwell will call you then your client. The client sees trainwell's phone number and name, NEVER your number.",
      confirmationText: "Call client",
    }).then(() => {
      api.phone.call({
        from_number: trainer!.phone_number,
        to_number: clientPhoneNumber,
      });

      onCall?.();

      enqueueSnackbar({
        message: "Calling client",
        variant: "success",
      });
    });
  }

  const clonedButton = cloneElement(
    button ?? (
      <Button
        size="small"
        startIcon={<CallRoundedIcon />}
        onClick={() => {
          showConfirmationDialog();
        }}
        sx={sx}
      >
        Call client
      </Button>
    ),
    {
      onClick: (event) => {
        button?.props.onClick?.(event);

        showConfirmationDialog();
      },
    },
  );

  return clonedButton;
}
