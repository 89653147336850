import AddRoundedIcon from "@mui/icons-material/AddRounded";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import {
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  IconButton,
  InputLabel,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { isBlank } from "@trainwell/features";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "src/hooks/stateHooks";
import {
  createCategory,
  createSubCategory,
  fetchTicket,
  removeCategory,
  removeSubCategory,
  renameCategory,
  renameSubCategory,
  selectTicketById,
  selectTicketCategories,
  updateTicket,
} from "src/slices/ticketsSlice";

type Props = {
  ticketId: string;
  size?: "medium" | "small";
};

export default function TicketQuickDetails({ ticketId, size }: Props) {
  const dispatch = useAppDispatch();
  const ticketCategories = useAppSelector(selectTicketCategories);
  const ticket = useAppSelector((state) => selectTicketById(state, ticketId));

  const [categoryAnchorEl, setCategoryAnchorEl] =
    useState<HTMLDivElement | null>(null);
  const categoryMenuOpen = Boolean(categoryAnchorEl);

  const [categorySpecificAnchorEl, setCategorySpecificAnchorEl] =
    useState<HTMLDivElement | null>(null);
  const categorySpecificMenuOpen = Boolean(categorySpecificAnchorEl);

  const [newCategoryName, setNewCategoryName] = useState<string | null>(null);
  const [confirmDeleteCateogryKey, setConfirmDeleteCateogryKey] = useState<
    string | null
  >(null);
  const [renamedCategory, setRenamedCategory] = useState<{
    name: string;
    key: string;
  } | null>(null);

  const [newSubCategoryName, setNewSubCategoryName] = useState<{
    name: string;
    parentCategoryKey: string;
  } | null>(null);
  const [confirmDeleteSubCateogry, setConfirmDeleteSubCateogry] = useState<{
    parentCategoryKey: string;
    subCategoryKey: string;
  } | null>(null);
  const [renamedSubCategory, setRenamedSubCategory] = useState<{
    name: string;
    parentCategoryKey: string;
    subCategoryKey: string;
  } | null>(null);

  useEffect(() => {
    if (ticketId && ticket?.id !== ticketId) {
      dispatch(fetchTicket(ticketId));
    }
  }, [ticketId]);

  if (!ticket || !ticketCategories) {
    return <Typography>Loading</Typography>;
  }

  return (
    <>
      <Stack direction={"row"} spacing={2} alignItems="center">
        {ticket.type === "tech_issue" && (
          <>
            <Chip
              size={size}
              label={
                ticketCategories.find(
                  (category) => ticket.category === category.key,
                )?.name
              }
              onDelete={(event) => {
                if (ticket.category !== "none") {
                  dispatch(
                    updateTicket({
                      id: ticketId,
                      category: "none",
                      category_specific: "none",
                    }),
                  );
                } else {
                  setCategoryAnchorEl(event.currentTarget);
                }
              }}
              deleteIcon={
                ticket.category !== "none" ? (
                  <DeleteRoundedIcon />
                ) : (
                  <AddRoundedIcon />
                )
              }
              onClick={(event) => {
                setCategoryAnchorEl(event.currentTarget);
              }}
              color={ticket.category !== "none" ? "blueSurface" : "default"}
            />
            <Menu
              anchorEl={categoryAnchorEl}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
              open={categoryMenuOpen}
              onClose={() => {
                setCategoryAnchorEl(null);
              }}
            >
              {ticketCategories.map((category) => (
                <MenuItem
                  key={category.key}
                  selected={ticket.category === category.key}
                  onClick={() => {
                    setCategoryAnchorEl(null);
                    dispatch(
                      updateTicket({
                        id: ticketId,
                        category: category.key,
                        category_specific: "none",
                      }),
                    );
                  }}
                >
                  <ListItemText primary={category.name} />
                  {category.key !== "none" && (
                    <>
                      <IconButton
                        edge="end"
                        size="small"
                        aria-label="edit"
                        sx={{
                          color: (theme) => theme.palette.text.secondary,
                          ml: 2,
                        }}
                        onClick={(event) => {
                          event.preventDefault();
                          event.stopPropagation();

                          setCategoryAnchorEl(null);
                          setRenamedCategory({
                            key: category.key,
                            name: "",
                          });
                        }}
                      >
                        <EditRoundedIcon fontSize="inherit" />
                      </IconButton>
                      <IconButton
                        edge="end"
                        size="small"
                        aria-label="delete"
                        sx={{
                          color: (theme) => theme.palette.text.secondary,
                          ml: 1,
                        }}
                        onClick={(event) => {
                          event.preventDefault();
                          event.stopPropagation();

                          setCategoryAnchorEl(null);
                          setConfirmDeleteCateogryKey(category.key);
                        }}
                      >
                        <DeleteRoundedIcon fontSize="inherit" />
                      </IconButton>
                    </>
                  )}
                </MenuItem>
              ))}
              <MenuItem
                onClick={() => {
                  setCategoryAnchorEl(null);

                  setNewCategoryName("");
                }}
              >
                <ListItemIcon>
                  <AddRoundedIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText primary="Add new category" />
              </MenuItem>
            </Menu>
            {ticket.category !== "none" && (
              <Chip
                size={size}
                label={
                  ticketCategories
                    .find((category) => ticket.category === category.key)
                    ?.specifics.find(
                      (specific) => specific.key === ticket.category_specific,
                    )?.name
                }
                onDelete={(event) => {
                  if (ticket.category_specific !== "none") {
                    dispatch(
                      updateTicket({
                        id: ticketId,
                        category_specific: "none",
                      }),
                    );
                  } else {
                    setCategorySpecificAnchorEl(event.currentTarget);
                  }
                }}
                deleteIcon={
                  ticket.category_specific !== "none" ? (
                    <DeleteRoundedIcon />
                  ) : (
                    <AddRoundedIcon />
                  )
                }
                onClick={(event) => {
                  setCategorySpecificAnchorEl(event.currentTarget);
                }}
                color={ticket.category !== "none" ? "blueSurface" : "default"}
              />
            )}
            <Menu
              anchorEl={categorySpecificAnchorEl}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
              open={categorySpecificMenuOpen}
              onClose={() => {
                setCategorySpecificAnchorEl(null);
              }}
            >
              {ticketCategories
                .find((category) => ticket.category === category.key)
                ?.specifics.map((subCategory) => (
                  <MenuItem
                    key={subCategory.key}
                    selected={ticket.category_specific === subCategory.key}
                    onClick={() => {
                      setCategorySpecificAnchorEl(null);
                      dispatch(
                        updateTicket({
                          id: ticketId,
                          category_specific: subCategory.key,
                        }),
                      );
                    }}
                  >
                    <ListItemText primary={subCategory.name} />
                    {subCategory.key !== "none" && (
                      <>
                        <IconButton
                          edge="end"
                          size="small"
                          aria-label="edit"
                          sx={{
                            color: (theme) => theme.palette.text.secondary,
                            ml: 2,
                          }}
                          onClick={(event) => {
                            event.preventDefault();
                            event.stopPropagation();

                            setCategorySpecificAnchorEl(null);
                            setRenamedSubCategory({
                              parentCategoryKey: ticket.category,
                              subCategoryKey: subCategory.key,
                              name: "",
                            });
                          }}
                        >
                          <EditRoundedIcon fontSize="inherit" />
                        </IconButton>
                        <IconButton
                          edge="end"
                          size="small"
                          aria-label="delete"
                          sx={{
                            color: (theme) => theme.palette.text.secondary,
                            ml: 1,
                          }}
                          onClick={(event) => {
                            event.preventDefault();
                            event.stopPropagation();

                            setCategorySpecificAnchorEl(null);
                            setConfirmDeleteSubCateogry({
                              parentCategoryKey: ticketCategories.find(
                                (category) => ticket.category === category.key,
                              )!.key,
                              subCategoryKey: subCategory.key,
                            });
                          }}
                        >
                          <DeleteRoundedIcon fontSize="inherit" />
                        </IconButton>
                      </>
                    )}
                  </MenuItem>
                ))}
              <MenuItem
                onClick={() => {
                  setCategorySpecificAnchorEl(null);

                  setNewSubCategoryName({
                    parentCategoryKey: ticketCategories.find(
                      (category) => ticket.category === category.key,
                    )!.key,
                    name: "",
                  });
                }}
              >
                <ListItemIcon>
                  <AddRoundedIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText primary="Add new sub category" />
              </MenuItem>
            </Menu>
          </>
        )}
        <FormControl size="small">
          <InputLabel>State</InputLabel>
          <Select
            value={ticket.state}
            label="State"
            onChange={(event) => {
              dispatch(
                updateTicket({
                  id: ticketId,
                  state: event.target.value as any,
                }),
              );
            }}
          >
            <MenuItem value={"none"}>None</MenuItem>
            <MenuItem value={"in_progress"}>In progress</MenuItem>
            <MenuItem value={"done"}>Done</MenuItem>
          </Select>
        </FormControl>
      </Stack>
      <Dialog
        open={newCategoryName !== null}
        onClose={() => {
          setNewCategoryName(null);
        }}
      >
        <DialogTitle>New category</DialogTitle>
        <DialogContent>
          <TextField
            margin="dense"
            label="Category name"
            value={newCategoryName}
            onChange={(event) => {
              setNewCategoryName(event.target.value);
            }}
            fullWidth
            sx={{ mb: 2, minWidth: "300px" }}
          />
        </DialogContent>
        <DialogActions>
          <Button
            variant="text"
            onClick={() => {
              setNewCategoryName(null);
            }}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            disabled={!newCategoryName || isBlank(newCategoryName)}
            onClick={() => {
              if (!newCategoryName || isBlank(newCategoryName)) {
                return;
              }

              dispatch(createCategory(newCategoryName));
              setNewCategoryName(null);
            }}
          >
            Add
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={confirmDeleteCateogryKey !== null}
        onClose={() => {
          setConfirmDeleteCateogryKey(null);
        }}
      >
        <DialogTitle>Delete category</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete the category{" "}
            <b>
              {
                ticketCategories.find(
                  (category) => category.key === confirmDeleteCateogryKey,
                )?.name
              }
            </b>
            ? Any ticket in this category will change to <b>none</b>.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="text"
            onClick={() => {
              setConfirmDeleteCateogryKey(null);
            }}
          >
            No, don&apos;t delete
          </Button>
          <Button
            variant="contained"
            color="error"
            onClick={() => {
              if (!confirmDeleteCateogryKey) {
                return;
              }

              dispatch(removeCategory(confirmDeleteCateogryKey));
              setConfirmDeleteCateogryKey(null);
            }}
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={renamedCategory !== null}
        onClose={() => {
          setRenamedCategory(null);
        }}
      >
        <DialogTitle>Rename category</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Rename{" "}
            <b>
              {
                ticketCategories.find(
                  (category) => category.key === renamedCategory?.key,
                )?.name
              }
            </b>
            .
          </DialogContentText>
          <TextField
            margin="dense"
            label="Category name"
            value={newCategoryName}
            defaultValue={
              ticketCategories.find(
                (category) => category.key === renamedCategory?.key,
              )?.name
            }
            onChange={(event) => {
              setRenamedCategory({
                key: renamedCategory!.key,
                name: event.target.value,
              });
            }}
            fullWidth
            sx={{ mb: 2, minWidth: "300px" }}
          />
        </DialogContent>
        <DialogActions>
          <Button
            variant="text"
            onClick={() => {
              setRenamedCategory(null);
            }}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            disabled={!renamedCategory || isBlank(renamedCategory.name)}
            onClick={() => {
              if (
                !renamedCategory ||
                !renamedCategory.key ||
                !renamedCategory.name ||
                isBlank(renamedCategory.name)
              ) {
                return;
              }

              dispatch(
                renameCategory({
                  categoryKey: renamedCategory.key,
                  newName: renamedCategory.name,
                }),
              );
              setRenamedCategory(null);
            }}
          >
            Rename
          </Button>
        </DialogActions>
      </Dialog>
      {/* 
      Sub categories 
      */}
      <Dialog
        open={newSubCategoryName !== null}
        onClose={() => {
          setNewSubCategoryName(null);
        }}
      >
        <DialogTitle>New sub-category</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Create a new sub-category for{" "}
            <b>
              {
                ticketCategories.find(
                  (category) =>
                    category.key === newSubCategoryName?.parentCategoryKey,
                )?.name
              }
            </b>
            .
          </DialogContentText>
          <TextField
            margin="dense"
            label="Sub-category name"
            value={newSubCategoryName?.name}
            onChange={(event) => {
              setNewSubCategoryName({
                name: event.target.value,
                parentCategoryKey: newSubCategoryName!.parentCategoryKey,
              });
            }}
            fullWidth
            sx={{ mb: 2, minWidth: "300px" }}
          />
        </DialogContent>
        <DialogActions>
          <Button
            variant="text"
            onClick={() => {
              setNewSubCategoryName(null);
            }}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            disabled={!newSubCategoryName || isBlank(newSubCategoryName.name)}
            onClick={() => {
              if (!newSubCategoryName || isBlank(newSubCategoryName.name)) {
                return;
              }

              dispatch(
                createSubCategory({
                  parentCategoryKey: newSubCategoryName.parentCategoryKey,
                  name: newSubCategoryName.name,
                }),
              );
              setNewSubCategoryName(null);
            }}
          >
            Add
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={confirmDeleteSubCateogry !== null}
        onClose={() => {
          setConfirmDeleteSubCateogry(null);
        }}
      >
        <DialogTitle>Delete sub-category</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete the sub-category{" "}
            <b>
              {
                ticketCategories
                  .find(
                    (category) =>
                      category.key ===
                      confirmDeleteSubCateogry?.parentCategoryKey,
                  )
                  ?.specifics.find(
                    (subCategory) =>
                      subCategory.key ===
                      confirmDeleteSubCateogry?.subCategoryKey,
                  )?.name
              }
            </b>
            ? Any ticket in this sub-category will change to <b>none</b>.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="text"
            onClick={() => {
              setConfirmDeleteSubCateogry(null);
            }}
          >
            No, don&apos;t delete
          </Button>
          <Button
            variant="contained"
            color="error"
            onClick={() => {
              if (!confirmDeleteSubCateogry) {
                return;
              }

              dispatch(
                removeSubCategory({
                  parentCategoryKey: confirmDeleteSubCateogry.parentCategoryKey,
                  subCategoryKey: confirmDeleteSubCateogry.subCategoryKey,
                }),
              );
              setConfirmDeleteSubCateogry(null);
            }}
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={renamedSubCategory !== null}
        onClose={() => {
          setRenamedSubCategory(null);
        }}
      >
        <DialogTitle>Rename-sub category</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Rename{" "}
            <b>
              {
                ticketCategories
                  .find(
                    (category) =>
                      category.key === renamedSubCategory?.parentCategoryKey,
                  )
                  ?.specifics.find(
                    (subCategory) =>
                      subCategory.key === renamedSubCategory?.subCategoryKey,
                  )?.name
              }
            </b>
            .
          </DialogContentText>
          <TextField
            margin="dense"
            label="Sub-category name"
            value={renamedSubCategory?.name}
            defaultValue={
              ticketCategories
                .find(
                  (category) =>
                    category.key === renamedSubCategory?.parentCategoryKey,
                )
                ?.specifics.find(
                  (subCategory) =>
                    subCategory.key === renamedSubCategory?.subCategoryKey,
                )?.name
            }
            onChange={(event) => {
              setRenamedSubCategory({
                subCategoryKey: renamedSubCategory!.subCategoryKey,
                parentCategoryKey: renamedSubCategory!.parentCategoryKey,
                name: event.target.value,
              });
            }}
            fullWidth
            sx={{ mb: 2, minWidth: "300px" }}
          />
        </DialogContent>
        <DialogActions>
          <Button
            variant="text"
            onClick={() => {
              setRenamedSubCategory(null);
            }}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            disabled={!renamedSubCategory || isBlank(renamedSubCategory.name)}
            onClick={() => {
              if (
                !renamedSubCategory ||
                !renamedSubCategory.parentCategoryKey ||
                !renamedSubCategory.subCategoryKey ||
                !renamedSubCategory.name ||
                isBlank(renamedSubCategory.name)
              ) {
                return;
              }

              dispatch(
                renameSubCategory({
                  parentCategoryKey: renamedSubCategory.parentCategoryKey,
                  subCategoryKey: renamedSubCategory.subCategoryKey,
                  newName: renamedSubCategory.name,
                }),
              );
              setRenamedSubCategory(null);
            }}
          >
            Rename
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
