import IosShareRoundedIcon from "@mui/icons-material/IosShareRounded";
import { LoadingButton } from "@mui/lab";
import { Autocomplete, Popover, TextField, Typography } from "@mui/material";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { useAppSelector } from "src/hooks/stateHooks";
import { api } from "src/lib/trainwellApi";
import { selectAllVisibleClients } from "src/slices/clientsSlice";

type Props = {
  phaseId: string;
  anchorEl: Element | null;
  onClose: () => void;
};

export function ShareToClientPopover({ phaseId, anchorEl, onClose }: Props) {
  const [loading, setLoading] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState<string | undefined>();
  const { enqueueSnackbar } = useSnackbar();
  const clients = useAppSelector(selectAllVisibleClients);

  const clientNames = clients.map((client) => {
    return {
      user_id: client.user_id,
      name: client.full_name,
    };
  });

  clientNames.sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0));

  function handleClose() {
    onClose();

    setSelectedUserId(undefined);
  }

  function handleShare() {
    if (!selectedUserId) {
      return;
    }

    setLoading(true);

    api.phases
      .shareToClient({
        phaseId,
        userId: selectedUserId,
      })
      .then(() => {
        setLoading(false);

        enqueueSnackbar("Shared", {
          variant: "success",
        });

        handleClose();
      })
      .catch(() => {
        setLoading(false);

        enqueueSnackbar("Error sharing", {
          variant: "error",
        });
      });
  }

  return (
    <Popover
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      slotProps={{
        paper: { sx: { p: 2, minWidth: "300px" } },
      }}
      onClose={handleClose}
    >
      <Typography variant="h2" sx={{ mb: 2 }}>
        Share to client
      </Typography>
      <Autocomplete
        fullWidth
        options={clientNames}
        onChange={(event, value) => {
          setSelectedUserId(value?.user_id);
        }}
        size="small"
        getOptionLabel={(option) => option.name}
        renderInput={(params) => (
          <TextField {...params} label="Client" placeholder="Select a client" />
        )}
        sx={{ mb: 2 }}
      />
      <LoadingButton
        variant="contained"
        disabled={!selectedUserId}
        startIcon={<IosShareRoundedIcon />}
        onClick={handleShare}
        loading={loading}
      >
        Share
      </LoadingButton>
    </Popover>
  );
}
