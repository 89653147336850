import EditCalendarRoundedIcon from "@mui/icons-material/EditCalendarRounded";
import SendRoundedIcon from "@mui/icons-material/SendRounded";
import { LoadingButton } from "@mui/lab";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Popover,
  TextField,
  Typography,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { isBlank } from "@trainwell/features";
import { differenceInMonths, startOfTomorrow } from "date-fns";
import { useState } from "react";
import { useAppDispatch, useAppSelector } from "src/hooks/stateHooks";
import { api } from "src/lib/trainwellApi";
import {
  dismissActionItemWithType,
  updateActionItem,
} from "src/slices/actionItemSlice";
import { sendTextMessage } from "src/slices/chatSlice";
import { selectClientById } from "src/slices/clientsSlice";
import { selectPrimaryTrainer } from "src/slices/trainerSlice";
import { DialogTitleWithClose } from "./DialogTitleWithClose";

type Props = {
  userId: string;
  actionItemId?: string;
  open: boolean;
  onClose: () => void;
};

export default function CheckInDialog({
  userId,
  actionItemId,
  open,
  onClose,
}: Props) {
  const dispatch = useAppDispatch();
  const client = useAppSelector((state) => selectClientById(state, userId));
  const trainer = useAppSelector(selectPrimaryTrainer);
  const [surveyMessage, setSurveyMessage] = useState(
    trainer?.message_templates.check_in_survey ?? "",
  );
  const [isSending, setIsSending] = useState(false);
  const [rescheduleAnchorEl, setRescheduleAnchorEl] =
    useState<HTMLButtonElement | null>(null);
  const [rescheduleDate, setRescheduleDate] = useState<Date | null>(
    startOfTomorrow(),
  );

  function handleClose() {
    if (isSending) {
      return;
    }

    onClose();
  }

  let dialogText = `${client?.full_name} has been with you and trainwell for ${differenceInMonths(
    new Date(),
    new Date(client?.account.membership.date_membership_started!),
  )} months, send them a survey to check in!`;

  if (client?.date_latest_coach_switch) {
    dialogText = `${client?.full_name} has been with you for ${differenceInMonths(
      new Date(),
      new Date(client.date_latest_coach_switch),
    )} months and with trainwell for ${differenceInMonths(
      new Date(),
      new Date(client?.account.membership.date_membership_started!),
    )} months, send them a survey to check in!`;
  }

  return (
    <div
      onClick={(e) => e.stopPropagation()}
      onMouseDown={(e) => e.stopPropagation()}
      onMouseUp={(e) => e.stopPropagation()}
    >
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
        <DialogTitleWithClose onClose={handleClose}>
          Send a check-in survey
        </DialogTitleWithClose>
        <DialogContent>
          <DialogContentText sx={{ mb: 4 }}>{dialogText}</DialogContentText>
          <TextField
            multiline
            minRows={2}
            fullWidth
            label="Message"
            value={surveyMessage}
            onChange={(event) => {
              setSurveyMessage(event.target.value);
            }}
            sx={{ mb: 2 }}
          />
          <Typography
            variant="body2"
            sx={{ color: (theme) => theme.palette.text.secondary }}
          >
            Message preview
          </Typography>
          <Typography
            sx={{
              backgroundColor: (theme) => theme.palette.primary.main,
              color: (theme) => theme.palette.primary.contrastText,
              borderRadius: `10px 10px 2px 10px`,
              px: 1,
              py: 0.5,
              whiteSpace: "pre-line",
              wordWrap: "break-word",
            }}
          >
            {surveyMessage
              ? surveyMessage + "\n\n" + "https://go.trainwell.net/survey"
              : "https://go.trainwell.net/survey"}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            disabled={isSending}
            variant="text"
            onClick={() => {
              dispatch(
                dismissActionItemWithType({
                  userId: userId,
                  type: "check_in_survey_send",
                }),
              );

              onClose();
            }}
          >
            This client doesn&apos;t need a survey
          </Button>
          {actionItemId && (
            <>
              <Button
                disabled={isSending}
                variant="text"
                startIcon={<EditCalendarRoundedIcon />}
                onClick={(event) => {
                  setRescheduleAnchorEl(event.currentTarget);
                }}
              >
                Reschedule
              </Button>
              <Popover
                anchorEl={rescheduleAnchorEl}
                open={Boolean(rescheduleAnchorEl)}
                onClose={() => {
                  setRescheduleAnchorEl(null);
                }}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                slotProps={{
                  paper: {
                    sx: {
                      p: 2,
                      minWidth: "300px",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-start",
                    },
                  },
                }}
              >
                <Typography variant="h2" sx={{ mb: 2 }}>
                  Reschedule
                </Typography>
                <DatePicker
                  label="Reschedule to..."
                  value={rescheduleDate}
                  minDate={startOfTomorrow()}
                  onChange={(newValue) => {
                    setRescheduleDate(newValue);
                  }}
                  sx={{ mb: 2 }}
                />
                <Button
                  startIcon={<EditCalendarRoundedIcon />}
                  size="small"
                  variant="contained"
                  onClick={() => {
                    dispatch(
                      updateActionItem({
                        actionItemId: actionItemId,
                        dateToSend: rescheduleDate?.toISOString(),
                      }),
                    );
                  }}
                >
                  Reschedule
                </Button>
              </Popover>
            </>
          )}
          <LoadingButton
            loading={isSending}
            variant="contained"
            startIcon={<SendRoundedIcon />}
            onClick={async () => {
              if (surveyMessage) {
                setIsSending(true);

                const { google_form } = await api.googleForms.createOne({
                  userId: userId,
                });

                dispatch(
                  sendTextMessage({
                    text: surveyMessage
                      ? `${surveyMessage}\n\n${google_form.short_url}`
                      : google_form.short_url,
                    userId: userId,
                  }),
                );

                dispatch(
                  dismissActionItemWithType({
                    userId: userId,
                    type: "check_in_survey_send",
                  }),
                );

                onClose();
                setIsSending(false);
              }
            }}
            disabled={isBlank(surveyMessage)}
          >
            Send message
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </div>
  );
}
