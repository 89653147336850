import AutoAwesomeRoundedIcon from "@mui/icons-material/AutoAwesomeRounded";
import ExpandLessRoundedIcon from "@mui/icons-material/ExpandLessRounded";
import ExpandMoreRoundedIcon from "@mui/icons-material/ExpandMoreRounded";
import SwapHorizRoundedIcon from "@mui/icons-material/SwapHorizRounded";
import { Box, IconButton, Stack, Typography } from "@mui/material";
import type { MessageTrainerSwitched } from "@trainwell/types";
import { useState } from "react";
import Markdown from "react-markdown";
import { useAppSelector } from "src/hooks/stateHooks";
import { getTrainerName } from "src/lib/coachUtility";
import { selectTrainerNames } from "src/slices/trainersSlice";

type Props = {
  message: MessageTrainerSwitched;
  combineBottom: boolean;
};

export function TrainerSwitchMessage({ message, combineBottom }: Props) {
  const [showSummary, setShowSummary] = useState(false);
  const trainerNames = useAppSelector(selectTrainerNames);

  let reason: string | null = null;

  if (message.content.reason === "manual_client") {
    reason = "client requested";
  } else if (message.content.reason === "Coach quit") {
    reason = "trainer departure";
  } else if (message.content.reason === "manual_staff") {
    reason = "staff";
  }

  const hasAiSummary = Boolean(
    message.content.ai_client_profile ||
      message.content.ai_recent_chat_highlights,
  );

  return (
    <Box
      sx={{
        maxWidth: "300px",
        borderRadius: `12px 12px 12px ${!combineBottom ? "0px" : "12px"}`,
        overflow: "hidden",
        backgroundColor: (theme) => theme.palette.backgroundSecondary.main,
        p: 1,
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <SwapHorizRoundedIcon
            sx={{
              fontSize: 16,
              color: (theme) => theme.palette.text.primary,
              mr: 1,
            }}
          />
          <Box>
            <Typography
              variant="h6"
              sx={{
                color: (theme) => theme.palette.text.primary,
              }}
            >
              Switched trainer
            </Typography>
            <Typography>
              From{" "}
              {getTrainerName(message.content.trainer_id_old, trainerNames)}
              {reason ? ` (${reason})` : ""}
            </Typography>
          </Box>
        </Box>
        {hasAiSummary && (
          <IconButton
            onClick={() => {
              setShowSummary(!showSummary);
            }}
            sx={{ ml: 1 }}
          >
            {showSummary ? (
              <ExpandLessRoundedIcon />
            ) : (
              <ExpandMoreRoundedIcon />
            )}
          </IconButton>
        )}
      </Box>
      {showSummary && (
        <Box sx={{ pt: 1 }}>
          <Typography>
            AI summaries can be wrong. You should double check important info
            with the client.
          </Typography>
          <Stack
            direction={"column"}
            spacing={4}
            sx={{
              pt: 4,
              ul: {
                paddingInlineStart: "1rem",
              },
            }}
          >
            {message.content.ai_client_profile && (
              <Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <AutoAwesomeRoundedIcon fontSize="small" sx={{ mr: 1 }} />
                  <Box>
                    <Typography variant="h2">Client profile</Typography>
                    <Typography variant="overline">AI generated</Typography>
                  </Box>
                </Box>
                <Markdown>{message.content.ai_client_profile}</Markdown>
              </Box>
            )}
            {message.content.ai_recent_chat_highlights && (
              <Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <AutoAwesomeRoundedIcon fontSize="small" sx={{ mr: 1 }} />
                  <Box>
                    <Typography variant="h2">Recent chat highlights</Typography>
                    <Typography variant="overline">AI generated</Typography>
                  </Box>
                </Box>
                <Markdown>{message.content.ai_recent_chat_highlights}</Markdown>
              </Box>
            )}
            {message.content.ai_chat_summary && (
              <Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <AutoAwesomeRoundedIcon fontSize="small" sx={{ mr: 1 }} />
                  <Box>
                    <Typography variant="h2">Chat summary</Typography>
                    <Typography variant="overline">AI generated</Typography>
                  </Box>
                </Box>
                <Markdown>{message.content.ai_chat_summary}</Markdown>
              </Box>
            )}
          </Stack>
        </Box>
      )}
    </Box>
  );
}
