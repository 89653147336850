import BugReportRoundedIcon from "@mui/icons-material/BugReportRounded";
import DirectionsRunRoundedIcon from "@mui/icons-material/DirectionsRunRounded";
import VisibilityRoundedIcon from "@mui/icons-material/VisibilityRounded";
import {
  Avatar,
  Box,
  Button,
  ButtonBase,
  Card,
  Chip,
  Dialog,
  DialogContent,
  Popover,
  Stack,
  Typography,
} from "@mui/material";
import { format } from "date-fns";
import { useEffect, useState } from "react";
import { useParams } from "react-router";
import { DialogTitleWithClose } from "src/components/misc/DialogTitleWithClose";
import { useAppDispatch } from "src/hooks/stateHooks";
import { dismissActionItemForWorkoutLog } from "src/slices/actionItemSlice";
import {
  useGetLogQuery,
  useGetWorkoutLogAlertsQuery,
} from "src/slices/api/logApi";
import { WorkoutAlertChip } from "../workout-builder/WorkoutAlertChip";
import LogGraph from "../workout-logs/LogGraph";
import LogIconGrid from "../workout-logs/LogIconGrid";
import LogOverview from "../workout-logs/LogOverview";
import { WorkoutLogClientNotes } from "./WorkoutLogClientNotes";
import { WorkoutLogDifficulty } from "./WorkoutLogDifficulty";

export function WorkoutLogCard() {
  const dispatch = useAppDispatch();
  const { logId } = useParams<{ logId: string | undefined }>();
  const { data: log } = useGetLogQuery(logId ?? "", {
    skip: !logId,
  });
  const [fitframOpen, setFitframeOpen] = useState(false);
  const { data: workoutLogAlerts } = useGetWorkoutLogAlertsQuery(logId ?? "", {
    skip: !logId,
  });
  const [previewAnchorEl, setPreviewAnchorEl] = useState<null | HTMLElement>(
    null,
  );

  useEffect(() => {
    if (!logId) {
      return;
    }

    dispatch(dismissActionItemForWorkoutLog(logId));
  }, [logId, dispatch]);

  if (!log || !logId) {
    return null;
  }

  return (
    <Card
      variant="outlined"
      sx={{
        mx: 2,
        mt: 1,
        backgroundColor: (theme) => theme.palette.background.paper,
        pb: 1,
      }}
    >
      <Box
        sx={{
          m: 2,
          display: "flex",
          alignItems: "flex-start",
          justifyContent: "space-between",
        }}
      >
        <Box>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Avatar
              sx={{
                backgroundColor: "#a3e635",
                mr: 1,
                width: 18,
                height: 18,
                borderRadius: "4px",
              }}
            >
              <DirectionsRunRoundedIcon
                sx={{
                  fontSize: 13,
                  color: (theme) => theme.palette.text.primary,
                }}
              />
            </Avatar>
            <Typography variant="h2">
              {log && !log.workout_id
                ? `External workout log (${log.name})`
                : "Workout log"}
            </Typography>
          </Box>
          <Typography variant="overline">
            Submitted{" "}
            {format(
              new Date(log.log_summary.date_started),
              "EEEE, MMM do yyyy, h:mm a",
            )}
          </Typography>
        </Box>
        <Button
          variant="text"
          size="small"
          startIcon={<VisibilityRoundedIcon />}
          onClick={(event) => {
            setPreviewAnchorEl(event.currentTarget);
          }}
        >
          Original log
        </Button>
      </Box>
      <Box sx={{ display: "flex" }}>
        <Box sx={{ width: "250px", ml: 2 }}>
          <LogIconGrid log={log} size="small" sx={{ mb: 2 }} />
          {log.log_summary.biometrics?.heartrate?.times &&
            log.log_summary.biometrics.heartrate.times.length > 0 &&
            logId && <LogGraph logId={logId} />}
          {log.share_photo_with_coach && log.photo_url && (
            <>
              <Typography sx={{ mb: 1, fontWeight: "bold" }}>
                FitFrame
              </Typography>
              <ButtonBase
                onClick={(event) => {
                  event.stopPropagation();
                  event.preventDefault();

                  setFitframeOpen(true);
                }}
                onMouseDown={(event) => {
                  event.stopPropagation();
                  event.preventDefault();
                }}
                sx={{
                  borderRadius: 1,
                  overflow: "hidden",
                  display: "flex",
                  justifyContent: "start",
                  ml: 2,
                }}
              >
                <img
                  width={200}
                  style={{
                    display: "block",
                    objectFit: "cover",
                    borderRadius: "8px",
                  }}
                  src={log.photo_url}
                />
              </ButtonBase>
            </>
          )}
        </Box>
        <Box sx={{ ml: 2, mr: 1, flex: 1 }}>
          {log.log_summary.user_feedback2?.difficulty && (
            <Box sx={{ px: 6, mb: 2 }}>
              <WorkoutLogDifficulty log={log} />
            </Box>
          )}
          <WorkoutLogClientNotes logId={logId} sx={{ mb: 2 }} />
          {log.log_summary.user_feedback2?.tech_feedback.bug_report && (
            <Card variant="outlined" sx={{ p: 1, my: 2 }}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <BugReportRoundedIcon fontSize="inherit" />
                <Typography variant="h6" sx={{ ml: 1 }}>
                  Bug report
                </Typography>
              </Box>
              <Typography>
                {log.log_summary.user_feedback2.tech_feedback.bug_report
                  .description ?? "--"}
              </Typography>
              {(log.log_summary.user_feedback2.tech_feedback.bug_report
                .categories?.length ?? 0) > 0 && (
                <Stack spacing={1} direction={"row"} sx={{ mt: 1 }}>
                  {log.log_summary.user_feedback2.tech_feedback.bug_report.categories?.map(
                    (category) => (
                      <Chip key={category} label={category} size="small" />
                    ),
                  )}
                </Stack>
              )}
            </Card>
          )}
          <Stack direction="column" spacing={1} alignItems={"flex-start"}>
            {workoutLogAlerts?.workout_log_alerts
              .filter((update) => update.trigger.scope === "workout")
              .map((update) => (
                <WorkoutAlertChip update={update} key={update._id} />
              ))}
          </Stack>
        </Box>
      </Box>
      <Popover
        open={Boolean(previewAnchorEl)}
        anchorEl={previewAnchorEl}
        onClose={() => {
          setPreviewAnchorEl(null);
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        slotProps={{
          paper: {
            sx: {
              width: "350px",
            },
          },
        }}
      >
        {Boolean(previewAnchorEl) && <LogOverview logId={logId} />}
      </Popover>
      <Dialog
        onClose={() => {
          setFitframeOpen(false);
        }}
        open={fitframOpen}
      >
        <DialogTitleWithClose
          onClose={() => {
            setFitframeOpen(false);
          }}
        >
          FitFrame
        </DialogTitleWithClose>
        <DialogContent dividers>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              borderRadius: 1,
              overflow: "hidden",
            }}
          >
            <img
              width={500}
              src={log.photo_url || "/assets/profile.png"}
              alt="fitframe"
            />
          </Box>
        </DialogContent>
      </Dialog>
    </Card>
  );
}
