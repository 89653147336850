import CampaignRoundedIcon from "@mui/icons-material/CampaignRounded";
import { LoadingButton } from "@mui/lab";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  TextField,
} from "@mui/material";
import { isBlank } from "@trainwell/features";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useAppSelector } from "src/hooks/stateHooks";
import { api } from "src/lib/trainwellApi";
import { selectPrimaryTrainer } from "src/slices/trainerSlice";

type Props = {
  open: boolean;
  onClose: () => void;
  defaultRequest?: string;
};

export default function PhaseRequestDialog({
  open,
  onClose,
  defaultRequest,
}: Props) {
  const [request, setRequest] = useState("");
  const [saving, setSaving] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const trainer = useAppSelector(selectPrimaryTrainer);

  useEffect(() => {
    setRequest(defaultRequest ?? "");
  }, [open]);

  return (
    <Dialog
      open={open}
      onClose={() => {
        if (saving) {
          return;
        }

        onClose();
      }}
    >
      <DialogTitle>Request a phase or workout</DialogTitle>
      <DialogContent sx={{ minWidth: "500px" }}>
        <Stack spacing={2} sx={{ pt: 1 }}>
          <TextField
            label="Request"
            value={request}
            onChange={(event) => {
              setRequest(event.target.value);
            }}
            sx={{ width: "100%" }}
          />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button
          variant="text"
          onClick={() => {
            onClose();
          }}
          disabled={saving}
        >
          Cancel
        </Button>
        <LoadingButton
          variant="contained"
          disabled={isBlank(request)}
          autoFocus
          startIcon={<CampaignRoundedIcon />}
          onClick={() => {
            if (!request) {
              return;
            }

            setSaving(true);

            api.phaseTemplates
              .request({
                trainerId: trainer!.trainer_id,
                message: request,
              })
              .then(() => {
                onClose();
                setSaving(false);

                enqueueSnackbar("Requested", { variant: "success" });
              })
              .catch(() => {
                setSaving(false);
                enqueueSnackbar("Error sending request", { variant: "error" });
              });
          }}
          loading={saving}
        >
          Request
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
