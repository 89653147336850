import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  TextField,
} from "@mui/material";
import type { Message } from "@trainwell/types";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { DialogTitleWithClose } from "src/components/misc/DialogTitleWithClose";
import { useAppDispatch, useAppSelector } from "src/hooks/stateHooks";
import { sendMessageAsEmail } from "src/slices/chatSlice";
import { selectClientById } from "src/slices/clientsSlice";
import { selectPrimaryTrainer } from "src/slices/trainerSlice";

type Props = {
  open: boolean;
  onClose: () => void;
  message: Message | null;
};

export default function SendAsEmailDialog({ open, onClose, message }: Props) {
  const dispatch = useAppDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [body, setBody] = useState("");
  const [subject, setSubject] = useState("Trainwell Trainer Message");
  const trainer = useAppSelector(selectPrimaryTrainer);
  const client = useAppSelector((state) =>
    message ? selectClientById(state, message?.to_id) : undefined,
  );

  useEffect(() => {
    setBody(
      `Hey ${client?.first_name},\n\n${message?.text}\n\n${trainer?.first_name}`,
    );
  }, [message?.text]);

  return (
    <Dialog
      onClose={() => {
        onClose();
      }}
      open={open}
      fullWidth
      maxWidth="sm"
    >
      <DialogTitleWithClose
        onClose={() => {
          onClose();
        }}
      >
        Send message as email
      </DialogTitleWithClose>
      <DialogContent>
        <TextField
          label="Subject"
          fullWidth
          value={subject}
          onChange={(event) => {
            setSubject(event.target.value);
          }}
          sx={{ my: 2 }}
        />
        <TextField
          label="Body"
          fullWidth
          multiline
          value={body}
          onChange={(event) => {
            setBody(event.target.value);
          }}
          sx={{ my: 2 }}
        />
      </DialogContent>
      <DialogActions>
        <Button
          variant="text"
          onClick={() => {
            onClose();
          }}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          onClick={() => {
            if (!message) {
              return;
            }

            onClose();

            dispatch(
              sendMessageAsEmail({
                messageId: message.message_id,
                chatId: message.to_id,
                body: body,
                subject: subject,
              }),
            )
              .unwrap()
              .catch(() => {
                enqueueSnackbar("Error sending email", {
                  variant: "error",
                });
              });
          }}
        >
          Send email
        </Button>
      </DialogActions>
    </Dialog>
  );
}
