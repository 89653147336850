export function getWeightUnit(system: "imperial" | "metric"): string {
  if (system === "metric") {
    return "kg";
  } else {
    return "lbs";
  }
}

export function roundedDecimal(num: number) {
  if (num < 100) {
    return num.toPrecision(2);
  } else {
    return Math.round(num).toString();
  }
}
