import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "src/hooks/stateHooks";
import useInterval from "src/hooks/useInterval";
import { fetchActionItems } from "src/slices/actionItemSlice";
import { fetchExerciseLibrary } from "src/slices/appSlice";
import {
  fetchClients,
  fetchMoreClientInfo,
  fetchUpcomingCalls,
} from "src/slices/clientsSlice";
import { fetchPhaseTemplates } from "src/slices/phaseTemplatesSlice";
import { fetchTemplates } from "src/slices/templatesSlice";
import { selectPrimaryTrainer } from "src/slices/trainerSlice";
import { fetchAllTrainerNames } from "src/slices/trainersSlice";

export default function LoadDataHelper() {
  const dispatch = useAppDispatch();
  const trainerId = useAppSelector(
    (state) => selectPrimaryTrainer(state)?.trainer_id,
  );
  const actionItemsStatus = useAppSelector((state) => state.actionItems.status);
  const trainerNamesStatus = useAppSelector(
    (state) => state.trainers.trainerNameStatus,
  );
  const templatesStatus = useAppSelector((state) => state.templates.status);
  const phaseTemplatesStatus = useAppSelector(
    (state) => state.phaseTemplates.status,
  );
  const moreClientInfoStatus = useAppSelector(
    (state) => state.clients.moreClientInfoStatus,
  );
  const clientsStatus = useAppSelector((state) => state.clients.status);
  const exerciseLibraryStatus = useAppSelector(
    (state) => state.app.exerciseStatus,
  );
  const upcomingCallsStatus = useAppSelector(
    (state) => state.clients.upcomingCallsStatus,
  );

  useEffect(() => {
    if (upcomingCallsStatus === "idle" && trainerId) {
      dispatch(fetchUpcomingCalls(trainerId));
    }
  }, [upcomingCallsStatus, dispatch, trainerId]);

  useEffect(() => {
    if (trainerNamesStatus === "idle") {
      dispatch(fetchAllTrainerNames());
    }
  }, [trainerNamesStatus, dispatch]);

  useEffect(() => {
    if (actionItemsStatus === "idle" && trainerId) {
      dispatch(fetchActionItems({ trainerId: trainerId }));
    }
  }, [actionItemsStatus, dispatch, trainerId]);

  useInterval(() => {
    if (trainerId) {
      dispatch(fetchActionItems({ trainerId: trainerId, refetch: true }));
    }
  }, 300000);

  useEffect(() => {
    if (clientsStatus === "idle" && trainerId) {
      dispatch(fetchClients(trainerId));
    }
  }, [clientsStatus, dispatch, trainerId]);

  useEffect(() => {
    if (templatesStatus === "idle" && trainerId) {
      dispatch(fetchTemplates(trainerId));
    }
  }, [templatesStatus, dispatch, trainerId]);

  useEffect(() => {
    if (phaseTemplatesStatus === "idle" && trainerId) {
      dispatch(fetchPhaseTemplates());
    }
  }, [phaseTemplatesStatus, dispatch, trainerId]);

  useEffect(() => {
    if (moreClientInfoStatus === "idle" && trainerId) {
      dispatch(fetchMoreClientInfo(trainerId));
    }
  }, [moreClientInfoStatus, dispatch, trainerId]);

  useEffect(() => {
    if (exerciseLibraryStatus === "idle" && trainerId) {
      dispatch(fetchExerciseLibrary());
    }
  }, [exerciseLibraryStatus, dispatch, trainerId]);

  return null;
}
