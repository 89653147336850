const notificationTypes: Record<string, string> = {
  first_app_open: "First app open",
  call_scheduled: "Call scheduled",
  call_canceled: "Call canceled",
  call_updated: "Call updated",
  cancellation_requested: "Cancellation requested",
  referral_initiated: "Client created referral",
  their_referral_started_trial: "Client's referral started trial",
  is_referral_started_trial: "Started trial from referral",
  their_referral_paid: "Client's Referral Paid",
  is_referral_paid: "Paid From Referral",
  request_to_pause: "Request to pause",
  progress_metric_logged: "Progress logged",
  progress_metric_logged_many: "Progress synced",
  all_time_pr: "All time PR 🥇",
  goal_changed: "Goal changed",
  level_up: "Level up",
  tip: "Tip received",
  survey_answered: "Survey answered",
};

export function getNotificationTitle(type: string) {
  let title = "Notification";

  if (type in notificationTypes) {
    title = notificationTypes[type];
  }

  return title;
}
