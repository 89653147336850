import DirectionsWalkRoundedIcon from "@mui/icons-material/DirectionsWalkRounded";
import { Avatar, Box, Stack, Typography } from "@mui/material";
import type { MessageStepGoalCompleted } from "@trainwell/types";
import { format } from "date-fns";
import { getDateFromDatabase } from "src/lib/date";

type Props = {
  message: MessageStepGoalCompleted;
  combineBottom: boolean;
};

export function StepsCompletedMessage({ message, combineBottom }: Props) {
  return (
    <Box
      sx={{
        maxWidth: "300px",
        borderRadius: `12px 12px 12px ${!combineBottom ? "0px" : "12px"}`,
        overflow: "hidden",
        backgroundColor: (theme) => theme.palette.background.default,
        p: 1,
        display: "flex",
        alignItems: "center",
        border: 1,
        borderColor: "divider",
      }}
    >
      <Stack
        direction={"column"}
        spacing={1}
        useFlexGap
        sx={{
          whiteSpace: "pre-line",
          wordWrap: "break-word",
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Avatar
            sx={{
              backgroundColor: (theme) => theme.palette.success.main,
              mr: 1,
              width: 20,
              height: 20,
              borderRadius: "6px",
            }}
          >
            <DirectionsWalkRoundedIcon
              sx={{
                fontSize: 16,
                color: (theme) => theme.palette.success.contrastText,
              }}
            />
          </Avatar>
          <Box>
            <Typography
              variant="h6"
              sx={{
                color: (theme) => theme.palette.text.primary,
              }}
            >
              Step goal completed!
            </Typography>
            <Typography
              variant="body2"
              sx={{
                color: (theme) => theme.palette.text.secondary,
              }}
            >
              {message.content.n_steps_performed.toLocaleString()} /{" "}
              {message.content.n_steps_target.toLocaleString()} steps ·{" "}
              {format(getDateFromDatabase(message.content.date), "MMM d")}
            </Typography>
          </Box>
        </Box>
      </Stack>
    </Box>
  );
}
