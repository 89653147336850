import type { ExerciseSource } from "@trainwell/types";

function exists(item: number | null | undefined) {
  return item || item === 0;
}

export function getOrm(
  data: {
    weight?: number | null;
    reps?: number | null;
    time?: number | null;
  },
  roundOrm?: boolean,
  exerciseSource?: ExerciseSource,
): number | null {
  const { weight, reps, time } = data;

  const weightExists = exists(weight);
  const repsExists = exists(reps);
  const timeExists = exists(time);

  let orm: number | null = null;

  if (!weightExists && !timeExists && repsExists) {
    orm = reps as number;
  }

  if (!weightExists && timeExists && !repsExists) {
    orm = time as number;
  }

  if (weightExists && !timeExists && repsExists) {
    orm = (weight as number) * (1 + (reps as number) / 30);
  }

  if (weightExists && timeExists && !repsExists) {
    if (exerciseSource?.strength_type === "weighted_timed_minute") {
      orm =
        (weight as number) / weightedTimedStrengthMultiplier(time as number);
    } else {
      orm = weight as number;
    }
  }

  if (orm !== null) {
    if (roundOrm) {
      return Math.round(orm);
    } else {
      return orm;
    }
  }

  return null;
}

/**
 * For weighted_timed_minute exercises, this calculates the ratio by which we multiply the client's estimated max (callibrated for a 1 minute performance)
 *  to get their expected max for a set of the length passed in <time>
 * For example, if the estimated max for a weighted plank is 100 lbs, that is the estimated max for 1 minute of weighted plank.
 *  If our set is of length 3 minutes, or 180 seconds, we pass time=120 to this function and the result will be 120 / (180 + 60) = .5,
 *  which represents the idea that we expect for 3 minutes, the client would be able to do half as much weight as they could for 1 minute.
 *
 * @param time
 * @returns multplier
 */
function weightedTimedStrengthMultiplier(time: number): number {
  const multiplier: number = 120 / (time + 60);
  return multiplier;
}
