import DirectionsWalkRoundedIcon from "@mui/icons-material/DirectionsWalkRounded";
import { Avatar, type SxProps, type Theme } from "@mui/material";

type Props = {
  sx?: SxProps<Theme>;
};

export function StreakBrokenStepsIcon({ sx = [] }: Props) {
  return (
    <Avatar
      sx={{
        width: 18,
        height: 18,
        background: (theme) => `linear-gradient(to top left,
                  rgba(0,0,0,0) 0%,
                  rgba(0,0,0,0) calc(50% - 1px),
                  ${theme.palette.background.paper} calc(50% - 1px),
                  ${theme.palette.background.paper} calc(50% + 1px),
                  rgba(0,0,0,0) calc(50% + 1px),
                  rgba(0,0,0,0) 100%)`,
        backgroundColor: (theme) => theme.palette.error.main,
        ...sx,
      }}
    >
      <DirectionsWalkRoundedIcon sx={{ fontSize: 14 }} />
    </Avatar>
  );
}
