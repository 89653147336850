import { Box, Typography } from "@mui/material";

type Props = {
  message?: string;
};

export default function NotFoundComponent({ message }: Props) {
  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
      }}
    >
      <Typography variant="h1">Not Found</Typography>
      <Typography>{message ? message : "Not found"}</Typography>
      <Box sx={{ display: "flex", justifyContent: "center", my: 4 }}>
        <img
          src={"/assets/copilot-done.svg"}
          width={200}
          height={200}
          alt="happy habit"
        />
      </Box>
    </Box>
  );
}
