import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import InboxRoundedIcon from "@mui/icons-material/InboxRounded";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import SendRoundedIcon from "@mui/icons-material/SendRounded";
import UploadRoundedIcon from "@mui/icons-material/UploadRounded";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  Grid,
  IconButton,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import type { TrainerMedia } from "@trainwell/types";
import Uppy from "@uppy/core";
import { Dashboard } from "@uppy/react";
import ScreenCapture from "@uppy/screen-capture";
import Transloadit, { type AssemblyResponse } from "@uppy/transloadit";
import Webcam from "@uppy/webcam";
import { useEffect, useMemo, useRef, useState } from "react";
import { DialogTitleWithClose } from "src/components/misc/DialogTitleWithClose";
import SearchField from "src/components/misc/SearchField";
import LoadingComponent from "src/components/miscPages/LoadingComponent";
import { TRANSLOADIT_AUTH_KEY } from "src/config/config";
import { useAppSelector } from "src/hooks/stateHooks";
import {
  useAddTrainerMediaMutation,
  useDeleteTrainerMediaMutation,
  useGetTrainerMediaQuery,
  useUpdateTrainerMediaMutation,
} from "src/slices/api/trainerMediaApi";
import { selectPrimaryTrainer } from "src/slices/trainerSlice";

const uppy = (
  typeof navigator === "undefined"
    ? undefined
    : new Uppy()
        .use(Webcam, {
          modes: ["video-audio"],
          videoConstraints: {
            facingMode: "user",
          },
          showRecordingLength: true,
          mobileNativeCamera: true,
        })
        .use(ScreenCapture)
        .use(Transloadit, {
          assemblyOptions: {
            params: {
              auth: { key: TRANSLOADIT_AUTH_KEY },
              template_id: "coach-media",
            },
          },
          waitForEncoding: true,
        })
) as Uppy;

type Props = {
  open: boolean;
  onClose: (coachMedia?: TrainerMedia) => void;
};

export default function CoachMediaDialog({ open, onClose }: Props) {
  const theme = useTheme();
  const trainer = useAppSelector(selectPrimaryTrainer);
  const { data: coachMedias } = useGetTrainerMediaQuery(trainer!.trainer_id);
  const [updateTrainerMedia] = useUpdateTrainerMediaMutation();
  const [deleteCoachMedia] = useDeleteTrainerMediaMutation();
  const [addCoachMedia] = useAddTrainerMediaMutation();
  const [uploadDialogOpen, setUploadDialogOpen] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [editingName, setEditingName] = useState<
    undefined | { name: string; id: string }
  >(undefined);
  const searchRef = useRef<HTMLDivElement | null>(null);

  const displayCoachMedias = useMemo(() => {
    if (!searchText) {
      return coachMedias;
    } else {
      return coachMedias?.filter((m) =>
        m.name.toLowerCase().includes(searchText.toLowerCase()),
      );
    }
  }, [searchText, coachMedias]);

  useEffect(() => {
    if (open) {
      setTimeout(() => {
        searchRef.current?.focus();
      }, 50);
    } else {
      setSearchText("");
    }
  }, [open]);

  useEffect(() => {
    uppy.setOptions({
      restrictions: {
        maxNumberOfFiles: 10,
        allowedFileTypes: ["video/*"],
      },
    });

    function uploaded(assembly: AssemblyResponse) {
      console.log("Uppy: upload success", assembly);

      if (
        "videos" in assembly.results &&
        assembly.results.videos.length &&
        "thumbnails" in assembly.results &&
        assembly.results.thumbnails.length
      ) {
        for (const videoResult of assembly.results.videos) {
          const thumbnailResult = assembly.results.thumbnails.find(
            (t) => t.original_id === videoResult.original_id,
          );

          if (videoResult.ssl_url && thumbnailResult?.ssl_url) {
            addCoachMedia({
              video_url: videoResult.ssl_url,
              thumbnail_url: thumbnailResult.ssl_url,
              height: videoResult.meta.height,
              width: videoResult.meta.width,
              trainer_id: trainer!.trainer_id,
              name: "New video",
            });
          }
        }
      }
    }

    function uploadComplete() {
      console.log("Uppy: upload complete");

      uppy.cancelAll();
      setUploadDialogOpen(false);
    }

    uppy.on("complete", uploadComplete).on("transloadit:complete", uploaded);

    return () => {
      uppy
        .off("complete", uploadComplete)
        .off("transloadit:complete", uploaded);
    };
  }, []);

  return (
    <>
      <Dialog
        onClose={(event) => {
          // @ts-expect-error
          event.stopPropagation();

          onClose();
        }}
        open={open}
        fullWidth
        maxWidth="md"
      >
        <DialogTitleWithClose
          onClose={(event) => {
            event.stopPropagation();

            onClose();
          }}
        >
          Media library
        </DialogTitleWithClose>
        <DialogContent dividers>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Button
              startIcon={<UploadRoundedIcon />}
              onClick={() => {
                setUploadDialogOpen(true);
              }}
              sx={{ mr: 2, minWidth: "180px" }}
            >
              Upload media
            </Button>
            <SearchField
              ref={searchRef}
              autoFocus
              value={searchText}
              onChange={(value) => {
                setSearchText(value);
              }}
              onClear={() => {
                setSearchText("");
              }}
            />
          </Box>
          <DialogContentText sx={{ mt: 2 }}>
            Your media library is higher quality than normal videos and only
            needs to upload / encode once vs for every client.
          </DialogContentText>
          <Box sx={{ height: "600px", overflowY: "auto", p: 2 }}>
            {!displayCoachMedias ? (
              <LoadingComponent message="Fetching media" />
            ) : displayCoachMedias.length === 0 ? (
              <Box
                sx={{
                  height: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  flexDirection: "column",
                }}
              >
                {searchText ? (
                  <SearchRoundedIcon
                    sx={{
                      fontSize: 52,
                      mb: 2,
                      color: (theme) => theme.palette.text.secondary,
                    }}
                  />
                ) : (
                  <InboxRoundedIcon
                    sx={{
                      fontSize: 52,
                      mb: 2,
                      color: (theme) => theme.palette.text.secondary,
                    }}
                  />
                )}
                <Typography
                  sx={{
                    textAlign: "center",
                    color: (theme) => theme.palette.text.secondary,
                  }}
                >
                  No {searchText ? "media matched search" : "media"}
                </Typography>
              </Box>
            ) : (
              <Grid container spacing={2}>
                {displayCoachMedias.map((coachMedia) => {
                  return (
                    <Grid item xs={12} sm={4} key={coachMedia.id}>
                      {editingName?.id === coachMedia.id ? (
                        <TextField
                          size="small"
                          autoFocus={true}
                          value={editingName.name}
                          onChange={(event) => {
                            setEditingName((prev) => {
                              return { id: prev!.id, name: event.target.value };
                            });
                          }}
                          onKeyDown={(event) => {
                            if (event.key === "Enter") {
                              event.preventDefault();
                              // @ts-expect-error
                              event.target.blur();
                            }
                          }}
                          onBlur={() => {
                            updateTrainerMedia({
                              id: editingName.id,
                              name: editingName.name,
                            });
                            setEditingName(undefined);
                          }}
                          sx={{ mb: 1 }}
                        />
                      ) : (
                        <Button
                          size="small"
                          variant="text"
                          onClick={() => {
                            setEditingName({
                              id: coachMedia.id,
                              name: coachMedia.name,
                            });
                          }}
                          sx={{
                            textAlign: "start",
                            mb: 1,
                          }}
                        >
                          {coachMedia.name}
                        </Button>
                      )}
                      <video
                        controls
                        height={150}
                        style={{
                          borderRadius: "8px",
                          display: "block",
                          width: "100%",
                        }}
                        poster={coachMedia.thumbnail_url}
                      >
                        <source src={coachMedia.video_url} />
                      </video>
                      <Box
                        sx={{ display: "flex", alignItems: "center", mt: 0.5 }}
                      >
                        <IconButton
                          color="error"
                          size="small"
                          onClick={() => {
                            deleteCoachMedia(coachMedia.id);
                          }}
                          sx={{ mr: 1 }}
                        >
                          <DeleteRoundedIcon fontSize="small" />
                        </IconButton>
                        <Button
                          size="small"
                          fullWidth
                          startIcon={<SendRoundedIcon />}
                          onClick={() => {
                            onClose(coachMedia);
                          }}
                        >
                          Send
                        </Button>
                      </Box>
                    </Grid>
                  );
                })}
              </Grid>
            )}
          </Box>
        </DialogContent>
      </Dialog>
      <Dialog
        onClose={(event) => {
          // @ts-expect-error
          event.stopPropagation();

          setUploadDialogOpen(false);
        }}
        open={uploadDialogOpen}
      >
        <DialogTitleWithClose
          onClose={(event) => {
            event.stopPropagation();

            setUploadDialogOpen(false);
          }}
        >
          Upload media
        </DialogTitleWithClose>
        <DialogContent dividers sx={{ p: 0 }}>
          <Box>
            <Dashboard
              uppy={uppy}
              plugins={["Webcam", "ScreenCapture"]}
              proudlyDisplayPoweredByUppy={false}
              showLinkToFileUploadResult={false}
              height={"500px"}
              doneButtonHandler={() => {
                setUploadDialogOpen(false);
              }}
              theme={theme.palette.mode}
            />
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
}
