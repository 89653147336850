import ArrowBackRoundedIcon from "@mui/icons-material/ArrowBackRounded";
import { Box, Button, Card, Typography } from "@mui/material";
import { AutosaveRichTextField } from "src/components/misc/AutosaveRichTextField";
import AutosaveTextField from "src/components/misc/AutosaveTextField";
import ControlBar from "src/components/misc/ControlBar";
import { useAppDispatch, useAppSelector } from "src/hooks/stateHooks";
import { selectPrimaryTrainer } from "src/slices/trainerSlice";
import { updateTrainer } from "src/slices/trainersSlice";

export default function NoteTemplatesPage() {
  const dispatch = useAppDispatch();
  const trainer = useAppSelector(selectPrimaryTrainer);

  if (!trainer || !trainer.note_templates) {
    return null;
  }

  return (
    <Box>
      <ControlBar
        leftContent={
          <Button
            variant="contained"
            href="/"
            startIcon={<ArrowBackRoundedIcon />}
            size="small"
          >
            Back
          </Button>
        }
      />
      <Box sx={{ p: 3 }}>
        <Typography variant="h1" sx={{ mb: 2 }}>
          Note templates
        </Typography>
        <Typography sx={{ mb: 4 }}>
          These note templates will autofill for new and existing clients if
          their notes are empty. Use them to help remember questions to ask and
          save time.
        </Typography>
        <Card variant="outlined" sx={{ p: 2, mb: 4 }}>
          <AutosaveTextField
            label="Personal notes"
            defaultValue={trainer.note_templates.personal ?? ""}
            onSave={(newValue) => {
              dispatch(
                updateTrainer({
                  trainer_id: trainer.trainer_id,
                  // @ts-expect-error
                  "note_templates.personal": newValue,
                }),
              );
            }}
          />
          <AutosaveTextField
            label="Source"
            defaultValue={trainer.note_templates.source ?? ""}
            onSave={(newValue) => {
              dispatch(
                updateTrainer({
                  trainer_id: trainer.trainer_id,
                  // @ts-expect-error
                  "note_templates.source": newValue,
                }),
              );
            }}
          />
          <AutosaveTextField
            label="Outcome goals"
            defaultValue={trainer.note_templates.goal_outcome ?? ""}
            onSave={(newValue) => {
              dispatch(
                updateTrainer({
                  trainer_id: trainer.trainer_id,
                  // @ts-expect-error
                  "note_templates.goal_outcome": newValue,
                }),
              );
            }}
          />
          <AutosaveTextField
            label="Process goals"
            defaultValue={trainer.note_templates.goal_process ?? ""}
            onSave={(newValue) => {
              dispatch(
                updateTrainer({
                  trainer_id: trainer.trainer_id,
                  // @ts-expect-error
                  "note_templates.goal_process": newValue,
                }),
              );
            }}
          />
          <AutosaveTextField
            label="Exercise history"
            defaultValue={trainer.note_templates.exercise_history ?? ""}
            onSave={(newValue) => {
              dispatch(
                updateTrainer({
                  trainer_id: trainer.trainer_id,
                  // @ts-expect-error
                  "note_templates.exercise_history": newValue,
                }),
              );
            }}
          />
          <AutosaveTextField
            label="Programming"
            defaultValue={trainer.note_templates.programming ?? ""}
            onSave={(newValue) => {
              dispatch(
                updateTrainer({
                  trainer_id: trainer.trainer_id,
                  // @ts-expect-error
                  "note_templates.programming": newValue,
                }),
              );
            }}
          />
          <AutosaveTextField
            label="Quick notes"
            defaultValue={trainer.note_templates.quick ?? ""}
            onSave={(newValue) => {
              dispatch(
                updateTrainer({
                  trainer_id: trainer.trainer_id,
                  // @ts-expect-error
                  "note_templates.quick": newValue,
                }),
              );
            }}
          />
          <AutosaveRichTextField
            label="Detailed notes"
            defaultValue={trainer.note_templates.detailed ?? ""}
            onSave={(newValue) => {
              dispatch(
                updateTrainer({
                  trainer_id: trainer.trainer_id,
                  // @ts-expect-error
                  "note_templates.detailed": newValue,
                }),
              );
            }}
          />
        </Card>
      </Box>
    </Box>
  );
}
