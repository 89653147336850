import {
  Autocomplete,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { useInfluencers } from "@trainwell/features";
import { shallowEqual } from "react-redux";
import { useAppDispatch, useAppSelector } from "src/hooks/stateHooks";
import { updateClient } from "src/slices/clientSlice";

const possibleSources = [
  "Reddit",
  "Friend or family",
  "Google",
  "YouTube",
  "Facebook",
  "Podcast",
  "App Store",
  "Other",
];

type Props = {
  userId: string;
};

export function ClientSource({ userId }: Props) {
  const dispatch = useAppDispatch();
  const client = useAppSelector(
    (state) =>
      state.client.client?.user_id === userId
        ? {
            user_provided_sournce: state.client.client.user_provided_sournce,
            user_provided_influencer_id:
              state.client.client.user_provided_influencer_id,
          }
        : undefined,
    shallowEqual,
  );
  const { data: influencers } = useInfluencers();

  const influencerOptions = (influencers ?? [])
    .sort((a, b) => a.display_name.localeCompare(b.display_name))
    .map((i) => i.id);

  if (!client) {
    return;
  }

  return (
    <>
      <FormControl fullWidth sx={{ mb: 2 }}>
        <InputLabel>Source</InputLabel>
        <Select
          value={client.user_provided_sournce ?? ""}
          label="Source"
          onChange={(event) => {
            dispatch(
              updateClient({
                user_id: userId,
                user_provided_sournce: event.target.value.toLowerCase() || null,
              }),
            );
          }}
          MenuProps={{
            sx: { maxHeight: 400 },
          }}
          sx={{ mb: 2 }}
        >
          {possibleSources.map((source) => (
            <MenuItem key={source.toLowerCase()} value={source.toLowerCase()}>
              {source}
            </MenuItem>
          ))}
          <MenuItem value={""}>None</MenuItem>
        </Select>
      </FormControl>
      {client.user_provided_sournce === "youtube" && (
        <Autocomplete
          sx={{ mb: 4 }}
          options={influencerOptions}
          disableCloseOnSelect
          getOptionLabel={(option) =>
            influencers?.find((i) => i.id === option)?.display_name ?? ""
          }
          blurOnSelect
          getOptionKey={(option) =>
            influencers?.find((i) => i.id === option)?.id ?? option
          }
          value={client.user_provided_influencer_id ?? ""}
          onChange={(event, newInfluencerId) => {
            dispatch(
              updateClient({
                user_id: userId,
                user_provided_influencer_id: newInfluencerId || null,
              }),
            );
          }}
          fullWidth
          renderInput={(params) => <TextField {...params} label="Influencer" />}
        />
      )}
    </>
  );
}
