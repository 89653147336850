import type { PayloadAction } from "@reduxjs/toolkit";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import * as Sentry from "@sentry/react";
import type { Trainer } from "@trainwell/types";
import set from "lodash-es/set";
import { trainerHasAccess, type AccessLocation } from "src/lib/accessRoles";
import { api } from "src/lib/trainwellApi";
import { resetActionItems } from "./actionItemSlice";
import { setIsAuditMode } from "./appSlice";
import { openChat, resetChat } from "./chatSlice";
import { resetClients } from "./clientsSlice";
import { resetPhaseTemplates } from "./phaseTemplatesSlice";
import type { RootState } from "./store";
import { resetTemplates } from "./templatesSlice";
import { updateTrainer } from "./trainersSlice";

export const fetchTrainer = createAsyncThunk(
  "trainer/fetchTrainer",
  async (
    data: { trainerId: string; ghostedTrainerId: string | null },
    { dispatch },
  ) => {
    const trainerPromise = api.trainers.login(data.trainerId);

    let ghostedTrainer: Trainer | undefined = undefined;

    if (data.ghostedTrainerId && data.ghostedTrainerId !== data.trainerId) {
      ghostedTrainer = await api.trainers.getOne(data.ghostedTrainerId);

      const trainer = await trainerPromise;

      if (!trainer.is_admin && !ghostedTrainer.allow_audit_without_auth) {
        ghostedTrainer = undefined;
      }

      dispatch(setIsAuditMode(true));
    }

    const trainer = await trainerPromise;

    Sentry.setUser({
      id: trainer.trainer_id,
      email: trainer.email,
      username: trainer.email,
    });

    return {
      trainer: trainer,
      ghostedTrainer: ghostedTrainer,
    };
  },
);

export const fetchGhostedTrainer = createAsyncThunk(
  "trainer/fetchGhostedTrainer",
  async (ghostedTrainerId: string, { getState, dispatch }) => {
    const state = getState() as RootState;

    const ghostedTrainer = await api.trainers.getOne(ghostedTrainerId);

    if (
      !state.trainer.trainer?.is_admin &&
      !ghostedTrainer.allow_audit_without_auth
    ) {
      throw new Error("Not an admin");
    }

    dispatch(setGhostedTrainer(ghostedTrainer));
    dispatch(resetTemplates());
    dispatch(resetPhaseTemplates());
    dispatch(resetActionItems());
    dispatch(resetChat());
    dispatch(resetClients());
    dispatch(setIsAuditMode(true));

    if (state.client.client?.user_id) {
      dispatch(openChat({ chatId: state.client.client.user_id }));
    }

    return { ghostedTrainer: ghostedTrainer };
  },
);

export const exitGhostMode = createAsyncThunk(
  "trainer/exitGhostMode",
  async (_, { dispatch }) => {
    dispatch(setGhostedTrainer(undefined));
    dispatch(resetTemplates());
    dispatch(resetPhaseTemplates());
    dispatch(resetActionItems());
    dispatch(resetChat());
    dispatch(resetClients());

    return;
  },
);

export const toggleFavoriteExercise = createAsyncThunk(
  "trainer/toggleFavoriteExercise",
  async (exerciseId: string, { getState, dispatch }) => {
    const state = getState() as RootState;

    const trainer = selectPrimaryTrainer(state);

    if (!trainer) {
      return;
    }

    let favoriteExerciseIds = JSON.parse(
      JSON.stringify(trainer.favorite_exercise_ids),
    ) as string[] | undefined;

    if (!favoriteExerciseIds) {
      favoriteExerciseIds = [exerciseId];
    } else {
      const index = favoriteExerciseIds.indexOf(exerciseId);

      if (index === -1) {
        favoriteExerciseIds.push(exerciseId);
      } else {
        favoriteExerciseIds.splice(index, 1);
      }
    }

    dispatch(
      updateTrainer({
        trainer_id: trainer.trainer_id,
        favorite_exercise_ids: favoriteExerciseIds,
      }),
    );

    return;
  },
);

// Define a type for the slice state
interface TrainerState {
  trainer: Trainer | undefined;
  ghostedTrainer: Trainer | undefined;
  authenticated: boolean;
  status: "idle" | "loading" | "succeeded" | "failed";
  error: string | undefined;
  ghostedTrainerStatus: "idle" | "loading" | "succeeded" | "failed";

  /**
   * Certain dash features are disabled while ghosting
   * ex. socket registration, reading messages, completing action items
   * Set this to true to temporarily disable restrictions for testing
   */
  disableGhostingProtections: boolean;
}

// Define the initial state using that type
const initialState: TrainerState = {
  trainer: undefined,
  ghostedTrainer: undefined,
  authenticated: false,
  status: "idle",
  error: undefined,
  ghostedTrainerStatus: "idle",
  disableGhostingProtections: false,
};

export const trainerSlice = createSlice({
  name: "trainer",
  initialState,
  reducers: {
    resetCoach: () => initialState,
    updateTrainerLocal: (
      state,
      action: PayloadAction<Partial<Trainer> & Pick<Trainer, "trainer_id">>,
    ) => {
      const update = action.payload;

      if (update.trainer_id === state.trainer?.trainer_id) {
        for (const [key, value] of Object.entries(update)) {
          set(state.trainer, key, value);
        }
      } else if (update.trainer_id === state.ghostedTrainer?.trainer_id) {
        for (const [key, value] of Object.entries(update)) {
          set(state.ghostedTrainer, key, value);
        }
      }
    },
    setAuthenticated: (state, action: PayloadAction<boolean>) => {
      const authenticated = action.payload;

      state.authenticated = authenticated;
    },
    setDisableGhostingProtections: (state, action: PayloadAction<boolean>) => {
      const disableGhostingProtections = action.payload;

      state.disableGhostingProtections = disableGhostingProtections;
    },
    setGhostedTrainer: (state, action: PayloadAction<Trainer | undefined>) => {
      const ghostedTrainer = action.payload;

      if (
        !ghostedTrainer ||
        (!state.trainer?.is_admin && !ghostedTrainer.allow_audit_without_auth)
      ) {
        state.ghostedTrainer = undefined;
        return;
      }

      if (!ghostedTrainer.note_templates) {
        ghostedTrainer.note_templates = {};
      }

      ghostedTrainer.note_templates.personal =
        ghostedTrainer.note_templates.personal ??
        "- Work:\n- Fun/Life:\n- Location:\n- Relationships / Support System:";
      ghostedTrainer.note_templates.source =
        ghostedTrainer.note_templates.source ??
        "- How did you find trainwell:\n- What made you join / what's your why?:";
      ghostedTrainer.note_templates.exercise_history =
        ghostedTrainer.note_templates.exercise_history ??
        "- Health/exercise history:\n- Loved/hated exercises:\n- Injuries/constraints:\n- Prefered training/programming style:";
      ghostedTrainer.note_templates.programming =
        ghostedTrainer.note_templates.programming ??
        "- Equipment:\n- Frequency:\n- Duration:\n- Days per week:";

      state.ghostedTrainer = ghostedTrainer;
      state.disableGhostingProtections = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchTrainer.pending, (state) => {
      state.status = "loading";
    });
    builder.addCase(fetchTrainer.fulfilled, (state, action) => {
      console.log("Redux: Fetched trainer");
      state.status = "succeeded";

      const { trainer, ghostedTrainer } = action.payload;

      if (!trainer.note_templates) {
        trainer.note_templates = {};
      }

      trainer.note_templates.personal =
        trainer.note_templates.personal ??
        "- Work:\n- Fun/Life:\n- Location:\n- Relationships / Support System:";
      trainer.note_templates.source =
        trainer.note_templates.source ??
        "- How did you find trainwell:\n- What made you join / what's your why?:";
      trainer.note_templates.exercise_history =
        trainer.note_templates.exercise_history ??
        "- Health/exercise history:\n- Loved/hated exercises:\n- Injuries/constraints:\n- Prefered training/programming style:";
      trainer.note_templates.programming =
        trainer.note_templates.programming ??
        "- Equipment:\n- Frequency:\n- Duration:\n- Days per week:";

      if (ghostedTrainer) {
        if (!ghostedTrainer.note_templates) {
          ghostedTrainer.note_templates = {};
        }

        ghostedTrainer.note_templates.personal =
          ghostedTrainer.note_templates.personal ??
          "- Work:\n- Fun/Life:\n- Location:\n- Relationships / Support System:";
        ghostedTrainer.note_templates.source =
          ghostedTrainer.note_templates.source ??
          "- How did you find trainwell:\n- What made you join / what's your why?:";
        ghostedTrainer.note_templates.exercise_history =
          ghostedTrainer.note_templates.exercise_history ??
          "- Health/exercise history:\n- Loved/hated exercises:\n- Injuries/constraints:\n- Prefered training/programming style:";
        ghostedTrainer.note_templates.programming =
          ghostedTrainer.note_templates.programming ??
          "- Equipment:\n- Frequency:\n- Duration:\n- Days per week:";

        state.ghostedTrainer = ghostedTrainer;
      }

      state.trainer = trainer;
    });
    builder.addCase(fetchTrainer.rejected, (state, action) => {
      state.status = "failed";
      state.error = action.error.message;
    });
    builder.addCase(fetchGhostedTrainer.pending, (state) => {
      state.ghostedTrainerStatus = "loading";
    });
    builder.addCase(fetchGhostedTrainer.fulfilled, (state) => {
      console.log("Redux: Fetched ghosted trainer");
      state.ghostedTrainerStatus = "succeeded";
    });
    builder.addCase(fetchGhostedTrainer.rejected, (state) => {
      state.ghostedTrainerStatus = "failed";
    });
  },
});

// Action creators are generated for each case reducer function
export const {
  resetCoach,
  setAuthenticated,
  updateTrainerLocal,
  setGhostedTrainer,
  setDisableGhostingProtections,
} = trainerSlice.actions;

export default trainerSlice.reducer;

export const selectIsGhosting = (state: RootState) => {
  if (
    state.trainer.ghostedTrainer &&
    (state.trainer.trainer?.is_admin === true ||
      state.trainer.ghostedTrainer.allow_audit_without_auth === true)
  ) {
    return true;
  } else {
    return false;
  }
};

export const selectPrimaryTrainer = (state: RootState) => {
  const isAuditing = selectIsGhosting(state);

  if (isAuditing) {
    return state.trainer.ghostedTrainer;
  } else {
    return state.trainer.trainer;
  }
};

export const selectPrimaryCoachTrainerId = (state: RootState) => {
  const primaryCoach = selectPrimaryTrainer(state);

  return primaryCoach?.trainer_id;
};

export const selectShouldUseDefaultWeights = (state: RootState) => {
  const trainer = selectPrimaryTrainer(state);

  return !trainer?.settings.disable_smart_orm;
};

export const selectHasAccess = (state: RootState, location: AccessLocation) => {
  const trainer = selectPrimaryTrainer(state);

  if (!trainer || !trainer.access_roles) {
    return false;
  }

  const hasAccess = trainerHasAccess(trainer.access_roles, location);

  return hasAccess;
};
