import { Box, Divider, Typography } from "@mui/material";
import { CompletedStepsIcon } from "./CompletedStepsIcon";
import { CompletedWorkoutAndStepsIcon } from "./CompletedWorkoutAndStepsIcon";
import { CompletedWorkoutIcon } from "./CompletedWorkoutIcon";
import { StreakBrokenStepsIcon } from "./StreakBrokenStepsIcon";
import { StreakBrokenWorkoutIcon } from "./StreakBrokenWorkoutIcon";
import { StreakFrozenIcon } from "./StreakFrozenIcon";
import { StreakInDangerStepsIcon } from "./StreakInDangerStepsIcon";
import { StreakInDangerWorkoutIcon } from "./StreakInDangerWorkoutIcon";
import { UpcomingStepsIcon } from "./UpcomingStepsIcon";
import { UpcomingWorkoutIcon } from "./UpcomingWorkoutIcon";

export function StreakInfoSteps() {
  return (
    <>
      <Typography variant="h2" sx={{ mt: 2 }}>
        How streaks work
      </Typography>
      <Typography sx={{ mb: 2 }}>
        Your streak tracks the consecutive days you complete a workout or hit
        your step goal.
      </Typography>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <CompletedWorkoutIcon sx={{ mr: 0.75 }} />
        <UpcomingWorkoutIcon sx={{ mr: 0.75 }} />
        <Typography fontWeight={"bold"}>Trainwell workout</Typography>
      </Box>
      <Typography>
        Complete any trainwell workout (including extras) on workout days to
        maintain your streak.
      </Typography>
      <Divider sx={{ my: 1 }} />
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <CompletedStepsIcon sx={{ mr: 0.75 }} />
        <UpcomingStepsIcon sx={{ mr: 0.75 }} />
        <Typography fontWeight={"bold"}>Daily step goal</Typography>
      </Box>
      <Typography>
        Reaching your daily step goal counts towards keeping your streak alive.
      </Typography>
      <Divider sx={{ my: 1 }} />
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <CompletedWorkoutAndStepsIcon sx={{ mr: 0.75 }} />
        <Typography fontWeight={"bold"}>Bold badge</Typography>
      </Box>
      <Typography>
        Earn a bolt badge when you complete both a workout and your daily step
        goal.
      </Typography>
      <Divider sx={{ my: 1 }} />
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <StreakInDangerWorkoutIcon sx={{ mr: 0.75 }} />
        <StreakInDangerStepsIcon sx={{ mr: 0.75 }} />
        <Typography fontWeight={"bold"}>Streak in danger</Typography>
      </Box>
      <Typography>
        If your day is ending and you haven&apos;t completed a workout or hit
        your step goal, we&apos;ll remind you to save your streak.
      </Typography>
      <Divider sx={{ my: 1 }} />
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <StreakFrozenIcon
          sx={{
            mr: 0.75,
          }}
        />
        <Typography fontWeight={"bold"}>Streak freezes</Typography>
      </Box>
      <Typography>
        If you miss a workout or your step goal, one of your streak freezes will
        automatically activate to keep your streak going.
      </Typography>
      <Divider sx={{ my: 1 }} />
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <StreakBrokenWorkoutIcon
          sx={{
            mr: 0.75,
          }}
        />
        <StreakBrokenStepsIcon sx={{ mr: 0.75 }} />
        <Typography fontWeight={"bold"}>Streak broken</Typography>
      </Box>
      <Typography>
        If you miss a workout or step goal and don&apos;t have any more streak
        freezes, your streak will end.
      </Typography>
    </>
  );
}
