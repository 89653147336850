import ExpandMoreRoundedIcon from "@mui/icons-material/ExpandMoreRounded";
import HelpOutlineRoundedIcon from "@mui/icons-material/HelpOutlineRounded";
import WarningRoundedIcon from "@mui/icons-material/WarningRounded";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Card,
  Chip,
  Grid,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { incentiveGroupRecord, type CompHistory } from "@trainwell/types";
import { format } from "date-fns";
import CompensationClientBreakdown from "./CompensationClientBreakdown";

const months = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

type Props = {
  compHistory: CompHistory;
};

export default function CompensationAccordion({ compHistory }: Props) {
  const month = compHistory.pay_period.month - 1;
  const year = compHistory.pay_period.year;
  const estimated = compHistory.estimated;

  const bonusBucketIds = compHistory.comp_buckets
    .filter((bucket) => bucket.category === "bonus")
    .map((bucket) => bucket.id);

  const extraBonusBreakdown = compHistory.comp_buckets.filter(
    (bucket) => bucket.extra_bonus > 0,
  );

  return (
    <Accordion disableGutters elevation={0} square={true}>
      <AccordionSummary expandIcon={<ExpandMoreRoundedIcon />}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
            mr: 1,
          }}
        >
          <Typography>
            <b>
              {months[month]} {year}
            </b>
            {estimated ? " (estimated)" : ""}
          </Typography>
          {!estimated && (
            <Chip
              label={`$${compHistory.comp_breakdown.month_total.toLocaleString()}`}
              sx={{
                backgroundColor: (theme) => theme.palette.successSurface.main,
              }}
            />
          )}
        </Box>
      </AccordionSummary>
      <AccordionDetails>
        <Typography variant="h1" sx={{ mb: 1 }}>
          Payment overview{estimated ? " estimate" : ""} for {months[month]}{" "}
          {year}
        </Typography>
        <Typography
          variant="body2"
          sx={{ mb: 2, color: (theme) => theme.palette.text.secondary }}
        >
          Based on client activity for {months[month === 0 ? 11 : month - 1]}{" "}
          {month === 0 ? year - 1 : year}
        </Typography>
        {estimated && (
          <Box sx={{ display: "flex", alignItems: "baseline" }}>
            <WarningRoundedIcon fontSize="inherit" color="warning" />
            <Typography sx={{ ml: 1 }}>
              This information contains estimates and relies on data still being
              collected. It can change by the end of the month
            </Typography>
          </Box>
        )}
        <Box sx={{ my: 4 }}>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <Card
                variant="outlined"
                sx={{
                  backgroundColor: (theme) => theme.palette.successSurface.main,
                  p: 1,
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "flex-start",
                  }}
                >
                  <Typography variant="body2">Gross payout</Typography>
                  <Tooltip
                    title="How much you earned this month based on your performance from last month"
                    placement="top"
                  >
                    <IconButton size="small">
                      <HelpOutlineRoundedIcon fontSize="inherit" />
                    </IconButton>
                  </Tooltip>
                </Box>
                <Typography sx={{ fontWeight: "bold", fontSize: 28 }}>
                  ${compHistory.comp_breakdown.month_total.toLocaleString()}{" "}
                  <Typography
                    component={"span"}
                    variant="body2"
                    sx={{
                      color: (theme) => theme.palette.text.secondary,
                      display: "inline",
                    }}
                  >
                    $
                    {(
                      compHistory.comp_breakdown.month_total * 12
                    ).toLocaleString()}{" "}
                    per year
                  </Typography>
                </Typography>
              </Card>
            </Grid>
            <Grid item xs={4}>
              <Card
                variant="outlined"
                sx={{
                  p: 1,
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "flex-start",
                  }}
                >
                  <Typography variant="body2">Clients who could pay</Typography>
                  <Tooltip
                    title="Clients that could have renewed their subscriptions last month (includes free and multi-month subscription clients but not trial clients or paused clients)"
                    placement="top"
                  >
                    <IconButton size="small">
                      <HelpOutlineRoundedIcon fontSize="inherit" />
                    </IconButton>
                  </Tooltip>
                </Box>
                {compHistory.comp_breakdown.incentive_groups.map((group) => (
                  <Typography
                    key={group.incentive_group}
                    sx={{ fontWeight: "bold", fontSize: 24 }}
                  >
                    {group.n_clients_effective}{" "}
                    <Typography
                      component={"span"}
                      variant="body2"
                      sx={{
                        color: (theme) => theme.palette.text.secondary,
                        display: "inline",
                      }}
                    >
                      ({incentiveGroupRecord[group.incentive_group]})
                    </Typography>
                  </Typography>
                ))}
              </Card>
            </Grid>
            <Grid item xs={4}>
              <Card
                variant="outlined"
                sx={{
                  p: 1,
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "flex-start",
                  }}
                >
                  <Typography variant="body2">Percent paid</Typography>
                  <Tooltip
                    title="Of the clients who could have paid last month, what percent did"
                    placement="top"
                  >
                    <IconButton size="small">
                      <HelpOutlineRoundedIcon fontSize="inherit" />
                    </IconButton>
                  </Tooltip>
                </Box>
                {compHistory.comp_breakdown.incentive_groups.map((group) => {
                  const percentPaid =
                    Math.round(group.retention_effective * 100 * 100) / 100;

                  return (
                    <Typography
                      key={group.incentive_group}
                      sx={{ fontWeight: "bold", fontSize: 24 }}
                    >
                      {percentPaid}%{" "}
                      <Typography
                        component={"span"}
                        variant="body2"
                        sx={{
                          color: (theme) => theme.palette.text.secondary,
                          display: "inline",
                        }}
                      >
                        ({incentiveGroupRecord[group.incentive_group]})
                      </Typography>
                    </Typography>
                  );
                })}
              </Card>
            </Grid>
          </Grid>
        </Box>
        <Box>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <Typography variant="h2" sx={{ mb: 2 }}>
                Gross payout breakdown{estimated ? " (estimated)" : ""}
              </Typography>
              <Card variant="outlined" sx={{ maxWidth: "350px" }}>
                <Typography sx={{ p: 1 }}>
                  Floor:
                  <span style={{ float: "right" }}>
                    ${compHistory.comp_breakdown.floor.toLocaleString()}
                  </span>
                </Typography>
                <Typography
                  sx={{
                    px: 1,
                    pt: 1,
                    backgroundColor: (theme) =>
                      theme.palette.backgroundSecondary.main,
                  }}
                >
                  Total commission:
                  <span style={{ float: "right" }}>
                    ${compHistory.comp_breakdown.bonus.toLocaleString()}
                  </span>
                </Typography>
                {compHistory.comp_breakdown.incentive_groups.map((group, i) => (
                  <Typography
                    key={i}
                    variant="body2"
                    sx={{
                      backgroundColor: (theme) =>
                        theme.palette.backgroundSecondary.main,
                      color: (theme) => theme.palette.text.secondary,
                      pr: 1,
                      pl: 3,
                    }}
                  >
                    {incentiveGroupRecord[group.incentive_group]}:
                    <span style={{ float: "right" }}>
                      ${group.bonus.toLocaleString()}
                    </span>
                  </Typography>
                ))}
                <Typography
                  sx={{
                    p: 1,
                  }}
                >
                  Extra bonus:
                  <span style={{ float: "right" }}>
                    ${compHistory.comp_breakdown.extra_bonus.toLocaleString()}
                  </span>
                </Typography>
                <Typography
                  sx={{
                    px: 1,
                    pt: 1,
                    backgroundColor: (theme) =>
                      theme.palette.backgroundSecondary.main,
                  }}
                >
                  Gross payout:
                  <span style={{ float: "right" }}>
                    ${compHistory.comp_breakdown.month_total.toLocaleString()}
                  </span>
                </Typography>
                <Typography
                  variant="body2"
                  sx={{
                    color: (theme) => theme.palette.text.secondary,
                    backgroundColor: (theme) =>
                      theme.palette.backgroundSecondary.main,
                    pl: 3,
                    pr: 1,
                  }}
                >
                  {format(
                    new Date(compHistory.pay_period.paycheck_dates[0]),
                    "MMM d",
                  )}
                  :
                  <span style={{ float: "right" }}>
                    $
                    {compHistory.comp_breakdown.first_paycheck.toLocaleString()}
                  </span>
                </Typography>
                <Typography
                  variant="body2"
                  sx={{
                    color: (theme) => theme.palette.text.secondary,
                    backgroundColor: (theme) =>
                      theme.palette.backgroundSecondary.main,
                    pl: 3,
                    pb: 1,
                    pr: 1,
                  }}
                >
                  {format(
                    new Date(compHistory.pay_period.paycheck_dates[1]),
                    "MMM d",
                  )}
                  :
                  <span style={{ float: "right" }}>
                    $
                    {compHistory.comp_breakdown.second_paycheck.toLocaleString()}
                  </span>
                </Typography>
              </Card>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="h2" sx={{ mb: 2 }}>
                Commission breakdown{estimated ? " (estimated)" : ""}
              </Typography>
              <Stack spacing={1}>
                {compHistory.comp_breakdown.incentive_groups.map((group, i) => {
                  const percentPaid =
                    Math.round(group.retention_effective * 100 * 100) / 100;

                  const percentPaidMinusThreshold =
                    Math.round(
                      Math.max(
                        100 *
                          (group.retention_effective -
                            group.retention_threshold),
                        0,
                      ) * 100,
                    ) / 100;

                  return (
                    <Box key={i}>
                      <Typography sx={{ fontWeight: "bold" }}>
                        Breakdown for{" "}
                        {incentiveGroupRecord[group.incentive_group]}
                      </Typography>
                      <Card variant="outlined" sx={{ maxWidth: "350px" }}>
                        <Typography sx={{ px: 1, pt: 1 }}>
                          Clients who could pay:
                          <span style={{ float: "right" }}>
                            {group.n_clients_effective}
                          </span>
                        </Typography>
                        <Typography
                          variant="body2"
                          sx={{
                            color: (theme) => theme.palette.text.secondary,
                            pr: 1,
                            pl: 3,
                          }}
                        >
                          Clients paid:
                          <span style={{ float: "right" }}>
                            {
                              compHistory.users.filter(
                                (user) =>
                                  (group.incentive_group === "percent_paid_75"
                                    ? group.incentive_group ===
                                        user.incentive_group ||
                                      !user.incentive_group
                                    : group.incentive_group ===
                                      user.incentive_group) &&
                                  bonusBucketIds.includes(user.comp_bucket_id),
                              ).length
                            }
                          </span>
                        </Typography>
                        <Typography
                          variant="body2"
                          sx={{
                            color: (theme) => theme.palette.text.secondary,
                            pr: 1,
                            pl: 3,
                            pb: 1,
                          }}
                        >
                          Percent paid:
                          <span style={{ float: "right" }}>{percentPaid}%</span>
                        </Typography>
                        <Typography
                          sx={{
                            p: 1,
                            backgroundColor: (theme) =>
                              theme.palette.backgroundSecondary.main,
                          }}
                        >
                          Percent paid minus {group.retention_threshold * 100}:
                          <span style={{ float: "right" }}>
                            {percentPaidMinusThreshold}%
                          </span>
                        </Typography>
                        <Typography
                          sx={{
                            p: 1,
                          }}
                        >
                          Commission:
                          <span style={{ float: "right" }}>
                            ${group.bonus.toLocaleString()}
                          </span>
                        </Typography>
                      </Card>
                    </Box>
                  );
                })}
              </Stack>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="h2" sx={{ mb: 2 }}>
                Extra bonus breakdown{estimated ? " (estimated)" : ""}
              </Typography>
              <Card variant="outlined" sx={{ maxWidth: "350px" }}>
                <Stack direction={"column"}>
                  {extraBonusBreakdown.map((bucket, i) => (
                    <Box
                      key={bucket.id}
                      sx={{
                        backgroundColor: (theme) =>
                          i % 2 === 0
                            ? undefined
                            : theme.palette.backgroundSecondary.main,
                      }}
                    >
                      <Typography sx={{ px: 1, pt: 1 }}>
                        {bucket.name}
                        <span style={{ float: "right" }}>
                          $
                          {(
                            bucket.extra_bonus *
                            compHistory.users.filter(
                              (c) => c.comp_bucket_id === bucket.id,
                            ).length
                          ).toLocaleString()}
                        </span>
                      </Typography>
                      <Typography
                        variant="body2"
                        sx={{
                          color: (theme) => theme.palette.text.secondary,
                          pr: 1,
                          pl: 3,
                          pb: 1,
                        }}
                      >
                        Clients
                        <span style={{ float: "right" }}>
                          ${bucket.extra_bonus.toLocaleString()} x{" "}
                          {
                            compHistory.users.filter(
                              (c) => c.comp_bucket_id === bucket.id,
                            ).length
                          }
                        </span>
                      </Typography>
                    </Box>
                  ))}
                  <Box
                    sx={{
                      backgroundColor: (theme) =>
                        extraBonusBreakdown.length % 2 === 0
                          ? undefined
                          : theme.palette.backgroundSecondary.main,
                    }}
                  >
                    <Typography sx={{ p: 1 }}>
                      Extra bonus
                      <span style={{ float: "right" }}>
                        $
                        {compHistory.comp_breakdown.extra_bonus.toLocaleString()}
                      </span>
                    </Typography>
                  </Box>
                </Stack>
              </Card>
            </Grid>
          </Grid>
        </Box>
        <CompensationClientBreakdown
          compBuckets={compHistory.comp_buckets}
          compClients={compHistory.users}
          estimated={estimated}
        />
      </AccordionDetails>
    </Accordion>
  );
}
