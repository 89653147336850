import ChatBubbleRoundedIcon from "@mui/icons-material/ChatBubbleRounded";
import ThumbUpAltRoundedIcon from "@mui/icons-material/ThumbUpAltRounded";
import WarningRoundedIcon from "@mui/icons-material/WarningRounded";
import { Box, Chip, Typography } from "@mui/material";
import type { SetHistory } from "@trainwell/types";
import { getConvertedWeight } from "@trainwell/workout-lib";
import { getFormattedTime } from "src/lib/misc";
import { getWeightUnit } from "src/lib/miscUtility";

export function getSetString(
  set: SetHistory,
  weightSystem: "imperial" | "metric",
) {
  let recapString: string | undefined;

  let repsString: string | undefined;
  let timeString: string | undefined;
  let weightString: string | undefined;

  if (set.reps_target) {
    repsString = `${
      set.reps_performed !== set.reps_target
        ? `${set.reps_performed} / ${set.reps_target}`
        : set.reps_performed
    } reps`;
  }

  if (set.time_target && set.time_performed) {
    const performedString = getFormattedTime(set.time_performed);

    const targetString = getFormattedTime(set.time_target);

    timeString = `${
      set.time_performed !== set.time_target
        ? `${performedString} / ${targetString}`
        : performedString
    }`;
  }

  if (set.weight_target) {
    weightString = `${
      set.weight_performed !== set.weight_target
        ? `${getConvertedWeight({
            weight: set.weight_performed ?? 0,
            fromSystem: "imperial",
            toSystem: weightSystem,
            round: true,
          })} / ${getConvertedWeight({ weight: set.weight_target ?? 0, fromSystem: "imperial", toSystem: weightSystem, round: true })}`
        : getConvertedWeight({
            weight: set.weight_performed,
            fromSystem: "imperial",
            toSystem: weightSystem,
            round: true,
          })
    } ${getWeightUnit(weightSystem)}`;
  }

  if (repsString && weightString) {
    recapString = `${repsString} ${weightString}`;
  } else if (repsString && timeString) {
    recapString = `${repsString} ${timeString}`;
  } else if (weightString && timeString) {
    recapString = `${weightString} ${timeString}`;
  } else if (repsString) {
    recapString = `${repsString}`;
  } else if (timeString) {
    recapString = timeString;
  }

  return recapString;
}

type Props = {
  set: SetHistory;
  weightSystem: "imperial" | "metric";
};

export default function SetHistoryCell({ set, weightSystem }: Props) {
  const recapString = getSetString(set, weightSystem);

  return (
    <>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Typography sx={{ fontWeight: "bold" }}>
          {recapString || "Error"}
        </Typography>
        {set.rir === "0_to_2" ? (
          <Chip
            size="small"
            icon={<ThumbUpAltRoundedIcon />}
            label={"0-2 RiR"}
          />
        ) : set.rir === "not_finished" ? (
          <Chip
            color="error"
            size="small"
            icon={<WarningRoundedIcon />}
            label={"Didn't finish"}
          />
        ) : set.rir === "3+" ? (
          <Chip
            color="error"
            size="small"
            icon={<WarningRoundedIcon />}
            label={"3+ RiR"}
          />
        ) : null}
      </Box>
      {set.notes_client && (
        <Box
          sx={{
            backgroundColor: (theme) => theme.palette.backgroundSecondary.main,
            borderRadius: `10px 10px 10px 2px`,
            display: "flex",
            alignItems: "center",
            p: 1,
            mt: 1,
          }}
        >
          <ChatBubbleRoundedIcon fontSize="inherit" sx={{ mr: 1 }} />
          <Typography>{set.notes_client}</Typography>
        </Box>
      )}
    </>
  );
}
