import VideocamRoundedIcon from "@mui/icons-material/VideocamRounded";
import { Box, Stack, Typography } from "@mui/material";
import type { MessageCallScheduled } from "@trainwell/types";
import { format, isSameYear } from "date-fns";
import { useAppSelector } from "src/hooks/stateHooks";
import { selectActionItemsForClient } from "src/slices/actionItemSlice";
import SendPrecallSurveyButton from "../SendPrecallSurveyButton";

type Props = {
  message: MessageCallScheduled;
  combineBottom: boolean;
};

export function CallScheduledMessage({ message, combineBottom }: Props) {
  const hasCallSurveyActionItem = useAppSelector((state) =>
    selectActionItemsForClient(state, message.from_id).some(
      (actionItem) => actionItem.type === "send_pre_check_in_survey",
    ),
  );
  let callDateText = format(
    message.content.call_date,
    "MMM d, yyyy 'at' h:mm a",
  );

  if (isSameYear(message.content.call_date, new Date())) {
    callDateText = format(message.content.call_date, "MMM d 'at' h:mm a");
  }

  return (
    <Box
      sx={{
        maxWidth: "300px",
        borderRadius: `12px 12px 12px ${!combineBottom ? "2px" : "12px"}`,
        overflow: "hidden",
        backgroundColor: (theme) => theme.palette.background.default,
        p: 1,
        border: 1,
        borderColor: "divider",
        width: "fit-content",
      }}
    >
      <Stack spacing={1}>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <VideocamRoundedIcon
            sx={{
              fontSize: 16,
              color: (theme) => theme.palette.text.primary,
              mr: 1,
            }}
          />
          <Box>
            <Typography
              variant="h6"
              sx={{
                color: (theme) => theme.palette.text.primary,
              }}
            >
              Call scheduled
            </Typography>
            <Typography
              variant="body2"
              sx={{
                color: (theme) => theme.palette.text.secondary,
              }}
            >
              For {callDateText}
            </Typography>
          </Box>
        </Box>
        {hasCallSurveyActionItem && <SendPrecallSurveyButton />}
      </Stack>
    </Box>
  );
}
