import ExpandMoreRoundedIcon from "@mui/icons-material/ExpandMoreRounded";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { shallowEqual } from "react-redux";
import LoadingComponent from "src/components/miscPages/LoadingComponent";
import { useAppSelector } from "src/hooks/stateHooks";
import ExerciseProgressCell from "./ExerciseProgressCell";

type Props = {
  userId: string;
  condensed?: boolean;
};

const ExerciseProgressPanel = ({ userId, condensed }: Props) => {
  const exerciseLibraryStatus = useAppSelector(
    (state) => state.app.exerciseStatus,
  );
  const preferredWeightSystem = useAppSelector(
    (state) =>
      state.client.client?.user_id === userId
        ? state.client.client.preferred_weight_system
        : undefined,
    shallowEqual,
  );
  const progressExercisesSelected = useAppSelector(
    (state) =>
      state.client.client?.user_id === userId
        ? state.client.client.progress_exercises_selected
        : undefined,
    shallowEqual,
  );
  const [expanded, setExpanded] = useState(false);

  return (
    <Accordion
      disabled={
        !progressExercisesSelected || progressExercisesSelected?.length === 0
      }
      disableGutters
      elevation={0}
      square
      slotProps={{ transition: { timeout: 0, unmountOnExit: true } }}
      expanded={condensed ? false : expanded}
      onChange={() => setExpanded(!expanded)}
      sx={{
        backgroundColor: "transparent",
      }}
    >
      <AccordionSummary expandIcon={<ExpandMoreRoundedIcon />}>
        <Typography variant="h6">
          📈{condensed ? "" : " Exercise progress"}
        </Typography>
      </AccordionSummary>
      <AccordionDetails sx={{ p: 0 }}>
        {exerciseLibraryStatus !== "succeeded" ? (
          <LoadingComponent />
        ) : (
          progressExercisesSelected?.map((exerciseMasterId) => (
            <ExerciseProgressCell
              key={exerciseMasterId}
              exerciseMasterId={exerciseMasterId}
              userId={userId}
              weightSystem={preferredWeightSystem ?? "imperial"}
            />
          ))
        )}
      </AccordionDetails>
    </Accordion>
  );
};

export default React.memo(ExerciseProgressPanel);
