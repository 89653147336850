import ClearRoundedIcon from "@mui/icons-material/ClearRounded";
import ScheduleRoundedIcon from "@mui/icons-material/ScheduleRounded";
import {
  Box,
  Button,
  Chip,
  Popover,
  Stack,
  Typography,
  type SxProps,
  type Theme,
} from "@mui/material";
import type { ActionItemCustom } from "@trainwell/types";
import { differenceInMinutes, format, isPast } from "date-fns";
import { useMemo, useState } from "react";
import { useAppSelector } from "src/hooks/stateHooks";
import useActionItem from "src/hooks/useActionItem";
import {
  makeSelectActionItemsForClient,
  selectActionItemById,
} from "src/slices/actionItemSlice";
import { SaveForLaterIcon } from "./SaveForLaterIcon";

type Props = {
  userId: string;
  sx?: SxProps<Theme>;
};

export default function SaveForLaterChips({ userId, sx = [] }: Props) {
  const selectActionItemsForClient = useMemo(
    makeSelectActionItemsForClient,
    [],
  );
  const actionItems = useAppSelector((state) =>
    selectActionItemsForClient(state, userId),
  );
  const customActionItems = actionItems.filter(
    (ai) => ai.type === "custom",
  ) as ActionItemCustom[];
  const [showScheduled, setShowScheduled] = useState(false);

  const currentActionItems = customActionItems.filter((actionItem) =>
    isPast(new Date(actionItem.date_to_send ?? actionItem.date_created)),
  );
  const futureActionItems = customActionItems.filter(
    (actionItem) =>
      !isPast(new Date(actionItem.date_to_send ?? actionItem.date_created)),
  );

  if (customActionItems.length <= 0) {
    return null;
  }

  return (
    <Box sx={sx}>
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          gap: 0.5,
        }}
      >
        {currentActionItems.map((actionItem) => (
          <ActionItemChip key={actionItem.id} actionItemId={actionItem.id} />
        ))}
        {showScheduled &&
          futureActionItems.map((actionItem) => (
            <ActionItemChip key={actionItem.id} actionItemId={actionItem.id} />
          ))}
      </Box>
      {futureActionItems.length > 0 && (
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Button
            size="small"
            variant="text"
            sx={{ fontSize: 12, py: 0, mt: 0.5 }}
            startIcon={<ScheduleRoundedIcon sx={{ fontSize: 12 }} />}
            onClick={(event) => {
              event.stopPropagation();
              event.preventDefault();

              setShowScheduled((prev) => !prev);
            }}
            onMouseDown={(event) => {
              event.stopPropagation();
              event.preventDefault();
            }}
          >
            {showScheduled ? "Hide" : "Show"} scheduled
          </Button>
        </Box>
      )}
    </Box>
  );
}

type PropsChip = {
  actionItemId: string;
};

function ActionItemChip({ actionItemId }: PropsChip) {
  const { handleActionItemClearButton } = useActionItem();
  const actionItem = useAppSelector((state) =>
    selectActionItemById(state, actionItemId),
  ) as ActionItemCustom | undefined;
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const open = Boolean(anchorEl);

  if (!actionItem) {
    return null;
  }

  const isFuture = !isPast(
    new Date(actionItem.date_to_send ?? actionItem.date_created),
  );

  return (
    <>
      <Chip
        size="small"
        label={
          isFuture
            ? `${format(actionItem.date_to_send ?? actionItem.date_created, "MMM d")} - ${actionItem.notes}`
            : actionItem.notes
        }
        icon={
          <SaveForLaterIcon
            type={actionItem.content?.custom_type ?? "other"}
            fontSize="small"
            sx={{
              color: (theme) =>
                theme.palette.mode === "light"
                  ? "#3b82f6 !important"
                  : "#93c5fd !important",
            }}
          />
        }
        onMouseEnter={(event) => {
          setAnchorEl(event.currentTarget);
        }}
        onMouseLeave={() => {
          setAnchorEl(null);
        }}
        deleteIcon={<ClearRoundedIcon />}
        onDelete={(event) => {
          event.stopPropagation();
          event.preventDefault();

          setAnchorEl(null);

          handleActionItemClearButton(actionItem);
        }}
        sx={{
          backgroundColor: (theme) =>
            theme.palette.mode === "light" ? "#dbeafe" : "#1e40af",
          border: 1,
          borderColor: (theme) =>
            theme.palette.mode === "light" ? "#bfdbfe" : "#1d4ed8",
        }}
      />
      <Popover
        sx={{
          pointerEvents: "none",
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        onClose={() => {
          setAnchorEl(null);
        }}
        disableRestoreFocus
      >
        <Box sx={{ p: 1, maxWidth: "400px" }}>
          <Stack spacing={0.5} direction="column">
            {actionItem.notes && <Typography>{actionItem.notes}</Typography>}
            <Typography variant="overline">
              Created{" "}
              {format(
                new Date(actionItem.date_created),
                "iiii, MMM d, yyyy 'at' h:mm a",
              )}
            </Typography>
            {actionItem.date_to_send &&
              Math.abs(
                differenceInMinutes(
                  actionItem.date_to_send,
                  actionItem.date_created,
                ),
              ) > 5 && (
                <Typography variant="overline">
                  Sent{" "}
                  {format(
                    new Date(actionItem.date_to_send),
                    "iiii, MMM d, yyyy 'at' h:mm a",
                  )}
                </Typography>
              )}
          </Stack>
        </Box>
      </Popover>
    </>
  );
}
