import { Box, Stack } from "@mui/material";
import { useSnackbar } from "notistack";
import { useEffect, useRef } from "react";
import { useAppDispatch, useAppSelector } from "src/hooks/stateHooks";
import {
  fetchAllMessages,
  readChat,
  selectClientChats,
  sendTextMessage,
  setFocusedUserId,
  setMessage,
} from "src/slices/chatSlice";
import { ThreadCard } from "./ThreadCard";

export function ThreadsList() {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useAppDispatch();
  let chats = useAppSelector(selectClientChats);
  const displayedChatIds = useRef<string[]>([]);
  const allMessagesStatus = useAppSelector(
    (state) => state.chat.allMessagesStatus,
  );

  useEffect(() => {
    if (allMessagesStatus === "idle") {
      dispatch(fetchAllMessages());
    }
  }, [allMessagesStatus, dispatch]);

  useEffect(() => {
    displayedChatIds.current = chats.map((chat) => chat.id);
  }, []);

  chats = chats.filter(
    (chat) =>
      chat.oldestUnreadMessageFromClient ||
      displayedChatIds.current.includes(chat.id),
  );

  return (
    <Stack
      direction="column"
      spacing={4}
      sx={{
        backgroundColor: (theme) => theme.palette.background.default,
        p: 2,
      }}
    >
      {chats.map((chat) => {
        return (
          <Box key={chat.id} sx={{ display: "flex", alignItems: "center" }}>
            <ThreadCard
              chatId={chat.id}
              onMouseOverLinger={() => {
                dispatch(setFocusedUserId(chat.id));
                dispatch(readChat(chat.id));
              }}
              onSendMessage={(message) => {
                dispatch(
                  sendTextMessage({
                    userId: chat.id,
                    text: message,
                    toGroup: chat.isGroupChat,
                    asTrainwell: chat.isTrainwell,
                    sourceDetailed: "unread_thread",
                  }),
                )
                  .unwrap()
                  .catch(() => {
                    enqueueSnackbar("Message failed to send", {
                      variant: "error",
                    });
                  });
                dispatch(setMessage({ chatId: chat.id, message: "" }));
              }}
            />
          </Box>
        );
      })}
    </Stack>
  );
}
