import ArrowBackRoundedIcon from "@mui/icons-material/ArrowBackRounded";
import ChatBubbleOutlineRoundedIcon from "@mui/icons-material/ChatBubbleOutlineRounded";
import {
  Box,
  ButtonBase,
  Grid,
  IconButton,
  Popover,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { useState } from "react";
import ClientName from "src/features/client/ClientName";
import ClientProfilePicture from "src/features/client/ClientProfilePicture";
import ClientCard from "src/features/client/name-tag/ClientCard";
import { useAppDispatch, useAppSelector } from "src/hooks/stateHooks";
import { getTrainerName } from "src/lib/coachUtility";
import { closeChat, selectSelectedChat } from "src/slices/chatSlice";
import { updateClient } from "src/slices/clientSlice";
import { selectClientById } from "src/slices/clientsSlice";
import { selectTrainerNames } from "src/slices/trainersSlice";
import { ChatMoreButton } from "./ChatMoreButton";
import SaveForLaterChips from "./SaveForLaterChips";

export function ClientChatHeader() {
  const dispatch = useAppDispatch();
  const selectedChat = useAppSelector(selectSelectedChat);
  const isChatFullscreen = useAppSelector(
    (state) => state.chat.isChatFullscreen,
  );
  const trainerNames = useAppSelector(selectTrainerNames);
  const [clientAnchorEl, setClientAnchorEl] =
    useState<HTMLButtonElement | null>(null);
  const client = useAppSelector((state) =>
    selectClientById(state, selectedChat?.id ?? ""),
  );
  const [chatNotesAnchorEl, setChatNotesAnchorEl] =
    useState<HTMLButtonElement | null>(null);
  const [chatNotes, setChatNotes] = useState("");

  if (!selectedChat) {
    return null;
  }

  return (
    <Box
      sx={{
        backgroundColor: (theme) => theme.palette.background.paper,
      }}
    >
      <Box
        sx={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          px: 1,
          py: 0.5,
          borderBottom: 1,
          borderColor: "divider",
        }}
      >
        <Box sx={{ flex: 1 }}>
          <Grid container spacing={1} alignItems="center">
            {selectedChat.isGroupChat && (
              <Grid item xs={1}>
                <IconButton
                  size="small"
                  onClick={() => {
                    dispatch(closeChat());
                  }}
                >
                  <ArrowBackRoundedIcon fontSize="inherit" />
                </IconButton>
              </Grid>
            )}
            <Grid
              item
              xs={"auto"}
              sx={{ display: "flex", alignItems: "center" }}
            >
              {!selectedChat.isGroupChat && (
                <>
                  <ButtonBase
                    sx={{
                      borderRadius: "18px",
                    }}
                    onClick={(event) => {
                      event.stopPropagation();

                      if (isChatFullscreen) {
                        setClientAnchorEl(event.currentTarget);
                      }
                    }}
                    onMouseDown={(event) => {
                      event.stopPropagation();
                    }}
                    onTouchStart={(event) => {
                      event.stopPropagation();
                    }}
                  >
                    {!selectedChat.isGroupChat ? (
                      <ClientProfilePicture
                        dimension={32}
                        userId={selectedChat.id}
                      />
                    ) : (
                      <img
                        style={{
                          position: "relative",
                          borderRadius: "18px",
                        }}
                        src={
                          selectedChat.clientHeadshotURL
                            ? selectedChat.clientHeadshotURL
                            : "/assets/profile.png"
                        }
                        width={36}
                        height={36}
                        alt="client headshot"
                      />
                    )}
                  </ButtonBase>
                  {clientAnchorEl && (
                    <Popover
                      anchorEl={clientAnchorEl}
                      open={Boolean(clientAnchorEl)}
                      onClose={() => {
                        setClientAnchorEl(null);
                      }}
                    >
                      <ClientCard />
                    </Popover>
                  )}
                </>
              )}
            </Grid>
            <Grid item xs>
              {selectedChat.isGroupChat ? (
                <Typography
                  variant="h6"
                  sx={{
                    textAlign: "start",
                    color: (theme) => theme.palette.text.primary,
                  }}
                >
                  {selectedChat.clientName}
                </Typography>
              ) : (
                <ClientName
                  userId={selectedChat?.id}
                  sx={{
                    fontWeight: "bold",
                    textAlign: "start",
                    color: (theme) => theme.palette.text.primary,
                  }}
                />
              )}
              {selectedChat.forceTrainerId && (
                <Typography
                  variant="body2"
                  sx={{
                    color: (theme) => theme.palette.text.secondary,
                    lineHeight: 1,
                    textAlign: "start",
                  }}
                >
                  With{" "}
                  {getTrainerName(selectedChat.forceTrainerId, trainerNames)}
                </Typography>
              )}
              {client && !client.last_login_date && (
                <Typography
                  variant="body2"
                  sx={{
                    color: (theme) => theme.palette.text.secondary,
                    lineHeight: 1,
                    textAlign: "start",
                  }}
                >
                  Not opened app (auto-sms)
                </Typography>
              )}
            </Grid>
          </Grid>
        </Box>
        {!selectedChat.isGroupChat && (
          <ChatMoreButton userId={selectedChat?.id} />
        )}
      </Box>
      {client && (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            px: 1,
            py: 0.25,
            backgroundColor: (theme) => theme.palette.background.paper,
            borderBottom: 1,
            borderColor: "divider",
          }}
        >
          <Tooltip title={"Set chat notes"} disableInteractive>
            <IconButton
              size="small"
              onClick={(event) => {
                setChatNotes(client.chat_notes ?? "");
                setChatNotesAnchorEl(event.currentTarget);

                setChatNotes(client.chat_notes ?? "");
              }}
              sx={{ mr: 1 }}
            >
              <ChatBubbleOutlineRoundedIcon fontSize="inherit" />
            </IconButton>
          </Tooltip>
          {client?.chat_notes ? (
            <Typography
              sx={{
                overflowWrap: "break-word",
                overflowX: "hidden",
              }}
            >
              {client.chat_notes}
            </Typography>
          ) : (
            <Typography variant="overline">Add chat notes</Typography>
          )}
        </Box>
      )}
      {selectedChat?.id && !selectedChat.isGroupChat && (
        <SaveForLaterChips
          userId={selectedChat.id}
          sx={{
            px: 1,
            py: 0.5,
            borderBottom: 1,
            borderColor: "divider",
          }}
        />
      )}
      <Popover
        open={Boolean(chatNotesAnchorEl)}
        onClose={() => {
          dispatch(
            updateClient({
              user_id: selectedChat?.id ?? "",
              chat_notes: chatNotes,
            }),
          );

          setChatNotesAnchorEl(null);
        }}
        anchorEl={chatNotesAnchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        disableRestoreFocus
        slotProps={{
          paper: {
            sx: {
              p: 2,
              width: 350,
            },
          },
        }}
      >
        <TextField
          label="Chat notes"
          size="small"
          fullWidth
          multiline
          value={chatNotes}
          onKeyDown={(event) => {
            if (event.key === "Enter") {
              event.stopPropagation();
              event.preventDefault();

              dispatch(
                updateClient({
                  user_id: selectedChat?.id ?? "",
                  chat_notes: chatNotes,
                }),
              );

              setChatNotesAnchorEl(null);
            }
          }}
          onChange={(event) => {
            setChatNotes(event.target.value);
          }}
        />
      </Popover>
    </Box>
  );
}
