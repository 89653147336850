import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import TimerRoundedIcon from "@mui/icons-material/TimerRounded";
import {
  Box,
  Card,
  IconButton,
  Popover,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import type { Workout } from "@trainwell/types";
import { useEffect, useState } from "react";
import TextFieldWithLimit from "src/components/misc/TextFieldWithLimit";
import LoadingPage from "src/components/miscPages/LoadingPage";
import { useAppDispatch } from "src/hooks/stateHooks";
import {
  getExerciseDisplayName,
  getExerciseIconURL,
} from "src/lib/mediaUtility";
import { api } from "src/lib/trainwellApi";
import { workoutLib } from "src/lib/trainwellWorkoutLib";
import { updateWorkout } from "src/slices/phasesSlice";
import { updateTemplate } from "src/slices/templatesSlice";

type Props = {
  workoutId?: string;
  templateId?: string;
  anchorEl: HTMLElement | null;
  onClose: () => void;
};

export default function WorkoutPreviewPopover({
  workoutId,
  templateId,
  anchorEl,
  onClose,
}: Props) {
  const dispatch = useAppDispatch();
  const [workout, setWorkout] = useState<Workout>();

  const open = Boolean(anchorEl);

  useEffect(() => {
    async function runAsync() {
      if (workoutId) {
        const fetchedWorkout = await api.workouts.getOne(workoutId);
        setWorkout(fetchedWorkout);
      } else if (templateId) {
        const { workout_template } =
          await api.workoutTemplates.getOne(templateId);
        const convertedWorkout =
          workoutLib.workouts.convertTemplateToWorkout(workout_template);
        setWorkout(convertedWorkout);
      }
    }

    if (anchorEl) {
      runAsync();
    }
  }, [anchorEl]);

  return (
    <Popover
      open={open}
      onClose={onClose}
      anchorEl={anchorEl}
      onBackdropClick={(event) => {
        event.stopPropagation();
      }}
      anchorOrigin={{
        vertical: "center",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      onMouseMove={(event) => {
        event.stopPropagation();
      }}
      onMouseDown={(event) => {
        event.stopPropagation();
      }}
    >
      {workout === null ? (
        <Typography sx={{ fontWeight: "bold", m: 2 }}>
          External workout
        </Typography>
      ) : (
        <Box
          sx={{
            maxHeight: "650px",
            width: "350px",
            overflowY: "auto",
            backgroundColor: (theme) => theme.palette.background.default,
          }}
        >
          <>
            {workout ? (
              <>
                <Box
                  sx={{
                    p: 1,
                    backgroundColor: (theme) => theme.palette.background.paper,
                    borderBottom: 1,
                    borderColor: "divider",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      mb: 1,
                    }}
                  >
                    <Typography sx={{ fontWeight: "bold" }}>
                      {templateId
                        ? "Workout template preview"
                        : "Workout preview"}
                    </Typography>
                    <IconButton
                      aria-label="close"
                      onClick={(event) => {
                        event.stopPropagation();
                        onClose();
                      }}
                      size="small"
                    >
                      <CloseRoundedIcon />
                    </IconButton>
                  </Box>
                  <TextFieldWithLimit
                    disabled={!workoutId}
                    fullWidth
                    size="small"
                    defaultValue={workout.name}
                    key={workout.name}
                    label="Name"
                    name="workoutPreview.workoutName"
                    characterLimit={44}
                    multiline={true}
                    onSave={(newName) => {
                      if (workoutId) {
                        dispatch(
                          updateWorkout({
                            workout_id: workoutId,
                            name: newName,
                          }),
                        );
                      } else if (templateId) {
                        dispatch(
                          updateTemplate({
                            template_id: templateId,
                            name: newName,
                          }),
                        );
                      }
                    }}
                    sx={{ mb: 1 }}
                  />
                  <TextField
                    disabled={!workoutId}
                    fullWidth
                    size="small"
                    defaultValue={workout.notes_trainer}
                    key={workout.notes_trainer}
                    label="Notes"
                    name="workoutPreview.workoutNotes"
                    helperText="Read out loud to clients"
                    multiline={true}
                    onBlur={(event) => {
                      const value =
                        event.target.value === "" ? null : event.target.value;

                      if (workoutId) {
                        dispatch(
                          updateWorkout({
                            workout_id: workoutId,
                            notes_trainer: value,
                          }),
                        );
                      } else if (templateId) {
                        dispatch(
                          updateTemplate({
                            template_id: templateId,
                            notes_trainer: value,
                          }),
                        );
                      }
                    }}
                    onKeyDown={(event) => {
                      if (event.key === "Enter") {
                        event.preventDefault();
                        // @ts-expect-error
                        event.target.blur();
                      }
                    }}
                  />
                </Box>
                <Box sx={{ p: 1 }}>
                  <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
                    <TimerRoundedIcon sx={{ mr: 1 }} />
                    <Tooltip title="Estimated duration">
                      <Typography sx={{ fontWeight: "bold" }}>
                        {Math.floor(
                          workoutLib.workouts.getWorkoutDuration(workout) / 60,
                        )}
                        m
                      </Typography>
                    </Tooltip>
                  </Box>
                  {workout.sections.map((section) => (
                    <Card
                      variant="outlined"
                      key={section.section_id}
                      sx={{ mb: 1, p: 1 }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "baseline",
                          mb: 1,
                        }}
                      >
                        <Typography
                          variant="h3"
                          sx={{
                            color: (theme) =>
                              section.section_name
                                ? theme.palette.text.primary
                                : theme.palette.error.main,
                          }}
                        >
                          {!section.section_name
                            ? "No name"
                            : section.section_name}
                        </Typography>
                        {section.cycles.length >= 1 && (
                          <Typography variant="overline" sx={{ ml: 1 }}>
                            x{section.cycles.length}
                          </Typography>
                        )}
                      </Box>
                      {section.cycles[0].exercises.map((exercise) => (
                        <Box
                          key={exercise.exercise_id}
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            mb: 0.5,
                          }}
                        >
                          <Box sx={{ mr: 1 }}>
                            <img
                              src={
                                getExerciseIconURL(
                                  exercise.exercise_master_id,
                                ) || "/assets/profile.png"
                              }
                              width={40}
                              height={40}
                              alt="workout"
                            />
                          </Box>
                          <Box>
                            <Typography>
                              {getExerciseDisplayName(
                                exercise.exercise_master_id,
                              )}
                            </Typography>
                            <Typography variant="overline">
                              {exercise.sets.length} set
                              {exercise.sets.length > 1 ? "s" : ""}
                            </Typography>
                          </Box>
                        </Box>
                      ))}
                    </Card>
                  ))}
                </Box>
              </>
            ) : (
              <LoadingPage message="Loading workout" />
            )}
          </>
        </Box>
      )}
    </Popover>
  );
}
