import ExpandMoreRoundedIcon from "@mui/icons-material/ExpandMoreRounded";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Card,
  Chip,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import {
  incentiveGroupAmountRecord,
  type CompBucket,
  type CompClient,
} from "@trainwell/types";
import CustomLink from "src/components/misc/CustomLink";

const categoryNames = {
  extra_bonus: "Extra bonus",
  bonus: "Paid",
  neutral: "Neutral",
  loss: "Canceled",
  unknown: "Unknown",
};

const categoryOrder = {
  extra_bonus: 0,
  bonus: 1,
  neutral: 3,
  loss: 2,
  unknown: 4,
};

type Props = {
  compBuckets: CompBucket[];
  compClients: CompClient[];
  estimated?: boolean;
};

export default function CompensationClientBreakdown({
  compBuckets,
  compClients,
  estimated,
}: Props) {
  const theme = useTheme();

  const categoryColors = {
    extraBonus: theme.palette.purpleSurface.main,
    bonus: theme.palette.successSurface.main,
    neutral: undefined,
    loss: theme.palette.errorSurface.main,
    unknown: undefined,
  };

  const groupIds = [...new Set(compBuckets.map((bucket) => bucket.category))];

  let groups = groupIds.map((groupId) => {
    return {
      id: groupId,
      name: categoryNames[groupId as keyof typeof categoryNames],
      color: categoryColors[groupId as keyof typeof categoryColors],
      buckets: compBuckets
        .filter((bucket) => bucket.category === groupId)
        .map((bucket) => {
          return {
            ...bucket,
            clients: compClients
              .filter((client) => client.comp_bucket_id === bucket.id)
              .sort((a, b) =>
                (a.full_name ?? "unknown").localeCompare(
                  b.full_name ?? "unknown",
                ),
              ),
          };
        })
        .filter((bucket) => bucket.clients.length > 0),
    };
  });

  const extraBonusGroup = {
    id: "extra_bonus",
    name: "Extra bonus",
    color: categoryColors.extraBonus,
    buckets: groups
      .map((group) => group.buckets.filter((bucket) => bucket.extra_bonus > 0))
      .flat(),
  };

  groups.push(extraBonusGroup);

  groups.sort(
    (a, b) =>
      categoryOrder[a.id as keyof typeof categoryOrder] -
      categoryOrder[b.id as keyof typeof categoryOrder],
  );

  if (!estimated) {
    groups = groups.filter((group) => group.id !== "unknown");
  }

  console.log(compClients);

  return (
    <>
      <Typography variant="h2" sx={{ my: 2 }}>
        Client breakdown{estimated ? " (estimated)" : ""}
      </Typography>
      <Card variant="outlined" sx={{ mb: 4, maxWidth: "500px" }}>
        {groups.map((group) => (
          <Accordion disableGutters elevation={0} square={true} key={group.id}>
            <AccordionSummary expandIcon={<ExpandMoreRoundedIcon />}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  width: "100%",
                  mr: 1,
                }}
              >
                <Typography variant="h6">{group.name}</Typography>
                <Chip
                  size="small"
                  label={group.buckets.reduce(
                    (partialSum, a) => partialSum + a.clients.length,
                    0,
                  )}
                  sx={{
                    backgroundColor: group.color,
                    ml: 2,
                  }}
                />
              </Box>
            </AccordionSummary>
            <AccordionDetails>
              {group.buckets.map((bucket) => (
                <Box key={bucket.id}>
                  <Typography variant="h6" sx={{ mt: 2 }}>
                    {bucket.name}
                    {bucket.extra_bonus > 0 &&
                      ` ($${bucket.extra_bonus.toLocaleString()} / client)`}
                  </Typography>
                  <Typography
                    variant="overline"
                    sx={{
                      mb: 1,
                    }}
                  >
                    {bucket.description}
                  </Typography>
                  <Stack direction={"column"}>
                    {bucket.clients.map((client) => (
                      <Typography
                        key={client.user_id}
                        sx={{ color: (theme) => theme.palette.text.secondary }}
                      >
                        <CustomLink
                          href={`https://trainer.trainwell.net/clients/${client.user_id}`}
                          text={`${client.comp_state.hidden ? "(Hidden) " : ""}${
                            client.full_name ?? "unknown"
                          }`}
                        />{" "}
                        ({incentiveGroupAmountRecord[client.incentive_group]})
                      </Typography>
                    ))}
                  </Stack>
                </Box>
              ))}
            </AccordionDetails>
          </Accordion>
        ))}
      </Card>
    </>
  );
}
