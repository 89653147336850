import { Box, darken, type SxProps, type Theme } from "@mui/material";

type Props = {
  sx?: SxProps<Theme>;
};

export function CompletedRestDayIcon({ sx = [] }: Props) {
  return (
    <Box
      sx={{
        width: 18,
        height: 18,
        borderRadius: 9,
        backgroundColor: (theme) =>
          darken(theme.palette.successSurface.main, 0.1),
        ...sx,
      }}
    />
  );
}
