import ExpandMoreRoundedIcon from "@mui/icons-material/ExpandMoreRounded";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "@mui/material";
import type { WorkoutLog } from "@trainwell/types";

type Props = {
  log: WorkoutLog;
};

function DetailsPanel({ log }: Props) {
  return (
    <Accordion
      disableGutters
      elevation={0}
      square
      onChange={(_, expanded) => {
        if (expanded) {
          console.log(
            "Here's your log. Good luck debugging, my friend :)",
            log,
          );
          console.log(
            "Motion packet summary 2: ",
            log.tech_summary?.motion_packet_summary_2,
          );
        }
      }}
    >
      <AccordionSummary expandIcon={<ExpandMoreRoundedIcon />}>
        <Typography sx={{ fontWeight: "bold" }}>Technical details</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <div style={{ textAlign: "left" }}>
          {!log.tech_summary ? (
            <Typography variant="body1">
              This log doesn&apos;t contain a tech_summary
            </Typography>
          ) : (
            <>
              <Typography variant="body1">
                <span style={{ fontWeight: "bold" }}>App version: </span>
                {log.tech_summary.app_version}
              </Typography>
              <Typography variant="body1">
                <span style={{ fontWeight: "bold" }}>Watch used: </span>
                {log.tech_summary.watch_used ? "true" : "false"}
              </Typography>
              <Typography variant="body1">
                <span style={{ fontWeight: "bold" }}>Watch battery: </span>
                {log.tech_summary.watch_battery}
              </Typography>
              <Typography variant="body1">
                <span style={{ fontWeight: "bold" }}>Watch crashes: </span>
                {log.tech_summary.watch_crashes ?? "null"}
              </Typography>
              <Typography variant="body1">Motion Packet Summary...</Typography>
              <div style={{ paddingLeft: "8px" }}>
                {!log.tech_summary.motion_packet_summary ? (
                  <Typography variant="body1">
                    This log doesn&apos;t contain a motion_packet_summary
                  </Typography>
                ) : (
                  <>
                    <Typography variant="body1">
                      <span style={{ fontWeight: "bold" }}>Packets sent: </span>
                      {log.tech_summary.motion_packet_summary.packets_sent}
                    </Typography>
                    <Typography variant="body1">
                      <span style={{ fontWeight: "bold" }}>
                        Packets sent successfully:{" "}
                      </span>
                      {
                        log.tech_summary.motion_packet_summary
                          .packets_sent_successfully
                      }
                    </Typography>
                    <Typography variant="body1">
                      <span style={{ fontWeight: "bold" }}>Packet drop: </span>
                      {log.tech_summary.motion_packet_summary.packet_drop}
                    </Typography>
                    <Typography variant="body1">
                      <span style={{ fontWeight: "bold" }}>Packet error: </span>
                      {log.tech_summary.motion_packet_summary.packet_error ??
                        "null"}
                    </Typography>
                  </>
                )}
              </div>
            </>
          )}
        </div>
      </AccordionDetails>
    </Accordion>
  );
}

export default DetailsPanel;
