import "emoji-picker-element";
import type { EmojiClickEvent } from "emoji-picker-element/shared";
import { useCallback, useRef } from "react";

type Props = {
  onSelect?: (emoji: string) => void;
};

export function EmojiPicker({ onSelect }: Props): React.ReactElement {
  const callbackRef = useRef<Props["onSelect"]>(onSelect);

  // Since we only add the callback once to the underlying HTML element
  // and we get refs randomly. It's not a good idea to attach a different
  // listener to it on each re-render. So we attach the parent listener
  // to a local ref and call that ref value instead.
  callbackRef.current = onSelect;

  const handleElementRef = useCallback((element: HTMLElement) => {
    if (element != null) {
      if (element.dataset["emoji-picker-registered"] == null) {
        element.setAttribute("emoji-picker-registered", "true");
        element.addEventListener("emoji-click", (event) => {
          callbackRef.current?.(
            (event as EmojiClickEvent).detail.unicode ?? "",
          );
        });
      }
    }
  }, []);

  return (
    <div
      onKeyDown={(event) => {
        event.stopPropagation();
      }}
    >
      {/* @ts-expect-error: Custom HTML Element */}
      <emoji-picker ref={handleElementRef} />
    </div>
  );
}
