import AcUnitRoundedIcon from "@mui/icons-material/AcUnitRounded";
import { Avatar, type SxProps, type Theme } from "@mui/material";

type Props = {
  sx?: SxProps<Theme>;
  hasNoFreeze?: boolean;
};

export function StreakFrozenIcon({ sx = [], hasNoFreeze }: Props) {
  return (
    <Avatar
      sx={{
        width: 18,
        height: 18,
        backgroundColor: (theme) =>
          hasNoFreeze ? theme.palette.text.disabled : theme.palette.blue.main,
        ...sx,
      }}
    >
      <AcUnitRoundedIcon sx={{ fontSize: 14 }} />
    </Avatar>
  );
}
