import { type Theme } from "@mui/material/styles";
import { chartsGridClasses } from "@mui/x-charts";
import type { ChartsComponents } from "@mui/x-charts/themeAugmentation";

export const chartsCustomizations: ChartsComponents<Theme> = {
  MuiChartsGrid: {
    styleOverrides: {
      root: ({ theme }) => ({
        [`& .${chartsGridClasses.line}`]: {
          strokeDasharray: "4 2",
          strokeWidth: 0.8,
        },
        ...theme.applyStyles("dark", {
          [`& .${chartsGridClasses.line}`]: {
            strokeDasharray: "4 2",
            strokeWidth: 0.8,
          },
        }),
      }),
    },
  },
};
