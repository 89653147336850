import {
  attachClosestEdge,
  extractClosestEdge,
  type Edge,
} from "@atlaskit/pragmatic-drag-and-drop-hitbox/closest-edge";
import { combine } from "@atlaskit/pragmatic-drag-and-drop/combine";
import {
  draggable,
  dropTargetForElements,
} from "@atlaskit/pragmatic-drag-and-drop/element/adapter";
import { pointerOutsideOfPreview } from "@atlaskit/pragmatic-drag-and-drop/element/pointer-outside-of-preview";
import { setCustomNativeDragPreview } from "@atlaskit/pragmatic-drag-and-drop/element/set-custom-native-drag-preview";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import ChatBubbleRoundedIcon from "@mui/icons-material/ChatBubbleRounded";
import ContentCopyRoundedIcon from "@mui/icons-material/ContentCopyRounded";
import DeleteRoundedIcon from "@mui/icons-material/Delete";
import DragIndicatorRoundedIcon from "@mui/icons-material/DragIndicatorRounded";
import {
  Box,
  Card,
  Checkbox,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  alpha,
} from "@mui/material";
import { memo, useEffect, useRef, useState } from "react";
import { createPortal } from "react-dom";
import { DragPreview } from "src/components/common/DragPreview";
import { DropIndicator } from "src/components/common/DropIndicator";
import { useAppDispatch, useAppSelector } from "src/hooks/stateHooks";
import {
  copySection,
  deleteSection,
  deselectAllExercises,
  deselectAllExercisesInSection,
  selectAllExercises,
  selectAllExercisesInSection,
  selectCycleById,
  selectIsSectionSelected,
  selectSectionById,
  updateSectionRepeats,
} from "src/slices/workoutSlice";
import ExerciseCell from "./ExerciseCell";
import SectionName from "./SectionName";

type Props = PropsDraggable & {
  isDragging: boolean;
  dragHandleRef: React.RefObject<HTMLButtonElement>;
};

const SectionCell = memo(function SectionCell({
  sectionId,
  isDragging,
  dragHandleRef,
}: Props) {
  const dispatch = useAppDispatch();
  const section = useAppSelector((state) =>
    selectSectionById(state, sectionId),
  );
  const firstCycle = useAppSelector((state) =>
    selectCycleById(state, section?.cycles[0] ?? ""),
  );
  const isSelected = useAppSelector((state) =>
    selectIsSectionSelected(state, sectionId),
  );

  if (!section) {
    return <Typography>Finding section</Typography>;
  }

  const cellContents = (
    <Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          px: 2,
          py: 1,
          backgroundColor: (theme) =>
            isSelected ? alpha(theme.palette.primary.main, 0.3) : undefined,
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <IconButton
            color="default"
            ref={dragHandleRef}
            onClick={(event) => {
              event.stopPropagation();
            }}
            sx={{
              cursor: "grab",
            }}
          >
            <DragIndicatorRoundedIcon />
          </IconButton>
          <Checkbox
            sx={{ mr: 1 }}
            name="exerciseCell.checkbox"
            checked={isSelected}
            onClick={(e) => {
              e.stopPropagation();

              if (e.shiftKey === true) {
                if (isSelected) {
                  dispatch(deselectAllExercises());
                } else {
                  dispatch(selectAllExercises());
                }
              } else {
                if (isSelected) {
                  dispatch(deselectAllExercisesInSection(section.section_id));
                } else {
                  dispatch(selectAllExercisesInSection(section.section_id));
                }
              }
            }}
          />
          <SectionName section={section} />
        </Box>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <FormControl variant="outlined" size="small" sx={{ mr: 1 }}>
            <InputLabel id="repeats-label">Cycles</InputLabel>
            <Select
              labelId="repeats-label"
              id="repeats"
              value={section.cycles.length}
              onChange={(event) => {
                const repeats = Number(event.target.value);
                dispatch(
                  updateSectionRepeats({
                    sectionId: sectionId,
                    repeats: repeats,
                  }),
                );
              }}
              label="Cycles"
            >
              {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((number) => {
                return (
                  <MenuItem key={number} value={number}>
                    {`${number} cycle${number > 1 ? "s" : ""}`}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
          <IconButton
            aria-label="copy"
            onClick={(event) => {
              event.stopPropagation();
              dispatch(copySection(sectionId));
            }}
            size="large"
          >
            <ContentCopyRoundedIcon />
          </IconButton>
          <IconButton
            aria-label="delete"
            onClick={(event) => {
              event.stopPropagation();
              dispatch(deleteSection(sectionId));
            }}
            size="large"
          >
            <DeleteRoundedIcon />
          </IconButton>
        </Box>
      </Box>
      {section.notes && (
        <Box sx={{ mr: 2, ml: 10, display: "flex", alignItems: "center" }}>
          <ChatBubbleRoundedIcon
            fontSize="inherit"
            sx={{ color: (theme) => theme.palette.text.secondary }}
          />
          <Typography
            variant="body2"
            sx={{ ml: 1, color: (theme) => theme.palette.text.secondary }}
          >
            {section.notes}
          </Typography>
        </Box>
      )}
      <Box>
        {firstCycle!.exercises.length <= 0 ? (
          <SectionDroppable sectionId={sectionId} />
        ) : (
          firstCycle?.exercises.map((exerciseId, index) => (
            <ExerciseCell
              key={exerciseId}
              sectionId={sectionId}
              exerciseId={exerciseId}
              exerciseIndex={index}
            />
          ))
        )}
      </Box>
    </Box>
  );

  return (
    <Box sx={{ px: 2, py: 2 }}>
      <Card
        variant="outlined"
        sx={{
          opacity: isDragging ? 0.4 : 1,
        }}
      >
        {cellContents}
      </Card>
    </Box>
  );
});

type PropsDraggable = {
  sectionId: string;
  sectionIndex: number;
  isLastSection: boolean;
};

function SectionCellDraggable(props: PropsDraggable) {
  const ref = useRef(null);
  const dragHandleRef = useRef<HTMLButtonElement>(null);
  const [dragging, setDragging] = useState<boolean>(false);
  const [closestEdge, setClosestEdge] = useState<Edge | null>(null);
  const [previewContainer, setPreviewContainer] = useState<HTMLElement | null>(
    null,
  );
  const section = useAppSelector((state) =>
    selectSectionById(state, props.sectionId),
  );

  useEffect(() => {
    const element = ref.current;
    const dragHandle = dragHandleRef.current;

    const index = props.sectionIndex;

    if (!element || !dragHandle) {
      return;
    }

    const data = {
      type: "section",
      sectionId: props.sectionId,
      index: props.sectionIndex,
    };

    return combine(
      draggable({
        element: dragHandle,
        getInitialData: () => data,
        onDragStart: () => setDragging(true),
        onDrop: () => setDragging(false),
        onGenerateDragPreview({ nativeSetDragImage }) {
          setCustomNativeDragPreview({
            nativeSetDragImage,
            getOffset: pointerOutsideOfPreview({
              x: "16px",
              y: "8px",
            }),
            render({ container }) {
              setPreviewContainer(container);
            },
          });
        },
      }),
      dropTargetForElements({
        element,
        canDrop({ source }) {
          return (
            source.data.type === "section" ||
            source.data.type === "template" ||
            source.data.type === "exercise" ||
            source.data.type === "exercise_source"
          );
        },
        getData({ input }) {
          return attachClosestEdge(data, {
            element,
            input,
            allowedEdges: ["top", "bottom"],
          });
        },
        onDrag({ self, source, location }) {
          const isSource =
            source.element === element || source.element === dragHandle;
          if (isSource) {
            setClosestEdge(null);
            return;
          }

          const sourceType = source.data.type as string;

          if (
            (sourceType === "exercise" || sourceType === "exercise_source") &&
            location.current.dropTargets.some(
              (t) =>
                t.data.type === "exercise" || t.data.type === "empty_section",
            )
          ) {
            setClosestEdge(null);
            return;
          }

          const closestEdge = extractClosestEdge(self.data);

          if (sourceType === "exercise_source" || sourceType === "template") {
            // These don't have an index
            setClosestEdge(closestEdge);

            return;
          }

          if (sourceType === "exercise") {
            // This type can be dropped in any index
            setClosestEdge(closestEdge);

            return;
          }

          const sourceIndex = source.data.index;
          if (typeof sourceIndex !== "number") {
            return;
          }

          const isItemBeforeSource = index === sourceIndex - 1;
          const isItemAfterSource = index === sourceIndex + 1;

          const isDropIndicatorHidden =
            (isItemBeforeSource && closestEdge === "bottom") ||
            (isItemAfterSource && closestEdge === "top");

          if (isDropIndicatorHidden) {
            setClosestEdge(null);
            return;
          }

          setClosestEdge(closestEdge);
        },
        onDragLeave() {
          setClosestEdge(null);
        },
        onDrop() {
          setClosestEdge(null);
        },
      }),
    );
  }, [props.sectionId, props.sectionIndex]);

  return (
    <>
      <div
        style={{
          position: "relative",
        }}
      >
        <div ref={ref}>
          <SectionCell
            isDragging={dragging}
            dragHandleRef={dragHandleRef}
            {...props}
          />
        </div>
        {closestEdge && <DropIndicator edge={closestEdge} gap="0px" />}
      </div>
      {previewContainer
        ? createPortal(
            <DragPreview text={section?.section_name ?? "Section"} />,
            previewContainer,
          )
        : null}
    </>
  );
}

export default SectionCellDraggable;

type DroppableProps = {
  sectionId: string;
};

function SectionDroppable({ sectionId }: DroppableProps) {
  const ref = useRef(null);
  const [isDraggedOver, setIsDraggedOver] = useState(false);

  useEffect(() => {
    const el = ref.current;
    if (!el) {
      return;
    }

    return dropTargetForElements({
      element: el,
      getData: () => ({ type: "empty_section", sectionId: sectionId }),
      onDragEnter: () => setIsDraggedOver(true),
      onDragLeave: () => setIsDraggedOver(false),
      onDrop: () => setIsDraggedOver(false),
      canDrop({ source }) {
        return (
          source.data.type === "exercise" ||
          source.data.type === "exercise_source"
        );
      },
    });
  }, [sectionId]);

  return (
    <div ref={ref}>
      <Box
        sx={{
          p: 2,
          display: "flex",
          borderStyle: "dashed",
          borderWidth: "2px",
          borderRadius: 1,
          borderColor: (theme) =>
            isDraggedOver ? theme.palette.primary.main : theme.palette.divider,
          backgroundColor: (theme) =>
            isDraggedOver
              ? alpha(theme.palette.primary.main, 0.2)
              : theme.palette.background.default,
          justifyContent: "center",
          flexDirection: "column",
          alignItems: "center",
          m: 2,
          transitionProperty: "border-color, background-color",
          transitionTimingFunction: "cubic-bezier(0.15, 1.0, 0.3, 1.0)",
          transitionDuration: "350ms",
        }}
      >
        <AddRoundedIcon
          sx={{
            color: (theme) =>
              isDraggedOver
                ? theme.palette.primary.main
                : theme.palette.text.secondary,
            transitionProperty: "color",
            transitionTimingFunction: "cubic-bezier(0.15, 1.0, 0.3, 1.0)",
            transitionDuration: "350ms",
          }}
        />
        <Typography
          sx={{
            color: (theme) =>
              isDraggedOver
                ? theme.palette.primary.main
                : theme.palette.text.secondary,
            transitionProperty: "color",
            transitionTimingFunction: "cubic-bezier(0.15, 1.0, 0.3, 1.0)",
            transitionDuration: "350ms",
          }}
        >
          Drop exercises here
        </Typography>
      </Box>
    </div>
  );
}
