import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  FormControlLabel,
  FormGroup,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import { DatePicker } from "@mui/x-date-pickers";
import {
  useCreateVacation,
  useUpdateVacation,
  type Vacation,
} from "@trainwell/features";
import {
  differenceInHours,
  endOfDay,
  isBefore,
  max,
  startOfDay,
  subDays,
} from "date-fns";
import { fromZonedTime, toZonedTime } from "date-fns-tz";
import { useEffect, useState } from "react";
import { DialogTitleWithClose } from "src/components/misc/DialogTitleWithClose";

const defaultStartDate = new Date(new Date().setHours(9, 0, 0, 0));
const defaultEndDate = new Date(new Date().setHours(17, 0, 0, 0));
const defaultType = "pto";

const interimCoachMessage =
  "During my leave you will get an interim trainer who does everything I do except video calls. You do not need to take action - your interim trainer will appear at the start of my leave and disappear at the end.";

type Props = {
  open: boolean;
  saveText?: string;
  titleText?: string;
  defaultVacation?: Vacation;
  trainerTimezone: string;
  trainerId: string;
  onClose: () => void;
};

export default function VacationEditDialog({
  open,
  saveText,
  titleText,
  defaultVacation,
  trainerTimezone,
  trainerId,
  onClose,
}: Props) {
  const [vacationStartDate, setVacationStartDate] =
    useState<Date>(defaultStartDate);
  const [vacationEndDate, setVacationEndDate] = useState<Date>(defaultEndDate);
  const [vacationType, setVacationType] = useState<Vacation["type"]>(
    defaultVacation?.type ?? defaultType,
  );
  const [clientMessage, setClientMessage] = useState<string>(
    defaultVacation?.message_to_clients ?? "",
  );
  const [dateToSendMessage, setDateToSendMessage] = useState<Date | null>(
    defaultVacation?.date_to_send_message_to_clients
      ? new Date(defaultVacation.date_to_send_message_to_clients)
      : null,
  );
  const [requiresInterimCoaches, setRequiresInterimCoaches] = useState<boolean>(
    defaultVacation?.requires_interim_trainers ?? false,
  );
  const [addedToCalendar, setAddedToCalendar] = useState<boolean>(
    defaultVacation ? true : false,
  );
  const updateVacation = useUpdateVacation();
  const createVacation = useCreateVacation();

  useEffect(() => {
    if (!open) {
      setVacationStartDate(defaultStartDate);
      setVacationEndDate(defaultEndDate);
      setVacationType(defaultType);
      setClientMessage("");
      setDateToSendMessage(null);
      setRequiresInterimCoaches(false);
      setAddedToCalendar(false);
    } else if (defaultVacation) {
      setVacationStartDate(
        toZonedTime(defaultVacation.date_start, trainerTimezone),
      );
      setVacationEndDate(
        toZonedTime(defaultVacation.date_end, trainerTimezone),
      );
      setVacationType(defaultVacation.type);
      setClientMessage(defaultVacation.message_to_clients ?? "");
      setDateToSendMessage(
        defaultVacation.date_to_send_message_to_clients
          ? new Date(defaultVacation.date_to_send_message_to_clients)
          : null,
      );
      setRequiresInterimCoaches(
        defaultVacation.requires_interim_trainers ?? false,
      );
      setAddedToCalendar(true);
    }
  }, [open]);

  function resetDateAndClose() {
    onClose();

    if (!defaultVacation) {
      setVacationStartDate(defaultStartDate);
      setVacationEndDate(defaultEndDate);
      setVacationType(defaultType);
      setClientMessage("");
      setDateToSendMessage(null);
      setRequiresInterimCoaches(false);
    }
  }

  const showMessageAndDate = vacationType === "pto" || vacationType === "sick";

  const requiresMessageAndDate =
    vacationType === "sick" ||
    (vacationType === "pto" &&
      differenceInHours(vacationEndDate, vacationStartDate) > 48);

  const isValid =
    isBefore(vacationStartDate, vacationEndDate) &&
    !(requiresMessageAndDate && !clientMessage) &&
    !(requiresMessageAndDate && !dateToSendMessage) &&
    (defaultVacation || addedToCalendar);

  return (
    <Dialog
      onClose={resetDateAndClose}
      aria-labelledby="customized-dialog-title"
      open={open}
    >
      <DialogTitleWithClose onClose={resetDateAndClose}>
        {titleText ?? "Add vacation time"}
      </DialogTitleWithClose>
      <DialogContent>
        <Typography sx={{ mb: 1 }}>
          Trainers are not shown to potential clients ahead of their vacation.
        </Typography>
        <Typography sx={{ mb: 2 }}>
          Times are in the trainer&apos;s timezone (ex. 9am is always 9am in
          their local time).
        </Typography>
        <Grid container spacing={2}>
          <Grid size={12}>
            <FormControl fullWidth>
              <InputLabel>Vacation type</InputLabel>
              <Select
                value={vacationType}
                label="Vacation type"
                onChange={(event) => {
                  setVacationType(event.target.value as Vacation["type"]);
                }}
                required
              >
                <MenuItem value={"pto"}>Paid time off</MenuItem>
                <MenuItem value={"sick"}>Sick time</MenuItem>
                <MenuItem value={"wto"}>Work time off</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid size={6}>
            <DatePicker
              label="Start date"
              value={vacationStartDate}
              onChange={(newValue) => {
                setVacationStartDate(newValue ?? new Date());
              }}
              minDate={new Date()}
              slotProps={{
                textField: {
                  fullWidth: true,
                  required: true,
                },
              }}
            />
          </Grid>
          <Grid size={6}>
            <DatePicker
              label="End date"
              value={vacationEndDate}
              onChange={(newValue) => {
                setVacationEndDate(newValue ?? new Date());
              }}
              minDate={vacationStartDate}
              slotProps={{
                textField: {
                  fullWidth: true,
                  required: true,
                },
              }}
            />
          </Grid>
          {showMessageAndDate && (
            <>
              <Grid size={12}>
                <TextField
                  label="Message to send to clients"
                  helperText={
                    requiresMessageAndDate && !clientMessage
                      ? "Message required"
                      : requiresMessageAndDate &&
                          dateToSendMessage !== null &&
                          isBefore(dateToSendMessage, new Date())
                        ? "Message will be sent immediatly to this trainer's clients"
                        : "This message will automatically be sent to each of this trainer's clients from the trainer on the morning of the day before their vacation starts"
                  }
                  fullWidth
                  multiline
                  minRows={2}
                  value={clientMessage}
                  onChange={(event) => {
                    setClientMessage(event.target.value);
                  }}
                  error={requiresMessageAndDate && !clientMessage}
                  required={requiresMessageAndDate}
                />
                {requiresInterimCoaches && !defaultVacation && (
                  <>
                    <Typography variant="overline">Full message</Typography>
                    <Typography
                      sx={{
                        backgroundColor: (theme) => theme.palette.primary.main,
                        color: (theme) => theme.palette.primary.contrastText,
                        borderRadius: `10px 10px 10px 2px`,
                        px: 1,
                        py: 0.5,
                        whiteSpace: "pre-line",
                        wordWrap: "break-word",
                      }}
                    >
                      {clientMessage + "\n\n" + interimCoachMessage}
                    </Typography>
                  </>
                )}
              </Grid>
              <Grid size={12}>
                <DatePicker
                  label="Day to send message"
                  value={dateToSendMessage}
                  onChange={(newValue) => {
                    setDateToSendMessage(newValue ?? new Date());
                  }}
                  minDate={max([subDays(vacationStartDate, 7), new Date()])}
                  maxDate={vacationStartDate}
                  slotProps={{
                    textField: {
                      required: requiresMessageAndDate,
                      error:
                        requiresMessageAndDate && dateToSendMessage === null,
                      helperText:
                        dateToSendMessage &&
                        isBefore(dateToSendMessage, new Date())
                          ? "Message will be sent IMMEDIATELY"
                          : undefined,
                      fullWidth: true,
                    },
                  }}
                />
              </Grid>
              <Grid size={12}>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={requiresInterimCoaches}
                        onChange={(event) => {
                          setRequiresInterimCoaches(event.target.checked);
                        }}
                        disabled={defaultVacation !== undefined}
                      />
                    }
                    label="Requires interim trainers"
                  />
                </FormGroup>
              </Grid>
            </>
          )}
          {!defaultVacation && (
            <Grid size={12}>
              <Tooltip title="Vacations of all types must be first entered into google calendar before they can be saved through the trainer dashboard. This can be done by the trainer OR the manager - if done by the manager, make sure it is on the TRAINER'S calendar, not yours">
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={addedToCalendar}
                        onChange={(event) => {
                          setAddedToCalendar(event.target.checked);
                        }}
                      />
                    }
                    label="Manually added to Google Calendar"
                  />
                </FormGroup>
              </Tooltip>
            </Grid>
          )}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button variant="text" onClick={resetDateAndClose}>
          Cancel
        </Button>
        <Button
          variant="contained"
          disabled={!isValid}
          onClick={() => {
            const startTime = fromZonedTime(
              startOfDay(vacationStartDate),
              trainerTimezone,
            );
            const endTime = fromZonedTime(
              endOfDay(vacationEndDate),
              trainerTimezone,
            );

            const convertedDateToSend = dateToSendMessage
              ? fromZonedTime(
                  dateToSendMessage.setHours(7, 0, 0, 0),
                  trainerTimezone,
                )
              : undefined;

            if (
              requiresMessageAndDate &&
              (!dateToSendMessage || !clientMessage)
            ) {
              return;
            }

            const message = showMessageAndDate
              ? clientMessage +
                (requiresInterimCoaches && !defaultVacation
                  ? `\n\n${interimCoachMessage}`
                  : "")
              : undefined;

            if (defaultVacation) {
              updateVacation.mutate({
                vacationId: defaultVacation.id,
                data: {
                  date_end: endTime,
                  date_start: startTime,
                  type: vacationType,
                  message_to_clients: message,
                  date_to_send_message_to_clients: showMessageAndDate
                    ? convertedDateToSend
                    : undefined,
                },
              });
            } else {
              createVacation.mutate({
                data: {
                  date_end: endTime,
                  date_start: startTime,
                  type: vacationType,
                  message_to_clients: message,
                  date_to_send_message_to_clients: showMessageAndDate
                    ? convertedDateToSend
                    : undefined,
                  requires_interim_trainers: requiresInterimCoaches,
                  trainer_id: trainerId,
                },
              });
            }

            onClose();
          }}
        >
          {saveText ??
            `Add vacation time${
              dateToSendMessage && isBefore(dateToSendMessage, new Date())
                ? " and send message"
                : ""
            }`}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
