import {
  Box,
  Button,
  Container,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { isBlank } from "@trainwell/features";
import { subYears } from "date-fns";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { useNavigate } from "react-router";
import { useAppDispatch, useAppSelector } from "src/hooks/stateHooks";
import { api } from "src/lib/trainwellApi";
import {
  selectPrimaryTrainer,
  updateTrainerLocal,
} from "src/slices/trainerSlice";

export default function SetupAccountPage() {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const trainer = useAppSelector(selectPrimaryTrainer);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [sex, setSex] = useState("");
  const [devicePlatform, setDevicePlatform] = useState("");
  const [height, setHeight] = useState("");
  const [weight, setWeight] = useState("");
  const [birthday, setBirthday] = useState<Date>();

  const regEx = /^\+[1-9]\d{10,14}$/;

  function submit() {
    api.trainers
      .setupAccount({
        trainerId: trainer!.trainer_id,
        phoneNumber: phoneNumber,
        height: Number(height),
        weight: Number(weight),
        sex: sex,
        birthday: birthday!.toString(),
        devicePlatform: devicePlatform as any,
      })
      .then((resp) => {
        dispatch(updateTrainerLocal(resp));

        navigate("/");
      })
      .catch((err) => {
        enqueueSnackbar("Error setting up trainer account", {
          variant: "error",
        });
      });
  }

  const isValid =
    !isBlank(phoneNumber) &&
    !isBlank(sex) &&
    !isBlank(devicePlatform) &&
    !isBlank(height) &&
    !isBlank(weight) &&
    birthday &&
    regEx.test(phoneNumber);

  return (
    <Container sx={{ p: 3 }} maxWidth="md">
      <Box sx={{ display: "flex", justifyContent: "center", mb: 4 }}>
        <img
          src="/assets/trainwell-wordmark.svg"
          alt="Logo"
          height={60}
          width={200}
        />
      </Box>
      <Typography variant="h1" sx={{ mb: 2 }}>
        Hello {trainer?.first_name}! Finish setting up your account
      </Typography>
      <Typography sx={{ mb: 4 }}>
        We use this information to get you setup in the trainwell system, both
        as a client and a trainer
      </Typography>
      <Grid container spacing={2} sx={{ mb: 2 }}>
        <Grid item xs={3}>
          <TextField
            fullWidth
            label="Phone number"
            placeholder="+15551234567"
            name="phoneNumber"
            type="tel"
            value={phoneNumber}
            onChange={(event) => {
              setPhoneNumber(event.target.value);
            }}
            error={!regEx.test(phoneNumber)}
            helperText={!regEx.test(phoneNumber) && "Invalid format"}
          />
        </Grid>
        <Grid item xs={3}>
          <FormControl fullWidth>
            <InputLabel>Sex</InputLabel>
            <Select
              label="Sex"
              name="sex"
              value={sex}
              onChange={(event) => {
                setSex(event.target.value);
              }}
            >
              <MenuItem value={"Male"}>Male</MenuItem>
              <MenuItem value={"Female"}>Female</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={3}>
          <TextField
            fullWidth
            label="Height"
            name="height"
            type="number"
            value={height}
            onChange={(event) => {
              setHeight(event.target.value);
            }}
            helperText={"Inches"}
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            fullWidth
            label="Weight"
            name="weight"
            type="number"
            value={weight}
            onChange={(event) => {
              setWeight(event.target.value);
            }}
            helperText={"Pounds"}
          />
        </Grid>
        <Grid item xs={3}>
          <DatePicker
            label="Birthday"
            value={birthday}
            onChange={(newValue) => {
              if (!newValue) {
                return;
              }
              setBirthday(newValue);
            }}
            maxDate={subYears(new Date(), 18)}
          />
        </Grid>
        <Grid item xs={3}>
          <FormControl fullWidth>
            <InputLabel>Phone</InputLabel>
            <Select
              label="Phone"
              name="devicePlatform"
              value={devicePlatform}
              onChange={(event) => {
                setDevicePlatform(event.target.value);
              }}
            >
              <MenuItem value={"ios"}>iOS</MenuItem>
              <MenuItem value={"android"}>Android</MenuItem>
            </Select>
          </FormControl>
        </Grid>
      </Grid>
      <Button disabled={!isValid} onClick={submit}>
        Finish
      </Button>
      <Box sx={{ display: "flex", justifyContent: "center", my: 4 }}>
        <img
          src={"/assets/copilot-welcome.svg"}
          width={300}
          height={300}
          alt="happy habit"
        />
      </Box>
    </Container>
  );
}
