import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "src/hooks/stateHooks";
import { selectAllActionItems } from "src/slices/actionItemSlice";
import { syncActionItemsToChat } from "src/slices/chatSlice";

export default function ActionItemHelper() {
  const dispatch = useAppDispatch();
  const actionItems = useAppSelector(selectAllActionItems);

  useEffect(() => {
    dispatch(syncActionItemsToChat(actionItems));
  }, [actionItems, dispatch]);

  return null;
}
