import type { Client } from "@trainwell/types";
import { isBefore, startOfToday } from "date-fns";

export function getAverage(numbers: number[]) {
  const total = numbers.reduce((acc, c) => acc + c, 0);
  return total / numbers.length;
}

export function toSentenceCase(string: string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function round(metric: number, precision?: number) {
  if (precision === undefined) {
    return Math.round(metric);
  }
  return Math.round(metric * 10 ** precision) / 10 ** precision;
}

export function range(min: number, max: number) {
  const len = max - min + 1;
  const arr = new Array(len);
  for (let i = 0; i < len; i++) {
    arr[i] = min + i;
  }
  return arr;
}

export function closeToNumber(
  value: number,
  target: number,
  plusMinus: number,
) {
  const lowerBound = target - plusMinus;
  const upperBound = target + plusMinus;

  return value >= lowerBound && value <= upperBound;
}

export function getFormattedTime(seconds: number) {
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds - minutes * 60;

  let formattedTime = "";

  if (minutes && remainingSeconds) {
    formattedTime = `${minutes}m ${remainingSeconds}s`;
  } else if (minutes) {
    formattedTime = `${minutes}m`;
  } else if (remainingSeconds) {
    formattedTime = `${remainingSeconds}s`;
  }

  return formattedTime;
}

export function getFormattedTimeFromMinutes(minutes: number) {
  const hours = Math.floor(minutes / 60);
  const remainingMinutes = minutes - hours * 60;

  const performedString = hours
    ? `${hours}h ${remainingMinutes}m`
    : `${remainingMinutes}m`;

  return performedString;
}

export function formatCentsToDollars(cents: number) {
  const dollarNumber = cents / 100;
  const dollars = dollarNumber.toLocaleString("en-US", {
    style: "currency",
    currency: "USD",
  });

  return dollars;
}

export function notCheckedInToday(client: Client) {
  const thisMorning = startOfToday();

  return (
    !client.date_last_trainer_check_in ||
    isBefore(client.date_last_trainer_check_in, thisMorning)
  );
}
