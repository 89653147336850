import QuestionAnswerRoundedIcon from "@mui/icons-material/QuestionAnswerRounded";
import RemoveCircleRoundedIcon from "@mui/icons-material/RemoveCircleRounded";
import { Box, Button, Chip, Stack, Tooltip, Typography } from "@mui/material";
import { useCreateAudit } from "@trainwell/features";
import type { MessageCancelRequested } from "@trainwell/types";
import { format } from "date-fns";
import { useAppDispatch, useAppSelector } from "src/hooks/stateHooks";
import { useIsInRolexTest } from "src/hooks/useIsInRolexTest";
import { updateMessage } from "src/slices/chatSlice";
import { setAuditPanelOpen } from "src/slices/clientSlice";

type Props = {
  message: MessageCancelRequested;
  combineBottom: boolean;
};

export function CancelRequestedMessage({ message, combineBottom }: Props) {
  const dispatch = useAppDispatch();
  const realTrainer = useAppSelector((state) => state.trainer.trainer);
  const isInRolexTest = useIsInRolexTest();
  const createAudit = useCreateAudit();

  return (
    <Box
      sx={{
        maxWidth: "300px",
        borderRadius: `12px 12px 12px ${!combineBottom ? "0px" : "12px"}`,
        overflow: "hidden",
        backgroundColor: (theme) => theme.palette.backgroundSecondary.main,
        p: 1,
        width: "fit-content",
      }}
    >
      <Stack
        direction="column"
        spacing={1}
        useFlexGap
        alignItems={"flex-start"}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <RemoveCircleRoundedIcon
            sx={{
              mr: 1,
            }}
          />
          <Box>
            <Typography
              variant="h6"
              sx={{
                color: (theme) => theme.palette.text.primary,
              }}
            >
              Cancel requested
            </Typography>
            {message.content.cancel_at && (
              <Typography variant="overline">
                Scheduled for {format(message.content.cancel_at, "MMM d, yyyy")}
              </Typography>
            )}
          </Box>
        </Box>
        {message.content.cancel_options && (
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              gap: 1,
            }}
          >
            {message.content.cancel_options.map((option, index) => {
              const isQuestionedReason =
                message.content.cancel_option_responded_to ===
                option.toLocaleLowerCase();
              return (
                <Tooltip
                  key={index}
                  disableInteractive
                  placement="right"
                  title={
                    isQuestionedReason
                      ? "The client was asked to elaborate on this option"
                      : ""
                  }
                >
                  <Chip
                    size="small"
                    label={
                      option === "Other"
                        ? `Other: ${message.content.cancel_option_custom}`
                        : option
                    }
                    icon={
                      isQuestionedReason ? (
                        <QuestionAnswerRoundedIcon />
                      ) : undefined
                    }
                  />
                </Tooltip>
              );
            })}
          </Box>
        )}
        {message.content.reason_client && (
          <Typography
            sx={{
              color: (theme) => theme.palette.text.primary,
            }}
          >
            &quot;{message.content.reason_client}&quot;
          </Typography>
        )}
        {isInRolexTest && !message.content?.audit_id && (
          <Button
            size="small"
            onClick={() => {
              createAudit.mutate(
                {
                  data: {
                    subject_user_id: message.from_id,
                    auditor_trainer_id: realTrainer!.trainer_id,
                    type: "client_cancellation",
                    text: `In what ways did you make this client feel uniquely cared for?

Anything you could have done to make this client feel more cared for?

What's your biggest opportunity for growth that you learned from this cancelation?`,
                  },
                },
                {
                  onSuccess: (audit) => {
                    dispatch(
                      updateMessage({
                        messageId: message.message_id,
                        auditId: audit.id,
                      }),
                    );
                  },
                },
              );
              dispatch(setAuditPanelOpen(true));

              setTimeout(() => {
                document
                  .getElementById("audit-panel")
                  ?.scrollIntoView({ behavior: "smooth", block: "center" });
              }, 50);
            }}
          >
            Start cancellation audit
          </Button>
        )}
      </Stack>
    </Box>
  );
}
