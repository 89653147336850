import type { FormControlLabelProps } from "@mui/material";
import { Box, FormControlLabel, FormHelperText, Switch } from "@mui/material";

type Props = {
  label: string;
  subLabel?: string;
  value: boolean;
  onSave: (newValue: boolean) => void;
} & Partial<FormControlLabelProps>;

export default function SmartEditSwitch({
  label,
  subLabel,
  value,
  onSave,
  ...formControlLabelProps
}: Props) {
  return (
    <Box>
      <FormControlLabel
        {...formControlLabelProps}
        control={
          <Switch
            checked={value}
            onChange={(event) => {
              onSave(event.target.checked);
            }}
            type="checkbox"
          />
        }
        label={label}
      />
      {subLabel && <FormHelperText sx={{ mt: 0 }}>{subLabel}</FormHelperText>}
    </Box>
  );
}
