import { Box, Card, Divider, Stack, Typography } from "@mui/material";
import type { LogCycle, LogSection } from "@trainwell/types";
import LogExerciseCell from "./LogExerciseCell";

type Props = {
  logId: string;
  section: LogSection;
  cycle: LogCycle;
  cycleIndex: number;
  enablePreview: boolean;
};

export default function LogCycleCell({
  logId,
  section,
  cycle,
  cycleIndex,
  enablePreview,
}: Props) {
  return (
    <Card
      variant="outlined"
      sx={{
        mb: 2,
        p: 2,
        borderWidth: 2,
      }}
    >
      <Box sx={{ display: "flex", alignItems: "baseline", mb: 1 }}>
        <Typography
          variant="h2"
          sx={{
            fontStyle: section.section_name ? "normal" : "italic",
            color: (theme) =>
              section.section_name
                ? theme.palette.text.primary
                : theme.palette.text.secondary,
          }}
        >
          {!section.section_name ? "no name" : section.section_name}
        </Typography>
        {section.cycles.length > 1 && (
          <Typography
            variant="body2"
            sx={{
              color: (theme) => theme.palette.text.secondary,
              ml: 1,
            }}
          >
            {"Cycle " + (cycleIndex + 1)}
          </Typography>
        )}
      </Box>
      <Stack direction={"column"} divider={<Divider />} spacing={2}>
        {cycle.exercises.map((exercise, exerciseIndex) => (
          <LogExerciseCell
            logId={logId}
            key={exercise.exercise_id}
            exercise={exercise}
            firstExercise={section.cycles[0].exercises[exerciseIndex]}
            enablePreview={enablePreview}
          />
        ))}
      </Stack>
    </Card>
  );
}
