import { Box, Typography } from "@mui/material";
import { useCompany } from "@trainwell/features";
import { useAppSelector } from "src/hooks/stateHooks";
import { selectPrimaryTrainer } from "src/slices/trainerSlice";

export function CheckForDashLock(props: any) {
  const isAdmin = useAppSelector(
    (state) => selectPrimaryTrainer(state)?.is_admin,
  );
  const { data: company } = useCompany();
  const disableDash = company?.disable_dash === true;

  if (disableDash && !isAdmin) {
    return (
      <Box
        sx={{
          width: "100%",
          height: "100vh",
          maxHeight: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
          backgroundColor: (theme) => theme.palette.background.default,
        }}
      >
        <Box sx={{ mb: 4 }}>
          <img
            src="/assets/trainwell-wordmark.svg"
            alt="Logo"
            height={60}
            width={200}
          />
        </Box>
        <Typography variant="h1" sx={{ mb: 1 }}>
          Paused
        </Typography>
        <Typography>
          Everyone&apos;s dash is temporarily paused. Check back soon :)
        </Typography>
      </Box>
    );
  }

  return props.children;
}
