import RestaurantRoundedIcon from "@mui/icons-material/RestaurantRounded";
import {
  Avatar,
  Box,
  Stack,
  Tooltip,
  Typography,
  useTheme,
  type SxProps,
  type Theme,
} from "@mui/material";
import { LineChart } from "@mui/x-charts";
import {
  getSentimentEmoji,
  useNutritionDays,
  useNutritionTimeSeries,
} from "@trainwell/features";
import type { ConsistencyTimeSeries } from "@trainwell/types";
import { endOfDay, isToday, startOfDay, startOfWeek, subDays } from "date-fns";
import { fromZonedTime, toZonedTime } from "date-fns-tz";
import LoadingComponent from "src/components/miscPages/LoadingComponent";
import { useAppSelector } from "src/hooks/stateHooks";
import { UpcomingRestDayIcon } from "../streaks/UpcomingRestDayIcon";

type Props = {
  sx?: SxProps<Theme>;
};

function ConsistencyIcon({ day }: { day?: ConsistencyTimeSeries[number] }) {
  switch (day?.state) {
    case "logged_meal":
      return (
        <Tooltip
          enterDelay={1000}
          arrow={false}
          title={
            <>
              <Typography variant="h2" sx={{ mb: 2 }}>
                {day.meal_logs.length} meals logged
              </Typography>
              <Stack
                spacing={2}
                sx={{
                  maxHeight: 500,
                  overflowY: "auto",
                }}
              >
                {day.meal_logs.map((mealLog) => (
                  <Box key={mealLog._id}>
                    <img
                      src={mealLog.image_url}
                      alt={"Nutrition"}
                      style={{
                        width: "100%",
                        height: "auto",
                        borderRadius: 1,
                      }}
                    />
                    {mealLog.sentiment_rating !== undefined && (
                      <Typography>
                        Client sentiment:{" "}
                        {Math.round(mealLog.sentiment_rating + 1)} / 5{" "}
                        {getSentimentEmoji(mealLog.sentiment_rating)}
                      </Typography>
                    )}
                  </Box>
                ))}
              </Stack>
            </>
          }
          slotProps={{
            tooltip: {
              sx: {
                backgroundColor: (theme) => theme.palette.background.paper,
                border: 1,
                borderColor: "divider",
                borderRadius: 1,
                color: (theme) => theme.palette.text.primary,
                boxShadow: 5,
                p: 1,
              },
            },
          }}
        >
          <Avatar
            sx={{
              width: 18,
              height: 18,
              backgroundColor: (theme) => theme.palette.success.main,
            }}
          >
            <RestaurantRoundedIcon sx={{ fontSize: 18 * 0.6 }} />
          </Avatar>
        </Tooltip>
      );
    case "did_not_log_meal":
      return (
        <Avatar
          sx={{
            width: 18,
            height: 18,
            backgroundColor: (theme) => theme.palette.error.main,
          }}
        >
          <RestaurantRoundedIcon sx={{ fontSize: 18 * 0.6 }} />
        </Avatar>
      );
    default:
      return <UpcomingRestDayIcon />;
  }
}

export function Nutrition({ sx = [] }: Props) {
  const theme = useTheme();
  const client = useAppSelector((state) => state.client.client);
  const nutritionDaysQuery = useNutritionDays({
    userId: client?.user_id ?? "",
    dateStart: fromZonedTime(
      startOfDay(subDays(new Date(), 21)),
      "Etc/UTC",
    ).toISOString(),
    dateEnd: fromZonedTime(endOfDay(new Date()), "Etc/UTC").toISOString(),
  });

  const nutritionTimeSeriesQuery = useNutritionTimeSeries({
    userId: client?.user_id ?? "",
    dateStart: fromZonedTime(
      startOfDay(subDays(new Date(), 21)),
      "Etc/UTC",
    ).toISOString(),
    dateEnd: fromZonedTime(endOfDay(new Date()), "Etc/UTC").toISOString(),
  });

  if (!client) {
    return <LoadingComponent message={"Loading your client 😄"} />;
  }

  if (nutritionDaysQuery.isPending || nutritionTimeSeriesQuery.isPending) {
    return <LoadingComponent message={"Loading nutrition info"} />;
  }

  if (nutritionDaysQuery.isError || nutritionTimeSeriesQuery.isError) {
    return <Typography>Error loading nutrition</Typography>;
  }

  const weeksMap =
    nutritionDaysQuery.data.time_series.reduce(
      (acc, nutritionDay) => {
        const week = startOfWeek(
          toZonedTime(nutritionDay.date, "Etc/UTC"),
        ).toISOString();
        if (!acc[week]) {
          acc[week] = [];
        }

        acc[week].push(nutritionDay);

        return acc;
      },
      {} as Record<string, ConsistencyTimeSeries>,
    ) ?? {};

  const weeks = Object.keys(weeksMap ?? {});

  const chartData = nutritionTimeSeriesQuery.data.time_series.map((point) => ({
    ...point,
    date: toZonedTime(point.date, "Etc/UTC"),
  }));

  return (
    <Box sx={sx}>
      <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
        <RestaurantRoundedIcon fontSize="inherit" />
        <Box sx={{ ml: 1 }}>
          <Typography sx={{ fontWeight: "bold" }}>Nutrition</Typography>
        </Box>
      </Box>
      {!client.last_login_date ? (
        <Typography>Has not opened the app</Typography>
      ) : (
        <>
          <Stack direction={"column"} spacing={0.5} sx={{ mb: 1 }}>
            {weeks.map((week) => {
              const nutritionDays = weeksMap[week];

              return (
                <Stack
                  key={week}
                  direction={"row"}
                  spacing={0.5}
                  alignItems={"flex-end"}
                >
                  {[0, 1, 2, 3, 4, 5, 6].map((day) => {
                    const nutritionDay = nutritionDays?.find(
                      (habitDay) =>
                        toZonedTime(
                          new Date(habitDay.date),
                          "Etc/UTC",
                        ).getDay() === day,
                    );

                    const isThisToday = nutritionDay
                      ? isToday(
                          toZonedTime(new Date(nutritionDay.date), "Etc/UTC"),
                        )
                      : false;

                    return (
                      <Box
                        key={day}
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                        }}
                      >
                        {isThisToday && <TodayDot />}
                        {<ConsistencyIcon key={day} day={nutritionDay} />}
                      </Box>
                    );
                  })}
                </Stack>
              );
            })}
          </Stack>
          <LineChart
            colors={[theme.palette.primary.main, theme.palette.secondary.main]}
            xAxis={[
              {
                dataKey: "date",
                scaleType: "time",
                // valueFormatter: (value) =>
                //   format(value as string, "MMM d, yyy"),
                min: chartData.at(0)?.date,
                max: chartData.at(-1)?.date,
              },
            ]}
            yAxis={[
              {
                min: 0,
                max: 1.1,
              },
            ]}
            leftAxis={null}
            bottomAxis={null}
            grid={{
              horizontal: true,
            }}
            series={[
              {
                dataKey: "eatwell",
                label: "eatwell",
                showMark: false,
                id: "eatwell",
                valueFormatter: (value) =>
                  value ? `${Math.round(value * 100)}%` : null,
              },
              {
                dataKey: "sentiment",
                label: "Sentiment",
                showMark: false,
                id: "sentiment",
                valueFormatter: (value) =>
                  value ? `${Math.round(value * 100)}%` : null,
              },
            ]}
            dataset={chartData}
            height={70}
            margin={{ top: 10, right: 0, left: 0, bottom: 0 }}
            slotProps={{
              legend: {
                hidden: true,
              },
            }}
            skipAnimation
          />
        </>
      )}
    </Box>
  );
}

function TodayDot() {
  return (
    <Box
      sx={{
        width: 8,
        height: 8,
        borderRadius: 4,
        backgroundColor: (theme) => theme.palette.primary.main,
        mb: 0.25,
      }}
    />
  );
}
