import {
  Box,
  Tooltip,
  Typography,
  type SxProps,
  type Theme,
} from "@mui/material";
import { differenceInCalendarDays } from "date-fns";
import LoadingPage from "src/components/miscPages/LoadingPage";
import { useAppSelector } from "src/hooks/stateHooks";

type Props = {
  sx?: SxProps<Theme>;
};

export function ClientCommunicationV2({ sx = [] }: Props) {
  const client = useAppSelector((state) => state.client.client);

  if (!client) {
    return <LoadingPage message={"Loading your client 😄"} />;
  }

  const now = new Date();

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        ...sx,
      }}
    >
      <Tooltip title="Days since their last message" disableInteractive>
        <Box>
          <Typography sx={{ fontWeight: "bold", fontSize: 24, lineHeight: 1 }}>
            {client.last_client_message_date
              ? differenceInCalendarDays(now, client.last_client_message_date)
              : "--"}
            <Typography
              component={"span"}
              variant="overline"
              sx={{ display: "inline" }}
            >
              d
            </Typography>
          </Typography>
          <Typography variant="overline">From client</Typography>
        </Box>
      </Tooltip>
      <Tooltip title="Days since your last message" disableInteractive>
        <Box>
          <Typography
            sx={{
              fontWeight: "bold",
              fontSize: 24,
              lineHeight: 1,
              textAlign: "right",
            }}
          >
            {client.last_trainer_message_date
              ? differenceInCalendarDays(now, client.last_trainer_message_date)
              : "--"}
            <Typography
              component={"span"}
              variant="overline"
              sx={{ display: "inline" }}
            >
              d
            </Typography>
          </Typography>
          <Typography
            variant="overline"
            sx={{
              textAlign: "right",
            }}
          >
            From you
          </Typography>
        </Box>
      </Tooltip>
    </Box>
  );
}
