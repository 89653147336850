export const NODE_ENV = import.meta.env.NODE_ENV;
export const PORT = import.meta.env.PORT;

// trainwell api key
export const TRAINWELL_API_KEY = import.meta.env.VITE_TRAINWELL_API_KEY;

// trainwell api domains
export const TRAINWELL_API_DOMAIN = import.meta.env.VITE_TRAINWELL_API_DOMAIN;
export const TRAINWELL_CHAT_API_DOMAIN = import.meta.env
  .VITE_TRAINWELL_CHAT_API_DOMAIN;
export const TRAINWELL_PYTHON_API_DOMAIN = import.meta.env
  .VITE_TRAINWELL_PYTHON_API_DOMAIN;

// Firebase
export const FIREBASE_API_KEY = import.meta.env.VITE_FIREBASE_API_KEY;
export const FIREBASE_AUTH_DOMAIN = import.meta.env.VITE_FIREBASE_AUTH_DOMAIN;
export const FIREBASE_PROJECT_ID = import.meta.env.VITE_FIREBASE_PROJECT_ID;
export const FIREBASE_STORAGE_BUCKET = import.meta.env
  .VITE_FIREBASE_STORAGE_BUCKET;
export const FIREBASE_MESSAGING_SENDER_ID = import.meta.env
  .VITE_FIREBASE_MESSAGING_SENDER_ID;
export const FIREBASE_APP_ID = import.meta.env.VITE_FIREBASE_APP_ID;
export const FIREBASE_PUSH_KEY = import.meta.env.VITE_FIREBASE_PUSH_KEY;

// Transloadit
export const TRANSLOADIT_AUTH_KEY = import.meta.env.VITE_TRANSLOADIT_AUTH_KEY;

export const removePII = import.meta.env.VITE_DEMO_MODE === "true";
