import DirectionsWalkRoundedIcon from "@mui/icons-material/DirectionsWalkRounded";
import { alpha, Avatar, type SxProps, type Theme } from "@mui/material";

type Props = {
  sx?: SxProps<Theme>;
};

export function UpcomingStepsIcon({ sx = [] }: Props) {
  return (
    <Avatar
      sx={{
        width: 18,
        height: 18,
        backgroundColor: (theme) => alpha(theme.palette.text.disabled, 0.2),
        ...sx,
      }}
    >
      <DirectionsWalkRoundedIcon
        sx={{
          fontSize: 14,
          color: (theme) => theme.palette.text.disabled,
        }}
      />
    </Avatar>
  );
}
