import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";

interface SliceState {
  workoutId: string | null;
  workoutTemplateId: string | null;
}

const initialState: SliceState = {
  workoutId: null,
  workoutTemplateId: null,
};

export const workoutBuilderSlice = createSlice({
  name: "workoutBuilder",
  initialState,
  reducers: {
    openWorkoutTemplate: (state, action: PayloadAction<string>) => {
      const workoutTemplateId = action.payload;

      state.workoutTemplateId = workoutTemplateId;
    },
    closeWorkoutBuilder: (state) => {
      state.workoutId = null;
      state.workoutTemplateId = null;
    },
  },
});

// Action creators are generated for each case reducer function
export const { openWorkoutTemplate, closeWorkoutBuilder } =
  workoutBuilderSlice.actions;

export default workoutBuilderSlice.reducer;
