import { onMessage } from "firebase/messaging";
import { useSnackbar } from "notistack";
import { useEffect } from "react";
import { firebaseMessaging } from "src/lib/firebase";
import { useAppSelector } from "./stateHooks";

export default function useNotificationListener() {
  const { enqueueSnackbar } = useSnackbar();
  const isChatFullscreen = useAppSelector(
    (state) => state.chat.isChatFullscreen,
  );

  useEffect(() => {
    if (!isChatFullscreen) {
      return;
    }

    firebaseMessaging.then((messaging) => {
      if (!messaging) {
        return;
      }

      function truncate(str: string, n: number) {
        return str.length > n ? str.slice(0, n - 1) + "&hellip;" : str;
      }

      onMessage(messaging, (payload) => {
        console.log("Push notifications: recieved: ", payload);
        let message = "New message";

        if (payload.notification?.body) {
          message += `: ${truncate(payload.notification.body, 15)}`;
        }
        enqueueSnackbar(message);
      });
    });
  }, [isChatFullscreen]);

  return;
}
