import { useHotkeys } from "react-hotkeys-hook";
import { useAppDispatch, useAppSelector } from "src/hooks/stateHooks";
import {
  toggleEquipmentDialogOpen,
  toggleNotesDialogOpen,
} from "src/slices/clientSlice";
import { ClientEquipmentDialog } from "./ClientEquipmentDialog";
import { ClientNotesDialog } from "./ClientNotesDialog";

export function ClientDialogs() {
  const dispatch = useAppDispatch();
  const notesDialogOpen = useAppSelector(
    (state) => state.client.notesDialogOpen,
  );
  const equipmentDialogOpen = useAppSelector(
    (state) => state.client.equipmentDialogOpen,
  );
  const savingNotes = useAppSelector((state) => state.client.notesSaving);

  useHotkeys(
    "n",
    () => {
      if (savingNotes) {
        return;
      }

      dispatch(toggleNotesDialogOpen());
    },
    [savingNotes],
  );

  useHotkeys("e", () => {
    dispatch(toggleEquipmentDialogOpen());
  });

  return (
    <>
      <ClientNotesDialog
        open={notesDialogOpen}
        onClose={() => {
          dispatch(toggleNotesDialogOpen());
        }}
      />
      <ClientEquipmentDialog
        open={equipmentDialogOpen}
        onClose={() => {
          dispatch(toggleEquipmentDialogOpen());
        }}
      />
    </>
  );
}
