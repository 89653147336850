import IosShareRoundedIcon from "@mui/icons-material/IosShareRounded";
import { LoadingButton } from "@mui/lab";
import { Autocomplete, Popover, TextField, Typography } from "@mui/material";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { useAppSelector } from "src/hooks/stateHooks";
import { selectTrainerNames } from "src/slices/trainersSlice";

type Props = {
  onShare: (targetTrainerId: string) => Promise<any>;
  anchorEl: Element | null;
  onClose: () => void;
};

export function ShareToCoachPopover({ onShare, anchorEl, onClose }: Props) {
  const [loading, setLoading] = useState(false);
  const [selectedTrainerId, setSelectedTrainerId] = useState<
    string | undefined
  >();
  const { enqueueSnackbar } = useSnackbar();
  const trainerNames = useAppSelector(selectTrainerNames);

  const coachNameOptions = trainerNames
    .filter((c) => (c.is_employee || c.is_staff) && !c.was_employee)
    .sort((a, b) => a.full_name.localeCompare(b.full_name));

  function handleClose() {
    onClose();

    setSelectedTrainerId(undefined);
  }

  function handleShare() {
    if (!selectedTrainerId) {
      return;
    }

    setLoading(true);

    onShare(selectedTrainerId)
      .then(() => {
        setLoading(false);

        enqueueSnackbar("Shared", {
          variant: "success",
        });

        handleClose();
      })
      .catch(() => {
        setLoading(false);

        enqueueSnackbar("Error sharing", {
          variant: "error",
        });
      });
  }

  return (
    <Popover
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      slotProps={{
        paper: { sx: { p: 2, minWidth: "300px" } },
      }}
      onClose={handleClose}
    >
      <Typography variant="h2" sx={{ mb: 2 }}>
        Share to trainer
      </Typography>
      <Autocomplete
        fullWidth
        options={coachNameOptions}
        onChange={(event, value) => {
          setSelectedTrainerId(value?.trainer_id);
        }}
        size="small"
        getOptionLabel={(option) => option.full_name}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Trainer"
            placeholder="Select a trainer"
          />
        )}
        sx={{ mb: 2 }}
      />
      <LoadingButton
        variant="contained"
        disabled={!selectedTrainerId}
        startIcon={<IosShareRoundedIcon />}
        onClick={handleShare}
        loading={loading}
      >
        Share
      </LoadingButton>
    </Popover>
  );
}
