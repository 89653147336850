import ClearRoundedIcon from "@mui/icons-material/ClearRounded";
import FilterListRoundedIcon from "@mui/icons-material/FilterListRounded";
import {
  Box,
  Button,
  Chip,
  FormControlLabel,
  Popover,
  Stack,
  Switch,
  Tooltip,
  Typography,
  alpha,
} from "@mui/material";
import {
  EquipmentType as EquipmentType2,
  type ExerciseGroup,
} from "@trainwell/types";
import { useEffect, useMemo, useState } from "react";
import { shallowEqual } from "react-redux";
import { useAppSelector } from "src/hooks/stateHooks";
import { exerciseGroups } from "src/lib/exercises";

enum BodyPartType {
  muscle_biceps = "Biceps",
  muscle_triceps = "Triceps",
  muscle_forearms = "Forearms",
  muscle_neck = "Neck",
  muscle_shoulders = "Shoulders",
  muscle_lats = "Lats",
  muscle_traps = "Traps",
  muscle_chest = "Chest",
  muscle_abs = "Abs",
  muscle_obliques = "Obliques",
  muscle_lower_back = "Lower back",
  muscle_hip_adductor = "Hip adductor",
  muscle_hip_abductor = "Hip abductor",
  muscle_hip_flexors = "Hip flexors",
  muscle_glutes = "Glutes",
  muscle_hamstrings = "Hamstrings",
  muscle_quads = "Quads",
  muscle_calves = "Calves",
  muscle_tibialis = "Tibialis",
}

enum EquipmentType {
  equipment_dumbbell = "Dumbbells",
  equipment_kettlebell = "Kettlebells",
  equipment_barbell = "Barbell",
  equipment_band = "Band",
  equipment_yoga_ball = "Yoga ball",
  equipment_trx = "TRX",
  equipment_cardio = "Cardio machine",
  equipment_medicine_ball = "Medicine ball",
  equipment_roller = "Roller",
  equipment_machine = "Machines",
  equipment_pullup_bar = "Pull-up bar",
  equipment_bodyweight = "Bodyweight",
  equipment_cables = "Cables",
  equipment_captains_chair = "Captain's chair",
  equipment_battle_ropes = "Battle ropes",
  equipment_bosu = "Bosu",
  equipment_slider = "Sliders",
  equipment_bench = "Bench",
  equipment_ez_curl_bar = "EZ curl bar",
  equipment_jumprope = "Jump rope",
  equipment_ladder = "Ladder",
}

enum SystemType {
  system_agility = "Agility",
  system_balance = "Balance",
  system_cardio = "Cardio",
  system_flexibility = "Flexibility",
  system_massage = "Massage",
  system_metabolic = "Metabolic",
  system_plyo = "Plyo",
  system_power = "Power",
  system_strength = "Strength",
  system_warmup = "Warmup",
}

enum TypeType {
  type_recovery = "Recovery",
  type_cardio = "Cardio",
  type_warmup = "Warm up",
}

enum TrackingType {
  hands_free = "Hands free",
  rom_feedback = "ROM",
  pacing_feedback = "Pacing",
  not_tracked_feedback = "Not tracked",
}

type Props = {
  onChange: (filteredExerciseGroupIds: string[]) => void;
};

export default function ExerciseFilterButton({ onChange }: Props) {
  const selectedEquipmentKeys = useAppSelector(
    (state) =>
      !state.client.client
        ? []
        : (
            Object.keys(
              state.client.client.equipment_detailed,
            ) as (keyof typeof EquipmentType2)[]
          ).filter((key) => state.client.client!.equipment_detailed[key]),
    shallowEqual,
  );
  const isTemplate = useAppSelector((state) => state.workout.isTemplate);

  const defaultEquipment: string[] = [];

  if (!isTemplate) {
    defaultEquipment.push("equipment_bodyweight");

    if (selectedEquipmentKeys.includes("dumbbell")) {
      defaultEquipment.push("equipment_dumbbell");
    }
    if (selectedEquipmentKeys.includes("barbell")) {
      defaultEquipment.push("equipment_barbell");
    }
    if (selectedEquipmentKeys.includes("yoga_ball")) {
      defaultEquipment.push("equipment_yoga_ball");
    }
    if (selectedEquipmentKeys.includes("trx")) {
      defaultEquipment.push("equipment_trx");
    }
    if (selectedEquipmentKeys.includes("cardio")) {
      defaultEquipment.push("equipment_cardio");
    }
    if (selectedEquipmentKeys.includes("medicine_ball")) {
      defaultEquipment.push("equipment_medicine_ball");
    }
    if (selectedEquipmentKeys.includes("foam_roller")) {
      defaultEquipment.push("equipment_roller");
    }
    if (selectedEquipmentKeys.includes("machine")) {
      defaultEquipment.push("equipment_machine");
    }
    if (selectedEquipmentKeys.includes("kettlebell")) {
      defaultEquipment.push("equipment_kettlebell");
    }
    if (selectedEquipmentKeys.includes("pullup_bar")) {
      defaultEquipment.push("equipment_pullup_bar");
    }
    if (selectedEquipmentKeys.includes("band")) {
      defaultEquipment.push("equipment_band");
    }
    if (selectedEquipmentKeys.includes("jumpRope")) {
      defaultEquipment.push("equipment_jumprope");
    }
    if (selectedEquipmentKeys.includes("ladder")) {
      defaultEquipment.push("equipment_ladder");
    }
    if (selectedEquipmentKeys.includes("bosu")) {
      defaultEquipment.push("equipment_bosu");
    }
    if (selectedEquipmentKeys.includes("battleRopes")) {
      defaultEquipment.push("equipment_battle_ropes");
    }
    if (selectedEquipmentKeys.includes("captainsChair")) {
      defaultEquipment.push("equipment_captains_chair");
    }
    if (selectedEquipmentKeys.includes("cables")) {
      defaultEquipment.push("equipment_cables");
    }
    if (selectedEquipmentKeys.includes("slider")) {
      defaultEquipment.push("equipment_slider");
    }
    if (selectedEquipmentKeys.includes("bench")) {
      defaultEquipment.push("equipment_bench");
    }
    if (selectedEquipmentKeys.includes("ez_curl_bar")) {
      defaultEquipment.push("equipment_ez_curl_bar");
    }
  }

  const possibleBodyParts = Object.keys(
    BodyPartType,
  ) as (keyof typeof BodyPartType)[];
  const possibleEquipment = Object.keys(
    EquipmentType,
  ).sort() as (keyof typeof EquipmentType)[];
  const possibleTypes = Object.keys(
    TypeType,
  ).sort() as (keyof typeof TypeType)[];
  const possibleTracking = Object.keys(
    TrackingType,
  ).sort() as (keyof typeof TrackingType)[];

  const possibleSystems = Object.keys(
    SystemType,
  ).sort() as (keyof typeof SystemType)[];

  const [selectedBodyParts, setSelectedBodyParts] = useState<string[]>([]);
  const [selectedEquipment, setSelectedEquipment] =
    useState<string[]>(defaultEquipment);
  const [selectedTypes, setSelectedTypes] = useState<string[]>([]);
  const [selectedSystems, setSelectedSystems] = useState<string[]>([]);
  const [selectedTracking, setSelectedTracking] = useState<string[]>([
    "hands_free",
  ]);
  const [showNoEquipment, setShowNoEquipment] = useState(false);
  const [includeSecondaryMovers, setIncludeSecondaryMovers] = useState(false);

  const exerciseList = useMemo(() => exerciseGroups, []);

  useEffect(() => {
    onChange(getExerciseList().map((g) => g.picker_group_id));
  }, [
    selectedTracking,
    selectedBodyParts,
    selectedEquipment,
    selectedTypes,
    selectedSystems,
    showNoEquipment,
    includeSecondaryMovers,
    exerciseList,
  ]);

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const open = Boolean(anchorEl);

  function getExerciseList() {
    let list = JSON.parse(JSON.stringify(exerciseGroups)) as ExerciseGroup[];

    if (list) {
      if (areBodyPartFiltersEnabled()) {
        let moverNumber = 2;

        if (includeSecondaryMovers) {
          moverNumber = 1;
        }

        list = list.filter((exerciseSource) => {
          let result = false;

          for (const key of selectedBodyParts) {
            if (exerciseSource[key] >= moverNumber) {
              result = true;
            }

            if (result) {
              return result;
            }
          }

          return false;
        });
      }

      if (areSystemFiltersEnabled()) {
        list = list.filter((exerciseSource) => {
          let result = false;

          for (const key of selectedSystems) {
            if (exerciseSource[key] === true) {
              result = true;
            }

            if (result) {
              return result;
            }
          }

          return false;
        });
      }

      if (areEquipmentFiltersEnabled()) {
        list = list.filter((exerciseSource) => {
          const passesNoEquipment =
            !exerciseSource.equipment_dumbbell &&
            !exerciseSource.equipment_kettlebell &&
            !exerciseSource.equipment_barbell &&
            !exerciseSource.equipment_ez_curl_bar &&
            !exerciseSource.equipment_hex_bar &&
            !exerciseSource.equipment_plate &&
            !exerciseSource.equipment_cardio &&
            !exerciseSource.equipment_band &&
            !exerciseSource.equipment_cables &&
            !exerciseSource.equipment_captains_chair &&
            !exerciseSource.equipment_battle_ropes &&
            !exerciseSource.equipment_medicine_ball &&
            !exerciseSource.equipment_trx &&
            !exerciseSource.equipment_roller &&
            !exerciseSource.equipment_yoga_ball &&
            !exerciseSource.equipment_machine &&
            !exerciseSource.equipment_bosu;

          if (showNoEquipment === true) {
            return passesNoEquipment;
          } else {
            let result = false;

            if (selectedEquipment.includes("equipment_bodyweight")) {
              result = passesNoEquipment;

              if (result) {
                return result;
              }
            }

            for (const key of selectedEquipment) {
              if (exerciseSource[key]) {
                result = true;
              }

              if (result) {
                return result;
              }
            }

            return false;
          }
        });
      }

      if (areTrackedFiltersEnabled()) {
        list = list.filter((exerciseSource) => {
          for (const key of selectedTracking) {
            if (key === "hands_free" && exerciseSource.hands_free) {
              return true;
            } else if (key === "rom_feedback" && exerciseSource.rom) {
              return true;
            } else if (key === "pacing_feedback" && exerciseSource.pacing) {
              return true;
            } else if (
              key === "not_tracked_feedback" &&
              exerciseSource.not_tracked
            ) {
              return true;
            }
          }

          return false;
        });
      }

      if (areTypeFiltersEnabled()) {
        list = list.filter((exerciseSource) => {
          for (const key of selectedTypes) {
            if (key === "type_cardio" && exerciseSource.is_cardio) {
              return true;
            } else if (key === "type_recovery" && exerciseSource.is_recovery) {
              return true;
            } else if (key === "type_warmup" && exerciseSource.is_warmup) {
              return true;
            }
          }

          return false;
        });
      }

      return list;
    } else {
      return [];
    }
  }

  function clearFilters() {
    setSelectedEquipment([]);
    setSelectedBodyParts([]);
    setSelectedTracking([]);
    setSelectedTypes([]);
    setSelectedSystems([]);
    setShowNoEquipment(false);
    setIncludeSecondaryMovers(false);
  }

  function areBodyPartFiltersEnabled() {
    return selectedBodyParts.length > 0;
  }

  function areEquipmentFiltersEnabled() {
    return selectedEquipment.length > 0 || showNoEquipment === true;
  }

  function areTypeFiltersEnabled() {
    return selectedTypes.length > 0;
  }

  function areTrackedFiltersEnabled() {
    return selectedTracking.length > 0;
  }

  function areSystemFiltersEnabled() {
    return selectedSystems.length > 0;
  }

  function areFiltersEnabled() {
    return (
      areBodyPartFiltersEnabled() ||
      areEquipmentFiltersEnabled() ||
      areTrackedFiltersEnabled() ||
      areTypeFiltersEnabled() ||
      areSystemFiltersEnabled()
    );
  }

  function toggleEquipment(equipmentKey: keyof typeof EquipmentType) {
    const newSelectedEquipment = [...selectedEquipment];
    const index = newSelectedEquipment.indexOf(equipmentKey);

    if (index === -1) {
      newSelectedEquipment.push(equipmentKey);
    } else {
      newSelectedEquipment.splice(index, 1);
    }

    setSelectedEquipment(newSelectedEquipment);
  }

  function toggleBodyPart(bodyPartKey: keyof typeof BodyPartType) {
    const newSelectedBodyParts = [...selectedBodyParts];
    const index = newSelectedBodyParts.indexOf(bodyPartKey);

    if (index === -1) {
      newSelectedBodyParts.push(bodyPartKey);
    } else {
      newSelectedBodyParts.splice(index, 1);
    }

    setSelectedBodyParts(newSelectedBodyParts);
  }

  function toggleType(typeKey: keyof typeof TypeType) {
    const newSelectdedTypes = [...selectedTypes];
    const index = newSelectdedTypes.indexOf(typeKey);

    if (index === -1) {
      newSelectdedTypes.push(typeKey);
    } else {
      newSelectdedTypes.splice(index, 1);
    }

    setSelectedTypes(newSelectdedTypes);
  }

  function toggleSystem(systemKey: keyof typeof SystemType) {
    const newSelectdedSystems = [...selectedSystems];
    const index = newSelectdedSystems.indexOf(systemKey);

    if (index === -1) {
      newSelectdedSystems.push(systemKey);
    } else {
      newSelectdedSystems.splice(index, 1);
    }

    setSelectedSystems(newSelectdedSystems);
  }

  function toggleTracking(trackingKey: keyof typeof TrackingType) {
    const newSelectedTracking = [...selectedTracking];
    const index = newSelectedTracking.indexOf(trackingKey);

    if (index === -1) {
      newSelectedTracking.push(trackingKey);
    } else {
      newSelectedTracking.splice(index, 1);
    }

    setSelectedTracking(newSelectedTracking);
  }

  return (
    <>
      <Box sx={{ position: "relative" }}>
        <Tooltip title="Filter">
          <Button
            size="small"
            onClick={(event) => {
              setAnchorEl(event.currentTarget);
            }}
            variant="text"
            sx={{
              backgroundColor: (theme) =>
                areFiltersEnabled()
                  ? alpha(theme.palette.primary.main, 0.075)
                  : undefined,
              minWidth: "20px",
            }}
          >
            <FilterListRoundedIcon />
            {areFiltersEnabled() && (
              <Button
                size="small"
                variant="text"
                onMouseOver={(event) => {
                  event.stopPropagation();
                }}
                onMouseEnter={(event) => {
                  event.stopPropagation();
                }}
                onMouseDown={(event) => {
                  event.stopPropagation();
                }}
                onClick={(event) => {
                  event.stopPropagation();

                  clearFilters();
                }}
                sx={{
                  py: 0.25,
                  px: 0.5,
                  minWidth: "20px",
                  "& .MuiButton-endIcon": {
                    ml: 0.5,
                  },
                  lineHeight: 1.5,
                }}
                endIcon={<ClearRoundedIcon />}
              >
                {selectedTypes.length +
                  selectedTracking.length +
                  selectedBodyParts.length +
                  selectedSystems.length +
                  (showNoEquipment ? 1 : selectedEquipment.length)}
              </Button>
            )}
          </Button>
        </Tooltip>
      </Box>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={() => {
          setAnchorEl(null);
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <Stack spacing={2} sx={{ p: 2, width: "350px" }}>
          <Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                mb: 1,
              }}
            >
              <Typography variant="h6">Body part</Typography>
              <FormControlLabel
                control={
                  <Switch
                    size="small"
                    checked={includeSecondaryMovers}
                    onChange={() => {
                      setIncludeSecondaryMovers(!includeSecondaryMovers);
                    }}
                  />
                }
                style={{ fontSize: "14px" }}
                label="Include secondary movers"
                labelPlacement="start"
              />
            </Box>
            {possibleBodyParts.map((key, i) => {
              return (
                <Chip
                  key={i}
                  label={BodyPartType[key]}
                  onClick={() => {
                    toggleBodyPart(key);
                  }}
                  sx={{
                    mr: 0.5,
                    mb: 0.5,
                    borderStyle: !selectedBodyParts.includes(key)
                      ? "dashed"
                      : undefined,
                  }}
                  variant={
                    !selectedBodyParts.includes(key) ? "outlined" : "filled"
                  }
                  color={
                    !selectedBodyParts.includes(key) ? "default" : "blueSurface"
                  }
                  size="small"
                />
              );
            })}
          </Box>
          <Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                mb: 1,
              }}
            >
              <Typography variant="h6">Equipment</Typography>
              <FormControlLabel
                control={
                  <Switch
                    size="small"
                    checked={showNoEquipment}
                    onChange={() => {
                      setShowNoEquipment(!showNoEquipment);
                    }}
                  />
                }
                style={{ fontSize: "14px" }}
                label="Requires none"
                labelPlacement="start"
              />
            </Box>
            {possibleEquipment.map((key, i) => {
              return (
                <Chip
                  key={i}
                  label={EquipmentType[key]}
                  onClick={() => {
                    toggleEquipment(key);
                  }}
                  sx={{
                    mr: 0.5,
                    mb: 0.5,
                    borderStyle: !selectedEquipment.includes(key)
                      ? "dashed"
                      : undefined,
                  }}
                  variant={
                    !selectedEquipment.includes(key) ? "outlined" : "filled"
                  }
                  color={
                    !selectedEquipment.includes(key) ? "default" : "blueSurface"
                  }
                  size="small"
                  disabled={showNoEquipment}
                />
              );
            })}
          </Box>
          <Box>
            <Typography variant="h6" sx={{ mb: 1 }}>
              System
            </Typography>
            {possibleSystems.map((key, i) => {
              return (
                <Chip
                  key={i}
                  label={SystemType[key]}
                  onClick={() => {
                    toggleSystem(key);
                  }}
                  sx={{
                    mr: 0.5,
                    mb: 0.5,
                    borderStyle: !selectedSystems.includes(key)
                      ? "dashed"
                      : undefined,
                  }}
                  variant={
                    !selectedSystems.includes(key) ? "outlined" : "filled"
                  }
                  color={
                    !selectedSystems.includes(key) ? "default" : "blueSurface"
                  }
                  size="small"
                />
              );
            })}
          </Box>
          <Box>
            <Typography variant="h6" sx={{ mb: 1 }}>
              Type
            </Typography>
            {possibleTypes.map((key, i) => {
              return (
                <Chip
                  key={i}
                  label={TypeType[key]}
                  onClick={() => {
                    toggleType(key);
                  }}
                  sx={{
                    mr: 0.5,
                    mb: 0.5,
                    borderStyle: !selectedTypes.includes(key)
                      ? "dashed"
                      : undefined,
                  }}
                  variant={!selectedTypes.includes(key) ? "outlined" : "filled"}
                  color={
                    !selectedTypes.includes(key) ? "default" : "blueSurface"
                  }
                  size="small"
                />
              );
            })}
          </Box>
          <Box>
            <Typography variant="h6" sx={{ mb: 1 }}>
              Tracking
            </Typography>
            {possibleTracking.map((key, i) => {
              return (
                <Chip
                  key={i}
                  label={TrackingType[key]}
                  onClick={() => {
                    toggleTracking(key);
                  }}
                  sx={{
                    mr: 0.5,
                    mb: 0.5,
                    borderStyle: !selectedTracking.includes(key)
                      ? "dashed"
                      : undefined,
                  }}
                  variant={
                    !selectedTracking.includes(key) ? "outlined" : "filled"
                  }
                  color={
                    !selectedTracking.includes(key) ? "default" : "blueSurface"
                  }
                  size="small"
                />
              );
            })}
          </Box>
        </Stack>
      </Popover>
    </>
  );
}
