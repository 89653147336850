import type { CoachMetricDetails } from "src/features/trainer-metrics/MetricsPage";
import type Metric from "src/interfaces/Metric";
import { round } from "./misc";

export function queryMetric(
  metrics: any,
  query: string,
  ignoreUndefined?: boolean,
): any {
  const index = (obj: any, i: any) => {
    if (obj === undefined) {
      return undefined;
    }

    return obj[i];
  };

  const value = query.split(".").reduce(index, metrics);

  if (!ignoreUndefined && value === undefined) {
    console.error(`${query} returns undefined`);
    return [];
  }

  return value;
}

export function calculateNetChange(metrics: Metric[]) {
  if (metrics.length === 0) {
    return {
      difference: 0,
      differencePercent: 0,
    };
  }

  const length = metrics.length;
  let diff = metrics[length - 1].metric - metrics[0].metric;
  diff = round(diff, 1);
  let percentDiff = metrics[0].metric !== 0 ? diff / metrics[0].metric : diff;
  percentDiff = round(percentDiff * 100);

  return {
    difference: diff,
    differencePercent: percentDiff,
  };
}

const pastWeek = new Date();
pastWeek.setDate(pastWeek.getDate() - 14);
pastWeek.setHours(0, 0, 0, 0);
const pastMonth = new Date();
pastMonth.setMonth(pastMonth.getMonth() - 1);
pastMonth.setDate(pastMonth.getDate() - 7);
pastMonth.setHours(0, 0, 0, 0);

export const frames = ["Past Month", "All Time", "Past Week"];

export function formatMetrics(metrics: Metric[], timeFrame: string) {
  let result = metrics.filter((obj) => Boolean(obj.metric) || obj.metric === 0);

  result = result.filter((obj) => {
    const objDate = new Date(obj.date);
    if (timeFrame === frames[2]) {
      return objDate > pastWeek;
    } else if (timeFrame === frames[0]) {
      return objDate > pastMonth;
    } else {
      return true;
    }
  });

  return result;
}

function sortMetricCategories(a: CoachMetricDetails, b: CoachMetricDetails) {
  if (a.trainer.trainer_id === "deltatrainer") {
    return -1;
  }
  if (b.trainer.trainer_id === "deltatrainer") {
    return 1;
  }

  if (a.isTest && !b.isTest) {
    return -1;
  } else if (!a.isTest && b.isTest) {
    return 1;
  }

  if (a.isCohort && !b.isCohort) {
    return -1;
  } else if (!a.isCohort && b.isCohort) {
    return 1;
  }

  return null;
}

export function sortMetricsByName(
  a: CoachMetricDetails,
  b: CoachMetricDetails,
  direction: "asc" | "desc",
) {
  const firstSort = sortMetricCategories(a, b);

  if (firstSort !== null) {
    return firstSort;
  }

  return (
    a.trainer.full_name.localeCompare(b.trainer.full_name) *
    (direction === "asc" ? 1 : -1)
  );
}

export function sortMetricsByTrialConversion(
  a: CoachMetricDetails,
  b: CoachMetricDetails,
  direction: "asc" | "desc",
) {
  const firstSort = sortMetricCategories(a, b);

  if (firstSort !== null) {
    return firstSort;
  }

  const metric1 = a.metricsSnapshot?.trial_conversions
      ? a.metricsSnapshot.trial_conversions.percent_paid_from_signed_up_month
      : 0,
    metric2 = b.metricsSnapshot?.trial_conversions
      ? b.metricsSnapshot.trial_conversions.percent_paid_from_signed_up_month
      : 0;

  return (metric1 - metric2) * (direction === "desc" ? 1 : -1);
}

export function sortMetricsByResponseTime(
  a: CoachMetricDetails,
  b: CoachMetricDetails,
  direction: "asc" | "desc",
) {
  const firstSort = sortMetricCategories(a, b);

  if (firstSort !== null) {
    return firstSort;
  }

  const metric1 = a.metricsSnapshot?.response_times.median_questions
      ? a.metricsSnapshot.response_times.median_questions
      : 0,
    metric2 = b.metricsSnapshot?.response_times.median_questions
      ? b.metricsSnapshot.response_times.median_questions
      : 0;

  return (metric1 - metric2) * (direction === "desc" ? 1 : -1);
}

export function sortMetricsByWorkoutCompletion(
  a: CoachMetricDetails,
  b: CoachMetricDetails,
  direction: "asc" | "desc",
) {
  const firstSort = sortMetricCategories(a, b);

  if (firstSort !== null) {
    return firstSort;
  }

  const metric1 = a.metricsSnapshot
      ? a.metricsSnapshot.habits.daily_workout_completion_week_active
      : 0,
    metric2 = b.metricsSnapshot
      ? b.metricsSnapshot.habits.daily_workout_completion_week_active
      : 0;

  return (metric1 - metric2) * (direction === "desc" ? 1 : -1);
}

export function sortMetricsByOneMonthRetention(
  a: CoachMetricDetails,
  b: CoachMetricDetails,
  direction: "asc" | "desc",
) {
  const firstSort = sortMetricCategories(a, b);

  if (firstSort !== null) {
    return firstSort;
  }

  const metric1 = a.metricsSnapshot
      ? a.metricsSnapshot.users.percent_retained_1_months
      : 0,
    metric2 = b.metricsSnapshot
      ? b.metricsSnapshot.users.percent_retained_1_months
      : 0;

  return (metric1 - metric2) * (direction === "desc" ? 1 : -1);
}

export function sortMetricsByThreeMonthRetention(
  a: CoachMetricDetails,
  b: CoachMetricDetails,
  direction: "asc" | "desc",
) {
  const firstSort = sortMetricCategories(a, b);

  if (firstSort !== null) {
    return firstSort;
  }

  const metric1 = a.metricsSnapshot
      ? a.metricsSnapshot.users.percent_retained_3_months
      : 0,
    metric2 = b.metricsSnapshot
      ? b.metricsSnapshot.users.percent_retained_3_months
      : 0;

  return (metric1 - metric2) * (direction === "desc" ? 1 : -1);
}

export function sortMetricsBySixMonthRetention(
  a: CoachMetricDetails,
  b: CoachMetricDetails,
  direction: "asc" | "desc",
) {
  const firstSort = sortMetricCategories(a, b);

  if (firstSort !== null) {
    return firstSort;
  }

  const metric1 = a.metricsSnapshot
      ? a.metricsSnapshot.users.percent_retained_6_months
      : 0,
    metric2 = b.metricsSnapshot
      ? b.metricsSnapshot.users.percent_retained_6_months
      : 0;

  return (metric1 - metric2) * (direction === "desc" ? 1 : -1);
}

export function sortMetricsByPaidClients(
  a: CoachMetricDetails,
  b: CoachMetricDetails,
  direction: "asc" | "desc",
) {
  const firstSort = sortMetricCategories(a, b);

  if (firstSort !== null) {
    return firstSort;
  }

  const metric1 = a.metricsSnapshot ? a.metricsSnapshot.users.count_paying : 0,
    metric2 = b.metricsSnapshot ? b.metricsSnapshot.users.count_paying : 0;

  return (metric1 - metric2) * (direction === "desc" ? 1 : -1);
}

export function sortMetricsByRankSum(
  a: CoachMetricDetails,
  b: CoachMetricDetails,
  direction: "asc" | "desc",
) {
  const firstSort = sortMetricCategories(a, b);

  if (firstSort !== null) {
    return firstSort;
  }

  const metric1 = a.rankSum,
    metric2 = b.rankSum;

  return (metric1 - metric2) * (direction === "desc" ? 1 : -1);
}
