import AccessTimeRoundedIcon from "@mui/icons-material/AccessTimeRounded";
import CircleRoundedIcon from "@mui/icons-material/CircleRounded";
import MarkChatUnreadRoundedIcon from "@mui/icons-material/MarkChatUnreadRounded";
import PushPinRoundedIcon from "@mui/icons-material/PushPinRounded";
import QuestionMarkRoundedIcon from "@mui/icons-material/QuestionMarkRounded";
import {
  alpha,
  Box,
  CardActionArea,
  Checkbox,
  Chip,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Tooltip,
  Typography,
} from "@mui/material";
import type { ActionItemWorkoutReview } from "@trainwell/types";
import {
  differenceInDays,
  format,
  isBefore,
  isThisWeek,
  isThisYear,
  isToday,
  isYesterday,
  parse,
} from "date-fns";
import { useState } from "react";
import { shallowEqual } from "react-redux";
import ClientProfilePicture from "src/features/client/ClientProfilePicture";
import { useAppDispatch, useAppSelector } from "src/hooks/stateHooks";
import { messageAsText } from "src/lib/chat";
import { selectActionItemsForClient } from "src/slices/actionItemSlice";
import {
  markChatAsUnread,
  openChat,
  toggleChatPinned,
  toggleSelectedChat,
} from "src/slices/chatSlice";
import { selectClientById } from "src/slices/clientsSlice";
import { selectTicketById } from "src/slices/ticketsSlice";
import { selectPrimaryTrainer } from "src/slices/trainerSlice";
import { selectTrainerNames } from "src/slices/trainersSlice";
import SaveForLaterChips from "./SaveForLaterChips";

type Props = {
  chatId: string;
  condensed?: boolean;
};

export default function ChatCell({ chatId, condensed }: Props) {
  const dispatch = useAppDispatch();
  const trainer = useAppSelector(selectPrimaryTrainer);
  const isChatFullscreen = useAppSelector(
    (state) => state.chat.isChatFullscreen,
  );
  const chat = useAppSelector((state) => state.chat.chats[chatId]);
  const isCurrentChat = useAppSelector(
    (state) => state.chat.selectedChatId === chatId,
  );
  const trainerNames = useAppSelector(selectTrainerNames, shallowEqual);
  const ticketForChat = useAppSelector((state) =>
    chat.ticketId ? selectTicketById(state, chat.ticketId) : undefined,
  );
  const isSelectingChats = useAppSelector(
    (state) => state.chat.isSelectingChats,
  );
  const isSelected = useAppSelector((state) =>
    state.chat.selectedChatIds.includes(chat.id),
  );
  const clientInfo = useAppSelector(
    (state) => state.clients.clientInfo[chat.id],
  );
  const checkInMode = useAppSelector((state) => state.chat.checkInMode?.type);
  const [contextMenu, setContextMenu] = useState<{
    mouseX: number;
    mouseY: number;
  } | null>(null);
  const client = useAppSelector((state) => selectClientById(state, chat.id));
  const hasUnreadWorkoutActionItems = useAppSelector(
    (state) =>
      selectActionItemsForClient(state, chat.id).filter(
        (ai) =>
          ai.type === "workout_review" &&
          (ai as ActionItemWorkoutReview).is_internal,
      ).length > 0,
  );

  const handleContextMenu = (event: React.MouseEvent) => {
    event.preventDefault();
    setContextMenu(
      contextMenu === null
        ? {
            mouseX: event.clientX + 2,
            mouseY: event.clientY - 6,
          }
        : // repeated contextmenu when it is already open closes it with Chrome 84 on Ubuntu
          // Other native context menus might behave different.
          // With this behavior we prevent contextmenu from the backdrop to re-locale existing context menus.
          null,
    );
  };

  const handleClose = () => {
    setContextMenu(null);
  };

  const mostRecentMessage = chat.messages.at(-1) ?? client?.latest_message;

  let dateString = "n/a";

  // Needs to be optional for very old clients with bad data in `latest_message`
  if (mostRecentMessage?.send_date) {
    const lastMessageDate = new Date(mostRecentMessage.send_date);

    if (isToday(lastMessageDate)) {
      dateString = format(lastMessageDate, "h:mm aaa");
    } else if (isYesterday(lastMessageDate)) {
      dateString = "Yesterday";
    } else if (isThisWeek(lastMessageDate)) {
      dateString = format(lastMessageDate, "EEEE");
    } else if (isThisYear(lastMessageDate)) {
      dateString = format(lastMessageDate, "MMM d");
    } else {
      dateString = format(lastMessageDate, "MMM d, yyyy");
    }
  }

  const hasUnreadMessage =
    Boolean(chat.oldestUnreadMessageFromClient) || hasUnreadWorkoutActionItems;

  const headshotUrl =
    (chat.isGroupChat && chat.messages.length > 0
      ? chat.messages[chat.messages.length - 1].from_headshot_url ||
        trainerNames.find(
          (trainer) =>
            trainer.trainer_id ===
            chat.messages[chat.messages.length - 1].from_id,
        )?.headshot_url
      : chat.clientHeadshotURL) || "/assets/profile.png";

  const profilePictureDimensions = !isChatFullscreen ? 28 : 48;
  const iconFontSize = condensed ? "9px" : !isChatFullscreen ? "12px" : "15px";

  const now = new Date();

  return (
    <>
      <CardActionArea
        onClick={() => {
          if (chat.isGroupChat) {
            dispatch(openChat({ chatId: chat.id }));
          }
        }}
        href={
          isChatFullscreen
            ? `/chat?chat_id=${chat.id}`
            : !chat.isGroupChat
              ? `/clients/${chat.id}`
              : ""
        }
        onContextMenu={handleContextMenu}
        sx={{
          backgroundColor: (theme) =>
            isSelected || isCurrentChat
              ? alpha(theme.palette.primary.main, 0.2)
              : undefined,
          borderBottom: 1,
          borderColor: "divider",
          display: "flex",
          py: 1,
          px: condensed ? 0.25 : !isChatFullscreen ? 0.5 : 1,
          alignItems: "center",
        }}
      >
        {!condensed && isSelectingChats && (
          <Checkbox
            checked={isSelected}
            onChange={() => {
              dispatch(toggleSelectedChat(chat.id));
            }}
            inputProps={{ "aria-label": "controlled" }}
            onClick={(event) => {
              event.stopPropagation();
            }}
            onMouseDown={(event) => {
              event.stopPropagation();
            }}
          />
        )}
        <Box
          sx={{
            flex: 1,
            overflow: "hidden",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              width: "100%",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                mr: condensed ? 0.25 : !isChatFullscreen ? 0.5 : 1,
                width: condensed ? "12px" : !isChatFullscreen ? "14px" : "18px",
              }}
            >
              {chat.oldestQuestionFromClient ? (
                <QuestionMarkRoundedIcon
                  sx={{ fontSize: iconFontSize }}
                  color={"inherit"}
                />
              ) : hasUnreadMessage ? (
                <CircleRoundedIcon
                  sx={{ fontSize: iconFontSize }}
                  color={"inherit"}
                />
              ) : null}
            </Box>
            {client ? (
              <Box
                sx={{
                  position: "relative",
                  width: profilePictureDimensions,
                  height: profilePictureDimensions,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Box
                  sx={{
                    opacity:
                      condensed && checkInMode === "unmessaged" ? 0.25 : 1,
                    position: "absolute",
                    top: 0,
                    left: 0,
                  }}
                >
                  <ClientProfilePicture
                    dimension={profilePictureDimensions}
                    userId={client.user_id}
                  />
                </Box>
                {condensed && checkInMode === "unmessaged" && (
                  <Typography
                    sx={{
                      fontWeight: "bold",
                      fontSize: 22,
                      textAlign: "center",
                      zIndex: 1,
                    }}
                  >
                    {client?.last_trainer_message_date
                      ? differenceInDays(now, client.last_trainer_message_date)
                      : "-"}
                  </Typography>
                )}
              </Box>
            ) : (
              <img
                style={{
                  borderRadius: `${profilePictureDimensions / 2}px`,
                }}
                width={profilePictureDimensions}
                height={profilePictureDimensions}
                src={headshotUrl}
                alt="Headshot"
              />
            )}
            {!condensed && checkInMode === "unmessaged" && (
              <Box sx={{ ml: 2 }}>
                <Tooltip
                  title="Days since your last message"
                  disableInteractive
                >
                  <Typography
                    sx={{
                      fontWeight: "bold",
                      fontSize: 24,
                    }}
                  >
                    {client?.last_trainer_message_date
                      ? differenceInDays(now, client.last_trainer_message_date)
                      : "-"}
                  </Typography>
                </Tooltip>
              </Box>
            )}
            {!condensed && (
              <Box
                sx={{
                  flex: 1,
                  ml: checkInMode === "unmessaged" ? 2 : 1,
                  overflowX: "hidden",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "flex-start",
                  }}
                >
                  <Typography variant="h6">{chat.clientName}</Typography>
                  {mostRecentMessage && (
                    <Typography
                      sx={{
                        opacity: 0.8,
                        fontSize: !isChatFullscreen ? 10 : 12,
                        whiteSpace: "nowrap",
                      }}
                    >
                      {dateString}
                    </Typography>
                  )}
                </Box>
                {mostRecentMessage ? (
                  <Typography
                    sx={{
                      fontSize: 12,
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      fontWeight:
                        mostRecentMessage?.from_id === trainer!.trainer_id ||
                        mostRecentMessage?.read_date
                          ? "normal"
                          : "bold",
                    }}
                  >
                    {mostRecentMessage?.from_id === trainer!.trainer_id && (
                      <i>You: </i>
                    )}
                    {messageAsText(mostRecentMessage, client)}
                  </Typography>
                ) : (
                  <Typography
                    sx={{
                      fontSize: 12,
                      maxWidth: "200px",
                    }}
                  >
                    No Messages
                  </Typography>
                )}
              </Box>
            )}
          </Box>
          {client && !condensed && (
            <SaveForLaterChips
              userId={client.user_id}
              sx={{ mt: 0.5, ml: 2 }}
            />
          )}
          {checkInMode === "planned_workout" && (
            <Tooltip disableInteractive title="Workout sheduled">
              <Chip
                icon={<AccessTimeRoundedIcon />}
                size="small"
                sx={{ mt: 0.5, ml: 2 }}
                label={format(
                  parse(
                    clientInfo.workoutTimesToday.at(0)!,
                    "HH:mm",
                    new Date(),
                  ),
                  "h:mm aaa",
                )}
                color={
                  isBefore(
                    parse(
                      clientInfo.workoutTimesToday.at(0)!,
                      "HH:mm",
                      new Date(),
                    ),
                    new Date(),
                  )
                    ? "errorSurface"
                    : undefined
                }
              />
            </Tooltip>
          )}
          <Box
            sx={{
              textAlign: "right",
              alignItems: "flex-end",
              display: "flex",
              flexDirection: "column",
            }}
          >
            {ticketForChat?.state === "done" && (
              <Chip
                color="success"
                size="small"
                label="Done"
                sx={{ fontSize: "12px", height: "20px" }}
              />
            )}
          </Box>
        </Box>
      </CardActionArea>
      {contextMenu && (
        <Menu
          open={contextMenu !== null}
          onClose={handleClose}
          anchorReference="anchorPosition"
          anchorPosition={
            contextMenu !== null
              ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
              : undefined
          }
        >
          <MenuItem
            onClick={() => {
              dispatch(toggleChatPinned(chat.id));
              handleClose();
            }}
          >
            <ListItemIcon>
              <PushPinRoundedIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText primary={chat.pinned ? "Unpin" : "Pin"} />
          </MenuItem>
          <MenuItem
            onClick={() => {
              dispatch(markChatAsUnread(chat.id));
              handleClose();
            }}
            disabled={hasUnreadMessage}
          >
            <ListItemIcon>
              <MarkChatUnreadRoundedIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText primary="Unread" />
          </MenuItem>
        </Menu>
      )}
    </>
  );
}
