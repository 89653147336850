import { Tooltip, Typography } from "@mui/material";
import { shallowEqual } from "react-redux";
import { useAppSelector } from "src/hooks/stateHooks";
import { getExerciseDisplayName } from "src/lib/mediaUtility";
import {
  selectExerciseById,
  selectExerciseValidity,
} from "src/slices/workoutSlice";

type Props = {
  exerciseId: string;
};

export default function ExerciseName({ exerciseId }: Props) {
  const client = useAppSelector((state) => state.client.client);
  const exercise = useAppSelector((state) =>
    selectExerciseById(state, exerciseId),
  );
  const exerciseValidity = useAppSelector(
    (state) => selectExerciseValidity(state, exerciseId, client),
    shallowEqual,
  );

  if (!exercise) {
    return <Typography>Finding exercise</Typography>;
  }

  return (
    <Tooltip
      title={exerciseValidity.message ?? ""}
      placement="top"
      disableInteractive
    >
      <Typography
        sx={{
          color: (theme) =>
            exerciseValidity.status === "error"
              ? theme.palette.error.main
              : exerciseValidity.status === "warn"
                ? theme.palette.warning.main
                : undefined,
          fontWeight: "bold",
          lineHeight: 1.25,
        }}
      >
        {client?.progress_exercises_selected?.includes(
          exercise.exercise_master_id,
        ) ? (
          <Tooltip title={"Client is tracking progress"} disableInteractive>
            <Typography sx={{ display: "inline" }}>📈 </Typography>
          </Tooltip>
        ) : (
          ""
        )}
        {getExerciseDisplayName(exercise.exercise_master_id)}
      </Typography>
    </Tooltip>
  );
}
