import type { ExerciseGroup, ExerciseSource } from "@trainwell/types";
import Fuse from "fuse.js";
import type { ExerciseLibrary } from "src/types/ExerciseLibrary";
import { workoutLib } from "./trainwellWorkoutLib";

export let exerciseMap: ExerciseLibrary = {};
export let exerciseGroups: ExerciseGroup[] = [];

export const exerciseGroupsFuse = new Fuse(exerciseGroups, {
  includeScore: true,
  keys: ["tags"],
  threshold: 0.4,
  findAllMatches: true,
});

export function setExerciseLibrary(library: ExerciseLibrary) {
  exerciseMap = library;
  workoutLib.setExerciseLibrary(library);
}

export function setExerciseListGrouped(list: ExerciseGroup[]) {
  exerciseGroups = list;

  exerciseGroupsFuse.setCollection(list);
}

export function getExerciseById(exerciseId: string) {
  if (exerciseMap) {
    return exerciseMap[exerciseId];
  } else {
    return undefined;
  }
}

export function getExercisesByGroupId(exerciseGroupId: string) {
  return exerciseGroups.find((item) => item.picker_group_id === exerciseGroupId)
    ?.exercises;
}

export function exercisesHaveSameValues(a: ExerciseSource, b: ExerciseSource) {
  const aValues = workoutLib.exercises.getValuesForExercise(a);
  const bValues = workoutLib.exercises.getValuesForExercise(b);

  return (
    aValues.weight === bValues.weight &&
    aValues.reps === bValues.reps &&
    aValues.time === bValues.time
  );
}
