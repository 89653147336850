import type { FormControlProps } from "@mui/material";
import { FormControl, MenuItem, Select } from "@mui/material";
import { addMinutes, format, parse, startOfToday } from "date-fns";
import { range } from "src/lib/misc";

type Props = {
  /** Value in `HH:mm` format */
  value: string;
  onSave: (newValue: string) => void;
  minHalfHour?: number;
  maxHalfHour?: number;
} & FormControlProps;

export default function TimeSelector({
  value,
  minHalfHour,
  maxHalfHour,
  onSave,
  ...rest
}: Props) {
  return (
    <FormControl {...rest}>
      <Select
        MenuProps={{ style: { maxHeight: "300px" } }}
        value={format(parse(value, "HH:mm", new Date()), "h:mm aaa")}
        onChange={(event) => {
          const value = event.target.value as string;

          const time = format(parse(value, "h:mm aaa", new Date()), "HH:mm");

          onSave(time);
        }}
      >
        {range(minHalfHour ?? 0, maxHalfHour ?? 47).map((i) => {
          const dateString = format(
            addMinutes(startOfToday(), i * 30),
            "h:mm aaa",
          );
          return (
            <MenuItem value={dateString} key={i}>
              {dateString}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
}
