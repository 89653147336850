import { useEffect } from "react";
import { useSearchParams } from "react-router";
import { setPwa } from "src/slices/appSlice";
import { useAppDispatch } from "./stateHooks";

export default function usePwa() {
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();
  const pwa = searchParams.get("pwa");

  useEffect(() => {
    if (pwa === "true") {
      dispatch(setPwa(true));
    }
  }, [pwa, dispatch]);

  return;
}
