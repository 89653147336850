import ArrowBackRoundedIcon from "@mui/icons-material/ArrowBackRounded";
import EditOffRoundedIcon from "@mui/icons-material/EditOffRounded";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import Masonry from "@mui/lab/Masonry";
import { Box, Button, Card, IconButton, Typography } from "@mui/material";
import type { HabitTaskMessage, Message } from "@trainwell/types";
import { format } from "date-fns";
import { useEffect, useState } from "react";
import ControlBar from "src/components/misc/ControlBar";
import { useAppDispatch, useAppSelector } from "src/hooks/stateHooks";
import { api } from "src/lib/trainwellApi";
import { updateMessage } from "src/slices/chatSlice";
import { selectPrimaryTrainer } from "src/slices/trainerSlice";

export function NiceMessagesPage() {
  const dispatch = useAppDispatch();
  const trainer = useAppSelector(selectPrimaryTrainer);
  const [niceMessages, setNiceMessages] = useState<Message[] | undefined>();
  const [editing, setEditing] = useState(false);

  useEffect(() => {
    api.messages.getNice(trainer?.trainer_id ?? "").then((res) => {
      const messages = res.messages;
      shuffleArray(messages);
      setNiceMessages(messages);
    });
  }, [trainer?.trainer_id]);

  if (!trainer || !niceMessages) {
    return null;
  }

  return (
    <Box>
      <ControlBar
        leftContent={
          <Button
            variant="contained"
            href="/"
            startIcon={<ArrowBackRoundedIcon />}
            size="small"
          >
            Back
          </Button>
        }
      />
      <Box
        sx={{
          p: 3,
          backgroundColor: (theme) => theme.palette.background.default,
        }}
      >
        <Box
          sx={{
            mb: 2,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography variant="h1">Nice messages</Typography>
          <IconButton size="small" onClick={() => setEditing(!editing)}>
            {editing ? <EditOffRoundedIcon /> : <EditRoundedIcon />}
          </IconButton>
        </Box>
        <Masonry columns={3} spacing={2}>
          {niceMessages?.map((message) => {
            if (message.type === "text") {
              return (
                <Card
                  key={message.message_id}
                  variant="outlined"
                  sx={{
                    p: 1,
                  }}
                >
                  <Typography sx={{ mb: 1 }}>{message.text}</Typography>
                  <Typography variant="overline">
                    {format(message.send_date, "MMMM do, yyyy")}
                  </Typography>
                  {editing && (
                    <Button
                      sx={{ mt: 1 }}
                      size="small"
                      variant="text"
                      color="error"
                      onClick={() => {
                        dispatch(
                          updateMessage({
                            messageId: message.message_id,
                            nice: false,
                          }),
                        );

                        setNiceMessages(
                          niceMessages?.filter(
                            (m) => m.message_id !== message.message_id,
                          ),
                        );
                      }}
                    >
                      Remove
                    </Button>
                  )}
                </Card>
              );
            } else if (message.type === "habit_task_log") {
              return (
                <Card
                  key={message.message_id}
                  variant="outlined"
                  sx={{
                    p: 1,
                  }}
                >
                  <Typography sx={{ mb: 1 }}>
                    {(message as HabitTaskMessage).habit_task.notes_client}
                  </Typography>
                  <Typography variant="overline">
                    {format(message.send_date, "MMMM do, yyyy")}
                  </Typography>
                  {editing && (
                    <Button
                      sx={{ mt: 1 }}
                      size="small"
                      variant="text"
                      color="error"
                      onClick={() => {
                        dispatch(
                          updateMessage({
                            messageId: message.message_id,
                            nice: false,
                          }),
                        );

                        setNiceMessages(
                          niceMessages?.filter(
                            (m) => m.message_id !== message.message_id,
                          ),
                        );
                      }}
                    >
                      Remove
                    </Button>
                  )}
                </Card>
              );
            }

            return null;
          })}
        </Masonry>
      </Box>
    </Box>
  );
}

function shuffleArray(array) {
  for (let i = array.length - 1; i >= 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
}
