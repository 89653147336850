import BugReportRoundedIcon from "@mui/icons-material/BugReportRounded";
import EngineeringRoundedIcon from "@mui/icons-material/EngineeringRounded";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import SupportAgentRoundedIcon from "@mui/icons-material/SupportAgentRounded";
import {
  Autocomplete,
  Box,
  Button,
  Card,
  Link,
  Menu,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { useNavigate } from "react-router";
import TicketQuickDetails from "src/features/admin/tickets/TicketQuickDetails";
import { useAppDispatch, useAppSelector } from "src/hooks/stateHooks";
import { trainerHasAccess } from "src/lib/accessRoles";
import { getTrainerName } from "src/lib/coachUtility";
import type { Chat } from "src/slices/chatSlice";
import { addMemberToTicket, selectTicketById } from "src/slices/ticketsSlice";
import { selectPrimaryTrainer } from "src/slices/trainerSlice";
import { selectTrainerNames } from "src/slices/trainersSlice";

type Props = {
  chat: Chat;
};

export default function TicketMessageCard({ chat }: Props) {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [dashAnchorEl, setDashAnchorEl] = useState<HTMLButtonElement | null>(
    null,
  );
  const dashMenuOpen = Boolean(dashAnchorEl);
  const trainer = useAppSelector(selectPrimaryTrainer);
  const ticket = useAppSelector((state) =>
    selectTicketById(state, chat.ticketId!),
  );
  const trainerNames = useAppSelector(selectTrainerNames);
  const [mentioningPerson, setMentioningPerson] = useState(false);

  let icon = <BugReportRoundedIcon />;

  if (!ticket) {
    return <Typography>Error: no ticket on chat</Typography>;
  }

  if (ticket.type === "customer_support") {
    icon = <SupportAgentRoundedIcon />;
  } else if (ticket.type === "request") {
    icon = <EngineeringRoundedIcon />;
  }

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start",
        width: "100%",
        pb: 2,
      }}
    >
      <Card variant="outlined" sx={{ width: "100%", p: 1 }}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Box sx={{ color: (theme) => theme.palette.text.primary, mr: 2 }}>
              {icon}
            </Box>
            <Typography
              sx={{
                fontWeight: "bold",
              }}
            >
              Ticket #{ticket.number}
            </Typography>
          </Box>
          {trainerHasAccess(trainer?.access_roles, "client_list") && (
            <Button
              variant="text"
              size="small"
              href={`/admin/tickets/all/${ticket.id}`}
              startIcon={<OpenInNewIcon />}
            >
              Ticket
            </Button>
          )}
        </Box>
        <Box
          sx={{
            whiteSpace: "pre-line",
            wordWrap: "break-word",
          }}
        >
          {ticket.message_coach && (
            <Typography>
              <b>Trainer message:</b> {ticket.message_coach}
            </Typography>
          )}
          {ticket.message_client && (
            <Typography sx={{ mb: 1 }}>
              <b>Client message:</b> {ticket.message_client}
            </Typography>
          )}
          <Typography>
            <b>Members:</b>{" "}
            {chat.memberIds
              ?.map((id) => getTrainerName(id, trainerNames))
              .join(", ")}
          </Typography>
        </Box>
        <Stack direction={"row"} spacing={1} alignItems="center">
          {ticket.user_id && (
            <>
              {" "}
              <Button
                variant="text"
                size="small"
                onClick={(event) => {
                  if (trainerHasAccess(trainer?.access_roles, "client_list")) {
                    setDashAnchorEl(event.currentTarget);
                  } else {
                    navigate(`/clients/${ticket.user_id}`);
                  }
                }}
              >
                Client{ticket.client_name ? ` - ${ticket.client_name}` : ""}
              </Button>
              <Menu
                anchorEl={dashAnchorEl}
                open={dashMenuOpen}
                onClose={() => {
                  setDashAnchorEl(null);
                }}
              >
                <Link
                  href={`/clients/${ticket.user_id}`}
                  sx={{
                    textDecoration: "none",
                    color: (theme) => theme.palette.text.primary,
                  }}
                >
                  <MenuItem>Trainer dash</MenuItem>
                </Link>
                <Link
                  href={`/admin/company/clients/${ticket.user_id}`}
                  sx={{
                    textDecoration: "none",
                    color: (theme) => theme.palette.text.primary,
                  }}
                >
                  <MenuItem>Admin dash</MenuItem>
                </Link>
              </Menu>
            </>
          )}
          {ticket.log_id && (
            <Button
              variant="text"
              size="small"
              href={`/clients/${ticket.user_id}/logs/${ticket.log_id}`}
            >
              Log
            </Button>
          )}
          {trainerHasAccess(trainer?.access_roles, "client_list") &&
            ticket.slack_message_ts && (
              <Button
                variant="text"
                size="small"
                onClick={() => {
                  setMentioningPerson(!mentioningPerson);
                }}
              >
                {mentioningPerson ? "Cancel" : "Mention"}
              </Button>
            )}
        </Stack>
        <TicketQuickDetails size="small" ticketId={ticket.id} />
        {mentioningPerson && (
          <Autocomplete
            fullWidth
            options={trainerNames.filter((trainer) => trainer.is_staff)}
            onChange={(event, value) => {
              if (value?.trainer_id) {
                dispatch(
                  addMemberToTicket({
                    ticketId: ticket.id,
                    trainerId: value.trainer_id,
                  }),
                )
                  .unwrap()
                  .then(() => {
                    setMentioningPerson(false);

                    enqueueSnackbar(`Added ${value.full_name}`, {
                      variant: "success",
                    });
                  });
              }
            }}
            getOptionLabel={(option) => option.full_name}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Mention"
                helperText="Sends a slack message mentioning this person"
              />
            )}
            sx={{
              my: 1,
            }}
          />
        )}
      </Card>
    </Box>
  );
}
