import { Paper, Popover, Typography } from "@mui/material";
import type { CoachMetricTemplateSubmodule } from "@trainwell/types";
import { ClientBucketsGraph } from "./ClientBucketsGraph";

type Props = {
  anchorEl?: HTMLElement | null;
  onClose: () => void;
  metricSnapshot: any;
  submodule: CoachMetricTemplateSubmodule;
};

export function ClientBucketsPopover({
  anchorEl,
  onClose,
  metricSnapshot,
  submodule,
}: Props) {
  return (
    <Popover
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      onClose={onClose}
      disableRestoreFocus
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
    >
      <Paper
        sx={{
          padding: 3,
          display: "flex",
          flexFlow: "column nowrap",
          alignItems: "center",
        }}
      >
        <Typography variant="h3" sx={{ mb: 1 }}>
          {submodule.metric_title}
        </Typography>
        <ClientBucketsGraph
          metricSnapshot={metricSnapshot}
          submodule={submodule}
        />
      </Paper>
    </Popover>
  );
}
