import CheckBoxOutlineBlankRoundedIcon from "@mui/icons-material/CheckBoxOutlineBlankRounded";
import CheckBoxRoundedIcon from "@mui/icons-material/CheckBoxRounded";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import {
  Autocomplete,
  Box,
  Checkbox,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { Virtuoso } from "react-virtuoso";
import SearchField from "src/components/misc/SearchField";
import { useAppDispatch, useAppSelector } from "src/hooks/stateHooks";
import { getSmartTagDetails } from "src/lib/phaseTags";
import {
  selectPossibleTemplateTags,
  selectTemplateLibrary,
} from "src/slices/templatesSlice";
import { selectPrimaryTrainer } from "src/slices/trainerSlice";
import { toggleIsInsertingTemplate } from "src/slices/workoutSlice";
import TemplateSelectCell from "./TemplateSelectCell";

const icon = <CheckBoxOutlineBlankRoundedIcon fontSize="small" />;
const checkedIcon = <CheckBoxRoundedIcon fontSize="small" />;

export default function TemplateSelector() {
  const [search, setSearch] = useState("");
  const dispatch = useAppDispatch();
  const [tags, setTags] = useState<string[]>([]);
  const trainerId = useAppSelector(
    (state) => selectPrimaryTrainer(state)!.trainer_id,
  );
  const possibleTags = useAppSelector((state) =>
    selectPossibleTemplateTags(state, trainerId),
  );
  const templates = useAppSelector((state) => {
    let fetchedTemplates = selectTemplateLibrary(state).filter(
      (t) => t.trainer_id !== "copilot",
    );

    fetchedTemplates = fetchedTemplates.sort((a, b) => {
      const aDate = (a.metadata.dates_updated[
        a.metadata.dates_updated.length - 1
      ] ?? a.metadata.date_created) as string;

      const bDate = (b.metadata.dates_updated[
        b.metadata.dates_updated.length - 1
      ] ?? b.metadata.date_created) as string;

      return bDate.localeCompare(aDate);
    });

    if (search !== "") {
      fetchedTemplates = fetchedTemplates.filter(
        (template) =>
          template.name.toLowerCase().indexOf(search.toLowerCase()) !== -1,
      );
    }

    if (tags.length !== 0) {
      fetchedTemplates = fetchedTemplates.filter((w: any) => {
        for (const tag of tags) {
          if (!w.tags || !w.tags.includes(tag)) {
            return false;
          }
        }

        return true;
      });
    }

    return fetchedTemplates;
  });

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        backgroundColor: (theme) => theme.palette.background.default,
        height: "100%",
      }}
    >
      <Box
        sx={{
          p: 1,
          borderBottom: 1,
          borderColor: "divider",
        }}
      >
        <Box
          sx={{
            mb: 1,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography variant="h2">Templates</Typography>
          <IconButton
            size="small"
            onClick={() => {
              dispatch(toggleIsInsertingTemplate());
            }}
          >
            <CloseRoundedIcon />
          </IconButton>
        </Box>
        <SearchField
          value={search}
          onChange={(value) => {
            setSearch(value);
          }}
          onClear={() => {
            setSearch("");
          }}
          sx={{ mb: 1 }}
        />
        <Autocomplete
          multiple
          size="small"
          options={possibleTags}
          disableCloseOnSelect
          getOptionLabel={(tag) => {
            const tagDetails = getSmartTagDetails(tag);

            return tagDetails.label;
          }}
          value={tags}
          onChange={(event, newValues) => {
            setTags(newValues);
          }}
          renderOption={(props, tag, { selected }) => {
            const tagDetails = getSmartTagDetails(tag);

            return (
              <li
                {...props}
                style={{
                  paddingLeft: 8,
                  paddingRight: 8,
                  paddingBottom: 0,
                  paddingTop: 0,
                }}
              >
                <Checkbox
                  icon={icon}
                  checkedIcon={checkedIcon}
                  style={{ marginRight: 8 }}
                  checked={selected}
                />
                {tagDetails.icon && (
                  <tagDetails.icon.type
                    {...tagDetails.icon.props}
                    sx={{ fontSize: "inherit", mr: 1 }}
                  />
                )}
                {tagDetails.label}
              </li>
            );
          }}
          fullWidth
          renderInput={(params) => <TextField {...params} label="Tags" />}
          componentsProps={{ popper: { style: { width: "fit-content" } } }}
        />
      </Box>
      <Virtuoso
        data={templates}
        style={{ height: "100%" }}
        itemContent={(index, template) => (
          <TemplateSelectCell
            key={template.template_id}
            templateId={template.template_id}
          />
        )}
      />
    </Box>
  );
}
