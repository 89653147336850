import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import DoneRoundedIcon from "@mui/icons-material/DoneRounded";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import {
  Box,
  Card,
  FormControlLabel,
  FormGroup,
  IconButton,
  Switch,
  Typography,
} from "@mui/material";
import type { Audit } from "@trainwell/features";
import {
  useAuditTags,
  useDeleteAudit,
  useUpdateAudit,
} from "@trainwell/features";
import { format } from "date-fns";
import { useConfirm } from "material-ui-confirm";
import { useState } from "react";
import AutosaveTextField from "src/components/misc/AutosaveTextField";
import CustomLink from "src/components/misc/CustomLink";
import { TagList } from "src/components/misc/TagList";
import { useAppSelector } from "src/hooks/stateHooks";
import { getTrainerName } from "src/lib/coachUtility";
import { selectIsGhosting } from "src/slices/trainerSlice";
import { selectTrainerNames } from "src/slices/trainersSlice";

type Props = {
  audit: Audit & { client_full_name?: string };
  showClientName?: boolean;
};

export default function AuditCell({ audit, showClientName }: Props) {
  const trainerNames = useAppSelector(selectTrainerNames);
  const realTrainer = useAppSelector((state) => state.trainer.trainer);
  const { data: auditTags } = useAuditTags({
    trainerId: realTrainer!.trainer_id,
  });
  const deleteAudit = useDeleteAudit();
  const updateAudit = useUpdateAudit();
  const [editing, setEditing] = useState(false);
  const isGhosting = useAppSelector(selectIsGhosting);
  const confirm = useConfirm();

  return (
    <Card variant="outlined" sx={{ p: 1 }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "flex-start",
        }}
      >
        <Box sx={{ mb: 1 }}>
          <Typography sx={{ fontWeight: "bold" }}>
            {!showClientName
              ? `${audit.is_client_complaint ? "Complaint" : audit.type === "client_cancellation" ? "Cancellation audit" : "Audit"}`
              : audit.subject_trainer_id
                ? "Trainer audit"
                : `Client ${audit.is_client_complaint ? "complaint" : "audit"}`}{" "}
            by {getTrainerName(audit.auditor_trainer_id, trainerNames)}
          </Typography>
          {showClientName && audit.client_full_name && (
            <Typography>
              For{" "}
              <CustomLink
                href={`/clients/${audit.subject_user_id}`}
                text={audit.client_full_name}
              />
            </Typography>
          )}
          <Typography variant="overline">
            Created {format(new Date(audit.date_created), "MMMM do, yyyy")}
          </Typography>
        </Box>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <IconButton
            onClick={() => {
              setEditing(!editing);
            }}
            size="small"
          >
            {editing ? (
              <DoneRoundedIcon fontSize="small" />
            ) : (
              <EditRoundedIcon fontSize="small" />
            )}
          </IconButton>
          <IconButton
            onClick={() => {
              confirm({
                title: "Delete audit",
                description: "Delete this audit?",
                confirmationText: "Delete audit",
                confirmationButtonProps: {
                  color: "error",
                },
              }).then(() => {
                deleteAudit.mutate({ auditId: audit.id });
              });
            }}
            size="small"
            color="error"
          >
            <DeleteRoundedIcon fontSize="small" />
          </IconButton>
        </Box>
      </Box>
      <TagList
        value={audit.tags.map((t) => ({ id: t, label: t }))}
        options={auditTags?.map((t) => ({ id: t, label: t }))}
        onAdd={(tag) => {
          const newTags = [...audit.tags, tag];

          updateAudit.mutate({
            auditId: audit.id,
            data: {
              tags: newTags,
            },
          });
        }}
        onRemove={(tag) => {
          const newTags = [...audit.tags].filter((t) => t !== tag);

          updateAudit.mutate({
            auditId: audit.id,
            data: {
              tags: newTags,
            },
          });
        }}
        sx={{ mb: 1 }}
      />
      {isGhosting && (
        <FormGroup sx={{ mb: 1 }}>
          <FormControlLabel
            slotProps={{
              typography: {
                variant: "body2",
              },
            }}
            control={
              <Switch
                size="small"
                checked={audit.is_client_complaint ?? false}
                onChange={(event) => {
                  updateAudit.mutate({
                    auditId: audit.id,
                    data: {
                      is_client_complaint: event.target.checked,
                    },
                  });
                }}
              />
            }
            label="Client complained about trainer"
          />
        </FormGroup>
      )}
      {editing ? (
        <AutosaveTextField
          label="Audit"
          defaultValue={audit.text}
          name="audit.text"
          disableGutter
          onSave={(text) => {
            updateAudit.mutate({
              auditId: audit.id,
              data: {
                text: text,
              },
            });
          }}
        />
      ) : (
        <Typography
          sx={{
            whiteSpace: "pre-line",
            wordWrap: "break-word",
          }}
        >
          {audit.text}
        </Typography>
      )}
    </Card>
  );
}
