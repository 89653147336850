import {
  Box,
  Button,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { useAppSelector } from "src/hooks/stateHooks";
import { getUserIdsMatchingFilter } from "src/lib/chat";
import ChatListFilter from "./ChatListFilter";

type Props = {
  onContinue: (userIds: string[]) => void;
  onBack: () => void;
};

export function CheckInCustom({ onContinue, onBack }: Props) {
  const [filters, setFilters] = useState<string[]>([]);
  const [mode, setMode] = useState<"and" | "or">("and");
  const userIds = useAppSelector((state) => {
    const filteredUserIdSets: string[][] = [];

    for (const filter of filters) {
      const newUserIds = getUserIdsMatchingFilter(
        filter,
        state.clients.clients,
        state.clients.clientInfo,
        state.actionItems.actionItems,
      );

      filteredUserIdSets.push(newUserIds);
    }

    if (!filteredUserIdSets.length) {
      return [];
    }

    let matchedUserIds: string[] = [];

    if (mode === "and") {
      matchedUserIds = filteredUserIdSets.reduce((acc, curr) => {
        return acc.filter((userId) => curr.includes(userId));
      });
    } else {
      matchedUserIds = filteredUserIdSets.reduce((acc, curr) => {
        return [...new Set([...acc, ...curr])];
      });
    }

    return [...new Set(matchedUserIds)];
  });

  return (
    <Box sx={{ px: 1, py: 2 }}>
      <Typography variant="h2" sx={{ mb: 2 }}>
        Custom check in
      </Typography>
      <Stack spacing={2} alignItems={"flex-start"}>
        <ChatListFilter
          onToggle={(filter) => {
            if (filters.includes(filter)) {
              setFilters(filters.filter((f) => f !== filter));
            } else {
              setFilters([...filters, filter]);
            }
          }}
          selectedFilters={filters}
        />
        <ToggleButtonGroup
          color="primary"
          value={mode}
          exclusive
          sx={{ mt: 1 }}
          size="small"
          onChange={(event, newValue) => {
            if (newValue === null) {
              return;
            }

            setMode(newValue);
          }}
        >
          <ToggleButton value="and" sx={{ py: 0.25 }}>
            And
          </ToggleButton>
          <ToggleButton value="or" sx={{ py: 0.25 }}>
            Or
          </ToggleButton>
        </ToggleButtonGroup>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            width: "100%",
          }}
        >
          <Button variant="text" onClick={onBack}>
            Back
          </Button>
          <Button
            onClick={() => {
              onContinue(userIds);
            }}
            disabled={userIds.length === 0}
          >
            Continue to {userIds.length} clients
          </Button>
        </Box>
      </Stack>
    </Box>
  );
}
