import { Box, Button } from "@mui/material";
import { GoogleAuthProvider, getAuth, signInWithPopup } from "firebase/auth";

export default function LoginPage() {
  const provider = new GoogleAuthProvider();

  const httpsEnabled = window.location.protocol === "https:";

  if (!httpsEnabled && window.location.hostname !== "localhost") {
    // Simulate an HTTP redirect:
    console.log("Redirecting to HTTPS...");
    window.location.replace(
      "https://" + window.location.hostname + location.search,
    );
  }

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        height: "100%",
        width: "100%",
        alignItems: "center",
        position: "absolute",
      }}
    >
      <Box sx={{ mb: 4 }}>
        <img
          src="/assets/trainwell-wordmark.svg"
          alt="Logo"
          height={90}
          width={300}
        />
      </Box>
      <Button
        onClick={() => {
          const auth = getAuth();
          signInWithPopup(auth, provider).catch((error) => {
            console.error(error);
          });
        }}
      >
        Sign in with Google
      </Button>
    </Box>
  );
}
