import type { TooltipProps } from "@mui/material";
import {
  Box,
  styled,
  Tooltip,
  tooltipClasses,
  Typography,
} from "@mui/material";
import ExerciseMedia from "src/components/misc/ExerciseMedia";
import { getExerciseById } from "src/lib/exercises";
import { getExerciseIconURL } from "src/lib/mediaUtility";

const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.text.primary,
    boxShadow: theme.shadows[8],
    fontSize: 16,
    maxWidth: 800,
    p: 0,
  },
}));

type Props = {
  exerciseMasterId: string;
  placement?: TooltipProps["placement"];
};

export default function ExerciseIcon({ exerciseMasterId, placement }: Props) {
  const exerciseSource = getExerciseById(exerciseMasterId);

  if (!exerciseSource) {
    return <>Error</>;
  }

  return (
    <LightTooltip
      placement={placement}
      disableInteractive
      title={
        <Box sx={{ p: 2, maxWidth: 600 }}>
          <Typography variant="h2" sx={{ textAlign: "center", mb: 2 }}>
            {exerciseSource.key_readable_copilot}
          </Typography>
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <ExerciseMedia
              key={exerciseSource.id}
              exerciseMasterId={exerciseSource.id}
              width={300}
              height={300}
            />
          </Box>
        </Box>
      }
    >
      <img
        style={{
          borderRadius: "4px",
        }}
        src={getExerciseIconURL(exerciseMasterId)!}
        alt={exerciseMasterId}
        width={30}
        height={30}
      />
    </LightTooltip>
  );
}
