import { CssBaseline, useMediaQuery } from "@mui/material";
import { ThemeProvider as MuiThemeProvider } from "@mui/material/styles";
import { useMemo } from "react";
import { useAppSelector } from "src/hooks/stateHooks";
import getTheme from "src/theme/theme";
import type { ThemeMode } from "src/theme/themeModes";

type Props = {
  children: React.ReactNode;
};

export function ThemeProvider({ children }: Props) {
  const trainerThemeMode = useAppSelector(
    (state) => state.trainer.trainer?.settings?.theme_dash ?? "light",
  ) as ThemeMode;
  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");

  const systemThemeMode: ThemeMode = prefersDarkMode ? "dark" : "light_soft";
  const themeMode =
    trainerThemeMode === "auto" ? systemThemeMode : trainerThemeMode;

  const theme = useMemo(() => getTheme(themeMode), [themeMode]);

  return (
    <MuiThemeProvider theme={theme}>
      <CssBaseline />
      {children}
    </MuiThemeProvider>
  );
}
