import CheckBoxOutlineBlankRoundedIcon from "@mui/icons-material/CheckBoxOutlineBlankRounded";
import CheckBoxRoundedIcon from "@mui/icons-material/CheckBoxRounded";
import ClearRoundedIcon from "@mui/icons-material/ClearRounded";
import FilterListRoundedIcon from "@mui/icons-material/FilterListRounded";
import {
  Autocomplete,
  Avatar,
  Box,
  Button,
  Checkbox,
  Chip,
  Popover,
  TextField,
  Tooltip,
  Typography,
  alpha,
} from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useAppSelector } from "src/hooks/stateHooks";

const icon = <CheckBoxOutlineBlankRoundedIcon fontSize="small" />;
const checkedIcon = <CheckBoxRoundedIcon fontSize="small" />;

type Props = {
  value: string[];
  onChange: (tags: string[]) => void;
  variant?: "button" | "chip";
};

export default function TagFilterButton({
  value,
  onChange,
  variant = "button",
}: Props) {
  const possibleTags = useAppSelector((state) => state.clients.possibleTags);
  const [anchorEl, setAnchorEl] = useState<
    HTMLButtonElement | HTMLDivElement | null
  >(null);
  const open = Boolean(anchorEl);
  const inputRef = useRef<HTMLInputElement>();

  useEffect(() => {
    if (open) {
      setTimeout(() => {
        inputRef.current?.focus();
      }, 150);
    }
  }, [open]);

  return (
    <>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        {variant === "chip" ? (
          <Chip
            label={"Client tags"}
            onClick={(event) => {
              setAnchorEl(event.currentTarget);
            }}
            size="small"
            variant={value.length === 0 ? "outlined" : "filled"}
            color={value.length === 0 ? "default" : "blueSurface"}
            sx={{
              borderStyle: value.length === 0 ? "dashed" : undefined,
            }}
            avatar={value.length ? <Avatar>{value.length}</Avatar> : undefined}
          />
        ) : (
          <Tooltip title="Filter" enterDelay={1000}>
            <Button
              size="small"
              onClick={(event) => {
                setAnchorEl(event.currentTarget);
              }}
              variant="text"
              sx={{
                backgroundColor: (theme) =>
                  value.length > 0
                    ? alpha(theme.palette.primary.main, 0.075)
                    : undefined,
                minWidth: "20px",
              }}
            >
              <FilterListRoundedIcon />
              {value.length > 0 && (
                <Button
                  size="small"
                  variant="text"
                  onMouseOver={(event) => {
                    event.stopPropagation();
                  }}
                  onMouseEnter={(event) => {
                    event.stopPropagation();
                  }}
                  onMouseDown={(event) => {
                    event.stopPropagation();
                  }}
                  onClick={(event) => {
                    event.stopPropagation();

                    onChange([]);
                  }}
                  sx={{
                    py: 0.25,
                    px: 0.5,
                    minWidth: "20px",
                    "& .MuiButton-endIcon": {
                      ml: 0.5,
                    },
                    lineHeight: 1.5,
                  }}
                  endIcon={<ClearRoundedIcon />}
                >
                  {value.length}
                </Button>
              )}
            </Button>
          </Tooltip>
        )}
      </Box>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={() => {
          setAnchorEl(null);
        }}
        anchorOrigin={{
          vertical: "center",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        slotProps={{
          paper: {
            sx: { p: 2 },
          },
        }}
      >
        <Typography variant="h2" sx={{ mb: 2 }}>
          Filter
        </Typography>
        <Autocomplete
          multiple
          size="small"
          options={possibleTags}
          disableCloseOnSelect
          openOnFocus
          getOptionLabel={(tag) => tag}
          value={value}
          onChange={(event, newValues) => {
            onChange(newValues as any);
          }}
          renderOption={(props, tag, { selected }) => {
            return (
              <li
                {...props}
                style={{
                  paddingLeft: 8,
                  paddingRight: 8,
                  paddingBottom: 0,
                  paddingTop: 0,
                }}
              >
                <Checkbox
                  icon={icon}
                  checkedIcon={checkedIcon}
                  style={{ marginRight: 8 }}
                  checked={selected}
                />
                {tag}
              </li>
            );
          }}
          style={{ width: 400 }}
          renderInput={(params) => (
            <TextField
              {...params}
              inputRef={inputRef}
              label="Type"
              placeholder="Filter by..."
            />
          )}
        />
      </Popover>
    </>
  );
}
