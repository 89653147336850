import AddRoundedIcon from "@mui/icons-material/AddRounded";
import EmojiEventsRoundedIcon from "@mui/icons-material/EmojiEventsRounded";
import ExpandLessRoundedIcon from "@mui/icons-material/ExpandLessRounded";
import ExpandMoreRoundedIcon from "@mui/icons-material/ExpandMoreRounded";
import {
  Box,
  Button,
  IconButton,
  Stack,
  Tooltip,
  Typography,
  type SxProps,
  type Theme,
} from "@mui/material";
import { useCreateMilestone, useMilestones } from "@trainwell/features";
import { useState } from "react";
import LoadingPage from "src/components/miscPages/LoadingPage";
import { useAppSelector } from "src/hooks/stateHooks";
import { MilestoneCell } from "./MilestoneCell";

type Props = {
  sx?: SxProps<Theme>;
};

export function Milestones({ sx = [] }: Props) {
  const client = useAppSelector((state) => state.client.client);
  const milestonesQuery = useMilestones({ userId: client?.user_id ?? "" });
  const milestones = milestonesQuery.data ?? [];
  const [showCompleted, setShowCompleted] = useState(false);
  const createMilestone = useCreateMilestone();

  if (!client) {
    return <LoadingPage message={"Loading your client 😄"} />;
  }

  const incompleteMilestones = milestones.filter(
    (milestone) => !milestone.date_achieved,
  );
  const completeMilestones = milestones.filter(
    (milestone) => milestone.date_achieved,
  );

  return (
    <Box sx={sx}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          mb: 1,
        }}
      >
        <Box sx={{ display: "flex", alignItems: "flex-start" }}>
          <EmojiEventsRoundedIcon
            fontSize="inherit"
            sx={{
              mt: 0.5,
              color: (theme) =>
                client.account.dashboard.date_onboarded &&
                !incompleteMilestones.length
                  ? theme.palette.error.main
                  : undefined,
            }}
          />
          <Typography sx={{ ml: 1, fontWeight: "bold" }}>Milestones</Typography>
        </Box>
      </Box>
      {incompleteMilestones.length === 0 && (
        <Button
          startIcon={<AddRoundedIcon />}
          fullWidth
          variant="outlined"
          onClick={() => {
            createMilestone.mutate({
              data: {
                user_id: client.user_id,
                type: "text",
                content: null,
                text: "",
              },
            });
          }}
        >
          Add a milestone
        </Button>
      )}
      <Stack spacing={1}>
        {incompleteMilestones.map((milestone) => (
          <MilestoneCell key={milestone._id} milestone={milestone} />
        ))}
      </Stack>
      <Stack
        direction={"row"}
        spacing={1}
        sx={{ mt: incompleteMilestones.length > 0 ? 1 : undefined }}
      >
        {incompleteMilestones.length > 0 && (
          <IconButton
            size="small"
            onClick={() => {
              createMilestone.mutate({
                data: {
                  user_id: client.user_id,
                  type: "text",
                  content: null,
                  text: "",
                },
              });
            }}
          >
            <AddRoundedIcon fontSize="inherit" />
          </IconButton>
        )}
        {completeMilestones.length > 0 && (
          <Tooltip
            disableInteractive
            title={showCompleted ? "Hide completed" : "Show completed"}
          >
            <IconButton
              size="small"
              onClick={() => setShowCompleted(!showCompleted)}
            >
              {showCompleted ? (
                <ExpandLessRoundedIcon fontSize="inherit" />
              ) : (
                <ExpandMoreRoundedIcon fontSize="inherit" />
              )}
            </IconButton>
          </Tooltip>
        )}
      </Stack>
      {showCompleted && (
        <Stack spacing={0.5} sx={{ mt: 1 }}>
          {completeMilestones.map((milestone) => (
            <MilestoneCell key={milestone._id} milestone={milestone} />
          ))}
        </Stack>
      )}
    </Box>
  );
}
