import type { MentionOptions } from "@tiptap/extension-mention";
import { ReactRenderer } from "@tiptap/react";
import tippy, { type Instance as TippyInstance } from "tippy.js";
import SuggestionList, {
  type SuggestionListRef,
} from "./MagicKeySuggestionList";

export type MagicKeySuggestion = {
  id: string;
  label: string;
  example?: string;
  tooltip?: string;
  subMagicKeys?: { id: string; label: string; example: string }[];
};

export const magicKeySuggestions: MagicKeySuggestion[] = [
  {
    label: "Client name",
    id: "client_name",
    subMagicKeys: [
      {
        label: "Client first name",
        id: "first_name",
        example: "Steve",
      },
      {
        label: "Client full name",
        id: "full_name",
        example: "Steve Jobs",
      },
    ],
  },
  {
    label: "Next workout",
    id: "next_workout",
    subMagicKeys: [
      {
        label: "Next workout day",
        id: "next_workout_day",
        example: "Wednesday",
      },
      {
        label: "Next workout date",
        id: "next_workout_date",
        example: "7th",
      },
      {
        label: "Next workout name",
        id: "next_workout_name",
        example: "Full Body A",
      },
      {
        label: "Next workout duration",
        id: "next_workout_duration",
        example: "33 minutes",
      },
    ],
  },
  {
    label: "Last missed workout",
    id: "last_missed_workout",
    subMagicKeys: [
      {
        label: "Last missed workout day",
        id: "last_missed_workout_day",
        example: "Wednesday",
      },
      {
        label: "Last missed workout date",
        id: "last_missed_workout_date",
        example: "7th",
      },
      {
        label: "Last missed workout name",
        id: "last_missed_workout_name",
        example: "Full Body A",
      },
      {
        label: "Last missed workout duration",
        id: "last_missed_workout_duration",
        example: "33 minutes",
      },
    ],
  },
  {
    label: "Missed workout streak length",
    id: "missed_workout_streak_length",
    example: "2",
  },
  {
    label: "Upcoming workout days",
    id: "upcoming_workout_days",
    tooltip: "Excludes today if the client did a workout today",
    subMagicKeys: [
      {
        label: "This week's upcoming workout days",
        id: "upcoming_workout_days_this_week",
        example: "Monday, Wednesday, Friday",
      },
      {
        label: "Next week's upcoming workout days",
        id: "upcoming_workout_days_next_week",
        example: "Monday, Wednesday, Friday",
      },
    ],
  },
  {
    label: "Links",
    id: "links",
    subMagicKeys: [
      {
        label: "Change trainer",
        id: "url_change_coach",
        example: "https://account.trainwell.net",
      },
      {
        label: "Schedule call",
        id: "url_schedule_call",
        example: "https://account.trainwell.net",
      },
      {
        label: "Billing portal",
        id: "url_billing_portal",
        example: "https://account.trainwell.net",
      },
    ],
  },
];

export const magicKetSuggestionsFlat: {
  id: string;
  label: string;
  example?: string;
}[] = magicKeySuggestions
  .map((magicKey) => (magicKey.subMagicKeys ? magicKey.subMagicKeys : magicKey))
  .flat();

/**
 * Workaround for the current typing incompatibility between Tippy.js and Tiptap
 * Suggestion utility.
 *
 * @see https://github.com/ueberdosis/tiptap/issues/2795#issuecomment-1160623792
 *
 * Adopted from
 * https://github.com/Doist/typist/blob/a1726a6be089e3e1452def641dfcfc622ac3e942/stories/typist-editor/constants/suggestions.ts#L169-L186
 */
const DOM_RECT_FALLBACK: DOMRect = {
  bottom: 0,
  height: 0,
  left: 0,
  right: 0,
  top: 0,
  width: 0,
  x: 0,
  y: 0,
  toJSON() {
    return {};
  },
};

export const magicKeySuggestionOptions: MentionOptions["suggestion"] = {
  items: async ({ query }): Promise<MagicKeySuggestion[]> =>
    magicKetSuggestionsFlat
      // Find matching entries based on what the user has typed so far (after
      // the @ symbol)
      .filter((item) => item.label.toLowerCase().includes(query.toLowerCase()))
      .slice(0, 10),

  render: () => {
    let component: ReactRenderer<SuggestionListRef> | undefined;
    let popup: TippyInstance | undefined;

    return {
      onStart: (props) => {
        component = new ReactRenderer(SuggestionList, {
          props,
          editor: props.editor,
        });

        popup = tippy("body", {
          getReferenceClientRect: () =>
            props.clientRect?.() ?? DOM_RECT_FALLBACK,
          appendTo: () => document.body,
          content: component.element,
          showOnCreate: true,
          interactive: true,
          trigger: "manual",
          placement: "bottom-start",
        })[0];
      },

      onUpdate(props) {
        component?.updateProps(props);

        popup?.setProps({
          getReferenceClientRect: () =>
            props.clientRect?.() ?? DOM_RECT_FALLBACK,
        });
      },

      onKeyDown(props) {
        if (props.event.key === "Escape") {
          popup?.hide();
          return true;
        }

        if (!component?.ref) {
          return false;
        }

        return component.ref.onKeyDown(props);
      },

      onExit() {
        popup?.destroy();
        component?.destroy();

        // Remove references to the old popup and component upon destruction/exit.
        // (This should prevent redundant calls to `popup.destroy()`, which Tippy
        // warns in the console is a sign of a memory leak, as the `suggestion`
        // plugin seems to call `onExit` both when a suggestion menu is closed after
        // a user chooses an option, *and* when the editor itself is destroyed.)
        popup = undefined;
        component = undefined;
      },
    };
  },
};
