import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import type { CoachNotification } from "@trainwell/types";
import { getAuth } from "firebase/auth";
import { TRAINWELL_API_DOMAIN, TRAINWELL_API_KEY } from "src/config/config";

export const coachNotificationApi = createApi({
  reducerPath: "coachNotificationApi",
  baseQuery: fetchBaseQuery({
    baseUrl: TRAINWELL_API_DOMAIN,
    prepareHeaders: async (headers) => {
      const auth = getAuth();

      const tokenString = await auth.currentUser?.getIdToken();

      if (tokenString) {
        headers.set("authorization", `Bearer ${tokenString}`);
      }

      headers.set("api-key", TRAINWELL_API_KEY);

      return headers;
    },
  }),
  endpoints: (build) => ({
    getCoachNotification: build.query<
      { coach_notifications: CoachNotification[] },
      { trainerId: string }
    >({
      query: (options) =>
        `/coachNotifications?trainer_id=${encodeURIComponent(
          options.trainerId,
        )}`,
    }),
  }),
});

export const { useGetCoachNotificationQuery } = coachNotificationApi;
