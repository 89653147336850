import {
  attachClosestEdge,
  extractClosestEdge,
  type Edge,
} from "@atlaskit/pragmatic-drag-and-drop-hitbox/closest-edge";
import { getReorderDestinationIndex } from "@atlaskit/pragmatic-drag-and-drop-hitbox/util/get-reorder-destination-index";
import { combine } from "@atlaskit/pragmatic-drag-and-drop/combine";
import {
  draggable,
  dropTargetForElements,
  monitorForElements,
} from "@atlaskit/pragmatic-drag-and-drop/element/adapter";
import { pointerOutsideOfPreview } from "@atlaskit/pragmatic-drag-and-drop/element/pointer-outside-of-preview";
import { setCustomNativeDragPreview } from "@atlaskit/pragmatic-drag-and-drop/element/set-custom-native-drag-preview";
import AccessTimeRoundedIcon from "@mui/icons-material/AccessTimeRounded";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import BedtimeRoundedIcon from "@mui/icons-material/BedtimeRounded";
import BookmarkRoundedIcon from "@mui/icons-material/BookmarkRounded";
import ChatBubbleOutlineRoundedIcon from "@mui/icons-material/ChatBubbleOutlineRounded";
import ChatBubbleRoundedIcon from "@mui/icons-material/ChatBubbleRounded";
import ConstructionRoundedIcon from "@mui/icons-material/ConstructionRounded";
import CropFreeRoundedIcon from "@mui/icons-material/CropFreeRounded";
import DragIndicatorRoundedIcon from "@mui/icons-material/DragIndicatorRounded";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import EmojiPeopleRoundedIcon from "@mui/icons-material/EmojiPeopleRounded";
import ExpandLessRoundedIcon from "@mui/icons-material/ExpandLessRounded";
import ExpandMoreRoundedIcon from "@mui/icons-material/ExpandMoreRounded";
import FiberNewOutlinedIcon from "@mui/icons-material/FiberNewOutlined";
import FitnessCenterRoundedIcon from "@mui/icons-material/FitnessCenterRounded";
import LoopRoundedIcon from "@mui/icons-material/LoopRounded";
import QuestionMarkRoundedIcon from "@mui/icons-material/QuestionMarkRounded";
import ReceiptRoundedIcon from "@mui/icons-material/ReceiptRounded";
import SaveRoundedIcon from "@mui/icons-material/SaveRounded";
import StarRoundedIcon from "@mui/icons-material/StarRounded";
import VisibilityOffRoundedIcon from "@mui/icons-material/VisibilityOffRounded";
import WavingHandRoundedIcon from "@mui/icons-material/WavingHandRounded";
import {
  alpha,
  Box,
  Button,
  FormControlLabel,
  FormGroup,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Switch,
  Tooltip,
  Typography,
} from "@mui/material";
import type { ActionItem, CheckInMode, Client } from "@trainwell/types";
import { isAfter, isBefore, isFuture, isSameDay } from "date-fns";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { createPortal } from "react-dom";
import { DragPreview } from "src/components/common/DragPreview";
import { DropIndicator } from "src/components/common/DropIndicator";
import LoadingComponent from "src/components/miscPages/LoadingComponent";
import { useAppDispatch, useAppSelector } from "src/hooks/stateHooks";
import { notCheckedInToday } from "src/lib/misc";
import { api } from "src/lib/trainwellApi";
import { selectSentActionItems } from "src/slices/actionItemSlice";
import { fetchChats, setCheckInMode } from "src/slices/chatSlice";
import type { ClientInfo } from "src/slices/clientsSlice";
import { selectPrimaryTrainer } from "src/slices/trainerSlice";
import { updateTrainer } from "src/slices/trainersSlice";
import CheckInChatList from "./CheckInChatList";
import { CheckInCustom } from "./CheckInCustom";
import { MagicKeyTextField } from "./MagicKeyTextField";

type Props = {
  condensed?: boolean;
};

const emptyArray = [];

export default function CheckInPanel({ condensed }: Props) {
  const dispatch = useAppDispatch();
  const clients = useAppSelector((state) => state.clients.clients);
  const clientsStatus = useAppSelector((state) => state.clients.status);
  const checkInMode = useAppSelector((state) => state.chat.checkInMode);
  const actionItems = useAppSelector(selectSentActionItems);
  const actionItemStatus = useAppSelector((state) => state.actionItems.status);
  const trainer = useAppSelector(selectPrimaryTrainer);
  const isFocusMode = trainer?.settings.check_in_focus_mode ?? false;
  const [showHidden, setShowHidden] = useState(false);
  const [editing, setEditing] = useState(false);
  const clientInfo = useAppSelector((state) => state.clients.clientInfo);

  const sortedVisibleCheckInModes = useMemo(() => {
    const hiddenIndex = (trainer?.settings.check_in_mode_order ?? []).indexOf(
      "{ HIDDEN }",
    );

    // Filter out check in modes that occur after the hidden mode
    // Try to match the order that's in the trainer settings
    // Any check in modes not in the settings will be at the end

    return checkInModes
      .filter((mode) => {
        const index = (trainer?.settings.check_in_mode_order ?? []).indexOf(
          mode.mode,
        );
        return hiddenIndex === -1 || index === -1 || index < hiddenIndex;
      })
      .sort((a, b) => {
        const aIndex = (trainer?.settings.check_in_mode_order ?? []).indexOf(
          a.mode,
        );
        const bIndex = (trainer?.settings.check_in_mode_order ?? []).indexOf(
          b.mode,
        );

        // Put anything with an index of -1 at the end
        if (aIndex === -1 && bIndex === -1) {
          return 0;
        } else if (aIndex === -1) {
          return 1;
        } else if (bIndex === -1) {
          return -1;
        }

        return aIndex - bIndex;
      });
  }, [trainer]);

  const sortedHiddenCheckInModes = useMemo(() => {
    const hiddenIndex = (trainer?.settings.check_in_mode_order ?? []).indexOf(
      "{ HIDDEN }",
    );

    // Filter out check in modes that occur after the hidden mode
    // Try to match the order that's in the trainer settings
    // Any check in modes not in the settings will be at the end

    return checkInModes
      .filter((mode) => {
        const index = (trainer?.settings.check_in_mode_order ?? []).indexOf(
          mode.mode,
        );
        return hiddenIndex !== -1 && index !== -1 && index > hiddenIndex;
      })
      .sort((a, b) => {
        const aIndex = (trainer?.settings.check_in_mode_order ?? []).indexOf(
          a.mode,
        );
        const bIndex = (trainer?.settings.check_in_mode_order ?? []).indexOf(
          b.mode,
        );
        return aIndex - bIndex;
      });
  }, [trainer]);

  console.log("sortedHiddenCheckInModes", sortedHiddenCheckInModes);

  const [editingCheckInModes, setEditingCheckInModes] = useState([
    ...sortedVisibleCheckInModes,
    "{ HIDDEN }",
    ...sortedHiddenCheckInModes,
  ]);

  useEffect(() => {
    if (clientsStatus === "succeeded") {
      dispatch(fetchChats());
    }
  }, [dispatch, clientsStatus]);

  useEffect(() => {
    return monitorForElements({
      onDrop(dropResult) {
        const source = dropResult.source;
        const sourceType = source.data.type as string | undefined;

        const target = dropResult.location.current.dropTargets[0];

        if (!target) {
          return;
        }

        const targetType = target.data.type as string | undefined;

        if (sourceType === "check_in_mode" && targetType === "check_in_mode") {
          console.log("DND: reorder check in mode");

          const sourceIndex = source.data.index as number;
          const targetIndex = target.data.index as number;

          const closestEdgeOfTarget = extractClosestEdge(target.data);

          const finishIndex = getReorderDestinationIndex({
            startIndex: sourceIndex,
            closestEdgeOfTarget,
            indexOfTarget: targetIndex,
            axis: "vertical",
          });

          const newCheckInModes = [...editingCheckInModes];
          const [removed] = newCheckInModes.splice(sourceIndex, 1);
          newCheckInModes.splice(finishIndex, 0, removed);

          setEditingCheckInModes(newCheckInModes);
        } else if (
          sourceType === "check_in_mode" &&
          targetType === "empty_check_in_mode"
        ) {
          console.log("DND: hide a check in mode");

          const sourceIndex = source.data.index as number;

          const newCheckInModes = [...editingCheckInModes];
          const [removed] = newCheckInModes.splice(sourceIndex, 1);
          newCheckInModes.push(removed);

          setEditingCheckInModes(newCheckInModes);
        }
      },
      canMonitor: ({ source }) =>
        ["check_in_mode", "empty_check_in_mode"].includes(
          source.data.type as string,
        ),
    });
  }, [dispatch, editingCheckInModes]);

  const checkInUserIds = useMemo(() => {
    if (actionItemStatus !== "succeeded") {
      return {
        notCheckInTodayUserIds: emptyArray,
        workoutInternalFeedbackUserIds: emptyArray,
        workoutInternalNoFeedbackUserIds: emptyArray,
        workoutExternalUserIds: emptyArray,
        habitFeedbackUserIds: emptyArray,
        missedHabitUserIds: emptyArray,
        messageUserIds: emptyArray,
        missedWorkoutUserIds: emptyArray,
        questionUserIds: emptyArray,
        hiddenUserIds: emptyArray,
        saveForLaterUserIds: emptyArray,
        nothingNewUserIds: emptyArray,
        newUserIds: emptyArray,
        trialUserIds: emptyArray,
        unmessagedUserIds: emptyArray,
        billingUserIds: emptyArray,
        vipUserIds: emptyArray,
        plannedWorkoutUserIds: emptyArray,
      };
    }

    const notCheckInTodayUserIds = clients
      .filter(
        (client) =>
          notCheckedInToday(client) && !client.account.dashboard.is_hidden,
      )
      .map((client) => client.user_id);

    const vipUserIds = clients
      .filter((client) => client.is_vip)
      .map((client) => client.user_id);

    const workoutInternalFeedbackUserIds =
      getSortedUserIdsWithInternalFeedbackWorkouts(actionItems);
    const workoutInternalNoFeedbackUserIds =
      getSortedUserIdsWithInternalNoFeedbackWorkouts(actionItems);
    const workoutExternalUserIds = getSortedUserIdsWithExternalWorkouts(
      actionItems,
      isFocusMode,
    );

    const habitFeedbackUserIds =
      getSortedUserIdsWithFeedbackHabits(actionItems);
    const missedHabitUserIds = getSortedUserIdsWithMissedHabits(actionItems);

    const messageUserIds = getSortedUserIdsWithMessages(
      actionItems,
      isFocusMode,
    );

    const questionUserIds = getSortedUserIdsWithQuestions(actionItems);

    const missedWorkoutUserIds = getSortedUserIdsWithMissedWorkouts(
      actionItems,
      isFocusMode,
    );

    const unmessagedUserIds = getSortedUserIdsUnmessaged(clients, isFocusMode);

    const plannedWorkoutUserIds = getSortedUserIdsWithPlannedWorkouts(
      clientInfo,
      clients,
    );

    const trialUserIds = clients
      .filter(
        (client) =>
          (!client.account.dashboard.date_onboarded ||
            isFuture(client.account.plan.date_trial_end as string)) &&
          !client.account.dashboard.is_hidden &&
          (notCheckedInToday(client) ||
            actionItems.some((ai) => ai.user_id === client.user_id)),
      )
      .sort((a, b) => {
        return a.full_name.localeCompare(b.full_name);
      })
      .map((client) => client.user_id);

    const newUserIds = getSortedUserIdsNew(actionItems);

    const hiddenUserIds = clients
      .filter((client) => client.account.dashboard.is_hidden)
      .sort((a, b) => {
        return a.full_name.localeCompare(b.full_name);
      })
      .map((client) => client.user_id);

    const saveForLaterUserIds = getSortedUserIdsSaveForLater(actionItems);

    const billingUserIds = getSortedUserIdsBilling(actionItems);

    const nothingNewUserIds = clients
      .filter(
        (client) =>
          notCheckedInToday(client) &&
          !client.account.dashboard.is_hidden &&
          !actionItems.some(
            (actionItem) => actionItem.user_id === client.user_id,
          ),
      )
      .map((client) => client.user_id);

    return {
      notCheckInTodayUserIds,
      workoutInternalFeedbackUserIds,
      workoutInternalNoFeedbackUserIds,
      workoutExternalUserIds,
      habitFeedbackUserIds,
      missedHabitUserIds,
      messageUserIds,
      missedWorkoutUserIds,
      questionUserIds,
      hiddenUserIds,
      saveForLaterUserIds,
      nothingNewUserIds,
      trialUserIds,
      newUserIds,
      unmessagedUserIds,
      billingUserIds,
      vipUserIds,
      plannedWorkoutUserIds,
    };
  }, [actionItemStatus, Boolean(checkInMode.type), isFocusMode]);

  function selectCheckInMode(mode: CheckInMode) {
    api.analytics.trackEvent({
      trainerId: trainer?.trainer_id,
      eventType: "trainer_check_in_mode",
      platform: "coach_dash",
      sessionId: "",
      eventContent: {
        check_in_mode: mode,
        quick_message: checkInMode?.quickMessageEnabled,
        focus: isFocusMode,
      },
    });

    if (mode === "custom") {
      dispatch(
        setCheckInMode({
          ...checkInMode,
          cachedType: mode,
          customUserIds: null,
        }),
      );
    } else if (checkInMode.quickMessageEnabled) {
      dispatch(
        setCheckInMode({
          ...checkInMode,
          cachedType: mode,
        }),
      );
    } else {
      dispatch(
        setCheckInMode({
          type: mode,
          cachedType: undefined,
        }),
      );
    }
  }

  const getUserIds = useCallback(
    (mode: CheckInMode) => {
      switch (mode) {
        case "save_for_later":
          return checkInUserIds.saveForLaterUserIds;
        case "workouts_internal_feedback":
          return checkInUserIds.workoutInternalFeedbackUserIds;
        case "workouts_internal_no_feedback":
          return checkInUserIds.workoutInternalNoFeedbackUserIds;
        case "workouts_external":
          return checkInUserIds.workoutExternalUserIds;
        case "messages":
          return checkInUserIds.messageUserIds;
        case "questions":
          return checkInUserIds.questionUserIds;
        case "workouts_missed":
          return checkInUserIds.missedWorkoutUserIds;
        case "unmessaged":
          return checkInUserIds.unmessagedUserIds;
        case "hidden":
          return checkInUserIds.hiddenUserIds;
        case "nothing_new":
          return checkInUserIds.nothingNewUserIds;
        case "new_clients":
          return checkInUserIds.newUserIds;
        case "trial":
          return checkInUserIds.trialUserIds;
        case "everyone":
          return checkInUserIds.notCheckInTodayUserIds;
        case "billing_changes":
          return checkInUserIds.billingUserIds;
        case "vip":
          return checkInUserIds.vipUserIds;
        case "habits_feedback":
          return checkInUserIds.habitFeedbackUserIds;
        case "missed_habits":
          return checkInUserIds.missedHabitUserIds;
        case "planned_workout":
          return checkInUserIds.plannedWorkoutUserIds;
        case "custom":
          return checkInMode.customUserIds ?? emptyArray;
        default:
          return emptyArray;
      }
    },
    [checkInUserIds, checkInMode.customUserIds],
  );

  const userIds = useMemo(() => {
    if (checkInMode?.type) {
      return getUserIds(checkInMode.type);
    }

    return emptyArray;
  }, [checkInMode?.type, getUserIds]);

  console.log("CheckInMode", checkInMode);

  if (actionItemStatus !== "succeeded") {
    return <LoadingComponent />;
  }

  if (checkInMode.type) {
    return <CheckInChatList userIds={userIds} condensed={condensed} />;
  }

  if (condensed) {
    return null;
  }

  if (
    checkInMode.cachedType === "custom" &&
    (checkInMode.customUserIds === null ||
      checkInMode.customUserIds === undefined)
  ) {
    return (
      <CheckInCustom
        onContinue={(userIds) => {
          if (!checkInMode.quickMessageEnabled) {
            dispatch(
              setCheckInMode({
                ...checkInMode,
                type: "custom",
                cachedType: undefined,
                customUserIds: userIds,
              }),
            );
          } else {
            dispatch(
              setCheckInMode({
                ...checkInMode,
                customUserIds: userIds,
              }),
            );
          }
        }}
        onBack={() => {
          dispatch(
            setCheckInMode({
              ...checkInMode,
              cachedType: undefined,
            }),
          );
        }}
      />
    );
  } else if (checkInMode.quickMessageEnabled && checkInMode.cachedType) {
    return (
      <MagicKeyTextField
        onBack={() => {
          dispatch(
            setCheckInMode({
              ...checkInMode,
              cachedType: undefined,
            }),
          );
        }}
        onContinue={() => {
          dispatch(
            setCheckInMode({
              ...checkInMode,
              cachedType: undefined,
              type: checkInMode.cachedType,
            }),
          );
        }}
        userIds={getUserIds(checkInMode.cachedType)}
        sx={{
          my: 2,
          mx: 1,
        }}
      />
    );
  }

  return (
    <Box
      sx={{
        height: "100%",
        display: "flex",
        flexDirection: "column",
        backgroundColor: (theme) => theme.palette.background.paper,
        px: 2,
        pb: 16,
        pt: 1,
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "flex-start",
          mb: 2,
        }}
      >
        <FormGroup>
          <Tooltip
            disableInteractive
            title="Changes some of the filters to include ONLY what they say"
            placement="top"
          >
            <FormControlLabel
              control={
                <Switch
                  checked={isFocusMode}
                  onChange={(event) => {
                    dispatch(
                      updateTrainer({
                        trainer_id: trainer!.trainer_id,
                        // @ts-expect-error
                        "settings.check_in_focus_mode": event.target.checked,
                      }),
                    );
                  }}
                />
              }
              label="Focus"
            />
          </Tooltip>
          <FormControlLabel
            control={
              <Switch
                checked={checkInMode.quickMessageEnabled}
                onChange={(event) => {
                  dispatch(
                    setCheckInMode({
                      ...checkInMode,
                      quickMessageEnabled: event.target.checked,
                    }),
                  );
                }}
              />
            }
            label="Quick message"
          />
        </FormGroup>
        <IconButton
          size="small"
          color="inherit"
          onClick={() => {
            setEditing(!editing);

            if (editing) {
              dispatch(
                updateTrainer({
                  trainer_id: trainer!.trainer_id,
                  // @ts-expect-error
                  "settings.check_in_mode_order": editingCheckInModes.map(
                    (checkInMode) =>
                      typeof checkInMode === "string"
                        ? checkInMode
                        : checkInMode.mode,
                  ),
                }),
              );
            }
          }}
        >
          {editing ? (
            <SaveRoundedIcon fontSize="small" />
          ) : (
            <EditRoundedIcon fontSize="small" />
          )}
        </IconButton>
      </Box>
      <List sx={{ width: "100%" }}>
        {editing ? (
          <>
            {editingCheckInModes.map((checkInMode, index) => {
              if (typeof checkInMode === "string") {
                return (
                  <Typography key={checkInMode} variant="h6" sx={{ my: 2 }}>
                    Hidden
                  </Typography>
                );
              }
              return (
                <CheckInModeItem
                  key={checkInMode.mode}
                  checkInMode={checkInMode}
                  clientCount={getUserIds(checkInMode.mode).length}
                  onClick={() => {
                    selectCheckInMode(checkInMode.mode);
                  }}
                  isFocusMode={isFocusMode}
                  editing={editing}
                  index={index}
                />
              );
            })}
            {editingCheckInModes.indexOf("{ HIDDEN }") !== -1 &&
              editingCheckInModes.indexOf("{ HIDDEN }") ===
                editingCheckInModes.length - 1 && <EmptyDroppable />}
          </>
        ) : (
          <>
            {sortedVisibleCheckInModes.map((checkInMode, index) => (
              <CheckInModeItem
                key={checkInMode.mode}
                checkInMode={checkInMode}
                clientCount={getUserIds(checkInMode.mode).length}
                onClick={() => {
                  selectCheckInMode(checkInMode.mode);
                }}
                isFocusMode={isFocusMode}
                editing={editing}
                index={index}
              />
            ))}
            {sortedHiddenCheckInModes.length > 0 && (
              <Button
                variant="text"
                startIcon={
                  showHidden ? (
                    <ExpandLessRoundedIcon />
                  ) : (
                    <ExpandMoreRoundedIcon />
                  )
                }
                onClick={() => {
                  setShowHidden(!showHidden);
                }}
                sx={{ mt: 2 }}
              >
                Hidden check in modes
              </Button>
            )}
            {showHidden &&
              sortedHiddenCheckInModes.map((checkInMode, index) => (
                <CheckInModeItem
                  key={checkInMode.mode}
                  checkInMode={checkInMode}
                  clientCount={getUserIds(checkInMode.mode).length}
                  onClick={() => {
                    selectCheckInMode(checkInMode.mode);
                  }}
                  isFocusMode={isFocusMode}
                  editing={editing}
                  index={index}
                />
              ))}
          </>
        )}
      </List>
    </Box>
  );
}

type ItemProps = {
  checkInMode: CheckInModeItem;
  isFocusMode: boolean;
  onClick: () => void;
  clientCount: number;
  editing: boolean;
  index: number;
};

function CheckInModeItem({
  checkInMode,
  isFocusMode,
  onClick,
  clientCount,
  editing,
  index,
}: ItemProps) {
  const { mode, label, tooltip, icon } = checkInMode;
  const ref = useRef(null);
  const dragHandleRef = useRef<HTMLButtonElement>(null);
  const [dragging, setDragging] = useState<boolean>(false);
  const [closestEdge, setClosestEdge] = useState<Edge | null>(null);
  const [previewContainer, setPreviewContainer] = useState<HTMLElement | null>(
    null,
  );

  useEffect(() => {
    const element = ref.current;
    const dragHandle = dragHandleRef.current;

    if (!element || !dragHandle) {
      return;
    }

    const data = {
      type: "check_in_mode",
      index: index,
    };

    return combine(
      draggable({
        element: dragHandle,
        getInitialData: () => data,
        onDragStart: () => setDragging(true),
        onDrop: () => setDragging(false),
        onGenerateDragPreview({ nativeSetDragImage }) {
          setCustomNativeDragPreview({
            nativeSetDragImage,
            getOffset: pointerOutsideOfPreview({
              x: "16px",
              y: "8px",
            }),
            render({ container }) {
              setPreviewContainer(container);
            },
          });
        },
      }),
      dropTargetForElements({
        element,
        canDrop({ source }) {
          return source.data.type === "check_in_mode";
        },
        getData({ input }) {
          return attachClosestEdge(data, {
            element,
            input,
            allowedEdges: ["top", "bottom"],
          });
        },
        onDrag({ self, source }) {
          const isSource =
            source.element === element || source.element === dragHandle;
          if (isSource) {
            setClosestEdge(null);
            return;
          }

          const closestEdge = extractClosestEdge(self.data);

          const sourceIndex = source.data.index;
          if (typeof sourceIndex !== "number") {
            return;
          }

          const isItemBeforeSource = index === sourceIndex - 1;
          const isItemAfterSource = index === sourceIndex + 1;

          const isDropIndicatorHidden =
            (isItemBeforeSource && closestEdge === "bottom") ||
            (isItemAfterSource && closestEdge === "top");

          if (isDropIndicatorHidden) {
            setClosestEdge(null);
            return;
          }

          setClosestEdge(closestEdge);
        },
        onDragLeave() {
          setClosestEdge(null);
        },
        onDrop() {
          setClosestEdge(null);
        },
      }),
    );
  }, [index, editing]);

  const labelText = label({
    count: clientCount,
    focusMode: isFocusMode,
  });

  return (
    <>
      <div
        style={{
          position: "relative",
        }}
      >
        <div
          ref={ref}
          style={{
            opacity: dragging ? 0.4 : 1,
          }}
        >
          <Tooltip
            disableInteractive
            title={
              editing
                ? ""
                : typeof tooltip === "string"
                  ? tooltip
                  : tooltip
                    ? tooltip(isFocusMode)
                    : ""
            }
            key={mode}
          >
            <ListItem disablePadding>
              {editing && (
                <IconButton
                  color="default"
                  ref={dragHandleRef}
                  onClick={(event) => {
                    event.stopPropagation();
                  }}
                  sx={{
                    cursor: "grab",
                  }}
                  size="small"
                >
                  <DragIndicatorRoundedIcon fontSize="small" />
                </IconButton>
              )}
              <ListItemButton
                onClick={() => {
                  if (editing) {
                    return;
                  }

                  onClick();
                }}
                disabled={mode !== "custom" && !editing && clientCount === 0}
              >
                <ListItemIcon>{icon}</ListItemIcon>
                <ListItemText primary={labelText} />
              </ListItemButton>
            </ListItem>
          </Tooltip>
        </div>
        {closestEdge && <DropIndicator edge={closestEdge} gap="0px" />}
      </div>
      {previewContainer
        ? createPortal(<DragPreview text={labelText} />, previewContainer)
        : null}
    </>
  );
}

const getSortedUserIdsWithFeedbackHabits = (actionItems: ActionItem[]) => {
  // Step 1: Filter for "workout_review" type
  const filteredItems = actionItems.filter(
    (item) => item.type === "habit_completed" && item.has_alerts_or_feedback,
  );

  // Step 2: Sort by date_created in ascending order
  const sortedItems = filteredItems.sort((a, b) =>
    (a.date_created as string).localeCompare(b.date_created as string),
  );

  // Step 3: Map to get user_ids
  const userIds = sortedItems.map((item) => item.user_id);

  // Step 4: Remove duplicates
  const uniqueUserIds = Array.from(new Set(userIds));

  return uniqueUserIds;
};

const getSortedUserIdsWithMissedHabits = (actionItems: ActionItem[]) => {
  // Step 1: Filter for "workout_review" type
  const filteredItems = actionItems.filter(
    (item) => item.type === "missed_habit",
  );

  // Step 2: Sort by date_created in ascending order
  const sortedItems = filteredItems.sort((a, b) =>
    (a.date_created as string).localeCompare(b.date_created as string),
  );

  // Step 3: Map to get user_ids
  const userIds = sortedItems.map((item) => item.user_id);

  // Step 4: Remove duplicates
  const uniqueUserIds = Array.from(new Set(userIds));

  return uniqueUserIds;
};

const getSortedUserIdsWithInternalFeedbackWorkouts = (
  actionItems: ActionItem[],
) => {
  // Step 1: Filter for "workout_review" type
  const filteredItems = actionItems.filter(
    (item) =>
      item.type === "workout_review" &&
      item.is_internal &&
      item.has_alerts_or_feedback,
  );

  // Step 2: Sort by date_created in ascending order
  const sortedItems = filteredItems.sort((a, b) =>
    (a.date_created as string).localeCompare(b.date_created as string),
  );

  // Step 3: Map to get user_ids
  const userIds = sortedItems.map((item) => item.user_id);

  // Step 4: Remove duplicates
  const uniqueUserIds = Array.from(new Set(userIds));

  return uniqueUserIds;
};

const getSortedUserIdsWithInternalNoFeedbackWorkouts = (
  actionItems: ActionItem[],
) => {
  // Step 1: Filter for "workout_review" type
  const filteredItems = actionItems.filter(
    (item) =>
      item.type === "workout_review" &&
      item.is_internal &&
      !item.has_alerts_or_feedback,
  );

  // Step 2: Sort by date_created in ascending order
  const sortedItems = filteredItems.sort((a, b) =>
    (a.date_created as string).localeCompare(b.date_created as string),
  );

  // Step 3: Map to get user_ids
  const userIds = sortedItems.map((item) => item.user_id);

  // Step 4: Remove duplicates
  const uniqueUserIds = Array.from(new Set(userIds));

  return uniqueUserIds;
};

const getSortedUserIdsWithPlannedWorkouts = (
  clientInfos: ClientInfo,
  clients: Client[],
) => {
  const now = new Date();

  const userIds = Object.keys(clientInfos)
    .map((userId) => {
      const clientInfo = clientInfos[userId];

      if (clientInfo.workoutTimesToday.length > 0) {
        return {
          userId,
          times: clientInfo.workoutTimesToday,
        };
      }

      return null;
    })
    .filter((c) => c !== null)
    .sort((a, b) => {
      return a.times[0].localeCompare(b.times[0]);
    })
    .filter((c) => {
      const client = clients.find((client) => client.user_id === c.userId);

      const workedOutToday =
        client?.last_workout_date && isSameDay(client.last_workout_date, now);

      if (workedOutToday) {
        return false;
      }

      const isBeforeWOrkoutTime = isBefore(c.times[0], now);

      if (isBeforeWOrkoutTime) {
        const messagedToday =
          client?.last_trainer_message_date &&
          isSameDay(client.last_trainer_message_date, now);

        return !messagedToday;
      } else {
        const messagedAfterWorkout =
          client?.last_trainer_message_date &&
          isAfter(client.last_trainer_message_date, c.times[0]);

        return !messagedAfterWorkout;
      }
    })
    .map((c) => c.userId);

  // Step 4: Remove duplicates
  const uniqueUserIds = Array.from(new Set(userIds));

  return uniqueUserIds;
};

const getSortedUserIdsWithExternalWorkouts = (
  actionItems: ActionItem[],
  isFocusMode: boolean,
) => {
  const excludeUserIds = isFocusMode
    ? actionItems
        .filter((item) => item.type === "workout_review" && item.is_internal)
        .map((item) => item.user_id)
    : [];

  // Step 1: Filter for "workout_review" type
  const filteredItems = actionItems.filter(
    (item) =>
      item.type === "workout_review" &&
      !item.is_internal &&
      !excludeUserIds.includes(item.user_id),
  );

  // Step 2: Sort by date_created in ascending order
  const sortedItems = filteredItems.sort((a, b) =>
    (a.date_created as string).localeCompare(b.date_created as string),
  );

  // Step 3: Map to get user_ids
  const userIds = sortedItems.map((item) => item.user_id);

  // Step 4: Remove duplicates
  const uniqueUserIds = Array.from(new Set(userIds));

  return uniqueUserIds;
};

const getSortedUserIdsWithMessages = (
  actionItems: ActionItem[],
  isFocusMode: boolean,
) => {
  const excludeUserIds = isFocusMode
    ? actionItems
        .filter((item) => item.type === "workout_review")
        .map((item) => item.user_id)
    : [];

  // Step 1: Filter for "workout_review" type
  const filteredItems = actionItems.filter(
    (item) => item.type === "dm" && !excludeUserIds.includes(item.user_id),
  );

  // Step 2: Sort by date_created in ascending order
  const sortedItems = filteredItems.sort((a, b) =>
    (a.date_created as string).localeCompare(b.date_created as string),
  );

  // Step 3: Map to get user_ids
  const userIds = sortedItems.map((item) => item.user_id);

  // Step 4: Remove duplicates
  const uniqueUserIds = Array.from(new Set(userIds));

  return uniqueUserIds;
};

const getSortedUserIdsUnmessaged = (
  clients: Client[],
  isFocusMode: boolean,
) => {
  const filteredClients = isFocusMode
    ? clients.filter(
        (client) =>
          notCheckedInToday(client) && !client.account.dashboard.is_hidden,
      )
    : clients.filter((client) => !client.account.dashboard.is_hidden);

  // Step 2: Sort by last message oldest to newest
  const sortedClients = filteredClients.sort((a, b) => {
    const aLastMessageDate = a.last_trainer_message_date as
      | string
      | null
      | undefined;
    const bLastMessageDate = b.last_trainer_message_date as
      | string
      | null
      | undefined;

    if (aLastMessageDate && bLastMessageDate) {
      return aLastMessageDate.localeCompare(bLastMessageDate);
    } else if (aLastMessageDate) {
      return 1;
    } else if (bLastMessageDate) {
      return -1;
    } else {
      return a.full_name.localeCompare(b.full_name);
    }
  });

  // Step 3: Map to get user_ids
  const userIds = sortedClients.map((client) => client.user_id);

  // Step 4: Remove duplicates
  const uniqueUserIds = Array.from(new Set(userIds));

  return uniqueUserIds;
};

const getSortedUserIdsWithQuestions = (actionItems: ActionItem[]) => {
  // Step 1: Filter for "workout_review" type
  const filteredItems = actionItems.filter(
    (item) => item.type === "respond" && item.respond_type === "question",
  );

  // Step 2: Sort by date_created in ascending order
  const sortedItems = filteredItems.sort((a, b) =>
    (a.date_created as string).localeCompare(b.date_created as string),
  );

  // Step 3: Map to get user_ids
  const userIds = sortedItems.map((item) => item.user_id);

  // Step 4: Remove duplicates
  const uniqueUserIds = Array.from(new Set(userIds));

  return uniqueUserIds;
};

const getSortedUserIdsWithMissedWorkouts = (
  actionItems: ActionItem[],
  isFocusMode: boolean,
) => {
  const excludeUserIds = isFocusMode
    ? actionItems
        .filter((item) => item.type === "workout_review" || item.type === "dm")
        .map((item) => item.user_id)
    : [];

  // Step 1: Filter for "workout_review" type
  const filteredItems = actionItems.filter(
    (item) =>
      item.type === "missed_workout" && !excludeUserIds.includes(item.user_id),
  );

  // Step 2: Sort by date_created in ascending order
  const sortedItems = filteredItems.sort((a, b) =>
    (a.date_created as string).localeCompare(b.date_created as string),
  );

  // Step 3: Map to get user_ids
  const userIds = sortedItems.map((item) => item.user_id);

  // Step 4: Remove duplicates
  const uniqueUserIds = Array.from(new Set(userIds));

  return uniqueUserIds;
};

const getSortedUserIdsNew = (actionItems: ActionItem[]) => {
  // Step 1: Filter for "workout_review" type
  const filteredItems = actionItems.filter(
    (item) =>
      item.type === "new_client" ||
      item.type === "client_returned_from_cancel" ||
      item.type === "client_returned_from_pause" ||
      item.type === "switched_coach_to_you" ||
      item.type === "client_unhidden",
  );

  // Step 2: Sort by date_created in ascending order
  const sortedItems = filteredItems.sort((a, b) =>
    (a.date_created as string).localeCompare(b.date_created as string),
  );

  // Step 3: Map to get user_ids
  const userIds = sortedItems.map((item) => item.user_id);

  // Step 4: Remove duplicates
  const uniqueUserIds = Array.from(new Set(userIds));

  return uniqueUserIds;
};

const getSortedUserIdsBilling = (actionItems: ActionItem[]) => {
  // Step 1: Filter for "workout_review" type
  const filteredItems = actionItems.filter(
    (item) =>
      item.type === "user_requested_cancelation" ||
      item.type === "user_requested_pause",
  );

  // Step 2: Sort by date_created in ascending order
  const sortedItems = filteredItems.sort((a, b) =>
    (a.date_created as string).localeCompare(b.date_created as string),
  );

  // Step 3: Map to get user_ids
  const userIds = sortedItems.map((item) => item.user_id);

  // Step 4: Remove duplicates
  const uniqueUserIds = Array.from(new Set(userIds));

  return uniqueUserIds;
};

const getSortedUserIdsSaveForLater = (actionItems: ActionItem[]) => {
  // Step 1: Filter for "workout_review" type
  const filteredItems = actionItems.filter((item) => item.type === "custom");

  // Get an array of user_ids from the fitlered items and order it by item.content.custom_type
  const sortedItems = filteredItems.sort((a, b) => {
    const aType = a.content?.custom_type ?? "other";
    const bType = b.content?.custom_type ?? "other";

    if (aType === bType) {
      return 0;
    } else if (aType === "priority") {
      return -1;
    } else if (bType === "priority") {
      return 1;
    } else if (aType === "workout") {
      return -1;
    } else if (bType === "workout") {
      return 1;
    } else if (aType === "video") {
      return -1;
    } else if (bType === "video") {
      return 1;
    } else if (aType === "message") {
      return -1;
    } else if (bType === "message") {
      return 1;
    } else if (aType === "calendar") {
      return -1;
    } else if (bType === "calendar") {
      return 1;
    } else {
      return 0;
    }
  });

  // Step 3: Map to get user_ids
  const userIds = sortedItems.map((item) => item.user_id);

  // Step 4: Remove duplicates
  const uniqueUserIds = Array.from(new Set(userIds));

  return uniqueUserIds;
};

type CheckInModeItem = {
  mode: CheckInMode;
  label: (options: { count: number; focusMode: boolean }) => string;
  tooltip?: ((focusMode: boolean) => string | undefined) | string;
  icon: React.ReactNode;
};

const checkInModes: CheckInModeItem[] = [
  {
    mode: "save_for_later",
    label: ({ count }) => `Later (${count})`,
    icon: <BookmarkRoundedIcon />,
  },
  {
    mode: "workouts_internal_feedback",
    label: ({ count }) => `Workouts with feedback (${count})`,
    icon: <FitnessCenterRoundedIcon />,
  },
  {
    mode: "workouts_internal_no_feedback",
    label: ({ count }) => `Workouts without feedback (${count})`,
    icon: <FitnessCenterRoundedIcon />,
  },
  {
    mode: "workouts_external",
    label: ({ count, focusMode }) =>
      focusMode
        ? `Only external workouts (${count})`
        : `External workouts (${count})`,
    icon: <FitnessCenterRoundedIcon />,
    tooltip: (focusMode) =>
      focusMode ? "Excludes clients with trainwell workouts" : "",
  },
  {
    mode: "questions",
    label: ({ count }) => `Questions (${count})`,
    icon: <QuestionMarkRoundedIcon />,
  },
  {
    mode: "messages",
    label: ({ count, focusMode }) =>
      `${focusMode ? "Only DMs" : "DMs"} (${count})`,
    icon: <ChatBubbleRoundedIcon />,
    tooltip: (focusMode) =>
      `Texts, images, and videos${focusMode ? ". Excludes clients with workouts" : ""}`,
  },
  {
    mode: "new_clients",
    label: ({ count }) => `New clients (${count})`,
    icon: <FiberNewOutlinedIcon />,
    tooltip:
      "New, transferred to you, returned from cancel, returned from pause",
  },
  {
    mode: "trial",
    label: ({ count }) => `Trial clients (${count})`,
    icon: <EmojiPeopleRoundedIcon />,
  },
  {
    mode: "workouts_missed",
    label: ({ count, focusMode }) =>
      `${focusMode ? "Only missed workouts" : "Missed workouts"} (${count})`,
    icon: <CropFreeRoundedIcon />,
    tooltip: (focusMode) =>
      focusMode ? "Excludes clients with DMs or workouts" : "",
  },
  {
    mode: "habits_feedback",
    label: ({ count }) => `Habits with feedback (${count})`,
    icon: <LoopRoundedIcon />,
  },
  {
    mode: "missed_habits",
    label: ({ count }) => `Missed habits (${count})`,
    tooltip: "You can turn on/off missed habit tracking for individual clients",
    icon: <LoopRoundedIcon />,
  },
  {
    mode: "unmessaged",
    label: ({ count, focusMode }) =>
      `${focusMode ? "Unmessaged & not checked in" : "Unmessaged"} (${count})`,
    icon: <ChatBubbleOutlineRoundedIcon />,
    tooltip: "Sorts clients by unmessaged and then oldest message from you",
  },
  {
    mode: "billing_changes",
    label: ({ count }) => `Billing changes (${count})`,
    icon: <ReceiptRoundedIcon />,
    tooltip: "Requests to pause/cancel",
  },
  {
    mode: "vip",
    label: ({ count }) => `VIPs (${count})`,
    icon: <StarRoundedIcon />,
  },
  {
    mode: "planned_workout",
    label: ({ count }) => `Planned workout (${count})`,
    icon: <AccessTimeRoundedIcon />,
  },
  {
    mode: "everyone",
    label: ({ count }) => `Not checked in today (${count})`,
    icon: <WavingHandRoundedIcon />,
  },
  {
    mode: "nothing_new",
    label: ({ count }) => `Nothing new (${count})`,
    icon: <BedtimeRoundedIcon />,
    tooltip: "Excludes clients who you checked in on today",
  },
  {
    mode: "custom",
    label: () => `Custom`,
    icon: <ConstructionRoundedIcon />,
  },
  {
    mode: "hidden",
    label: ({ count }) => `Hidden (${count})`,
    icon: <VisibilityOffRoundedIcon />,
  },
];

function EmptyDroppable() {
  const ref = useRef(null);
  const [isDraggedOver, setIsDraggedOver] = useState(false);

  useEffect(() => {
    const el = ref.current;
    if (!el) {
      return;
    }

    return dropTargetForElements({
      element: el,
      getData: () => ({ type: "empty_check_in_mode" }),
      onDragEnter: () => setIsDraggedOver(true),
      onDragLeave: () => setIsDraggedOver(false),
      onDrop: () => setIsDraggedOver(false),
      canDrop({ source }) {
        return source.data.type === "check_in_mode";
      },
    });
  }, []);

  return (
    <div ref={ref}>
      <Box
        sx={{
          p: 1,
          display: "flex",
          borderStyle: "dashed",
          borderWidth: "2px",
          borderRadius: 1,
          borderColor: (theme) =>
            isDraggedOver ? theme.palette.primary.main : theme.palette.divider,
          backgroundColor: (theme) =>
            isDraggedOver
              ? alpha(theme.palette.primary.main, 0.2)
              : theme.palette.background.default,
          justifyContent: "center",
          flexDirection: "column",
          alignItems: "center",
          transitionProperty: "border-color, background-color",
          transitionTimingFunction: "cubic-bezier(0.15, 1.0, 0.3, 1.0)",
          transitionDuration: "350ms",
        }}
      >
        <AddRoundedIcon
          sx={{
            color: (theme) =>
              isDraggedOver
                ? theme.palette.primary.main
                : theme.palette.text.secondary,
            transitionProperty: "color",
            transitionTimingFunction: "cubic-bezier(0.15, 1.0, 0.3, 1.0)",
            transitionDuration: "350ms",
          }}
        />
        <Typography
          sx={{
            color: (theme) =>
              isDraggedOver
                ? theme.palette.primary.main
                : theme.palette.text.secondary,
            transitionProperty: "color",
            transitionTimingFunction: "cubic-bezier(0.15, 1.0, 0.3, 1.0)",
            transitionDuration: "350ms",
          }}
        >
          Drop to hide a check in mode
        </Typography>
      </Box>
    </div>
  );
}
