import AccessibilityNewRoundedIcon from "@mui/icons-material/AccessibilityNewRounded";
import FastForwardRoundedIcon from "@mui/icons-material/FastForwardRounded";
import type { SxProps, Theme } from "@mui/material";
import { Stack, Tooltip, useTheme } from "@mui/material";
import type { ExerciseSource } from "@trainwell/types";
import { exerciseMap } from "src/lib/exercises";

type Props = {
  exerciseMasterId: string;
  sx?: SxProps<Theme>;
};

export default function TrackingStatus({ exerciseMasterId, sx = [] }: Props) {
  const theme = useTheme();
  let color = theme.palette.warning.main;
  let tip = "Manually ended";
  let symbol = "manual";

  let rom = false;
  let pace = false;

  if (exerciseMap && exerciseMasterId in exerciseMap) {
    const exerciseSource = exerciseMap[exerciseMasterId] as ExerciseSource;

    rom = exerciseSource.has_rom_realtime;
    pace = exerciseSource.has_pacing_realtime;

    const isTimed =
      exerciseSource.is_recovery ||
      exerciseSource.is_bodyweight_timed ||
      exerciseSource.is_warmup ||
      exerciseSource.is_weighted_timed;

    if (exerciseSource.is_currently_tracked && !isTimed) {
      color = theme.palette.success.main;
      tip = "Auto advanced";
      symbol = "watch";
    } else if (isTimed) {
      color = theme.palette.success.main;
      tip = "Timed";
      symbol = "timer";
    } else if (exerciseSource.is_cardio) {
      color = theme.palette.success.main;
      tip = "Manually started and ended cardio";
      symbol = "manual";
    }
  }

  return (
    <Stack
      direction={"row"}
      spacing={0}
      sx={[
        {
          alignItems: "center",
        },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    >
      {/* <Tooltip title={tip} disableInteractive>
        {symbol === "watch" ? (
          <WatchRoundedIcon fontSize="inherit" sx={{ color: color }} />
        ) : symbol === "timer" ? (
          <AccessTimeRoundedIcon fontSize="inherit" sx={{ color: color }} />
        ) : (
          <TouchAppRoundedIcon fontSize="inherit" sx={{ color: color }} />
        )}
      </Tooltip> */}
      {pace === true && (
        <Tooltip title={"This exercise has pacing feedback"} disableInteractive>
          <FastForwardRoundedIcon fontSize="inherit" sx={{ color: color }} />
        </Tooltip>
      )}
      {rom === true && (
        <Tooltip title={"This exercise has ROM feedback"} disableInteractive>
          <AccessibilityNewRoundedIcon
            fontSize="inherit"
            sx={{ color: color }}
          />
        </Tooltip>
      )}
    </Stack>
  );
}
