import RestaurantRoundedIcon from "@mui/icons-material/RestaurantRounded";
import { Box, Dialog, DialogContent, Stack, Typography } from "@mui/material";
import { getSentimentEmoji } from "@trainwell/features";
import type { MessageMealLogSentiment } from "@trainwell/types";
import { useState } from "react";
import { DialogTitleWithClose } from "src/components/misc/DialogTitleWithClose";

type Props = {
  message: MessageMealLogSentiment;
  combineBottom: boolean;
};

export function MealLogSentimentMessage({ message, combineBottom }: Props) {
  const [imageDialogOpen, setImageDialogOpen] = useState(false);

  return (
    <>
      <Box
        sx={{
          maxWidth: "300px",
          borderRadius: `10px 10px 10px ${!combineBottom ? "0px" : "10px"}`,
          overflow: "hidden",
          backgroundColor: (theme) => theme.palette.backgroundSecondary.main,
          p: 1,
          display: "flex",
          alignItems: "center",
          minWidth: "200px",
          // border: 1,
          // borderColor: "divider",
        }}
      >
        <Stack
          direction={"column"}
          useFlexGap
          spacing={1}
          flex={1}
          alignItems={"flex-start"}
        >
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <RestaurantRoundedIcon />
            <Typography
              variant="h6"
              sx={{
                color: (theme) => theme.palette.text.primary,
                ml: 1,
              }}
            >
              Meal sentiment
            </Typography>
          </Box>
          <Box sx={{ width: "100%" }}>
            <Typography>
              Client sentiment: {Math.round(message.content.sentiment + 1)} / 5{" "}
              {getSentimentEmoji(message.content.sentiment)}
            </Typography>
          </Box>
          <img
            height={150}
            style={{
              // width: "100%",
              borderRadius: "10px",
              display: "block",
              objectFit: "contain",
            }}
            src={message.content.image_url}
            onClick={() => {
              setImageDialogOpen(true);
            }}
          />
        </Stack>
      </Box>
      <Dialog
        onClose={() => {
          setImageDialogOpen(false);
        }}
        aria-labelledby="client-name"
        open={imageDialogOpen}
      >
        <DialogTitleWithClose
          onClose={() => {
            setImageDialogOpen(false);
          }}
        >
          Image
        </DialogTitleWithClose>
        <DialogContent dividers>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <img
              width={600}
              height={600}
              src={message.content.image_url}
              style={{
                objectFit: "contain",
              }}
              alt="chat media"
            />
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
}
