import { createAsyncThunk } from "@reduxjs/toolkit";
import { api } from "src/lib/trainwellApi";
import type { RootState } from "./store";
import { selectIsGhosting, selectPrimaryTrainer } from "./trainerSlice";

export const logClick = createAsyncThunk(
  "analytics/logClick",
  async (data: { elementName: string }, { getState }) => {
    const { elementName } = data;

    const state = getState() as RootState;

    const isGhosting = selectIsGhosting(state);
    const trainer = selectPrimaryTrainer(state);

    if (isGhosting) {
      return;
    }

    const response = await api.analytics.trackEvent({
      trainerId: trainer?.trainer_id,
      eventType: "click",
      platform: "coach_dash",
      sessionId: "",
      eventContent: {
        element_name: elementName,
      },
    });

    return response;
  },
);
