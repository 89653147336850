import {
  attachClosestEdge,
  extractClosestEdge,
  type Edge,
} from "@atlaskit/pragmatic-drag-and-drop-hitbox/closest-edge";
import { combine } from "@atlaskit/pragmatic-drag-and-drop/combine";
import {
  draggable,
  dropTargetForElements,
} from "@atlaskit/pragmatic-drag-and-drop/element/adapter";
import { pointerOutsideOfPreview } from "@atlaskit/pragmatic-drag-and-drop/element/pointer-outside-of-preview";
import { setCustomNativeDragPreview } from "@atlaskit/pragmatic-drag-and-drop/element/set-custom-native-drag-preview";
import CalendarViewWeekRoundedIcon from "@mui/icons-material/CalendarViewWeekRounded";
import RemoveCircleRoundedIcon from "@mui/icons-material/RemoveCircleRounded";
import {
  Avatar,
  Box,
  Button,
  CardActionArea,
  CircularProgress,
  IconButton,
  Typography,
} from "@mui/material";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { format, parse } from "date-fns";
import { memo, useEffect, useRef, useState } from "react";
import { createPortal } from "react-dom";
import { useNavigate, useParams } from "react-router";
import { DragPreview } from "src/components/common/DragPreview";
import { DropIndicator } from "src/components/common/DropIndicator";
import { useAppDispatch, useAppSelector } from "src/hooks/stateHooks";
import { isHabitDayInPast, isProgramHabit } from "src/lib/habits";
import {
  removeWorkoutFromWeekPlanDay,
  selectClientsWorkoutValidity,
  selectIsClientInCPT2,
  selectWeekPlanById,
  updateHabitWeek,
} from "src/slices/clientSlice";
import { selectPhaseById, selectWorkoutById } from "src/slices/phasesSlice";
import WorkoutPreviewPopover from "../phase-column/WorkoutPreviewPopover";

type DraggableProps = {
  workoutId: string;
  habitWeekId: string;
  index: number;
  weekPlanId: string;
  dayIndex: number;
};

export default function WorkoutTaskCellDraggable({
  workoutId,
  habitWeekId,
  index,
  weekPlanId,
  dayIndex,
}: DraggableProps) {
  const ref = useRef(null);
  const [dragging, setDragging] = useState<boolean>(false);
  const [closestEdge, setClosestEdge] = useState<Edge | null>(null);
  const [previewContainer, setPreviewContainer] = useState<HTMLElement | null>(
    null,
  );
  const workout = useAppSelector((state) =>
    selectWorkoutById(state, workoutId ?? ""),
  );

  const isInPast = useAppSelector((state) =>
    isHabitDayInPast(selectWeekPlanById(state, weekPlanId)!.date, dayIndex),
  );
  const phaseType = useAppSelector(
    (state) =>
      selectPhaseById(
        state,
        selectWorkoutById(state, workoutId ?? "")?.phase_id ?? "",
      )?.type,
  );

  useEffect(() => {
    const element = ref.current;

    if (!element) {
      return;
    }

    const data = {
      type: "workout_task",
      workoutId: workoutId,
      dayIndex: dayIndex,
      weekPlanId: weekPlanId,
      isSource: false,
      habitWeekId: habitWeekId,
      index: index,
      phaseType: phaseType,
    };

    return combine(
      draggable({
        element: element,
        getInitialData: () => data,
        onDragStart: () => setDragging(true),
        onDrop: () => setDragging(false),
        canDrag: () => !isInPast,
        onGenerateDragPreview({ nativeSetDragImage }) {
          setCustomNativeDragPreview({
            nativeSetDragImage,
            getOffset: pointerOutsideOfPreview({
              x: "16px",
              y: "8px",
            }),
            render({ container }) {
              setPreviewContainer(container);
            },
          });
        },
      }),
      dropTargetForElements({
        element,
        canDrop({ source }) {
          return (
            source.data.type === "workout" ||
            source.data.type === "workout_extra" ||
            source.data.type === "workout_task" ||
            source.data.type === "workout_task_past"
          );
        },
        getData({ input }) {
          return attachClosestEdge(data, {
            element,
            input,
            allowedEdges: ["top", "bottom"],
          });
        },
        onDrag({ self, source }) {
          const isSource = source.element === element;
          if (isSource) {
            setClosestEdge(null);
            return;
          }

          const closestEdge = extractClosestEdge(self.data);

          const sourceIndex = source.data.index;
          if (typeof sourceIndex !== "number") {
            setClosestEdge(closestEdge);

            return;
          }

          if (
            source.data.weekPlanId !== weekPlanId ||
            source.data.dayIndex !== dayIndex
          ) {
            setClosestEdge(closestEdge);

            return;
          }

          const isItemBeforeSource = index === sourceIndex - 1;
          const isItemAfterSource = index === sourceIndex + 1;

          const isDropIndicatorHidden =
            (isItemBeforeSource && closestEdge === "bottom") ||
            (isItemAfterSource && closestEdge === "top");

          if (isDropIndicatorHidden) {
            setClosestEdge(null);
            return;
          }

          setClosestEdge(closestEdge);
        },
        onDragLeave() {
          setClosestEdge(null);
        },
        onDrop() {
          setClosestEdge(null);
        },
      }),
    );
  }, [
    index,
    isInPast,
    phaseType,
    workoutId,
    weekPlanId,
    dayIndex,
    habitWeekId,
  ]);

  return (
    <>
      <div
        style={{
          position: "relative",
        }}
      >
        <div
          ref={ref}
          style={{
            opacity: dragging ? 0.5 : 1,
          }}
        >
          <WorkoutTaskCell
            workoutId={workoutId}
            weekPlanId={weekPlanId}
            dayIndex={dayIndex}
          />
        </div>
        {closestEdge && <DropIndicator edge={closestEdge} gap="0px" />}
      </div>
      {previewContainer
        ? createPortal(
            <DragPreview text={workout?.name ?? "Workout"} />,
            previewContainer,
          )
        : null}
    </>
  );
}

type Props = {
  workoutId: string;
  weekPlanId: string;
  dayIndex: number;
};

const WorkoutTaskCell = memo(function WorkoutTaskCell({
  workoutId,
  weekPlanId,
  dayIndex,
}: Props) {
  const { userId } = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const weekPlanDate = useAppSelector(
    (state) => selectWeekPlanById(state, weekPlanId)?.date,
  );
  const workout = useAppSelector((state) =>
    selectWorkoutById(state, workoutId ?? ""),
  );
  const phase = useAppSelector((state) =>
    selectPhaseById(state, workout?.phase_id ?? ""),
  );
  const workoutValidityStatus = useAppSelector(
    (state) => selectClientsWorkoutValidity(state, workoutId ?? "")?.status,
  );
  const habitWeek = useAppSelector((state) =>
    weekPlanId
      ? selectWeekPlanById(state, weekPlanId).habit_weeks.find(isProgramHabit)
      : null,
  );
  const isInCpt2Test = useAppSelector(selectIsClientInCPT2);
  const defaultTime = useAppSelector(
    (state) =>
      (state.client.client!.workout_times
        ? state.client.client!.workout_times.at(dayIndex)?.time
        : null) || null,
  );

  if (!weekPlanDate) {
    return <Typography>Error habit week plan</Typography>;
  }

  const isInPast = isHabitDayInPast(weekPlanDate, dayIndex);

  const time =
    dayIndex !== undefined
      ? habitWeek?.workout_metadata
          ?.at(dayIndex)
          ?.find((metadata) => metadata.workout_id === workoutId)
          ?.target_start_time
      : undefined;

  if (!workout) {
    return (
      <Box sx={{ display: "flex", alignItems: "center", p: 0.5 }}>
        <CircularProgress size={14} />
        {!isInPast && (
          <IconButton
            size="small"
            color="error"
            onClick={(event) => {
              event.stopPropagation();

              dispatch(
                removeWorkoutFromWeekPlanDay({
                  weekPlanId: weekPlanId!,
                  dayIndex: dayIndex!,
                  workoutId: workoutId,
                }),
              );
            }}
            onMouseDown={(event) => {
              event.stopPropagation();
            }}
          >
            <RemoveCircleRoundedIcon sx={{ fontSize: "12px" }} />
          </IconButton>
        )}
      </Box>
    );
  }

  return (
    <>
      <CardActionArea
        onClick={() => {
          navigate(`/clients/${userId}/workouts/${workoutId}`);
        }}
        onContextMenu={(event) => {
          event.preventDefault();

          setAnchorEl(event.currentTarget);
        }}
        sx={{
          backgroundColor: (theme) =>
            !workout.metadata.date_last_opened ||
            workoutValidityStatus === "error"
              ? theme.palette.errorSurface.main
              : undefined,
          p: 0.5,
        }}
      >
        {phase?.type === "multiple" && (
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Avatar
              sx={{
                backgroundColor: (theme) => theme.palette.success.main,
                mr: 0.25,
                width: 8,
                height: 8,
                borderRadius: "2px",
                opacity: 0.75,
              }}
            >
              <CalendarViewWeekRoundedIcon
                sx={{
                  fontSize: 6,
                }}
              />
            </Avatar>
            <Typography
              sx={{
                fontSize: 9,
                lineHeight: 1,
                color: (theme) => theme.palette.text.secondary,
              }}
            >
              {phase.name}
            </Typography>
          </Box>
        )}
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Typography variant="body2" sx={{ flex: 1 }}>
            {workout.name}
          </Typography>
          {!isInPast && (
            <IconButton
              size="small"
              color="error"
              onClick={(event) => {
                event.stopPropagation();

                dispatch(
                  removeWorkoutFromWeekPlanDay({
                    weekPlanId: weekPlanId!,
                    dayIndex: dayIndex!,
                    workoutId: workoutId,
                  }),
                );
              }}
              onMouseDown={(event) => {
                event.stopPropagation();
              }}
              sx={{
                ml: 0.25,
              }}
            >
              <RemoveCircleRoundedIcon sx={{ fontSize: "12px" }} />
            </IconButton>
          )}
        </Box>
        {isInCpt2Test && (
          <Box
            onClick={(evnet) => {
              evnet.stopPropagation();
            }}
            onMouseDown={(evnet) => {
              evnet.stopPropagation();
            }}
          >
            <TimePicker
              value={time ? parse(time, "HH:mm", new Date()) : null}
              onChange={(newValue) => {
                if (
                  !habitWeek ||
                  dayIndex === undefined ||
                  !workoutId ||
                  !weekPlanId
                ) {
                  return;
                }

                if (!newValue) {
                  const newMetadata = structuredClone(
                    habitWeek.workout_metadata ?? [
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                    ],
                  );

                  if (!newMetadata[dayIndex] || !newMetadata[dayIndex].length) {
                    // Already empty
                  } else {
                    newMetadata[dayIndex] = newMetadata[dayIndex]!.filter(
                      (metadata) => metadata.workout_id !== workoutId,
                    );
                  }

                  dispatch(
                    updateHabitWeek({
                      habitWeek: {
                        id: habitWeek.id,
                        workout_metadata: newMetadata,
                      },
                      planId: weekPlanId,
                    }),
                  );
                } else {
                  const newTime = format(newValue, "HH:mm");

                  const newMetadata = structuredClone(
                    habitWeek.workout_metadata ?? [
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                    ],
                  );

                  if (!newMetadata[dayIndex] || !newMetadata[dayIndex].length) {
                    newMetadata[dayIndex] = [
                      {
                        workout_id: workoutId,
                        target_start_time: newTime,
                      },
                    ];
                  } else {
                    newMetadata[dayIndex] = newMetadata[dayIndex]!.map(
                      (metadata) => {
                        if (metadata.workout_id === workoutId) {
                          return {
                            ...metadata,
                            target_start_time: newTime,
                          };
                        }
                        return metadata;
                      },
                    );
                  }

                  dispatch(
                    updateHabitWeek({
                      habitWeek: {
                        id: habitWeek.id,
                        workout_metadata: newMetadata,
                      },
                      planId: weekPlanId,
                    }),
                  );
                }
              }}
              slotProps={{
                textField: {
                  size: "small",
                  placeholder: "Start time",
                  error: !time,
                },
                openPickerButton: {
                  size: "small",
                },
                openPickerIcon: {
                  sx: {
                    fontSize: 18,
                  },
                },
                inputAdornment: {
                  sx: {
                    ml: 0,
                  },
                },
              }}
              sx={{
                mt: 1,
                "& .MuiInputBase-root": {
                  pr: 0.5,
                },
                "& .MuiInputBase-input": {
                  pl: 0.5,
                  py: 0.5,
                },
              }}
            />
            {defaultTime && !time && (
              <Button
                size="small"
                variant="text"
                fullWidth
                onClick={() => {
                  if (
                    !habitWeek ||
                    dayIndex === undefined ||
                    !workoutId ||
                    !weekPlanId
                  ) {
                    return;
                  }

                  const newMetadata = structuredClone(
                    habitWeek.workout_metadata ?? [
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                    ],
                  );

                  if (!newMetadata[dayIndex] || !newMetadata[dayIndex].length) {
                    newMetadata[dayIndex] = [
                      {
                        workout_id: workoutId,
                        target_start_time: defaultTime,
                      },
                    ];
                  } else {
                    newMetadata[dayIndex] = newMetadata[dayIndex]!.map(
                      (metadata) => {
                        if (metadata.workout_id === workoutId) {
                          return {
                            ...metadata,
                            target_start_time: defaultTime,
                          };
                        }
                        return metadata;
                      },
                    );
                  }

                  dispatch(
                    updateHabitWeek({
                      habitWeek: {
                        id: habitWeek.id,
                        workout_metadata: newMetadata,
                      },
                      planId: weekPlanId,
                    }),
                  );
                }}
              >
                Default time
              </Button>
            )}
          </Box>
        )}
      </CardActionArea>
      {anchorEl && (
        <WorkoutPreviewPopover
          anchorEl={anchorEl}
          workoutId={workoutId}
          onClose={() => {
            setAnchorEl(null);
          }}
        />
      )}
    </>
  );
});
