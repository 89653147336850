import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { getToken } from "firebase/messaging";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { FIREBASE_PUSH_KEY } from "src/config/config";
import { useAppDispatch, useAppSelector } from "src/hooks/stateHooks";
import usePwa from "src/hooks/usePwa";
import { firebaseMessaging } from "src/lib/firebase";
import { toggleFullChatMode } from "src/slices/chatSlice";
import {
  selectIsGhosting,
  selectPrimaryTrainer,
} from "src/slices/trainerSlice";
import { updateTrainer } from "src/slices/trainersSlice";

export default function PwaHelper() {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const isPhone = useMediaQuery(theme.breakpoints.down("sm"));
  const pwa = useAppSelector((state) => state.app.pwa);
  const trainer = useAppSelector(selectPrimaryTrainer);
  const { enqueueSnackbar } = useSnackbar();
  const isGhosting = useAppSelector(selectIsGhosting);
  const [asked, setAsked] = useState(
    JSON.parse(
      localStorage.getItem("asked_about_notifications") ?? "false",
    ) as boolean,
  );
  const isChatFullscreen = useAppSelector(
    (state) => state.chat.isChatFullscreen,
  );
  const navigate = useNavigate();

  useEffect(() => {
    if (isPhone && !isChatFullscreen) {
      dispatch(toggleFullChatMode());
      navigate("/chat?pwa=true");
    } else if (!isPhone && isChatFullscreen) {
      dispatch(toggleFullChatMode());
      navigate("/");
    }
  }, [isPhone, isChatFullscreen, dispatch]);

  usePwa();

  useEffect(() => {
    if (pwa && trainer?.trainer_id) {
      firebaseMessaging.then((messaging) => {
        if (!messaging) {
          return;
        }

        getToken(messaging, {
          vapidKey: FIREBASE_PUSH_KEY,
        })
          .then((currentToken) => {
            if (currentToken) {
              // Send the token to your server and update the UI if necessary
              console.log("Push notifications: found token", currentToken);

              if (!isGhosting) {
                dispatch(
                  updateTrainer({
                    trainer_id: trainer.trainer_id!,
                    fcm_token: currentToken,
                  }),
                );
              }
            } else {
              // Show permission request UI
              console.log(
                "Push notifications: No registration token available. Request permission to generate one.",
              );
            }
          })
          .catch((err) => {
            console.log(
              "Push notifications: An error occurred while retrieving token. ",
              err,
            );
            // ...
          });
      });
    }
  }, [pwa, trainer?.trainer_id]);

  if (isPhone && !pwa) {
    return (
      <Dialog open={true}>
        <DialogTitle>Download as a web app</DialogTitle>
        <DialogContent>
          <Typography>
            The trainer dash works better as a web app! Press the share button,
            press &quot;Add to Home Screen&quot;, then then press
            &quot;Add&quot;. That&apos;s it!
          </Typography>
        </DialogContent>
        <img
          src={"/assets/install-web-app.jpg"}
          alt={"how to install as a web app"}
          width={"100%"}
          style={{
            objectFit: "contain",
          }}
        />
      </Dialog>
    );
  } else if (pwa && trainer?.settings.notifications.enabled && !asked) {
    return (
      <Dialog open={true}>
        <DialogTitle>Enable notifications?</DialogTitle>
        <DialogContent>
          <Typography>
            You can change your notifications preferences in the coach dash.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            onClick={() => {
              dispatch(
                updateTrainer({
                  trainer_id: trainer.trainer_id,
                  // @ts-expect-error
                  "settings.notifications.enabled": false,
                }),
              );

              localStorage.setItem("asked_about_notifications", "true");
            }}
          >
            Disable
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              console.log("Push notifications: requesting permission");

              localStorage.setItem("asked_about_notifications", "true");
              setAsked(true);

              Notification.requestPermission().then((permission) => {
                if (permission === "granted") {
                  enqueueSnackbar(`Notifications enabled`, {
                    variant: "success",
                  });
                } else {
                  enqueueSnackbar(`Notifications disabled`, {
                    variant: "error",
                  });
                }

                if (permission === "granted") {
                  console.log("Push notifications: permission granted");

                  firebaseMessaging.then((messaging) => {
                    if (!messaging) {
                      return;
                    }

                    getToken(messaging, {
                      vapidKey: FIREBASE_PUSH_KEY,
                    })
                      .then((currentToken) => {
                        if (currentToken) {
                          // Send the token to your server and update the UI if necessary
                          console.log(
                            "Push notifications: found token",
                            currentToken,
                          );

                          if (!isGhosting) {
                            dispatch(
                              updateTrainer({
                                trainer_id: trainer.trainer_id!,
                                fcm_token: currentToken,
                              }),
                            );
                          }
                        } else {
                          // Show permission request UI
                          console.log(
                            "Push notifications: No registration token available. Request permission to generate one.",
                          );
                        }
                      })
                      .catch((err) => {
                        console.log(
                          "Push notifications: An error occurred while retrieving token. ",
                          err,
                        );
                        // ...
                      });
                  });
                }
              });
            }}
          >
            Yes, enable
          </Button>
        </DialogActions>
      </Dialog>
    );
  } else {
    return null;
  }
}
