import type { PayloadAction } from "@reduxjs/toolkit";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import type { Client } from "@trainwell/types";
import { api } from "src/lib/trainwellApi";
import type { RootState } from "src/slices/store";

export const searchCompanyClients = createAsyncThunk(
  "allClients/searchCompanyClients",
  async (data: { state?: string; query: string; isVip?: boolean }) => {
    const response = await api.clients.search(data);

    return response;
  },
);

// Define a type for the slice state
interface AllClientsState {
  clients: Awaited<ReturnType<(typeof api)["clients"]["search"]>>;
  status: "idle" | "loading" | "succeeded" | "failed";
  error: string | undefined;
}

// Define the initial state using that type
const initialState: AllClientsState = {
  clients: [],
  status: "idle",
  error: undefined,
};

export const allClientsSlice = createSlice({
  name: "allClients",
  initialState,
  reducers: {
    resetAllClients: () => initialState,
    updateAllClients: (state, action: PayloadAction<Client>) => {
      const newClient = action.payload;

      const index = state.clients.findIndex(
        (client) => client.user_id === newClient.user_id,
      );

      if (index !== -1) {
        state.clients[index] = newClient;
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(searchCompanyClients.pending, (state) => {
      state.status = "loading";
    });
    builder.addCase(searchCompanyClients.fulfilled, (state, action) => {
      console.log("Redux: Got all clients");
      state.status = "succeeded";

      const clients = action.payload;

      state.clients = clients;
    });
    builder.addCase(searchCompanyClients.rejected, (state, action) => {
      state.status = "failed";
      state.error = action.error.message;
    });
  },
});

// Action creators are generated for each case reducer function
export const { resetAllClients, updateAllClients } = allClientsSlice.actions;

export default allClientsSlice.reducer;

export const selectClientById = (state: RootState, userId: string) =>
  state.allClients.clients.find((client) => client.user_id === userId);
