import { TextField, type TextFieldProps } from "@mui/material";
import debounce from "lodash-es/debounce";
import { useCallback, useState } from "react";

type Props = {
  label?: string;
  defaultValue?: string;
  helperText?: string;
  onSave: (newValue: string, blured?: boolean) => void;
  name?: string;
  disableGutter?: boolean;
  onlySaveOnBlur?: boolean;
  blurOnEnter?: boolean;
  sanitizeValue?: (value: string) => string;
} & TextFieldProps;

export default function AutosaveTextField({
  label,
  helperText,
  defaultValue,
  onSave,
  name,
  disableGutter,
  onlySaveOnBlur,
  blurOnEnter,
  sanitizeValue,
  onChange,
  ...textFieldProps
}: Props) {
  const [value, setValue] = useState(defaultValue);
  const [saved, setSaved] = useState(false);

  const debouncedSave = useCallback(
    debounce(async (newValue: string) => {
      await onSave(newValue);
      setSaved(true);
    }, 3000),
    [],
  );

  let displayedHelperText = helperText;

  if (saved) {
    if (displayedHelperText) {
      displayedHelperText += "\nSaved";
    } else {
      displayedHelperText = "Saved";
    }
  }

  const { onBlur, ...textFieldPropsToApply } = textFieldProps;

  return (
    <TextField
      variant="outlined"
      fullWidth
      label={label}
      multiline={true}
      value={value}
      onChange={(event) => {
        if (onlySaveOnBlur) {
          return;
        }

        const sanitizedValue = sanitizeValue
          ? sanitizeValue(event.target.value)
          : event.target.value;

        setValue(sanitizedValue);
        debouncedSave(sanitizedValue);

        if (saved) {
          setSaved(false);
        }

        onChange?.(event);
      }}
      onBlur={(event) => {
        const sanitizedValue = sanitizeValue
          ? sanitizeValue(event.target.value)
          : event.target.value;

        onSave(sanitizedValue, true);

        onBlur?.(event);
      }}
      onKeyDown={(event) => {
        if (blurOnEnter && event.key === "Enter") {
          event.stopPropagation();
          event.preventDefault();

          (event.target as HTMLInputElement).blur();
        }
      }}
      helperText={displayedHelperText}
      sx={{
        whiteSpace: "pre-wrap",
        mb: disableGutter ? undefined : 4,
      }}
      name={name}
      {...textFieldPropsToApply}
    />
  );
}
